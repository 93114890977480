import { EventEmitter } from "events";
import dispatcher from "../dispatcher/dispatcher";
import logger from "./../components/Logging/Logger";
import websocketCom from "../webcom/WebsocketCom";

class UserServicesStore extends EventEmitter {
  constructor() {
    super();
    this.LOG_ORIGIN = "UserServicesStore";
    this.blueLabel = ["trace", "debug", "info", "warn"];
    this.redLabel = ["error", "fatal"];

    this._resetStore();

    this._resetStore = this._resetStore.bind(this);
    this._resetServices = this._resetServices.bind(this);
    this._processUserMessage = this._processUserMessage.bind(this);
    this.getServiceLogLevel = this.getServiceLogLevel.bind(this);
    this._updateServiceNumberLogs = this._updateServiceNumberLogs.bind(this);
    this._processSessionLifecycleActions = this._processSessionLifecycleActions.bind(
      this
    );
    logger.log(this.LOG_ORIGIN, "INFO", "Initialized.", "");
  }

  _resetStore() {     // carried out at store creation time and service close time
    this._resetServices();

    this.logsStore = new Map();
    this.logsStore.set("seqNumber", "0");
    this.logRegStore = {};
    this.serviceNumberLogs = new Map();
    //this.logPerService = new Map();
  }

  _resetServices() {
    this.servicesList = {};
    this.serviceDetailsList = new Map();
  }


  fullLogRefresh() {
    this.clearLogs();
    const chatMsg = "getlog once";
    websocketCom.sendUserMessage(chatMsg);
  }

  getServiceList() {
    return this.servicesList;
  }

  // check if a given service name is in the database
  //isServiceName(service) {
  //  if (service in this.servicesList) return true;
  //  else return false;
  //}

  getTemplateServices() {
    return this.servicesList;
  }

  // get all logs in the database
  getLogs() {
    return this.logsStore;
  }

  clearLogs() {
    this.logsStore = new Map();
    this.logsStore.set("seqNumber", "0");
    this.serviceNumberLogs = new Map();
  }

  getNumberLogs(service) {
    let label = "blue";
    let number = 0;

    // check if service has entry in number logs db
    let value = [];
    if (service in this.serviceNumberLogs) {
      value = this.serviceNumberLogs[service];
      if (value[1] > 0) label = "red";
      number = value[0] + value[1];
    } else {
      //no entry --> return zero log entries
      label = "";
      number = 0;
    }
    return [label, number, value[0], value[1] ];
  }

  getServiceLogLevel(service) {
    let result = null;
    for (let loopService in this.logRegStore) {
      if (loopService === service) result = this.logRegStore[loopService];
    }
    if (result) return result;
    else return "off";
  }

  _updateServiceList(msg) {
    const newServiceList = JSON.parse(msg.payload);
    //this._resetStore();
    this._resetServices();
    this.servicesList = newServiceList;
    this.emit("ChangeEvent_NewServiceList");
  }

  _updateUserData(msg) {
    switch (msg.cmd) {
      case "added":
      case "modified":
      case "set":
        //const data = msg.value.split(":");
        //this.serviceDetailsList.get(msg.service).set(data[0], data[1]);
        this.serviceDetailsList.set(msg.key, msg.value);
        break;

      case "deleted":
        if (this.serviceDetailsList.has(msg.key))
          this.serviceDetailsList.delete(msg.key);
        break;
      default: {
      }
    }
  }

  _updateLogStore(msg) {
    // get sequence number
    const seqNumber = this.logsStore.get("seqNumber");
    const newSeqNumber = (parseInt(seqNumber, 10) + 1).toString();
    this.logsStore.set("seqNumber", newSeqNumber);
    // check if date already in store. if not create it.
    if (!this.logsStore.has(msg.date)) {
      this.logsStore.set(msg.date, new Map());
    }
    // add log data
    this.logsStore.get(msg.date).set(newSeqNumber, new Map());
    const pointer = this.logsStore.get(msg.date).get(newSeqNumber);
    pointer.set("service", msg.service);
    pointer.set("so", msg.so);
    pointer.set("uuid", this.servicesList[msg.service]);
    pointer.set("level", msg.log_level);
    pointer.set("time", msg.time);
    pointer.set("data", msg.log);
    //this._updateLogPerService(msg);
    this._updateServiceNumberLogs(msg.service, msg.log_level);
    this.emit("ChangeEvent_UserLogUpdate");
  }

  /*_updateLogPerService(logData) {
    const service = logData.service;
    // check if service is already in key value store
    // if not create entry for service
    if (!this.logPerService.has(service)) {
      this.logPerService.set(service, new Map());
    }
    let serviceLog = this.logPerService.get(service);
    const numberNextEntry = serviceLog.size + 1;
    // create next entry
    serviceLog.set(numberNextEntry, new Map());
    let newLogEntry = serviceLog.get(numberNextEntry);
    newLogEntry.set("service", logData.service);
    newLogEntry.set("so", logData.so);
    newLogEntry.set("uuid", this.servicesList[logData.service]);
    newLogEntry.set("level", logData.log_level);
    newLogEntry.set("time", logData.time);
    newLogEntry.set("data", logData.log);
  }*/

  _updateServiceNumberLogs(service, logLevel) {
    // check if service is already in key value store
    // if not, create entry for service
    if (!(service in this.serviceNumberLogs)) {
      this.serviceNumberLogs[service] = [0, 0];
    }

    if (this.blueLabel.includes(logLevel)) this.serviceNumberLogs[service][0]++;
    if (this.redLabel.includes(logLevel)) this.serviceNumberLogs[service][1]++;
  }

  _updateLogRegStore(msg) {
    this.logRegStore = JSON.parse(msg.payload);
    this.emit("ChangeEvent_LogLevelUpdate");
  }

  _processUserMessage(msg) {
    switch (msg.scope) {
      case "services": {
        this._updateServiceList(msg);
        break;
      }
      case "userdata": {
        this._updateUserData(msg);
        break;
      }
      case "log":
        this._updateLogStore(msg);
        break;
      case "registerlog":
        this._updateLogRegStore(msg);
        break;
      default: {
      }
    }
  }

  _processSessionLifecycleActions(msg) {
    if (msg["status"] === "close") {
      console.log("Reset UserServicesStore.");
      this._resetStore();
    }
  }

  handleActions(action) {
    switch (action.type) {
      case "ACTION_UserMessageReceived": {
        this._processUserMessage(action.msg);
        break;
      }
      case "ACTION_SessionLifecycle": {
        this._processSessionLifecycleActions(action.msg);
        break;
      }
      default: {
      }
    }
  }
}

const userServicesStore = new UserServicesStore();
dispatcher.register(userServicesStore.handleActions.bind(userServicesStore));
export default userServicesStore;
