import React from "react";
import notificationsStore from "./../stores/NotificationsStore";

import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";

const styles = theme => ({
  
  root: {
    background: theme.palette.error.dark
  },
  errorIcon: { fontSize: 25, marginRight: 15 },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

class NotificationSign extends React.Component {
  _isMounted = false;
  constructor() {
    super();

    this.state = { notification: "", open: false };

    this._cE_NewNotification = this._cE_NewNotification.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    notificationsStore.on(
      "ChangeEvent_NewNotification",
      this._cE_NewNotification
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    notificationsStore.removeListener(
      "ChangeEvent_NewNotification",
      this._cE_NewNotification
    );
  }

  _cE_NewNotification() {
    if (this._isMounted) {
      this.setState({
        notification: notificationsStore.getNextNotification()
      });
      this.setState({ open: true });
    }
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            classes: { root: classes.root },
            "aria-describedby": "message-id"
          }}
          message={
            <span id="message-id" className={classes.message}>
              <ErrorIcon className={classes.errorIcon} />
              {this.state.notification.message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(NotificationSign);
