import { EventEmitter } from "events";
import dispatcher from "./../dispatcher/dispatcher";


class UtilityStore extends EventEmitter {

  /*constructor() {
    super();
    this.userStatus = {
      loggedIn: false,
      jid: '',
      name: '',
    }
  }

  getUserStatus() {
    return this.userStatus;
  } */

  handleActions(action) {
    //console.log("UtilityStore received an action", action);
    switch (action.type) {
      case "WINDOW_RESIZE": {
        this.emit("window_size_changed");
        break;
      }
          /*      case "LOGIN_REQUEST": {
        this.emit("login_request", action.id);
        break;
      }
      case "SESSION_START": {
        this.userStatus.loggedIn = true;
        this.userStatus.jid = action.id.jid;

        console.log('UtilityStore : action: ', action.id);
        console.log('UtilityStore : nameShort', action.id.nameShort);
        this.userStatus.name = action.id.nameShort;
        console.log('utilitystore state = ', this.userStatus);
        this.emit("session_start", action);
        break;
      }
      case "LOGOUT_REQUEST": {
        this.emit("logout_request", action);
        break;
      }
      case "LOGOUT_DONE": {
        this.userStatus.loggedIn = false;
        this.userStatus.jid = '';
        this.userStatus.name = '';
        this.emit("logout_done", action);
        break;
      } */
      default: {}
    }
  }
}

const utilityStore = new UtilityStore();
dispatcher.register(utilityStore.handleActions.bind(utilityStore));

export default utilityStore;

