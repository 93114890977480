import React from "react";
import sMS from "./../../../../stores/SessionManagementStore";
import Error from "./../Manage/Error";
import Info from "./../Manage/Info";

// -------------------------------------------------------------
// material-ui
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import TextField from "@material-ui/core/TextField";
// -------------------------------------------------------------


class Profile extends React.Component {
  _isMounted = false;

  constructor() {
    super();

    this.profile = {}
    this.oldPasswordError = false
    this.passwordError = false
    this.passwordConfirmError = false

    this.errorMessage = ""    
    this.infoMessage = ""

    this.state = {
        oldPassword: "",
        oldPasswordHelpText: "",
        password: "",
        passwordHelpText: "",
        passwordConfirm: "",
        passwordConfirmHelpText: "",

        comError: false,
        comSuccess:false
    };

    this._changePw = this._changePw.bind(this);
    this._renderProfileData = this._renderProfileData.bind(this);
    this._handleOldPasswordChange = this._handleOldPasswordChange.bind(this);
    this._handlePasswordChange = this._handlePasswordChange.bind(this);
    this._handlePasswordConfirmChange = this._handlePasswordConfirmChange.bind(this);
    this._renderChangePassword = this._renderChangePassword.bind(this);
    this._requestChangePassword = this._requestChangePassword.bind(this);
    this._checkInput = this._checkInput.bind(this);
    this.closeInfoDialog = this.closeInfoDialog.bind(this);
    this.closeErrorDialog = this.closeErrorDialog.bind(this);  
  }

  componentDidMount() {
    this._isMounted = true;
	this.profile = sMS.getProfileData()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


 _changePw() {
    const tokenRequestData = sMS.getAuthUpdateData()
    const url = "https://" + tokenRequestData.url + "/api/changepw";
    const changePwData = {"current_pw": this.state.oldPassword, "new_pw": this.state.password}
    const dataSet = {"auth":tokenRequestData.reAuthData,"changepw": changePwData}
    console.log("dataSet=", dataSet)
    const dataSet_json = JSON.stringify(dataSet)
    const isAsynchronous = true;
    let response;
    let xhr = new XMLHttpRequest();

    const _this = this;
    xhr.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        let response = JSON.parse(xhr.responseText);
        if (response.status === "error") {
          _this.errorMessage = (<div>
                                  <div>Change password request failed!</div>
                                  <div style={{height: 15}} />
                                  <div>Details:</div>
                                  <div>{response.message}</div>
                                </div>)
          _this.setState({comError: true})
        } else {
          //delete response.status
          //_this.setState({usersData: response})
          console.log("Success: Password Changed", response.message);

          _this.infoMessage = (<div>
                                 Success: Password Changed!
                               </div>)
          _this.setState({comSuccess: true})
        }
      }
    };
    xhr.onerror = function() {
      //response = {
      //  status: "error",
      //  description: "Could not connect to api authority."
      //};
      console.log("error:", response);
      //_this.errorMessage="Could not connect to Platform Management Server."
      //_this.setState({comError: true})      
    };
    xhr.open("POST", url, isAsynchronous);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.send(dataSet_json);
  }


  closeErrorDialog() {
    this.setState({comError: false})
    this.errorMessage=""
  }

  closeInfoDialog() {
    this.setState({comSuccess: false})
    this.infoMessage=""
  }


  _requestChangePassword() {
    const response = this._checkInput()
    if (response === "error") return

    console.log("send request password change")
    this._changePw()
    this.setState({
        oldPassword: "",
        oldPasswordHelpText: "",
        password: "",
        passwordHelpText: "",
        passwordConfirm: "",
        passwordConfirmHelpText: "" })
  }

  _checkInput() {

    if (this.state.oldPassword === "") {
      this.setState({oldPasswordHelpText: "Enter your current password"})
      this.oldPasswordError = true;
      return "error"
    }

    if (this.passwordError || this.passwordConfirmError) {
      return "error"
    }

    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({passwordHelpText: "Set And Confirm Password"})
      this.passwordError = true;
      return "error"
    }
    
    if (this.state.password.length === 0) {
      this.setState({passwordHelpText: "Set Password"})
      this.passwordError = true;
      return "error"
    }
    return "ok"
  }


  _handleOldPasswordChange(event) {
    this.setState({
      oldPassword: event.target.value
    });
    this.oldPasswordError = false;
    this.setState({oldPasswordHelpText: ""})
  }

  _handlePasswordChange(event) {
    this.setState({
      password: event.target.value
    });
    if (event.target.value.length < 8) {
      this.passwordError = true
      this.setState({passwordHelpText: "Minimum length 8 characters"});
      return
    }
    this.passwordError = false
    this.setState({passwordHelpText: "Password"})
    
  }

  _handlePasswordConfirmChange(event) {
    this.setState({
      passwordConfirm: event.target.value
    });

    if (event.target.value === this.state.password) {
      this.passwordConfirmError = false;
      this.setState({ passwordConfirmHelpText: "Password Confirmed" });
    } else {
      this.passwordConfirmError = true;
      this.setState({ passwordConfirmHelpText: "Mismatch" });
    }
  }

  
  _renderProfileData() {

	this.profile = sMS.getProfileData()
    const panel = (
            <div style={{paddingTop: 10, paddingLeft: 40, paddingRight: 20}} >
                <div> <Typography style={{fontSize: 16, fontWeight:"bold"}}>{this.profile.given_name} {this.profile.family_name}</Typography></div>
                {/*<div style={{paddingLeft: 60}}>
                    <IconButton onClick={this._handleUserMenuClicked}>
                        <EditIcon />
                    </IconButton>
                    <Menu open={this.userMenuOpen} onClose={(e)=>this._closeUserMenu(e,"")} anchorEl={this.state.userMenuAnchor} >
                        <MenuItem key="update" disabled={true} dense={true} onClick={(e)=>this._closeUserMenu(e,"update")}>Update User Data</MenuItem>
                        <MenuItem key="reset" dense={true} onClick={(e)=>this._closeUserMenu(e,"reset")}>Reset Password</MenuItem>
                        <MenuItem key="delete" dense={true} onClick={(e)=>this._closeUserMenu(e,"delete")}>Delete This Member</MenuItem>
                    </Menu>
                        
                </div> */}

                <div style={{height: 5}} />
                <div>
                        {this._renderText(this.profile.user_name,"Short Name")}
                        {this._renderText(this.profile.email,"email")}
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {this._renderText(this.profile.department,"Department")}
                            <div style={{width: 50}} />
                            {this._renderText(this.profile.city,"City")}
                        </div>
                    {this._renderText(this.profile.admin_rights,"Platform Admin Rights")}
                    </div>
                </div>
    )
    return panel
  }

  _renderChangePassword() {
    const panel = (
        <div style={{paddingTop: 10, paddingLeft: 40, paddingRight: 20}} >
        <div style={{height: 35}} />

        <Typography style={{fontSize: 16, fontWeight:"bold"}}>Change Password</Typography>
        <div style={{height: 5}} />

              <TextField
                value={this.state.oldPassword}
                onChange={this._handleOldPasswordChange}
                style={{ width: 200 }}
                id="password-input"
                label="Current Password"
                margin="dense"
                error={this.oldPasswordError}
                helperText={this.state.oldPasswordHelpText}
                type="password"
              />

            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                value={this.state.password}
                onChange={this._handlePasswordChange}
                style={{ width: 200 }}
                id="password-input"
                label="New Password"
                margin="dense"
                error={this.passwordError}
                helperText={this.state.passwordHelpText}
                type="password"
              />
              <div style={{ width: 30 }} />
              <TextField
                value={this.state.passwordConfirm}
                onChange={this._handlePasswordConfirmChange}
                style={{ width: 200 }}
                id="password-confirm"
                label="Confirm New Password"
                margin="dense"
                error={this.passwordConfirmError}
                helperText={this.state.passwordConfirmHelpText}
                type="password"
              />
            </div>

            <div style={{height: 20}} />
            <Button onClick={this._requestChangePassword} color="primary" variant="outlined">
              Change Password
            </Button>
            <div style={{height: 25}} />
    
        </div>
    )
    return panel
  }

  render() {
    const page = (
      <div>

        <Error isActive={this.state.comError}
               handle_func_closeErrorDialog={this.closeErrorDialog}
               errorMessage={this.errorMessage}
        />
         <Info isActive={this.state.comSuccess}
               handle_func_closeInfoDialog={this.closeInfoDialog}
               infoMessage={this.infoMessage}
        />       

        <div
          id="frame"
          style={{
            backgroundColor: "white",
            border: "1px solid bdbdbd",
            boxShadow: "0px 0px 8px 0px #bdbdbd",
            borderRadius: 9,
            marginTop: 15,
            paddingBottom: 0
          }}
        >
        {this._renderHeadline()}
        </div>

        <div style={{height: 20}} />



        <div
          id="frame"
          style={{
            backgroundColor: "white",
            border: "1px solid bdbdbd",
            boxShadow: "0px 0px 8px 0px #bdbdbd",

            borderRadius: 0,
            paddingTop: 15,
            paddingBottom: 10
          }}
        >
                {this._renderProfileData()}
                {this._renderChangePassword()}

        </div>


        
</div>
    );

    return page;
  }

  // =============================================================================================================

  _renderText(value, topic) {
    return (
      <div style={{ textAlign: "left"}}>
        <div id="number">
          <Typography
            variant="body1"
            style={{
              fontSize: 14,
              fontWeight: "medium"
            }}
          >
            {value}
          </Typography>
        </div>
        <div
          id="caption"
          style={{ position: "relative", top: -9}}
        >
          <Typography
            variant="overline"
            style={{
              fontSize: 9,
              color: "#1757575"
            }}
          >
            {topic}
          </Typography>
        </div>
      </div>
    );
  }

  _renderHeadline() {
    return (
      <div id="header" style={{ display: "flex", flexDirection: "row" }}>
        <div
          id="colorblotch"
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#795548",

            position: "relative",
            top: -15,
            left: 20,
            boxShadow: "1px 4px 7px 2px #bdbdbd",
            borderRadius: 4
          }}
        >
          <AccountCircleIcon
            style={{
              width: 30,
              height: 30,
              color: "white",
              position: "relative",
              top: 10,
              left: 10
            }}
          />
        </div>
        <div
          id="headline"
          style={{
            flexGrow: 1,
            textAlign: "left",
            paddingTop: -20,
            paddingLeft: 35
          }}
        >
          <div id="type">
            <Typography
              variant="overline"
              style={{ fontSize: 9, color: "#757575" }}
            >
              Management
            </Typography>
          </div>
          <div id="text">
            <Typography
              variant="body1"
              fontWeight="bold"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                position: "relative",
                top: -9
              }}
            >
              Your Profile
            </Typography>
          </div>
        </div>

      </div>
    );
  }
}
export default  Profile;
