import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const styles = {
  card: {
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    height: 135
  },
  cardcontent: {
    width: 100 + "%",
    textAlign: "center",
    marginTop: 0 + "px",
    marginBottom: 30 + "px"
  }
};
class NewServiceCard2 extends React.Component {
  serviceCardClicked() {
    this.props.callback();
  }

  render() {
    const { classes } = this.props;

    const card = (
      <div style={{ marginRight: 15, marginBottom: 10 }}>
        <Card className={classes.card} raised={this.props.raised}>
          <CardActionArea
            onClick={e => this.serviceCardClicked(e, this.props.serviceName)}
          >
            <CardContent className={classes.cardcontent}>
              <div>
                <Typography style={{ color: "#bdbdbd" }} variant="h1">
                  +
                </Typography>
                <Typography style={{ color: "#757575" }} variant="caption">
                  Create New Service
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    );
    return card;
  }
}
export default withStyles(styles)(NewServiceCard2);
