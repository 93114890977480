import React from "react";
import pM from "./PopupManager";
import websocketCom from "./../../webcom/WebsocketCom";


class RestoreCard  extends React.Component {

    constructor() {
        super();

        this.state = ({col: "Gray", col_bg: "WhiteSmoke", col_border: "Gray", borderWidth: 1})

        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
    }

    _onMouseOver(event) {
        this.setState({col: "Black", col_bg: "White", col_border: "Black", borderWidth: 3} );
    }

    _onMouseLeave(event) {
        this.setState({col: "Gray", col_bg: "WhiteSmoke", col_border: "Gray", borderWidth: 1} );
    }

    _onClickRestore(event) {
        console.log("need to restore:", this.props.soName, this.props.entry)
        const msg = this.props.soName+" load "+this.props.entry.release;
        websocketCom.sendServiceMessage(msg);
        pM.deletePopup(this.props.id);
    }

    render() {

        const saveTime = new Date(this.props.entry.date);
        const month = saveTime.getMonth()+1;
        const date = saveTime.getDate()+"."+month+"."+saveTime.getFullYear();
        const time = saveTime.getHours()+":"+saveTime.getSeconds();
        return (
            <div style={{display: "flex", flexDirection: "column",
                         backgroundColor: this.state.col_bg, color: this.state.col, borderRadius: 8,
                         borderStyle: "solid", borderWidth: this.state.borderWidth, borderColor: this.state.col_border,
                         fontStyle: "Roboto",
                         width:226, marginLeft: 10, marginBottom: 10, padding: 10}}
                 onMouseOver={(event) => {this._onMouseOver(event)} }
                 onMouseLeave={(event) => {this._onMouseLeave(event)} }
                 onClick={(event) => {this._onClickRestore(event)} }
            >
                <div style={{fontSize: 12, fontWeight: "bold", textAlign: "right"}}>{date} {time}</div>
                <div style={{height: 10}} />
                <div style={{fontSize: 16, fontWeight: "bold"}}>{this.props.entry.tag}</div>
                <div style={{height: 10}} />
                <div style={{fontSize: 14, fontWeight: "normal"}}>{this.props.entry.comment}</div>
            </div>
        )
    }

}

export default RestoreCard;

