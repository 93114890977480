import React from "react";
import websocketCom from "./../../../webcom/WebsocketCom.js";
import aSDS from "./../../../stores/ActiveServiceDataStore";
import gLS from "./../../../stores/GraphLayoutStore";
import outputDS from "./DebugOutputDataStore";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Feedback";
import MessageIcon from "@material-ui/icons/Message";
import DeleteIcon from "@material-ui/icons/Delete";
import FollowOutputIcon from '@material-ui/icons/VerticalAlignBottom';
import NotInterestedIcon from '@material-ui/icons/NotInterested';


const styles = theme => ({
        item: {paddingLeft: 5, paddingRight: 5, marginLeft: 2, marginRight: 2,
               color: "#424242", backgroundColor: "#e0e0e0", borderRadius: 3, cursor: "default",
               '&:hover': {color: "#212121", backgroundColor: "#bdbdbd"} },
        itemSelect: {paddingLeft: 5, paddingRight: 5, marginLeft: 2, marginRight: 2,
          color: "white", backgroundColor: "#ef6c00",
          borderRadius: 3, cursor: "default",
          '&:hover': {color: "white", backgroundColor: "#e65100"} },

        icon_select_background: {'&:hover': {backgroundColor: "#f5f5f5"} },
	      icon_not_select: {width: 18, height: 18, marginLeft: 0, marginTop: 3, color: "#9e9e9e"},
    	  icon_select: {width: 18, height: 18, marginLeft: 0, marginTop: 3, color: "#212121"},

      })



class Output extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.firstSoUpdate = true;
    //    this.followOutput = true;

    this.state=({soListNG: [], soList: [], selectedSo: {}, show_print: true, show_log: true, follow_output: true })

    //this._ce_ServiceDataStore = this._ce_ServiceDataStore.bind(this);
    this._cE_GLS_SelectionUpdate = this._cE_GLS_SelectionUpdate.bind(this);
    this._ce_OutputDataStore = this._ce_OutputDataStore.bind(this);
    //this._toggleSoSelect = this._toggleSoSelect.bind(this);
    this._toggle = this._toggle.bind(this);
    this._renderLogList = this._renderLogList.bind(this);
  }

  componentDidMount() {
    //_cE_GLS_SelectionUpdate() {
    this._isMounted = true;
    //aSDS.on("change", this._ce_ServiceDataStore);
    gLS.on("ChangeEvent_GraphLayoutUpdate", this._cE_GLS_SelectionUpdate);
    outputDS.on("ChangeEvent_OutputDataStore", this._ce_OutputDataStore);
    outputDS.setFollowOutput(this.state.follow_output)

    //this._ce_ServiceDataStore();
  }

  componentWillUnmount() {
    this._isMounted = false;
    //aSDS.removeListener("change", this._ce_ServiceDataStore);
    gLS.removeListener("ChangeEvent_GraphLayoutUpdate", this._cE_GLS_SelectionUpdate);
    outputDS.removeListener("ChangeEvent_OutputDataStore", this._ce_OutputDataStore);
    outputDS.clearLogs();
    // restore logging levels that have been changed
    for (const so in this.state.selectedSo) {
      const msg = so + " log level " + this.state.selectedSo[so];
      websocketCom.sendServiceMessage(msg);
    }
  }

  //_ce_ServiceDataStore() {
  //  console.log("start")
  //  const soList = aSDS.getSoList();
  //  this.setState({soList: soList});
    //this.setState({soList: aSDS.getSoList()});
  //  if (this.firstSoUpdate === true) {
  //    for (const so of soList) {
  //      this._toggleSoSelect(so)
  //    }
  //    this.firstSoUpdate = false
  //  }
  //}

  _cE_GLS_SelectionUpdate() {
    const soList = gLS.getDebugView();

   let tempList = this.state.soListNG;
   let finalList = this.state.soListNG;
    // check for new or removed so against soListNG
    // loop over received soList and check against soListNG
    for (const so of soList) {
      if (!(tempList.includes(so))) {
        finalList.push(so);
        this._toggleSoSelect(so);
      }
    }
    // check soListNG for no longer valid so
    for (const so of tempList) {
      if(! (soList.includes(so)) ) {
        const index = finalList.indexOf(so)
        finalList.splice(index,1);
        this._toggleSoSelect(so);
      }
    }
    this.setState({soListNG: finalList})
  }

  _ce_OutputDataStore() {
    this.setState({logs: outputDS.getLogs() })
  }

  _clearLogs() {
    outputDS.clearLogs();
  }

  _toggle(type) {
    if (type==="showLog") this.setState({show_log: !this.state.show_log})
    if (type==="showPrint") this.setState({show_print: !this.state.show_print})
    if (type==="followOutput") {
      outputDS.setFollowOutput(!this.state.follow_output)
      this.setState({follow_output: !this.state.follow_output})
    }
  }


  _toggleSoSelect(so) {
    let selectState = this.state.selectedSo;
    if (selectState.hasOwnProperty(so)) {
      // restore old log level
      const msg = so + " log level " + selectState[so];
      websocketCom.sendServiceMessage(msg);
      delete selectState[so];
    } else {
      // get and store current log level
      const logLevel = aSDS.getSmartObjectData(so).get("log").get("currentLevel");
      selectState[so] = logLevel
      const msg = so + " log level trace"
      websocketCom.sendServiceMessage(msg);
    }
    this.setState({selectedSo: selectState})
  }


  _renderLogList() {
    //const {classes} = this.props;

    if (!this.state.logs) return <div />
    let container = [];

    let counter = 0
    for (const log of this.state.logs) {

      let icon=<div />
      let item=<div />
      let showItem = false;
      let level = "";


      if (this.state.soListNG.includes(log.so)) {

      if (log.level === "print" && this.state.show_print) {
        icon = <MessageIcon style={{width: 15, height: 15, marginLeft: 15, marginRight: 10}} />
        level = "";
        showItem = true;
      } else if (log.level !== "print" && this.state.show_log) {
          icon = <MenuIcon style={{width: 15, height: 15, marginLeft: 15, marginRight: 10}} />
          level = log.level;
          showItem = true;
      }


      }

    if (showItem) {
      item = (
          <div key={"log-"+counter} style={{borderBottom: "1px solid #e0e0e0"}} >

        <div style={{ display: "flex", alignItems: "center", fontFamily: "Roboto", fontSize: 12}} >
          {icon}
          <div style={{paddingRight: 10}} >{log.time}</div>
          <div style={{minWidth: 60}}>{level}</div>
          <div style={{minWidth: 100}}>{log.so}</div>
          <div>{log.msg}</div>

              </div>

        </div>
      )
      container.push(item);
      counter++;
    }
    }
    return container
  }


  render() {
    const {classes} = this.props;

    if (this.state.soListNG.length === 0) {
      let content = <div />;
      if (this.props.height > 150) {
        const marginTop = (this.props.height - 8 - 120 - (this.props.height * 0.12)) / 2;
        content =
            <div style={{
              display: "flex", justifyContent: "center", backgroundColor: "White",
              height: 100 + "%", width: 100 + "%"
            }}>
              <NotInterestedIcon style={{fontSize: 120, color: "WhiteSmoke", marginTop: marginTop}}/>
            </div>
      }
      return content
    }

    const scrollboxDiv = document.getElementById("scrollbox")

    if (this.state.follow_output) {
      //  const scrollboxDiv = document.getElementById("scrollbox")
      if (scrollboxDiv) scrollboxDiv.scrollTop = scrollboxDiv.scrollHeight
    }


    const SOSELECTHEIGHT = 0;
    const showLog = this.state.show_log ? classes.icon_select : classes.icon_not_select
    const showPrint = this.state.show_print ? classes.icon_select : classes.icon_not_select
    const followOutput = this.state.follow_output ? classes.icon_select : classes.icon_not_select

    const panel = (
    <div style={{display: "flex"}}>


      <div style={{width: 30, backgroundColor: "#e0e0e0", color: "white", overflowY: "hidden"}} >
          <div className={classes.icon_select_background} style={{width: 30, height: 30}} onClick={() => this._toggle("showLog")} >
          <MenuIcon className={showLog} style={{width: 18, height: 18, marginLeft: 6, marginTop: 6}} />
        </div>
          <div className={classes.icon_select_background} style={{width: 30, height: 30}} onClick={() => this._toggle("showPrint")}>
          <MessageIcon className={showPrint} style={{width: 18, height: 18, marginLeft: 6, marginTop: 6}} />
        </div>
        <div className={classes.icon_select_background} style={{width: 30, height: 30}}  onClick={() => this._clearLogs()}>
          <DeleteIcon className={classes.icon_select} style={{width: 18, height: 18, marginLeft: 6, marginTop: 6}}/>
        </div>
        <div className={classes.icon_select_background} style={{width: 30, height: 30}}  onClick={() => this._toggle("followOutput")}>
          <FollowOutputIcon className={followOutput} style={{width: 18, height: 18, marginLeft: 6, marginTop: 6}}/>
        </div>
      </div>


      <div style={{width: 100+"%"}} >


        <div key="log-list" style={{height: this.props.height-SOSELECTHEIGHT, minHeight: this.props.height-SOSELECTHEIGHT, width: 100+"%" }} >
          <div style={{height: 100+"%", width: 100+"%",  display: "flex", flexDirection: "column"}} >
          <div id="scrollbox" style={{overflow: "auto", flexGrow: 1}} >
          {this._renderLogList()}
          </div>
          </div>
        </div>

      </div>


    </div>)

    return panel
  }

}

export default withStyles(styles) (Output);
