import React from "react";
import websocketCom from "./../../../webcom/WebsocketCom.js";
import * as cmdLineActions from "./../../../actions/CmdLineActions";
import TerminalHistory from "./TerminalHistory";
import cmdLineStore from "./../../../stores/CmdLineStore";
import "./ServiceTerminal.css";

// ------------------------------------------------------
// material-ui
import Button from "@material-ui/core/Button";
// ------------------------------------------------------

class ServiceTerminal extends React.Component {
  cmdHistoryDepth = 0;
  // allow pressing enter to sumbit a command
  _keyPressed = event => {
    if (event.key === "Enter") {
      this._newChatMessage();
    }
  };

  _keyDown = event => {
    if (event.key === "ArrowUp") {
      const chatInput = document.getElementById("inputchatmessage");
      this.cmdHistoryDepth++;
      const command = cmdLineStore.getOldCmd(this.cmdHistoryDepth);
      chatInput.value = command;
    }
    if (event.key === "ArrowDown") {
      const chatInput = document.getElementById("inputchatmessage");
      this.cmdHistoryDepth--;
      if (this.cmdHistoryDepth < 0) this.cmdHistoryDepth = 0;
      const command = cmdLineStore.getOldCmd(this.cmdHistoryDepth);
      chatInput.value = command;
    }
  };

  _newChatMessage() {
    /* A new chat message has been entered on the command line
     * This function is triggerd by pressing the Submit button in the GUI of the Command Line module.
     * This function reads the message from the input field, checks if this is a message, which requires
     * internal handling (i.e. upload a method buffer from the edit module or this is a plain xmpp message
     * which can be sent out immediately.
     * messages trigger EXT_CHAT_CMD
     */

    const chatInput = document.getElementById("inputchatmessage");
    const chatMessage = chatInput.value;

    // do not hanlde empty messages
    if (chatMessage === "") return;

    websocketCom.sendServiceMessage(chatMessage);
    cmdLineActions.externalChatCmd(chatMessage); // trigger EXT_CHAT_ACTION

    // clear command input element
    chatInput.value = "";
    this.cmdHistoryDepth = 0;
  }

  render() {
    //    const chatSubmitButton = (
    //      <Button
    //        id="inputbutton"
    //        bsStyle="primary"
    //        bsSize="xsmall"
    //        onClick={this._newChatMessage.bind(this)}
    //        style={{ marginLeft: 20 + "px", marginRight: 30 + "px" }}
    //      >
    //        Send
    //      </Button>
    //    );

    const chatSubmitButton = (
      <Button
        id="inputbutton"
        //bsStyle="primary"
        //bsSize="xsmall"
        color="primary"
        size="small"
        onClick={this._newChatMessage.bind(this)}
        style={{ marginLeft: 20 + "px", marginRight: 30 + "px" }}
      >
        Send
      </Button>
    );

    const view = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          height: this.props.height-40,
          width: 100 + "%",

        }}
      >
        <div id="terminal-scroll-box" style={{ flexGrow: 1, overflow: "auto" }}>
          <TerminalHistory />
        </div>

        <div>
          <div
            id="inputline"
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 10 + "px",
              paddingBottom: 10 + "px"
            }}
          >
            <input
              id="inputchatmessage"
              style={{ flexGrow: 1, marginLeft: 10 + "px", height: 28 + "px" }}
              onKeyPress={this._keyPressed}
              onKeyDown={this._keyDown}
              type="text"
            />

            {chatSubmitButton}
          </div>
        </div>
      </div>
    );

    return view;
  }
}

export default ServiceTerminal;
