//import userServiceStore from "./../stores/UserServicesStore";
class UserMsgParser {
  processMsg(msg) {
    const messageType = msg[0];
    let userStoreUpdate = null;
    switch (messageType) {
      case "services":
        userStoreUpdate = this._processServiceMessage(msg, "services");
        break;
      case "facilities":
        userStoreUpdate = this._processFacilitiesMessage(msg, "facilities");
        break;
      case "userdata":
        userStoreUpdate = this._processUserDataMessage(msg);
        break;
      case "log":
        userStoreUpdate = this._processLogMessage(msg);
        break;
      case "logregister":
        userStoreUpdate = this._processLogRegisterMessage(msg);
        break;
      case "error":
        userStoreUpdate = this._processErrorMessage(msg);
        break;
      default: {
        console.log("Unknown message:", msg);
      }
    }
    return userStoreUpdate;
  }

  _processServiceMessage(msg, scope) {
    const msgWordCount = msg.length;
    const wordsInPayload = msgWordCount - 1;
    let payload = "";
    for (let i = 0; i < wordsInPayload; i++) {
      payload = payload + msg[1 + i];
    }
    payload = payload.trimRight();

    const userStoreUpdate = {
      scope: scope,
      cmd: "table",
      payload: payload
    };
    return [userStoreUpdate];
  }

  _processFacilitiesMessage(msg, scope) {
    // there is one incoming facilities msg from the user manager
    // this msg holds the table describing all facilities the user owns
    const facitlitiesStoreUpdate = this._processServiceMessage(msg, scope);
    return facitlitiesStoreUpdate;
  }

  _processUserDataMessage(msg) {
    //const msgWordCount = msg.length;
    //const wordsInPayload = msgWordCount - 3;
    //let msgContainer = [];
    let userStoreUpdate = {};

    switch (msg[1]) {
      case "set":
      case "added":
      case "modified":
        // for (let i = 0; i < wordsInPayload; i++) {
        userStoreUpdate = {
          scope: "userdata",
          cmd: msg[1],
          key: msg[2],
          value: msg[3]
        };
        //   msgContainer.push(userStoreUpdate);
        // }
        break;

      case "deleted": {
        userStoreUpdate = {
          scope: "userdata",
          cmd: msg[1],
          key: msg[2]
        };
        // msgContainer.push(userStoreUpdate);
        break;
      }
      default: {
      }
    }
    return [userStoreUpdate];
    // return msgContainer;
  }

  _processLogMessage(msg) {
    if (msg[0] === "log") {
      const origin = msg[1].split(":");

      const msgWordCount = msg.length;
      const wordsInLog = msgWordCount - 5;
      let log = "";
      for (let i = 0; i < wordsInLog; i++) {
        log = log + msg[5 + i] + " ";
      }
      log = log.trimRight();

      const userStoreUpdate = {
        scope: "log",
        service: origin[0],
        so: origin[1],
        log_level: msg[2],
        date: msg[3],
        time: msg[4],
        log: log
      };

      return [userStoreUpdate];
    } else {
      return null;
    }
  }

  _processLogRegisterMessage(msg) {
    const msgWordCount = msg.length;
    const wordsInPayload = msgWordCount - 1;
    let payload = "";
    for (let i = 0; i < wordsInPayload; i++) {
      payload = payload + msg[1 + i];
    }
    payload = payload.trimRight();

    const userStoreUpdate = {
      scope: "registerlog",
      payload: payload
    };
    return [userStoreUpdate];
  }

  _processErrorMessage(msg) {
    //console.log("  --> UserMsgParser: Processing error message:", msg);

    let notificationStoreUpdate = "";

    /*    if (
      msg[1] === "userdata" &&
      msg[2] === "undefined" &&
      msg[3] === "key" &&
      userServiceStore.isServiceName(msg[4])
    ) {
      // request on service relate user scoped data failed
      // this can happen when a new service is created and no userdata entry
      // for this service has been created in the generic user key value store so far
      // if this happens it is checked if the failed key is the name of a service the
      // user owns. If so the entry is created. Otherwise a error message for the
      // undefined key is created.
      userServiceStore.createServiceDataEntry(msg[4]);
    } else {
*/
    const msgWordCount = msg.length;
    const wordsInMsg = msgWordCount - 1;
    let notificationMsg = "";
    for (let i = 0; i < wordsInMsg; i++) {
      notificationMsg = notificationMsg + msg[1 + i] + " ";
    }
    notificationMsg = notificationMsg.trimRight();
    notificationStoreUpdate = {
      scope: "notification",
      type: msg[0],
      origin: "user",
      message: notificationMsg
    };
    return [notificationStoreUpdate];
    //}
  }
}

const userMsgParser = new UserMsgParser();
export default userMsgParser;
