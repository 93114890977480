import React from "react";
import gLS from "./../../stores/GraphLayoutStore";
import config from "./../config";
import CancelIcon from '@material-ui/icons/Cancel';

class Popup extends React.Component {

  constructor(props) {
    super(props);

    this.state = {closeButtonColor: "black"}

    this.border = { "minX": config.setup.canvas.borderWidth, "minY": config.setup.canvas.borderWidth,
                     "maxX": config.setup.canvas.width - config.setup.canvas.borderWidth,
                     "maxY": config.setup.canvas.height - config.setup.canvas.borderWidth }

    this.isHoverCloseButton = false;
    this.isTracking = false;
    this.trackCal= {"x": 0, "y": 0}
    this.switchCloseButtonColor = this.switchCloseButtonColor.bind(this);
    this._buildMenu = this._buildMenu.bind(this);
    this._buildPanel = this._buildPanel.bind(this);
    this._handleMouseMove = this._handleMouseMove.bind(this);
  }

  componentDidMount() {
    window.addEventListener("mousemove", this._handleMouseMove);
  }
  componentWillUnmount() {
    window.removeEventListener("mousemove", this._handleMouseMove);
  }


  closePopup(event) {
    event.stopPropagation();
    this.props.delCallback(this.props.id);
  }

  switchCloseButtonColor() {
    this.isHoverCloseButton = ! this.isHoverCloseButton;
    const color = this.isHoverCloseButton ? "orange":"black"
    this.setState({closeButtonColor: color})

  }

  preventMouseUpBubble(event) {
    event.stopPropagation();
  }

  _handleMouseDown(event) {
    if (event.button === 0) {
      this.trackCal = {"x": event.clientX, "y": event.clientY};
      this.isTracking = true;
    }
  }

  _handleMouseUp(event) {
    if (event !== null) { event.stopPropagation(); }
    this.isTracking = false;
    this.trackCal = {"x": 0, "y": 0}
    this.props.moveCallback({"x": 0, "y": 0})
  }

  _handleMouseMove(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.isTracking) {
      const scaleFactor = gLS.getScaleFactor();
      let stopTracking = false;
      let delta = {"x": (event.clientX - this.trackCal.x)/scaleFactor,
                   "y": (event.clientY - this.trackCal.y)/scaleFactor };
      this.trackCal = {"x": event.clientX, "y": event.clientY};
      this.props.moveCallback(delta)
      if (stopTracking) {this._handleMouseUp(null)}
    }
  }


  render() {

    let panel = null;

    switch (this.props.elementClass) {
      case "menu":
           panel = this._buildMenu();
           break;

      case "panel":
           panel = this._buildPanel();
           break;

      default:  break;
    }

    return panel
  }


  _buildMenu() {
    const menu = (
      <g key={"conf-so-"+this.props.soName}
         onMouseUp={(event) => this.preventMouseUpBubble(event) } >

        <rect x={this.props.geometry.x} y={this.props.geometry.y}
              width={this.props.geometry.width} height={this.props.geometry.height}
              stroke="gray" strokeWidth="2" strokeOpacity="0.5"
              fill="white" fillOpacity="1"
        />
        <foreignObject x={this.props.geometry.x} y={this.props.geometry.y}
                       width={this.props.geometry.width} height={this.props.geometry.height} >

          <div style={{fontFamily: "Roboto", fontSize: 13, backgroundColor: "white"}} >
            {this.props.content}
          </div>
        </foreignObject>
      </g>
    )
    return menu;
  }

  _buildPanel() {

    let x = this.props.geometry.x; let y = this.props.geometry.y

    const width = this.props.geometry.width; const height = this.props.geometry.height;
    if ( x < this.border.minX ) {x = this.border.minX}
    if ( y < this.border.minY ) {y = this.border.minY}
    if ( x+width > this.border.maxX) { x = this.border.maxX - width}
    if ( y+height > this.border.maxY) { y = this.border.maxY - height}

    const panel = (
      <g key={"conf-so-"+this.props.soName}
        onMouseUp={(event) => this._handleMouseUp(event) }
        onMouseDown={(event) => this._handleMouseDown(event) }
      >

      <rect x={x} y={y+9}
              width={this.props.geometry.width-9} height={this.props.geometry.height}
              stroke="gray" strokeWidth="2" strokeOpacity="0.5"
              fill="white" fillOpacity="1"
        />
        <foreignObject x={x} y={y}
                       width={this.props.geometry.width} height={this.props.geometry.height+10} >


          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }} >
            <div style={{flexGrow: 1}} />
            <CancelIcon onClick={(event) => this.closePopup(event) }
                        onMouseEnter={() => this.switchCloseButtonColor() }
                        onMouseLeave={() => this.switchCloseButtonColor() }
                        style={{width: 26, height: 26, color: this.state.closeButtonColor, backgroundColor: "white", borderRadius: 12}}/>
          </div>


          <div style={{backgroundColor: "#424242", color: "white", height:8, marginTop: -17, marginRight: 10}} > </div>


          <div style={{fontFamily: "Roboto", fontSize: 13,  marginTop: -0}} >
            {this.props.content}
          </div>
        </foreignObject>
      </g>
    )
    return panel;
  }

}

export default Popup;

