class Logger {
  // Define levels that are maintened for logging within this project
  _logLevels = ["OFF", "DEBUG", "INFO", "WARNING", "ERROR", "FATAL"];

  // include date time in msg log?
  _logIncludeTime = false;

  // store per module which log level should be printed to the console
  // only logging requests (function log), which include a log level equal or higher to what is
  // configured here will be printed to the console.
  _logLevelsModules = {
    SessionManager: "ERROR",
    ServiceManager: "ERROR",
    WebsocketCom: "ERROR",
    WindowFrame: "ERROR",
    UserServicesStore: "ERROR",
    ActiveServiceMgmtStore: "ERROR",
    SessionManagementStore: "ERROR",
    GraphManager: "ERROR",
    GraphDataStore: "ERROR",
    HierarchyFilter: "ERROR",
    FacilitiesStore: "ERROR"
  };

  /**
   * [log: print a log message to the console ]
   * @param  origin:      string        [Class name of origin, which triggers this function
                                         note: origin must be defined in '_logLevelsModules']
   * @param  msgLogLevel: string        [log level of the msg that should be printed]
   * @param  message:     string        [the message itself that should be printed]
   * @param  data:        string        [additional data that should be printed with the message]
   */
  log(origin, msgLogLevel, message, data) {
    const originLogLevel = this._logLevelsModules[origin];
    const oll = this._logLevels.indexOf(originLogLevel);
    const mll = this._logLevels.indexOf(msgLogLevel);

    if (oll === -1 || mll === -1) {
      let givenLogLevel = "";
      if (oll === -1) givenLogLevel = originLogLevel;
      if (mll === -1) givenLogLevel = msgLogLevel;
      this._printMessage(
        "Logger",
        "ERROR",
        "Unknown log level.",
        givenLogLevel
      );
    }

    if (mll >= oll) {
      this._printMessage(origin, msgLogLevel, message, data);
    }
  }

  _printMessage(origin, msgLogLevel, message, data) {
    if (this._logIncludeTime) {
      const now = new Date();
      console.log(
        msgLogLevel +
          "  " +
          String(now) +
          "  " +
          origin +
          " : " +
          message +
          " ",
        data
      );
    } else {
      console.log(msgLogLevel + "  " + origin + " : " + message + " ", data);
    }
  }
}

const logger = new Logger();
export default logger;
