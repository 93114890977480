import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import aSDS from "./../../stores/ActiveServiceDataStore";
import Popup from "./Popup";
import pM from "./PopupManager";
import gLS from "./../../stores/GraphLayoutStore";
import MenuEntry from "./MenuEntry";
import MenuEntrySoCol from "./MenuEntrySoCol";
import MenuEntryView from "./MenuEntryView";

class ConfSO  extends React.Component {

  constructor() {
    super();
    // set size for this configuration panel
    this.width = 180; this.height = 283;

    this.selectView = this.selectView.bind(this);
    this._setSoColor = this._setSoColor.bind(this);
    this._viewCallback = this._viewCallback.bind(this);
  }



  selectView(view) {

    let graphLayout = {}; let soLayout = {}; let scaleFactor = null; let location = {};

    switch (view) {

      case "delete":
        const msg = "delete " + this.props.soName;
        websocketCom.sendServiceMessage(msg);
        pM.deletePopup(this.props.id);
        break;

      case "port":
        pM.deletePopup(this.props.id);
        graphLayout = gLS.getGraphLayout();
        soLayout = graphLayout[this.props.soName];
        scaleFactor = this.props.location.scaleFactor;
        location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                    "y": (soLayout.graph_data.y),
                    "scaleFactor": scaleFactor }
        pM.addPopup(this.props.soName, "", location, "confports");
        break;

      case "addport":
        pM.deletePopup(this.props.id);
        graphLayout = gLS.getGraphLayout();
        soLayout = graphLayout[this.props.soName];
        scaleFactor = this.props.location.scaleFactor;
        location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                    "y": (soLayout.graph_data.y),
                    "scaleFactor": scaleFactor }
        pM.addPopup(this.props.soName, "", location, "addport");
        break;

      case "addmethod":
        pM.deletePopup(this.props.id);
        graphLayout = gLS.getGraphLayout();
        soLayout = graphLayout[this.props.soName];
        scaleFactor = this.props.location.scaleFactor;
        location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                          "y": (soLayout.graph_data.y),
                          "scaleFactor": scaleFactor }
        pM.addPopup(this.props.soName, "", location, "addmethod");
        break;

      case "setmapping":
        pM.deletePopup(this.props.id);
        graphLayout = gLS.getGraphLayout();
        soLayout = graphLayout[this.props.soName];
        scaleFactor = this.props.location.scaleFactor;
        location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                          "y": (soLayout.graph_data.y),
                          "scaleFactor": scaleFactor }
        pM.addPopup(this.props.soName, "", location, "setmapping");
        break;

        case "snapshot":
            pM.deletePopup(this.props.id);
            graphLayout = gLS.getGraphLayout();
            soLayout = graphLayout[this.props.soName];
            scaleFactor = this.props.location.scaleFactor;
            location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                "y": (soLayout.graph_data.y),
                "scaleFactor": scaleFactor }
            pM.addPopup(this.props.soName, "", location, "snapshot");
            break;

        case "restore":
            pM.deletePopup(this.props.id);
            graphLayout = gLS.getGraphLayout();
            soLayout = graphLayout[this.props.soName];
            scaleFactor = this.props.location.scaleFactor;
            location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                "y": (soLayout.graph_data.y),
                "scaleFactor": scaleFactor }
            pM.addPopup(this.props.soName, "", location, "restore");
            break;

        case "stats":
            pM.deletePopup(this.props.id);
            graphLayout = gLS.getGraphLayout();
            soLayout = graphLayout[this.props.soName];
            scaleFactor = this.props.location.scaleFactor;
            location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                "y": (soLayout.graph_data.y),
                "scaleFactor": scaleFactor }
            pM.addPopup(this.props.soName, "", location, "stats");
            break;

      case "export":
            pM.deletePopup(this.props.id);
            graphLayout = gLS.getGraphLayout();
            soLayout = graphLayout[this.props.soName];
            scaleFactor = this.props.location.scaleFactor;
            location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                "y": (soLayout.graph_data.y),
                "scaleFactor": scaleFactor }
            pM.addPopup(this.props.soName, "", location, "exportso");
            break;

      default:
        break;
    }

  }

  _setSoColor(color) {
    pM.deletePopup(this.props.id);
    gLS.setSoColor(this.props.soName, color);
  }

  _viewCallback(size) {
    pM.deletePopup(this.props.id);
    gLS.setView(this.props.soName, size);
  }


  _buildDefaultMenu() {

    // set size for this configuration panel
    this.width = 180; this.height = 283;

    const panel = (
      <div style={{cursor: "default"}} >
       <MenuEntryView soName={this.props.soName} callback={this._viewCallback} entries={"all"} />
       <MenuEntrySoCol callback={this._setSoColor} />
        <div style={{minHeight: 1, maxHeight: 1, marginTop: 2, marginBottom: 2, marginRight: 0,  backgroundColor: "Gray"}} />
       <MenuEntry iconName="addport" text="Add Port..." callback={this.selectView} />
       <MenuEntry iconName="addmethod" text="Add Method..." callback={this.selectView} />
       <MenuEntry iconName="setmapping" text="Mapping..." callback={this.selectView} />
       <MenuEntry iconName="stats" text="Statistics..." callback={this.selectView} />
        <div style={{minHeight: 1, maxHeight: 1, marginTop: 2, marginBottom: 2, marginRight: 0,  backgroundColor: "Gray"}} />
        <MenuEntry iconName="snapshot" text="Snapshot..." callback={this.selectView} />
        <MenuEntry iconName="restore" text="Restore..." callback={this.selectView} />
        <MenuEntry iconName="export" text="Export..." callback={this.selectView} />
        <div style={{minHeight: 1, maxHeight: 1, marginTop: 2, marginBottom: 2, marginRight: 0,  backgroundColor: "Gray"}} />
       <MenuEntry iconName="delete" text={"Delete "+this.props.soName} callback={this.selectView} />
      </div>
    )
    return panel
  }

  _buildChartMenu() {
    // set size for this configuration panel
    this.width = 180; this.height = 60;

    const panel = (
      <div style={{cursor: "default"}} >
       <MenuEntryView soName={this.props.soName} callback={this._viewCallback} entries={"restricted"} />
        <div style={{minHeight: 1, maxHeight: 1, marginTop: 2, marginBottom: 2, marginRight: 0,  backgroundColor: "Gray"}} />
       <MenuEntry iconName="delete" text={"Delete "+this.props.soName} callback={this.selectView} />
      </div>
    )
    return panel
  }


  _buildConfSoMenu() {

    let panel = <div />
    let specialHandling = "none";

      // =========================================================
      // check for special SO that do not need all entries...
      let chart_setup = aSDS.getAttribute(this.props.soName, "chart_setup");
      if (chart_setup !== null) {
        chart_setup = chart_setup.trim();
        if (chart_setup !== "null") specialHandling = "chart"
      }



    // =========================================================

    switch (specialHandling) {
      case "char":
        panel = this._buildChartMenu();
        break;
      default:
        panel = this._buildDefaultMenu();
        break;
    }

    return panel
  }


  render() {

    // Content of panel is defined here
    const view = (<div key={"content-so-conf-"+this.props.soName}> {this._buildConfSoMenu()} </div>)

    let  geometry = this.props.location;
    //const scaleFactor = this.props.location.scaleFactor;
    geometry["width"] = this.width; geometry["height"] = this.height;

    const panel = ( <Popup geometry={geometry} content={view}
                           id={this.props.id} elementClass={this.props.elementClass}
    delCallback={this.props.delCallback} /> )
    return panel
  }

}

//let confSO = new ConfSO();
export default ConfSO;

