import { useD3 } from "./../../../../../hooks/useD3";
import React from "react";
import * as d3 from "d3";

function ScatterPlot(props) {
  let data = props.data;
  let metrikName = "undefined";
  if (props.metrikName) metrikName = props.metrikName;

  let maxValueX = d3.max(props.data, (d) => d[0]);
  let maxValueY = d3.max(props.data, (d) => d[1]);


  const ref = useD3(
    (svg) => {
      const height = 120;
      const width = 295;
      const margin = { top: 5, right: 5, bottom: 35, left: 55 };

      const x = d3
        .scaleLinear()
        .domain([0, maxValueX])
        .range([margin.left, width]);

      const xAxis = (g) =>
        g
          .style("font-size", "14px")
          .style("font-weight", "bold")
          .attr("transform", `translate(0,${height+10})`)
          .call(d3.axisBottom(x));


      const y = d3
        .scaleLinear()
        .domain([0, maxValueY])
        .range([height+10, 10]);


      const yAxis = (g) =>
        g
          .attr("transform", `translate(${margin.left},0)`)
          .style("font-size", "14px")
          .style("font-weight", "bold")
          .call(d3.axisLeft(y))

      svg.select(".x-axis").call(xAxis);
      svg.select(".y-axis").call(yAxis);

      svg
        .select(".plot-area")
        .selectAll("circle")
        .data(props.data)
        .join("circle")
        .attr("cx", (value) => x(value[0]) )
        .attr("cy", (value) => y(value[1]) )
        .attr("r", 2.3)
        .attr("fill", "White")
        .attr("stroke", "White")
        .attr("stroke-width", 1.0);
    },
    [props.stateChange]
  );

  return (
    <svg
      ref={ref}
      style={{
        height: 150,
        width: 330,
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );
}

export default ScatterPlot;

