import React from "react";
import websocketCom from "./../../../../webcom/WebsocketCom";
import aSDS from "../../../../stores/ActiveServiceDataStore";

import SettingsIcon from "@material-ui/icons/Settings";
import ReturnIcon from "@material-ui/icons/KeyboardReturn";
import WipeIcon from '@material-ui/icons/RemoveFromQueue';
import BarChart from "./ChartGraph/BarChart";


class Bar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeView: "chart", // can be chart or config
      metricName: props.chartSetup.name,
      wipeChart: false,
    };
    // chart config data in: props.chartSetup
    // chart plot data in: props.chartData

    this._buildConfigView = this._buildConfigView.bind(this);
    this._buildChartView = this._buildChartView.bind(this);
    this._changeActiveView = this._changeActiveView.bind(this);
    this._handleNameChange = this._handleNameChange.bind(this);
    this._wipeChart = this._wipeChart.bind(this);
  }

  componentDidMount() {
    this.state.metricName = this.props.chartSetup.name;
  }

  //componentWillUnmount() {}

  _handleNameChange(event) {
    this.setState({ metricName: event.target.value });
  }

  _wipeChart( event, status) {
    event.stopPropagation();
    this.setState({wipeChart: status})
    this.forceUpdate();
  }


  _changeActiveView(event, newView) {
    event.stopPropagation();
    this.setState({ activeView: newView });

    if (newView === "chart") {
      let temp_chartSetup = this.props.chartSetup;
      temp_chartSetup.name = this.state.metricName;
      const newSetup = JSON.stringify(temp_chartSetup);
      const msg = this.props.soName + " setattribute chart_setup " + newSetup;
      websocketCom.sendServiceMessage(msg);
    }
  }

  _buildConfigView() {
    const panel = (
      <div
        style={{
          fontFamily: "Roboto",
          fontSize: 14,
          fontWeight: "bold",
          paddingTop: 10,
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }} />
          <div style={{ fontSize: 15 }}>Bar Chart - Configuration</div>
          <div style={{ flexGrow: 1 }} />
        </div>

        <div style={{ height: 24 }} />
        <div style={{ display: "flex", paddingLeft: 35 }}>
          <div style={{ fontSize: 15 }}>Metric Name:</div>
          <div style={{ width: 12 }} />
          <input
            style={{
              fontFace: "Roboto",
              fontSize: 13,
              width: 160,
              minHeight: 14,
            }}
            onChange={(event) => this._handleNameChange(event)}
            onClick={(event) => event.stopPropagation()}
            value={this.state.metricName}
            type="text"
            id="name"
            name="name"
            autoFocus
          />
        </div>
      </div>
    );

    return panel;
  }

  _buildChartView() {
    // received chartData JSON convertion failed
    if (this.props.chartData === null) {
      const issuePanel = (
        <div style={{ textAlign: "center", fontFamily: "Roboto" }}>
          <div style={{ height: 48 }} />
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            No data for metric {this.props.chartSetup.name} available.
          </div>
        </div>
      );
      return issuePanel;
    }

    // ============================================================================
    // Bar: check for valid data
    if ( typeof this.props.chartData !== 'number' ) {
      const issuePanel = (
        <div style={{ textAlign: "center", fontFamily: "Roboto" }}>
          <div style={{ height: 48 }} />
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            Unknown data format for metric {this.props.chartSetup.name}. Array expected.
          </div>
        </div>
      );
      return issuePanel;
    }
    // ============================================================================

    // ============================================================================
    // Bar: draw panel

    let chartPanel = (
      <div>
        <div style={{display: "flex", fontSize: 32, fontWeight: "bold"}}>
          <div style={{flexGrow: 1}} />
          <div>{this.props.chartData}</div>
          <div style={{flexGrow: 1}} />
        </div>
      <BarChart
        data={this.props.chartData}
        metrikName={this.state.metricName}
        wipeChart={this.state.wipeChart}
    />
      </div>
    );

    // ============================================================================

    return chartPanel;
  }

  render() {
    let content = <div />;
    let footer = <div />;
    let metric = "";

    switch (this.state.activeView) {
      case "config":
        content = this._buildConfigView();
        footer = (
          <ReturnIcon
            style={{ paddingRight: 5 }}
            onClick={(event) => {
              this._changeActiveView(event, "chart");
            }}
          />
        );
        break;
      case "chart":
        content = this._buildChartView();
        metric = "Metric: " + this.state.metricName;
        footer = (
          <div>

          <WipeIcon
            style={{ paddingRight: 15 }}
            onMouseDown={(event) => {
              this._wipeChart(event, true);
            }}
            onMouseUp={(event) => {
              this._wipeChart(event, false);
            }}
            onMouseLeave={(event) => {
              this._wipeChart(event, false);
            }}
            onClick={(event) => event.stopPropagation()}
        />

          <SettingsIcon
            style={{ paddingRight: 5 }}
            onClick={(event) => {
              this._changeActiveView(event, "config");
            }}
        />
          </div>
        );
        break;
      default:
        content = <div>Unexpected Error</div>;
        break;
    }

    const panel = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: 180,
        }}
      >
        <div style={{ flexGrow: 1 }}>{content}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              paddingLeft: 10,
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {metric}
          </div>
          <div style={{ flexGrow: 1 }} />
          <div> {footer} </div>
        </div>
      </div>
    );

    return panel;
  }
}

export default Bar;
