import React from 'react';
import aSDS from '../../../stores/ActiveServiceDataStore';
import gLS from '../../../stores/GraphLayoutStore';
import PortNG from './Port_NG';
import pM from '../../PopUp/PopupManager';
import selectionManager from '../../SelectionManager/SelectionManager';
import Dashboard from '../Dashboards/Dashboard';
import MethodItem from './MethodItem';
import InletItem from './InletItem';
import StatusLine from './SoStatusLine';
import KingPigeonDevice from '../Dashboards/KingPigeon/KingPigeonDevice';
import OpenEdgeDevice from '../Dashboards/OpenEdge/OpenEdgeDevice';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import QueueLengthIcon from '@material-ui/icons/FilterNone';
import IODeviceIcon from '@material-ui/icons/SettingsInputComponent';
import ClearIcon from '@material-ui/icons/Clear';
import HidePortDetails from '@material-ui/icons/FullscreenExit';
import config from '../../config';

import DefaultIcon from '../../../images/soIcons/default.png';
import ReliableIcon from '../../../images/soIcons/reliable.png';
import ScatterPlot from '../../../images/soIcons/scatterPlot.png';
import BarChart from '../../../images/soIcons/barChart.png';
import LineChart from '../../../images/soIcons/lineChart.png';
import Histogram from '../../../images/soIcons/histogram.png';
import Number from '../../../images/soIcons/number.png';
import Digital from '../../../images/soIcons/digital.png';
import DigitalFalse from '../../../images/soIcons/digitalFalse.png';
import DigitalTrue from '../../../images/soIcons/digitalTrue.png';
import MSTeams from '../../../images/soIcons/ms-teams.png';
import Slack from '../../../images/soIcons/slack.png';
import Thingsboard from '../../../images/soIcons/thingsboard.png';
import Tasmota from '../../../images/soIcons/tasmota.png';

class SmartObjectNG extends React.Component {
  constructor(props) {
    super(props);

    this.positionX = 0;
    this.positionY = 0;

    this.positionX_start = 0;
    this.positionY_start = 0;

    this.tracking = false;
    this.mouse_down_move = false;
    this.was_move = false;

    this.dbclickTimer = null;
    //this.timer = null;

    this.callResponses = [];
    this.showCallResponse = false;

    this.id = 'so ' + this.props.soName;

    this.state = {
      isSelect: false,
      detailViewPort: null,
      colorAddInlet: 'white',
      colorAddOutlet: 'white',
      //colorAddMthod: "white",
    }; //,
    //callResponses: [], showCallResponse: false };

    this._handleMouseDown = this._handleMouseDown.bind(this);
    this._handleMouseUp = this._handleMouseUp.bind(this);
    this._handleMouseMove = this._handleMouseMove.bind(this);
    this._hideDetailViewPortClick = this._hideDetailViewPortClick.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this._onMouseOverAddxlet = this._onMouseOverAddxlet.bind(this);
    this._onMouseLeaveAddxlet = this._onMouseLeaveAddxlet.bind(this);
    this._onMouseOverAddMethod = this._onMouseOverAddMethod.bind(this);
    this._onMouseLeaveAddMethod = this._onMouseLeaveAddMethod.bind(this);
    this._onContextMenu = this._onContextMenu.bind(this);
    this._handleContextPortMenu = this._handleContextPortMenu.bind(this);
    this._showCallResponse = this._showCallResponse.bind(this);
    this._cE_SelectionUpdate = this._cE_SelectionUpdate.bind(this);
  }

  componentDidMount() {
    window.addEventListener('mousemove', this._handleMouseMove);
    selectionManager.on('ChangeEvent_Selection', this._cE_SelectionUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this._handleMouseMove);
    selectionManager.removeListener(
      'ChangeEvent_Selection',
      this._cE_SelectionUpdate,
    );
  }

  _cE_SelectionUpdate() {
    const resp = selectionManager.checkSelect(this.id);
    const isSelected = resp[0];
    const scope = resp[1];

    if (isSelected) {
      this.setState({isSelect: true});
      this.selectionScope = scope;
    } else {
      this.setState({isSelect: false});
      this.selectionScope = null;
    }

    const detailViewPort = selectionManager.getPortViewSelection(
      this.props.soName,
    );
    this.setState({detailViewPort: detailViewPort});
  }

  _handleMouseDown(event) {
    //this.props.callbackIsStackTop(this.props.soName);
    if (event.button === 0) {      
      // left button down to move so on canvas
      this.mouse_down_move = true;
      gLS.blockSoCoreUpdate(this.props.soName);
    }
  }

  _handleMouseUp() {
    //console.log("mouse up")
    this.tracking = false;
    this.mouse_down_move = false;

    gLS.unblockSoCoreUpdate(this.props.soName);
  }

  _handleClick(event) {
    const self = this;
    event.stopPropagation();
this.props.callbackIsStackTop(this.props.soName);
    if (this.dbclickTimer !== null) {
      // we have a double click event
      clearTimeout(this.dbclickTimer);
      this.dbclickTimer = null;

      let secondView = 'large';
      // =========================================================
      //  move to panel instead large for chart SO extension
      let chart_setup = aSDS.getAttribute(this.props.soName, "chart_setup");
      if (chart_setup !== null) {
        chart_setup = chart_setup.trim();
        if (chart_setup !== "null") secondView = "panel"
      }
      // =========================================================

      const newView =
        gLS.getView(this.props.soName) === 'compact' ? secondView : 'compact';
      gLS.setView(this.props.soName, newView);
      return;
    }

    this.setState({admendmentPanel: null});
    if (!this.tracking && !this.was_move) {
      const scope = event.shiftKey ? 'secondary' : 'primary';
      this.dbclickTimer = setTimeout(function() {
        self._dbClickTimeout(scope);
      }, 250);
    }
    this.was_move = false;
  }

  _dbClickTimeout(scope) {
    // we have a single click event
    this.dbclickTimer = null;
    selectionManager.toggle(this.id, scope, 'so');
  }

  _onContextMenu(event, soName) {
    event.preventDefault();
this.props.callbackIsStackTop(this.props.soName);
    let element = document.getElementById('container-svg-graph');
    if (!element) {
      return;
    }
    pM.deletePopups();
    const location = {
      x: (element.scrollLeft + event.clientX - 80) / this.props.scaleFactor,
      y: (element.scrollTop + event.clientY - 65) / this.props.scaleFactor,
      scaleFactor: this.props.scaleFactor,
    };

    const tempSoData = aSDS.getSmartObjectData(this.props.soName); //.get("methods");
    if (tempSoData === null) {
      return <div />;
    }
    const soState = tempSoData.get('info').get('state');
    let contextType = 'confso';
    if (soState === '' || soState === 'undef' || soState === 'lost') {
      contextType = 'contextsodead';
    }
    pM.addPopup(soName, '', location, contextType, 'MediumSeaGreen');
  }

  _handleContextPortMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.detailViewPort !== null) {
      let element = document.getElementById('container-svg-graph');
      if (!element) {
        return;
      }
      pM.deletePopups();
      const location = {
        x: (element.scrollLeft + event.clientX - 80) / this.props.scaleFactor,
        y: (element.scrollTop + event.clientY - 150) / this.props.scaleFactor,
        scaleFactor: this.props.scaleFactor,
      };
      pM.addPopup(
        this.props.soName,
        this.state.detailViewPort,
        location,
        'contextport',
        'MediumSeaGreen',
      );
    }
  }

  _handlePortClick(event) {
    event.stopPropagation();
    if (this.state.detailViewPort !== null) {
      const scope = event.shiftKey ? 'secondary' : 'primary';
      const id = 'port ' + this.props.soName + ' ' + this.state.detailViewPort;
      selectionManager.toggle(id, scope, 'port');
    }
  }

  _handleMouseDownPort(event) {
    event.stopPropagation();
  }

  _handleMouseMove(event) {
    if (this.mouse_down_move) {
      
this.props.callbackIsStackTop(this.props.soName);
      
      const scale = this.props.scaleFactor;

      if (this.tracking === false) {
        //this.was_move = true
        this.tracking = true;
        this.positionX = event.clientX;
        this.positionX_start = this.positionX;
        this.positionY = event.clientY;
        this.positionY_start = this.positionY;
      }

      let deltaxPos = event.clientX - this.positionX;
      let deltayPos = event.clientY - this.positionY;
      this.positionX = event.clientX;
      this.positionY = event.clientY;

      const deltaPosXOverall = this.positionX_start - this.positionX;
      const deltaPosYOverall = this.positionY_start - this.positionY;

      const minMove = Math.sqrt(
        deltaPosXOverall * deltaPosXOverall +
          deltaPosYOverall * deltaPosYOverall,
      );
      if (minMove > 2) {
        this.was_move = true;
      }

      const xpos = this.props.graphData.graph_data.x;
      const ypos = this.props.graphData.graph_data.y;

      const geometry = this.props.graphData.graph_data;
      const extension = this.props.graphData.graph_extension;
      const width = geometry.width + extension.d_w;
      const height = geometry.height + extension.d_h;

      if (xpos + deltaxPos <= config.setup.canvas.borderWidth) {
        deltaxPos = -xpos + config.setup.canvas.borderWidth + 2;
        gLS.updateSoPosition(
          this.props.soName,
          deltaxPos / scale,
          deltayPos / scale,
        );
        this._handleMouseUp();
      } else if (ypos + deltayPos <= config.setup.canvas.borderWidth) {
        deltayPos = -ypos + config.setup.canvas.borderWidth + 2;
        gLS.updateSoPosition(
          this.props.soName,
          deltaxPos / scale,
          deltayPos / scale,
        );
        this._handleMouseUp();
      } else if (
        xpos + deltaxPos + width >
        config.setup.canvas.width - config.setup.canvas.borderWidth
      ) {
        deltaxPos =
          config.setup.canvas.width -
          xpos -
          config.setup.canvas.borderWidth -
          width -
          2; //6000 - xpos - 200 - 50
        gLS.updateSoPosition(
          this.props.soName,
          deltaxPos / scale,
          deltayPos / scale,
        );
        this._handleMouseUp();
      } else if (
        ypos + deltayPos + height >
        config.setup.canvas.width - config.setup.canvas.borderWidth
      ) {
        deltayPos =
          config.setup.canvas.height -
          ypos -
          config.setup.canvas.borderWidth -
          height -
          2; //4500 - ypos - 135 - 50
        gLS.updateSoPosition(
          this.props.soName,
          deltaxPos / scale,
          deltayPos / scale,
        );
        this._handleMouseUp();
      } else {
        gLS.updateSoPosition(
          this.props.soName,
          deltaxPos / scale,
          deltayPos / scale,
        );
      }
    }
  }

  _hideDetailViewPortClick(event) {
    event.stopPropagation();
    const id = 'port ' + this.props.soName + ' ' + this.state.detailViewPort;
    selectionManager.delete_Id(id);
    selectionManager.deletePortViewSelection(id);
    gLS.redraw();
  }

  _clickNewxlet(event, direction) {
    event.stopPropagation();

    const soLayout = this.props.graphData;
    const location = {
      x: soLayout.graph_data.x + soLayout.graph_data.width + 30,
      y: soLayout.graph_data.y,
      scaleFactor: this.props.scaleFactor,
    };

    const popup = direction === 'inlet' ? 'addinlet' : 'addoutlet';
    pM.addPopup(
      this.props.soName,
      this.state.detailViewPort,
      location,
      popup,
      'MediumSeaGreen',
    );
  }
  _onMouseOverAddxlet(event, direction) {
    if (direction === 'inlet') {
      this.setState({colorAddInlet: 'orange'});
    } else {
      this.setState({colorAddOutlet: 'orange'});
    }
  }
  _onMouseLeaveAddxlet(event) {
    this.setState({colorAddInlet: 'white', colorAddOutlet: 'white'});
  }

  _clickNewMethod(event) {
    event.stopPropagation();
    const soLayout = this.props.graphData;
    const location = {
      x: soLayout.graph_data.x + soLayout.graph_data.width + 30,
      y: soLayout.graph_data.y,
      scaleFactor: this.props.scaleFactor,
    };
    pM.addPopup(this.props.soName, '', location, 'addmethod', 'MediumSeaGreen');
  }
  _onMouseOverAddMethod(event) {
    this.setState({colorAddMethod: 'orange'});
  }
  _onMouseLeaveAddMethod(event) {
    this.setState({colorAddMethod: 'white'});
  }

  _showCallResponse() {
    if (Object.keys(this.callResponses).length > 0) {
      this.callResponses.shift();
    }
    if (Object.keys(this.callResponses).length === 0) {
      this.showCallResponse = false;
    } else {
      window.setTimeout(this._showCallResponse, 8000);
    }
    this.forceUpdate();
  }

  _buildDashboardView(extension) {
    if (extension.view !== 'panel') {
      return <div />;
    }

    let view = <div />;

    // check if special so that has dedicated panel view (e.g. i/o Dev representation)
    let IO_device_setup = aSDS.getAttribute(
      this.props.soName,
      'IO_device_setup',
    );
    if (IO_device_setup) {
      IO_device_setup = JSON.parse(IO_device_setup);
      if (IO_device_setup.type === 'KingPigeon') {
        view = (
          <KingPigeonDevice
            model={IO_device_setup.model}
            name={IO_device_setup.name}
            soName={this.props.soName}
            // data={IO_device_data}
          />
        );
      } else if (IO_device_setup.type === 'OpenEdge') {
        view = (
          <OpenEdgeDevice
            model={IO_device_setup.model}
            name={IO_device_setup.name}
            soName={this.props.soName}
            // data={IO_device_data}
          />
        );
      }
    } else {
      view = <Dashboard soName={this.props.soName} />;
    }
    return view;
  }

  _buildPortDetailsView(extension) {
    if (extension.view !== 'large') {
      return <div />;
    }
    let showPortDetails = 'none';
    if (extension.view === 'large' && this.state.detailViewPort !== null) {
      showPortDetails = 'block';
    }
    const view = (
      <div style={{height: extension.main_h}}>
        <div
          style={{
            display: showPortDetails,
            color: 'black',
            backgroundColor: this.so_ac_col,
            borderRadius: 10,
            height: extension.main_h,
            marginLeft: 15,
            paddingLeft: 15,
            marginRight: 15,
            paddingRight: 15,
            paddingBottom: 0,
            paddingTop: 0,
            border: '1px solid LightGray',
          }}
          onClick={event => this._handlePortClick(event)}
          onMouseDown={event => this._handleMouseDownPort(event)}
          onContextMenu={event => this._handleContextPortMenu(event)}>
          {this._buildXletView('inlet')}
          {this._buildXletView('outlet')}
        </div>
      </div>
    );

    return view;
  }

  _buildMethodList(width) {
    const tempSoData = aSDS.getSmartObjectData(this.props.soName); //.get("methods");
    if (tempSoData === null) {
      return <div />;
    }
    const methods = tempSoData.get('methods');
    if (methods.size === 0) {
      return <div />;
    }
    let methodContainer = [];
    const itemWidth = width / 2 - 23;
    for (let key of methods.keys()) {
      const methodItem = (
        <MethodItem
          key={this.props.soName + '-' + key}
          soName={this.props.soName}
          scaleFactor={this.props.scaleFactor}
          methodName={key}
          status={methods.get(key).get('status')}
          width={itemWidth}
        />
      );
      methodContainer.push(methodItem);
    }
    return methodContainer;
  }

  _buildMethodView(extension, so_type) {
    if (extension.view === 'compact') {
      if (aSDS.getAttribute(this.props.soName, 'IO_device_setup')) {
        let model = <div />;
        let IO_device_setup = aSDS.getAttribute(
          this.props.soName,
          'IO_device_setup',
        );
        IO_device_setup = JSON.parse(IO_device_setup);
        if ('name' in IO_device_setup) {
          model = <div>{IO_device_setup.name}</div>;
        }

        return (
          <div>
            <div style={{display: 'flex', flexDirecton: 'row'}}>
              <div style={{flexGrow: 1}} />
              <IODeviceIcon
                style={{fontSize: 45, paddingTop: 0, color: 'PaperWhite'}}
              />
              <div style={{flexGrow: 1}} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirecton: 'row',
                fontFamily: 'Roboto',
                fontSize: 14,
                fontWeight: 'bold',
                color: 'PaperWhite',
              }}>
              <div style={{flexGrow: 1}} />
              <div style={{fontSize: 11, paddingTop: 5}}>{model}</div>
              <div style={{flexGrow: 1}} />
            </div>
          </div>
        );
      }
    }

    if (so_type !== null && extension.view === 'compact') {
      let text = '';
      let icon = null;
      let width = 0;
      let yoffset = 0;
      let number = "";
      let opacity = 1.0;
      
      switch (so_type) {
        case 'Default':
          text = 'regular';
          icon = DefaultIcon;
          width = 55;
          yoffset = 0;
          //opacity = 0.8;
          break;  
        case 'Reliable':
          text = 'reliable';
          icon = ReliableIcon;
          width = 45;
          yoffset = 0;
          break;  
        case 'Number':

          // =========================================================
          //  get state to show in compact state
          let chart_data_number = aSDS.getAttribute(this.props.soName, 'chart_data');
          if (chart_data_number !== null) {
            chart_data_number = chart_data_number.trim();
            if (chart_data_number !== 'null') {
              try {
                const parsedData = JSON.parse(chart_data_number);
                if (typeof parsedData === 'number') {
                  number = parsedData;
                } else { icon = Digital }
              } catch (e) {
                console.log(
                  'ERROR: received data for chart_data not valid JSON.',
                );
                icon = Number
              }
            }
          }
          // =========================================================


          text = 'Number';
          width = 70;
          yoffset = 10;
          break;
        case 'Time Series':
          text = 'Line Chart';
          icon = LineChart;
          width = 70;
          yoffset = 10;
          break;
        case 'Histogram':
          text = 'Histogram';
          icon = Histogram;
          width = 70;
          yoffset = 7;
          break;
        case 'Bar':
          text = 'Bar Chart';
          icon = BarChart;
          width = 75;
          yoffset = 12;
          break;
        case 'Scatter Plot':
          text = 'Scatter Plot';
          icon = ScatterPlot;
          width = 60;
          yoffset = 5;
          break;
        case 'Digital Signal':
          // =========================================================
          //  get state to show in compact state
          icon = Digital; width = 60;
          let chart_data = aSDS.getAttribute(this.props.soName, 'chart_data');
          if (chart_data !== null) {
            chart_data = chart_data.trim();
            if (chart_data !== 'null') {
              try {
                const parsedData = JSON.parse(chart_data);
                if (typeof parsedData === 'string') {
                  if (parsedData === 'true') {
                    icon = DigitalTrue;
                    width = 45;
                  } else if (parsedData === 'false') {
                    icon = DigitalFalse;
                    width = 45;
                  } else {
                    icon = Digital;
                    width = 60;
                  }
                }
              } catch (e) {
                console.log(
                  'ERROR: received data for chart_data not valid JSON.',
                );
                icon = Digital
                width = 75
              }
            }
          }
          // =========================================================

          text = 'Digital Signal';
          yoffset = 7;
          break;
        case 'MS Teams Gateway':
          text = so_type;
          icon = MSTeams;
          width = 45;
          yoffset = 5;
          break;
        case 'Slack Gateway':
          text = "Gateway";
          icon = Slack;
          width = 100;
          yoffset = 12;
          break;
        case 'TB Gateway':
          text = "Gateway";
          icon = Thingsboard;
          width = 125;
          yoffset = 10;
          break;
        case 'TB Device':
          text = "Device";
          icon = Thingsboard;
          width = 125;
          yoffset = 10;
          break;
        case 'Tasmota MQTT':
          text = "MQTT Client";
          icon = Tasmota;
          width = 120;
          yoffset = 8;
        break;  
        default:
          break;
      }

      // special handling in case number
      let badge = <div />
      if (icon) {
        badge =
          <img
            draggable="false"
            style={{color: 'white', userSelect: 'none', opacity: opacity}}
            width={width}
            src={icon}
            alt="chart"
          />
      } else {
        badge = <div style={{paddingTop: 0, marginBottom: 5, paddingLeft: 5, paddingRight: 5,
                             fontSize: 28, fontWeight: "bold", backgroundColor: "rgb(237,231,246)",
          color: "rgb(26,35,126)", width: 100, textAlign: "center",
          overflow: "hidden" }}>{number}</div>
      }


      const panel = (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
        <div style={{height: yoffset}} />
        {badge}
          <div style={{fontSize: 13, width: 160, textAlign: 'center'}}>
            {text}
          </div>
        </div>
      );

      return panel;
    }

    if (extension.view !== 'large') {
      return <div />;
    }

    const width = this.props.graphData.graph_data.width + extension.d_w;
    const methodList = this._buildMethodList(width);

    const callReturn = aSDS.getSoCallResponses(this.props.soName);
    if (Object.keys(callReturn).length > 0) {
      for (const key in callReturn) {
        let resp = {};
        resp[key] = callReturn[key];
        this.callResponses.push(resp);
      }
      if (!this.showCallResponse) {
        this.showCallResponse = true;
        window.setTimeout(this._showCallResponse, 8000);
      }
    }

    let callResponsePanel = <div />;
    if (this.showCallResponse) {
      const respToShow = this.callResponses[0];
      const method = Object.keys(respToShow)[0];
      const response = respToShow[method];
      const numberQueuedResponses = Object.keys(this.callResponses).length;

      let queueIcon = <div />;
      if (numberQueuedResponses > 1) {
        queueIcon = (
          <div style={{paddingLeft: 4}}>
            <div>
              <QueueLengthIcon />
            </div>
            <div
              style={{
                fontFamily: 'Roboto',
                fontSize: 11,
                marginTop: -3,
                paddingLeft: 7,
              }}>
              {numberQueuedResponses}
            </div>
          </div>
        );
      }

      callResponsePanel = (
        <div style={{display: 'flex', color: 'white', paddingTop: 3}}>
          <div style={{flexGrow: 1}}>
            <div style={{fontFamily: 'Roboto', fontSize: 13}}>
              Call Response {method}:
            </div>
            <div
              style={{
                fontFamily: 'Roboto',
                fontSize: 15,
                fontWeight: 'bold',
                overflowY: 'scroll',
                height: 58,
              }}>
              {response}
            </div>
          </div>
          <div>{queueIcon}</div>
        </div>
      );
    }

    const view = (
      <div
        style={{
          display: 'block',
          color: 'black',
          backgroundColor: 'transparent',
          height: extension.method_h,
          marginLeft: 15,
          marginRight: 15,
        }}>
        <div
          style={{
            display: 'flex',
            fontFamily: 'Roboto',
            fontSize: 15,
            fontWeight: 'bold',
            paddingTop: 15,
            paddingBottom: 6,
            color: 'white',
          }}>
          <div>Methods</div>
          <div>
            <AddCircleIcon
              style={{
                paddingLeft: 5,
                paddingTop: 2,
                height: 15,
                color: this.state.colorAddMethod,
              }}
              onClick={event => this._clickNewMethod(event)}
              onMouseOver={event => this._onMouseOverAddMethod(event)}
              onMouseLeave={event => this._onMouseLeaveAddMethod(event)}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}>
          {methodList}
        </div>

        {callResponsePanel}
      </div>
    );

    return view;
  }

  _buildXletView(direction) {
    // direction must be "inlet" our "outlet"

    // check if there is a port selected for this SO
    if (this.state.detailViewPort === null) {
      return <div />;
    }

    let xletContainer = [];

    // draw port header only when inlets are processed.
    // outlets are drawn below inlets and thus do not need this port header
    if (direction === 'inlet') {
      const portHeader = (
        <div
          key={this.props.soName + '-' + this.state.detailViewPort}
          style={{
            fontFamily: 'Roboto',
            fontSize: 16,
            fontWeight: 'bold',
            paddingTop: 10,
            paddingBottom: 3,
            color: 'white',
          }}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div>{this.state.detailViewPort}</div>
            <div style={{flexGrow: 1}} />
            <div onClick={event => this._hideDetailViewPortClick(event)}>
              <HidePortDetails style={{marginTop: -9, marginRight: -10}} />
            </div>
          </div>
        </div>
      );
      xletContainer.push(portHeader);
    }

    const xletHeadline = direction === 'inlet' ? 'Inlets' : 'Outlets';
    const addIconColor =
      direction === 'inlet'
        ? this.state.colorAddInlet
        : this.state.colorAddOutlet;
    const inletHeader = (
      <div
        key={
          this.props.soName +
          '-' +
          this.state.detailViewPort +
          '-' +
          direction +
          '-'
        }
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontFamily: 'Roboto',
          fontSize: 15,
          fontWeight: 'bold',
          paddingTop: 6,
          paddingBottom: 0,
          color: 'white',
        }}>
        <div>{xletHeadline}</div>
        <div>
          <AddCircleIcon
            style={{
              paddingLeft: 5,
              paddingTop: 2,
              height: 15,
              color: addIconColor,
            }}
            onClick={event => this._clickNewxlet(event, direction)}
            onMouseOver={event => this._onMouseOverAddxlet(event, direction)}
            onMouseLeave={event => this._onMouseLeaveAddxlet(event)}
          />
        </div>
      </div>
    );
    xletContainer.push(inletHeader);

    const tempSoData = aSDS.getSmartObjectData(this.props.soName);
    if (tempSoData === null) {
      return xletContainer;
    }
    const tempPortsData = tempSoData.get('ports');
    if (!tempPortsData.has(this.state.detailViewPort)) {
      return xletContainer;
    }
    const xletsData = tempPortsData.get(this.state.detailViewPort);

    const xlets =
      direction === 'inlet'
        ? xletsData.get('inlets')
        : xletsData.get('outlets');
    if (xlets.size > 0) {
      for (let key of xlets.keys()) {
        const xletItem = (
          <InletItem
            key={
              this.props.soName + '-' + this.state.detailViewPort + '-' + key
            }
            soName={this.props.soName}
            portName={this.state.detailViewPort}
            inletName={key}
            methodName={xlets.get(key).get('method')}
            direction={direction}
            scaleFactor={this.props.scaleFactor}
          />
        );
        xletContainer.push(xletItem);
      }
    }
    return xletContainer;
  }

  _buildStatusBar(extension) {
    if (extension.view !== 'large') {
      return <div />;
    }

    const statusBar = (
      <div
        style={{
          display: 'block',
          color: 'black',
          backgroundColor: this.so_col,
          height: extension.status_h,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}>
        <StatusLine
          soName={this.props.soName}
          showGlobal={this.props.showGlobal}
          showDebug={this.props.showDebug}
          mapping={this.props.soData.get('info').get('host')}
          graphData={this.props.graphData}
          so_ac_col={this.so_ac_col}
        />
      </div>
    );
    return statusBar;
  }

  _drawBody() {
    const soName = this.props.soName;
    const geometry = this.props.graphData.graph_data;
    const extension = this.props.graphData.graph_extension;
    const x = geometry.x;
    const y = geometry.y;
    const width = geometry.width + extension.d_w;
    const height = geometry.height + extension.d_h;

    let color = this.props.graphData.graph_data.color;
    if (!(color in config.so_col)) {
      color = 'green';
    }
    this.so_col = config.so_col[color].main;
    this.so_ac_col = config.so_col[color].accent;

    let so_type = "Default";
    const type = aSDS.getSOType(this.props.soName);
    if (type==="reliable") so_type = "Reliable"
    
    const IO_device_setup = aSDS.getAttribute(
      this.props.soName,
      'IO_device_setup',
    );
    if (IO_device_setup) {
      this.so_col = '#424242';
      this.so_ac_col = '#424242';
      so_type = 'IO_device';
    }

    // =========================================================
    //  dedicated color for chart SO extension

      let chart_setup = aSDS.getAttribute(this.props.soName, "chart_setup");
      if (chart_setup !== null) {
        chart_setup = chart_setup.trim();
        if (chart_setup !== "null") {

          try {
            const parsedData = JSON.parse(chart_setup);
            if (typeof parsedData === "object") {

              so_type = parsedData.type;
              this.so_col = "#1a237e";
              this.so_ac_col = "#1a237e";

            }
          } catch (e) { console.log("ERROR: received data for chart_setup not valid JSON."); }


        }
      }

    // =========================================================
    //  handle color for so which have a dedicated_so_setup attribute

      let dedicated_so_setup = aSDS.getAttribute(this.props.soName, "dedicated_so_setup");
      if (dedicated_so_setup !== null) {
        dedicated_so_setup = dedicated_so_setup.trim();
        if (dedicated_so_setup !== "null") {

          try {
            const parsedData = JSON.parse(dedicated_so_setup);
            if (typeof parsedData === "object") {

		so_type = parsedData.type;
		
		switch (so_type) {
		
		case "MS Teams Gateway":
		    this.so_col = "#3130a2";
		    this.so_ac_col = "#3e41bd";
		    break;
    case "Slack Gateway":
        this.so_col = "#e01e52";
        this.so_ac_col = "#e01e52";
        break;
		default:
		    break;
		    
		}
            }
          } catch (e) { console.log("ERROR: received data for chart_setup not valid JSON."); }


        }
      }

    // =========================================================

    const portDetailsView = this._buildPortDetailsView(extension);
    const methodView = this._buildMethodView(extension, so_type);
    const statusBar = this._buildStatusBar(extension);
    const dashboardView = this._buildDashboardView(extension);
    let soContainer = [];

    soContainer.push('<g key={' + soName + '_container} >');

    if (this.state.isSelect) {
      const border = (
        <g key={soName + '_border'}>
          <rect
            x={x - 3}
            y={y - 3}
            width={width + 6}
            height={height + 6}
            stroke="blue"
            strokeWidth="7"
            strokeOpacity="1"
            fillOpacity="0"
          />
        </g>
      );
      soContainer.push(border);
    }

    //check for dead so
    let content = <div />;
    const tempSoData = aSDS.getSmartObjectData(this.props.soName); //.get("methods");
    if (tempSoData === null) {
      return <div />;
    }
    const soState = tempSoData.get('info').get('state');
    if (soState === '' || soState === 'undef' || soState === 'lost') {
      this.so_col = config.so_dead_col;
      content = (
        <div>
          <ClearIcon
            style={{
              fontSize: 90,
              marginTop: -15,
              paddingLeft: 30,
              color: 'WhiteSmoke',
            }}
          />
        </div>
      );
    } else {
      content = (
        <div>
          {portDetailsView}
          {methodView}
          {dashboardView}
          {statusBar}
        </div>
      );
    }

    const soFigure = (
      <g key={soName}>
        <foreignObject
          x={x}
          y={y}
          width={width}
          height={height}
          onMouseDown={event => this._handleMouseDown(event)}
          onMouseUp={() => this._handleMouseUp()}>
          <div
            style={{
              backgroundColor: this.so_col,
              border: 'white',
              color: 'white',
              borderWidth: 4,
              borderStyle: 'solid',
              height: height - 8,
              borderRadius: 10,
              userSelect: 'none',
            }}
            onContextMenu={event => this._onContextMenu(event, soName)}
            onClick={event => this._handleClick(event)}>
            <div style={{height: geometry.height - 12}}>
              <div
                style={{
                  textAlign: 'center',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  fontWeight: 'bold',
                  paddingTop: 8,
                }}>
                {this.props.soName}
              </div>
            </div>
            {content}
          </div>
        </foreignObject>
      </g>
    );
    soContainer.push(soFigure);
    soContainer.push('</g>');

    return soContainer;
  }

  _drawPorts() {
    let portContainer = [];

    //check for dead so
    //let content = <div />;
    const tempSoData = aSDS.getSmartObjectData(this.props.soName); //.get("methods");
    if (tempSoData === null) {
      return <div />;
    }
    const soState = tempSoData.get('info').get('state');
    if (soState === '' || soState === 'undef' || soState === 'lost') {
      return portContainer;
    }

    const extension = this.props.graphData.graph_extension;
    let showPortDetails = false;
    if (extension.view === 'large' && this.state.detailViewPort !== null) {
      showPortDetails = true;
    }

    if (this.props.graphData.ports) {
      for (const port of this.props.graphData.ports) {
        portContainer.push(
          <PortNG
            key={port.name + 'container'}
            soName={this.props.soName}
            port={port}
            graphData={this.props.graphData}
            scaleFactor={this.props.scaleFactor}
            showPortDetails={showPortDetails}
            so_ac_col={this.so_ac_col}
          />,
        );
      }
    }
    return portContainer;
  }

  render() {
    let soView = [];
    soView.push(this._drawBody());
    soView.push(this._drawPorts());

    return soView;
  }
}

export default SmartObjectNG;
