class LogMessage {
  processMsg(msg) {
    const MSG_TYPE = 0;
    const LOG_MSG_RECEIVE_SO = 1;
    const LOG_LEVEL = 2;

    if (msg[MSG_TYPE] === "log") {
      const msgWordCount = msg.length;
      const wordsInLog = msgWordCount - 3;
      let log = "";
      for (let i = 0; i < wordsInLog; i++) {
        log = log + msg[3 + i] + " ";
      }
      log = log.trimRight();

      const platformStoreUpdate = {
        scope: "log_message",
        cmd: "so_log",
        so: msg[LOG_MSG_RECEIVE_SO],
        level: msg[LOG_LEVEL],
        log: log
      };

      return [platformStoreUpdate];
    } else {
      return [null];
    }
  }
}

const logMessage = new LogMessage();
export default logMessage;
