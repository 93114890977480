import React from "react";
//import aSDS from "./../../stores/ActiveServiceDataStore";
//import gLS from "./../../stores/GraphLayoutStore";
//import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";
//import pM from "./PopupManager";
import SoMapping from "./SoMapping";


class SetMapping extends React.Component {

  constructor(props) {
    super(props);
    // set size for this configuration panel
    this.width = 300; this.height = 240;

    this.state = {moveOffset: {"x":0, "y":0} }

    this.moveCallback = this.moveCallback.bind(this);
    this._buildSetMapping = this._buildSetMapping.bind(this);
  }


  _panelClick(event) {event.stopPropagation() }

  moveCallback(delta) {
    this.setState({moveOffset: delta})
  }


  _buildSetMapping() {
    return (
      <SoMapping soName={this.props.soName} width={this.width-10} height={this.height} />
    )
  }


  render() {

    // Content of panel is defined here
    const view = (<div key={"content-mapping-conf-"+this.props.soName}> {this._buildSetMapping()} </div>)

    let  geometry = this.props.location;
    geometry["x"] = geometry["x"]+this.state.moveOffset.x; geometry["y"] = geometry["y"]+this.state.moveOffset.y;
    geometry["width"] = this.width; geometry["height"] = this.height;

    const panel = ( <Popup geometry={geometry} content={view}
                           id={this.props.id} elementClass={this.props.elementClass}
                           delCallback={this.props.delCallback}
                           moveCallback={this.moveCallback}
                    /> )
    return panel
  }

}

export default SetMapping;
