import React from "react";
import websocketCom from "./../../../../webcom/WebsocketCom";
import sMS from "./../../../../stores/SessionManagementStore";
// -----------------------------------------------------------------------
//    material=ui
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import FaceIcon from '@material-ui/icons/Face';
import BlockIcon from '@material-ui/icons/Block';
// -----------------------------------------------------------------------

class UserManagementPanel extends React.Component {
  constructor() {
    super();
    this.state = {
      collaboratorInput: "",
      comError: false,
      usersData: {}
    };

    this._handleCollaboratorInput = this._handleCollaboratorInput.bind(this);
    this._getUsersData = this._getUsersData.bind(this);
    this._buildUserCaps = this._buildUserCaps.bind(this);
    this._handleChipClick = this._handleChipClick.bind(this);
 }

  _isMounted = false;
  activeUserIsAdmin = false;

  componentDidMount() {
    this._isMounted = true;
    this._getUsersData()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  _getUsersData() {

    const tokenRequestData = sMS.getAuthUpdateData()
    const url = "https://" + tokenRequestData.url + "/api/users";
    const dataSet_json = JSON.stringify(tokenRequestData.reAuthData)
    const isAsynchronous = true;
    let response;
    let xhr = new XMLHttpRequest();

    const _this = this;
    xhr.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        let response = JSON.parse(xhr.responseText);
        if (response.status === "error") {
          console.log("ERROR: Failed to retrieve users infomration");
          _this.setState({comError: true})
        } else {
          delete response.status
          _this.setState({usersData: response})
        }
      }
    };
    xhr.onerror = function() {
      response = {
        status: "error",
        description: "Could not connect to api authority."
      };
      console.log(response);
      _this.setState({comError: true})
    };

    xhr.open("POST", url, isAsynchronous);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.send(dataSet_json);
  }

  _handleCollaboratorInput(e) {
    this.setState({
      collaboratorInput: e.target.value
    });
  }

  _handleRemoveUser(key, user) {
    if (this.activeUserIsAdmin) {
      const message = "service deluser " + user;
      websocketCom.sendServiceMessage(message);
    }
  }

  _addCollaborator() {
    if (this.state.collaboratorInput !== "") {
//      const message =
//        "service adduser " + this.state.collaboratorInput + " visitor";
      const message =
        "service adduser " + this.state.collaboratorInput + " admin";
      websocketCom.sendServiceMessage(message);
      this.setState({ collaboratorInput: "" });
    }
  }

  _addUser(user) {
      const message = "service adduser " + user + " admin";
      websocketCom.sendServiceMessage(message);
  }

  _removeUser(user) {
      const message = "service deluser " + user;
      websocketCom.sendServiceMessage(message);
  }



  _handleChipClick(event, user) {
     if (this.props.userList.has(user)) {
       this._removeUser(user)
     } else {
       this._addUser(user)
     }
  }

  _changeUserPermission = event => {
    const user = event.target.name;
    const permission = event.target.value;
    const message = "service setuser " + user + " " + permission;
    websocketCom.sendServiceMessage(message);
  };

  _buildUserList() {
    this.activeUserIsAdmin = false;
    for (let user of this.props.userList.keys()) {
      const currentRole = this.props.userList.get(user);
      if (user === this.props.activeUser && currentRole === "admin") {
        this.activeUserIsAdmin = true;
        break;
      }
    }

    let userContainer = [];
    let userItem = "";

    for (let user of this.props.userList.keys()) {
      //let currentRole = this.props.userList.get(user);

      userItem = (
        <div
          key={user}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            marginBottom: 20 + "px"
          }}
        >
          <div style={{ marginTop: 9 + "px", minWidth: 250 + "px" }}>
            {user}
          </div>

           {/*<form autoComplete="off">
            <FormControl style={{ width: 150 }}>
              <InputLabel htmlFor="user">Permission</InputLabel>
              <Select
                value={currentRole}
                onChange={this._changeUserPermission}
                inputProps={{
                  name: user,
                  id: "user"
                }}
              >
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"user"}>Write</MenuItem>
                <MenuItem value={"visitor"}>Read</MenuItem>
              </Select>
            </FormControl>
          </form>*/}

          <div
            style={{ marginLeft: 20 + "px" }}
            onClick={key => this._handleRemoveUser(key, user)}
          >
            <DeleteIcon />
          </div>
        </div>
      );
      userContainer.push(userItem);
    }
    return userContainer;
  }


  _buildUserCaps() {
    let capsContainer = [];
    let userItem = <div />

    let isSelected = false

    for (let user in this.state.usersData) {
        if (this.state.usersData.hasOwnProperty(user)) {


          let chipColor = "default"
          let chipIcon = <BlockIcon />
          if (this.props.userList.has(user)) {
            isSelected = true
            chipColor = "primary"
            chipIcon = <FaceIcon />
          }


            const label = <div>{this.state.usersData[user].given_name + " " + this.state.usersData[user].family_name}</div>
            userItem = <Chip
                        key={user}
                        style={{minWidth: 0, marginRight: 15, paddingTop: 8, paddingBottom: 8}}
                        color={chipColor}
                        clickable
                        onClick={event => this._handleChipClick(event,user)}
                        icon={chipIcon}
                        label={label} />


        }
        if (user !== sMS.getUserName()) {
          if (isSelected) { capsContainer.unshift(userItem) } else { capsContainer.push(userItem) }
        }
    }
    return capsContainer

  }

  render() {
    return (
      <div>
        <Typography style={{fontSize: 14, fontWeight: "bold", paddingTop: 0, paddingBottom: 15}} >Manage Collaborators</Typography>
        <div>
            {this._buildUserCaps()}
        </div>
      </div>
    );
  }
}

export default UserManagementPanel;
