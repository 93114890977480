
import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";
import pM from "./PopupManager";
import selectionManager from "./../SelectionManager/SelectionManager";
//import aSDS from "./../../stores/ActiveServiceDataStore";
//import gLS from "./../../stores/GraphLayoutStore";
import MenuEntry from "./MenuEntry";

class ContextOutlet  extends React.Component {

  constructor(props) {
    super(props);
    // set size for this configuration panel
    this.width = 185; this.height = 26;
    this.id = "outlet "+this.props.soName+" "+this.props.portName;

    this.selectView = this.selectView.bind(this);
  }



  selectView(view) {
    //const id = "port "+this.props.soName+" "+this.props.port;
    let msg = "";
    //let graphLayout=null; let soLayout=null; let scaleFactor=null; let location=null;

    switch (view) {

      case "delete":
        pM.deletePopup(this.props.id);
        selectionManager.delete_Id(this.id)
        msg = this.props.soName + " deloutlet " + this.props.port;
        websocketCom.sendServiceMessage(msg);
        break;

      default:
        break;
    }

  }


  _buildOutletContextMenu() {

    const outletName = this.props.port.split(" ")[1];

      const panel = (
      <div style={{cursor: "default"}} >
       <MenuEntry iconName="delete" text={"Delete "+outletName} callback={this.selectView} />
      </div>
    )
    return panel
  }


  render() {

    // Content of panel is defined here
    const view = (<div key={"content-context-outlet-"+this.props.soName}> {this._buildOutletContextMenu()} </div>)

    let  geometry = this.props.location;
    //const scaleFactor = this.props.location.scaleFactor;
    geometry["width"] = this.width; geometry["height"] = this.height;

    const panel = ( <Popup geometry={geometry} content={view}
                           id={this.props.id} elementClass={this.props.elementClass}
                           delCallback={this.props.delCallback} /> )
    return panel
  }

}

export default ContextOutlet;
