import dispatcher from "../dispatcher/dispatcher";

export function changeActiveService(msg) {
	//console.log("dispatcher: Dispatching ACTION_ChangeActiveService", msg);
	dispatcher.dispatch({
		type: "ACTION_ChangeActiveService",
		msg
	});
}


export function closeActiveService(msg) {
    dispatcher.dispatch({
        type: "ACTION_CloseActiveService",
        msg
    })
}



// ----------------------------------------------------------
// TODO
// the code bolow can be deleted when the old "header" was
// removed from the project!!!

// --- legacy function needed by old header component
// --- will be removed soon...
/*export function changeService(id) {
	dispatcher.dispatch({
		type: "CHANGE_SERVICE",
		id
	});
}*/
// ----------------------------------------------------------
