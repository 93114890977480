import React from "react";
import websocketCom from "./../../../../webcom/WebsocketCom";
import uSS from "./../../../../stores/UserServicesStore";
import fS from "./../../../../stores/FacilitiesStore";
// -------------------------------------------------------------
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
// -------------------------------------------------------------

const styles = theme => ({
  serviceInputFormControl: {
    //margin: theme.spacing.unit,
    minWidth: 220
  }
});

class CreateServiceModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedServiceName: "",
      selectedServiceTemplate: "Default",
      selectedFacility: ""
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this._handleChangeServiceName = this._handleChangeServiceName.bind(this);
    this._handleChangeTemplate = this._handleChangeTemplate.bind(this);
    this._handleChangeFacility = this._handleChangeFacility.bind(this);
  }

  handleClose() {
    this.setState({
      selectedServiceName: "",
      selectedServiceTemplate: "Default",
      selectedFacility: ""
    });

    this.props.closeHandle();
  }

  handleCreate() {
    let msg = "";
    if (this.state.selectedServiceTemplate === "Default")
      msg =
        "newservice " +
        this.state.selectedServiceName +
        " " +
        this.state.selectedFacility;
    else
      msg =
        "cloneservice " +
        this.state.selectedServiceName +
        " " +
        this.state.selectedServiceTemplate +
        " " +
        this.state.selectedFacility;

    websocketCom.sendUserMessage(msg);
    this.handleClose();
  }

  _buildServiceTemplateMenu() {
    const templateServiceTable = uSS.getTemplateServices();
    let MenuItemContainer = [];
    let menuItem = (
      <MenuItem key="default" value="Default">
        Default
      </MenuItem>
    );
    MenuItemContainer.push(menuItem);

    for (let service in templateServiceTable) {
      menuItem = (
        <MenuItem key={service} value={service}>
          {service}
        </MenuItem>
      );
      MenuItemContainer.push(menuItem);
    }
    return MenuItemContainer;
  }

  _buildFacilitiesMenu() {
    const facilities = fS.getFacilitiesByName();
    let MenuItemContainer = [];
    for (let fac in facilities) {
      const menuItem = (
        <MenuItem key={fac} value={fac}>
          {fac}
        </MenuItem>
      );
      MenuItemContainer.push(menuItem);
    }
    return MenuItemContainer;
  }

  _handleChangeServiceName(event) {
    const serviceName = event.target.value;
    this.setState({ selectedServiceName: serviceName });
  }

  _handleChangeTemplate(event) {
    const templateName = event.target.value;
    this.setState({ selectedServiceTemplate: templateName });
  }

  _handleChangeFacility(event) {
    const facilityName = event.target.value;
    this.setState({ selectedFacility: facilityName });
  }

  render() {
    const { classes } = this.props;

    const dialog = (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Create Service</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a service, define a service name and assign the new
              service to one of your facilities. Optional you can choose a
              Service Template, from which the service should be derived from.
            </DialogContentText>
            <div style={{ minHeight: 15 + "px" }} />
            <FormControl className={classes.serviceInputFormControl} fullWidth>
              <TextField
                autoFocus
                margin="dense"
                id="service-name"
                label="Service Name"
                value={this.state.selectedServiceName}
                onChange={this._handleChangeServiceName}
                fullWidth
              />
            </FormControl>
            <div style={{ height: 15 + "px" }} />
            <FormControl className={classes.serviceInputFormControl} fullWidth>
              <InputLabel htmlFor="facility-simple">Facility</InputLabel>
              <Select
                value={this.state.selectedFacility}
                onChange={this._handleChangeFacility}
                inputProps={{
                  name: "facility",
                  id: "facility-simple"
                }}
              >
                {this._buildFacilitiesMenu()}
              </Select>
            </FormControl>
            <div style={{ height: 15 + "px" }} />
            <FormControl className={classes.serviceInputFormControl} fullWidth>
              <InputLabel htmlFor="serviceTemplate-simple">
                Service Template
              </InputLabel>
              <Select
                value={this.state.selectedServiceTemplate}
                onChange={this._handleChangeTemplate}
                inputProps={{
                  name: "serviceTemplate",
                  id: "serviceTemplate-simple"
                }}
              >
                {this._buildServiceTemplateMenu()}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleCreate} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );

    return dialog;
  }
}

export default withStyles(styles)(CreateServiceModal);
