import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";
import pM from "./PopupManager";
import MenuEntry from "./MenuEntry";

class ContextSoDead  extends React.Component {

    constructor() {
        super();
        // set size for this configuration panel
        this.width = 180; this.height = 30;

        this.selectView = this.selectView.bind(this);
    }



    selectView(view) {
        switch (view) {
            case "delete":
                const msg = "delete " + this.props.soName;
                websocketCom.sendServiceMessage(msg);
                pM.deletePopup(this.props.id);
                break;
            default:
                break;
        }
    }


    _buildConfSoMenu() {

        const panel = (
            <div style={{cursor: "default"}} >
                <MenuEntry iconName="delete" text={"Delete "+this.props.soName} callback={this.selectView} />
            </div>

        )
        return panel
    }


    render() {

        // Content of panel is defined here
        const view = (<div key={"content-so-conf-"+this.props.soName}> {this._buildConfSoMenu()} </div>)

        let  geometry = this.props.location;
        //const scaleFactor = this.props.location.scaleFactor;
        geometry["width"] = this.width; geometry["height"] = this.height;

        const panel = ( <Popup geometry={geometry} content={view}
                               id={this.props.id} elementClass={this.props.elementClass}
                               delCallback={this.props.delCallback} /> )
        return panel
    }

}

//let confSO = new ConfSO();
export default ContextSoDead;

