import React from "react";
import websocketCom from "./../webcom/WebsocketCom";
import EventEmitter from "events";
import dispatcher from "../dispatcher/dispatcher";
import logger from "./../components/Logging/Logger";

// icons for devices with special names
import Generic from "./../images/deviceIcons/generic.svg";

import Earth from "./../images/deviceIcons/earth.svg";
import Neptune from "./../images/deviceIcons/neptune.svg";
import Sun from "./../images/deviceIcons/sun.svg";
import Mercury from "./../images/deviceIcons/mercury.svg";
import Venus from "./../images/deviceIcons/venus.svg";
import Mars from "./../images/deviceIcons/mars.svg";
//import Uranus from "./../images/deviceIcons/uranus.svg";
//import Jupiter from "./../images/deviceIcons/jupiter.svg";
//import Saturn from "./../images/deviceIcons/saturn.svg";
import Dagobah from "./../images/deviceIcons/dagobah.svg";
import AhchTo from "./../images/deviceIcons/ahch-to.svg";

class FacilitiesStore extends EventEmitter {
  //facilities = new Map();
  LOG_ORIGIN = "FacilitiesStore";

  constructor() {
    super();

    this._resetStore();

    // this object defines the mapping of device name onto icon and color used
    // to represent the device in the GUI
    // if a device name is not in this list, the generic icon will be
    // used for such a device
    this.deviceNameToIcon = {
      generic: { icon: Generic, color: "generic" },

      earth: { icon: Earth, color: "earth" },
      neptune: { icon: Neptune, color: "neptune" },
      sun: { icon: Sun, color: "sun" },
      mercury: { icon: Mercury, color: "mercury" },
      venus: { icon: Venus, color: "venus" },
      mars: { icon: Mars, color: "mars" },
      //uranus: { icon: Uranus, color: "uranus" },
      ////jupiter: { icon: Jupiter, color: "jupiter" },
      /////saturn: { icon: Saturn, color: "saturn" },
      "ahch-to": { icon: AhchTo, color: "core" },
      dagobah: { icon: Dagobah, color: "core" }
    };

    this._resetStore = this._resetStore.bind(this);
    
    logger.log(this.LOG_ORIGIN, "INFO", "Started.", "");
  }

  _resetStore() {
    this.facilities = new Map();
    this.periphery = {}
  }

  getFacilities() {
    return this.facilities;
  }

  getFacilitiesByName() {
    let facilityList = {};
    for (let [facilityUuid, details] of this.facilities) {
      const name = details.get("name");
      facilityList[name] = facilityUuid;
    }
    return facilityList;
  }

  getFacilityUuidByName(facName) {
    let facUuid = null;
    for (let [facilityUuid, details] of this.facilities) {
      const name = details.get("name");
      if (name === facName) {
        facUuid = facilityUuid;
        break;
      }
    }
    return facUuid;
  }

  getFacilityUuidByDevice(deviceUuid) {
    let facUuid = null;
    let found = false;

    // loop over all facilities
    for (let [facilityUuid, details] of this.facilities) {
      // get device list
      const devices = details.get("devices");
      if (devices) {
        if (devices.has(deviceUuid)) {
          // device found
          facUuid = facilityUuid;
          found = true;
          break;
        }
      }
      if (found) break;
    }
    return facUuid;
  }

  // check if a given uuid matches a facility in the database
  isFacility(uuid) {
    const found = this.facilities.has(uuid);
    return found;
  }

  // get all devices assigned to a facility
  getDevices(facUuid) {
    let found = new Map();
    if (
      this.facilities.has(facUuid) &&
      this.facilities.get(facUuid).has("devices")
    ) {
      found = this.facilities.get(facUuid).get("devices");
    }
    return found;
  }

  getUuidFromDeviceName(deviceName) {}

  _getDeviceNameFromUuid(deviceUuid) {
    const facUuid = this.getFacilityUuidByDevice(deviceUuid);
    const devices = this.getDevices(facUuid);
    const devName = devices.get(deviceUuid).get("name");
    return devName;
  }

  // get the color code of a device
  getDeviceColor(deviceUuid) {
    // simplest possible implementation: search for device in facilities map
    // loop over all facilities
    // eslint-disable-next-line no-unused-vars
    for (let [facility, details] of this.facilities) {
      // get devices assigned to the facility
      const devices = details.get("devices");
      if (devices) {
        if (devices.has(deviceUuid)) {
          // device found => report back color code
          return devices.get(deviceUuid).get("color");
        }
      }
    }
    logger.log(
      this.LOG_ORIGIN,
      "ERROR",
      "Color code for device not found:",
      deviceUuid
    );
    return null;
  }

  getDeviceIcon(deviceUuid, deviceIsActive, width) {
    const deviceName = this._getDeviceNameFromUuid(deviceUuid);
    let icon = Generic;
    if (deviceName in this.deviceNameToIcon)
      icon = this.deviceNameToIcon[deviceName].icon;

    //const deviceList = this.getDevices(
    //  this.getFacilityUuidByDevice(deviceUuid)
    //);

    //console.log("deviceList =", deviceList)

    //const deviceIsActive = ( deviceList.get(deviceUuid).get("active") === "true" );
    //console.log("deviceIsActive =", deviceIsActive)
    let imgWidth = 45;
    if (width) imgWidth = width;

    if (deviceName === "sun") imgWidth = imgWidth + 10;

    let styleImg = {};

    if (deviceIsActive) {
      styleImg = {
        minWidth: imgWidth,
        width: imgWidth,
        maxWidth: imgWidth
      };
    } else {
      styleImg = {
        filter: "grayscale(1)",
        opacity: 0.5,
        minWidth: imgWidth,
        width: imgWidth,
        maxWidth: imgWidth
      };
    }

    const iconView = (
      <div>
        <source srcSet={icon} type="image/svg+xml" />
        <img style={styleImg} src={icon} alt="Device" />
      </div>
    );
    return iconView;
  }

  _procFacilityMsgRecv_Table(msg) {
    logger.log(
      this.LOG_ORIGIN,
      "DEBUG",
      "Processing incoming facility (table) message:",
      msg.payload
    );

    const recvFacilitiesList = JSON.parse(msg.payload);

    // are all local facilities in the received facilities list?
    // if not delete local facilities that are not in the advertised
    // facilities list, as they are no longer known in the platform.
    // eslint-disable-next-line no-unused-vars
    for (let [facility, details] of this.facilities) {
      let found = false;
      for (let entry in recvFacilitiesList) {
        if (facility === recvFacilitiesList[entry]) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.facilities.delete(facility);
        logger.log(this.LOG_ORIGIN, "INFO", "Facility deleted:", facility);
      }
    }

    // are there facilities in the received facilities list that are not in local facilities db?
    // if yes, add them to the local facilities db
    for (let recvFacility in recvFacilitiesList) {
      if (!this.facilities.has(recvFacilitiesList[recvFacility])) {
        this.facilities.set(recvFacilitiesList[recvFacility], new Map());
        this.facilities
          .get(recvFacilitiesList[recvFacility])
          .set("name", recvFacility);
        this.facilities
          .get(recvFacilitiesList[recvFacility])
          .set("uuid", recvFacilitiesList[recvFacility]);
        this.facilities
          .get(recvFacilitiesList[recvFacility])
          .set("autodiscovery", "true");
        this.facilities
            .get(recvFacilitiesList[recvFacility])
            .set("devices", new Map() );
        // --------------------------------------------------------------------------
        // handle color: add color pool to new facility
        this.facilities
          .get(recvFacilitiesList[recvFacility])
          .set("colorpool", [
            "DeepOrange",
            "LightGreen",
            "DeepPurple",
            "LightBlue"
          ]);
        // --------------------------------------------------------------------------

        logger.log(
          this.LOG_ORIGIN,
          "INFO",
          "Facility added:",
          recvFacilitiesList[recvFacility]
        );
      }
    }
    this.emit("ChangeEvent_FacilityDbUpdate");
  }

  _procDeviceTable(msg) {
    logger.log(
      this.LOG_ORIGIN,
      "DEBUG",
      "Processing incoming facility (device table) message:",
      msg.payload
    );

    // check reported facility for devices has entry in facilities db
    if (!this.facilities.has(msg.fac)) {
      // FATAL!!! - msg from unknown facility uuid should already be blocked by MsgHandler
      logger.log(
        this.LOG_ORIGIN,
        "FATAL",
        "Facility uuid in devices message unknown:",
        msg.fac
      );
      return;
    }

    if (!this.facilities.get(msg.fac).has("devices")) {
      this.facilities.get(msg.fac).set("devices", new Map());
    }

    const recvDevTable = msg.payload.join("");
    const recvDevicesList = JSON.parse(recvDevTable);
    // are all local devices in the received devices list?
    // if not delete local devices that are not in the advertised
    // devices list, as they are no longer known in the platform.
    const deviceList = this.facilities.get(msg.fac).get("devices");
    // eslint-disable-next-line no-unused-vars
    for (let [device, details] of deviceList) {
      let found = false;
      for (let entry in recvDevicesList) {
        if (device === recvDevicesList[entry]) {
          found = true;
          break;
        }
      }
      if (!found) {
        // --------------------------------------------------------------------------
        // handle color
        const color = deviceList.get(device).get("color");
        // only put color back in colorpool if not special device name color
        if (!("color" in this.deviceNameToIcon)) {
          const colorpoolRef = this.facilities.get(msg.fac).get("colorpool");
          if (color !== "LightBlue") colorpoolRef.unshift(color); // put color back in pool for further use
        }
        // --------------------------------------------------------------------------
        deviceList.delete(device);
        logger.log(this.LOG_ORIGIN, "INFO", "Device deleted:", device);
      }
    }

    // are there devices in the received devoces list that are not in local devices db?
    // if yes, add them to the local devices db
    for (let entry in recvDevicesList) {
      if (!deviceList.has(recvDevicesList[entry])) {
        deviceList.set(recvDevicesList[entry], new Map());
        deviceList.get(recvDevicesList[entry]).set("name", entry);
        deviceList
          .get(recvDevicesList[entry])
          .set("uuid", recvDevicesList[entry]);
        deviceList.get(recvDevicesList[entry]).set("active", "false");
        deviceList.get(recvDevicesList[entry]).set("devcon", new Map());
        deviceList
          .get(recvDevicesList[entry])
          .get("devcon")
          .set("status", "");
        deviceList
          .get(recvDevicesList[entry])
          .get("devcon")
          .set("utilization", [0, 0, 0]);
        deviceList.get(recvDevicesList[entry]).set("coreversion", "unknown");
        deviceList.get(recvDevicesList[entry]).set("coreupdateready", "unknown");
        deviceList.get(recvDevicesList[entry]).set("cluster", []);
        deviceList.get(recvDevicesList[entry]).set("role", []);
        // --------------------------------------------------------------------------
        // handle color
        //
        const device = deviceList.get(recvDevicesList[entry]).get("name");

        let color = "";
        if (device in this.deviceNameToIcon) {
          color = this.deviceNameToIcon[device]["color"];
        } else {
          const colorpoolRef = this.facilities.get(msg.fac).get("colorpool");
          color = colorpoolRef.shift();
          if (color === "LightBlue") colorpoolRef.unshift(color); // put last color back for further use
        }

        deviceList.get(recvDevicesList[entry]).set("color", color);
        // --------------------------------------------------------------------------

        // get software release running on the new device
        let message = recvDevicesList[entry] + " getcoreversion";
        websocketCom.sendOutOfBandMessage(message, msg.fac);

        // check for update
        const deviceData = deviceList.get(recvDevicesList[entry])
        const deviceUuid = deviceData.get("uuid")
        // message = deviceUuid + " update"
        message = deviceUuid + " exec update_core"
        websocketCom.sendOutOfBandMessage(message, this.getFacilityUuidByName("genesis"));

        logger.log(
          this.LOG_ORIGIN,
          "INFO",
          "Device added:",
          recvDevicesList[entry]
        );
      }
    }
    this.emit("ChangeEvent_FacilityDbUpdate");
  }

  _procDeviceActiveTable(msg) {
    logger.log(
      this.LOG_ORIGIN,
      "DEBUG",
      "Processing incoming facility (active device table) message:",
      msg.payload
    );
    // check reported facility for active devices has entry in facilities db
    if (!this.facilities.has(msg.fac)) {
      // FATAL!!! - msg from unknown facility uuid should already be blocked by MsgHandler
      logger.log(
        this.LOG_ORIGIN,
        "FATAL",
        "Facility uuid in activetable message unknown:",
        msg.fac
      );
      return;
    }
    if (!this.facilities.get(msg.fac).has("devices")) {
      return;
      // this.facilities.get(msg.fac).set("devices", new Map());
    }
    const recvDevTable = msg.payload.join("");
    const recvDevicesList = JSON.parse(recvDevTable);
    const deviceList = this.facilities.get(msg.fac).get("devices");


    // check if we have devices that became active again
    // most likely this is due to an cenocore sw update that has been just carried out
    for (let activeDevice in recvDevicesList) {
        const activeDeviceUuid = recvDevicesList[activeDevice];
        if (deviceList.has(activeDeviceUuid)) {
            const isActive = deviceList.get(activeDeviceUuid).get("active");
            if ( isActive === "false") {
                let message = activeDeviceUuid + " getcoreversion";
                websocketCom.sendOutOfBandMessage(message, this.getFacilityUuidByName("genesis"));
                message = activeDeviceUuid + " exec update_core"
                websocketCom.sendOutOfBandMessage(message, this.getFacilityUuidByName("genesis"));
            }
        } 
    } 


    // mark all devices as not active and then update with just recieved
    // active devices list
    for (let device of deviceList) {
      deviceList.get(device[0]).set("active", "false");
    }

    for (let activeDevice in recvDevicesList) {
      const activeDeviceUuid = recvDevicesList[activeDevice];
      if (deviceList.has(activeDeviceUuid)) {
        deviceList.get(activeDeviceUuid).set("active", "true");
      } else {
        logger.log(
          this.LOG_ORIGIN,
          "ERROR",
          "Device uuid in activetable message unknown.",
          ""
        );
      }
    }
    this.emit("ChangeEvent_FacilityDbUpdate");
  }

  _procAutoDiscovery(msg) {
    this.facilities.get(msg.fac).set("autodiscovery", msg.payload);
    this.emit("ChangeEvent_FacilityDbUpdate");
  }

  _procDeviceAdded(msg) {
    const deviceList = this.facilities.get(msg.fac).get("devices");
    const deviceName = msg.payload[0];
    const deviceUuid = msg.payload[1];

    deviceList.set(deviceUuid, new Map());
    deviceList.get(deviceUuid).set("name", deviceName);
    deviceList.get(deviceUuid).set("uuid", deviceUuid);
    deviceList.get(deviceUuid).set("active", "false");
    deviceList.get(deviceUuid).set("devcon", new Map());
    deviceList
      .get(deviceUuid)
      .get("devcon")
      .set("status", "");
    deviceList
      .get(deviceUuid)
      .get("devcon")
      .set("utilization", [0, 0, 0]);
    deviceList.get(deviceUuid).set("coreversion", "unknown");
    deviceList.get(deviceUuid).set("coreupdateready", "unknown");
    deviceList.get(deviceUuid).set("cluster", []);
    deviceList.get(deviceUuid).set("role", []);
    // --------------------------------------------------------------------------
    // handle color

    let color = "";
    if (deviceName in this.deviceNameToIcon) {
      color = this.deviceNameToIcon[deviceName]["color"];
    } else {
      const colorpoolRef = this.facilities.get(msg.fac).get("colorpool");
      color = colorpoolRef.shift();
      if (color === "LightBlue") colorpoolRef.unshift(color); // put last color back for further use
    }

    deviceList.get(deviceUuid).set("color", color);
    // --------------------------------------------------------------------------

    // get software release running on the new device
    let message = deviceUuid + " getcoreversion";
    websocketCom.sendOutOfBandMessage(message, msg.fac);

    // check for update
    //message = deviceUuid + " update"
    message = deviceUuid + " exec update_core"
    websocketCom.sendOutOfBandMessage(message, this.getFacilityUuidByName("genesis"));

    logger.log(this.LOG_ORIGIN, "INFO", "Device added:", msg.payload[1]);
    this.emit("ChangeEvent_FacilityDbUpdate");
  }

  _procDevconSet(msg) {
    const device = this.facilities
      .get(msg.fac)
      .get("devices")
      .get(msg.device);

    if (device) {
      if (!device.has("devcon")) device.set("devcon", new Map());
      const devcon = device.get("devcon");

      if (!devcon.has("status")) {
        devcon.set("status", "");
        devcon.set("utilization", [0, 0, 0]);
      }

      devcon.set("status", msg.payload[0]);
      devcon.set("utilization", [
        msg.payload[1],
        msg.payload[2],
        msg.payload[3]
      ]);
      this.emit("ChangeEvent_FacilityDbUpdate");
    }
  }

  _procCoreversionSet(msg) {
    const device = this.facilities
      .get(msg.fac)
      .get("devices")
      .get(msg.device);

    if (device) {
      device.set("coreversion", msg.payload);
      this.emit("ChangeEvent_FacilityDbUpdate");
    }
  }

  _procCluster(msg) {
    const device = this.facilities
      .get(msg.fac)
      .get("devices")
      .get(msg.device);
    if (device) {
      device.set("cluster", msg.payload);
      this.emit("ChangeEvent_FacilityDbUpdate");
    }
  }

  _procDevrole(msg) {
    const device = this.facilities
      .get(msg.fac)
      .get("devices")
      .get(msg.device);

    if (device) {
      device.set("role", msg.payload);
      this.emit("ChangeEvent_FacilityDbUpdate");
    }
  }


  _procPeripherySource(msg) {
    const peripheryDevice = { "code": msg.payload, "response": "Periphery file download success", "error": "", "status": "OK", "execResponse": "" }
    this.periphery[msg.device] = peripheryDevice;
    this.emit("ChangeEvent_PeripherySourceDownload")
  }

  _procPeripheryLoad(msg) {
    if (!this.periphery.hasOwnProperty(msg.device)) {
      console.log("Fatal error: Periphery load update for unknown device. This should never happen!")
      return
    }

    let periphery = this.periphery[msg.device]

    if (msg.status === "OK") {
      periphery.response = "Periphery file upload success";
      periphery.error = "";
      periphery.status = "OK";
      periphery.execResponse = msg.payload;
    } else {
      periphery.response = "Periphery file upload error";
      periphery.error = msg.payload;
      periphery.status = "ERROR";
      periphery.execResponse = "";      
    }
    this.periphery[msg.device] = periphery;
    this.emit("ChangeEvent_PeripheryUpload");
  }

  _procDeviceUpdate(msg) {
    const device = this.facilities
      .get(msg.fac)
      .get("devices")
      .get(msg.device);


    const payload = JSON.parse(msg.payload)      
    
    const status = payload.status
    if (device) {

        if (status === "noupdate") {
            device.set("coreupdateready", "unknown")
        } else if (status === "artifact ready for update") {
            const updateVersion = payload.version
            device.set("coreupdateready", updateVersion)
        } else {
            device.set("coreupdateready", "error");
        }
        this.emit("ChangeEvent_FacilityDbUpdate");
    }


//    if (device) {
//      if (msg.status === "done") {
//        device.set("coreupdateready", msg.payload);
//      } else {
//        device.set("coreupdateready", "error");
//      }
//      this.emit("ChangeEvent_FacilityDbUpdate");
//    }
  }


  getPeripheryDevice(deviceUuid) {
    return this.periphery[deviceUuid]
  }


  _handleActions(action) {
    switch (action.type) {
      case "ACTION_SessionLifecycle": {
        if (action.msg["status"] === "close") {
          this._resetStore();
          console.log("Reset FacilitiesStore");
        }
        break;
      }

      case "ACTION_FacilitiesMessageReceived": {
        //
        //
        //  Messages with scope facility
        //
        if (action.msg.cmd === "table") {
          this._procFacilityMsgRecv_Table(action.msg);
        }
        if (action.msg.cmd === "device_table") {
          //console.log("in device table: ", action.msg);
          this._procDeviceTable(action.msg);
        }

        if (action.msg.cmd === "active_table") {
          //console.log("in active table: ", action.msg);
          this._procDeviceActiveTable(action.msg);
        }

        if (action.msg.cmd === "autodiscovery") {
          this._procAutoDiscovery(action.msg);
        }
        if (action.msg.cmd === "device_added") {
          this._procDeviceAdded(action.msg);
        }
        //
        //
        //  Messages with scope device
        //
        if (action.msg.cmd === "devcon_set") {
          this._procDevconSet(action.msg);
        }
        if (action.msg.cmd === "coreversion") {
          this._procCoreversionSet(action.msg);
        }
        if (action.msg.cmd === "cluster") {
          this._procCluster(action.msg);
        }
        if (action.msg.cmd === "devrole") {
          this._procDevrole(action.msg);
        }
        if (action.msg.cmd === "periphery source") {
          this._procPeripherySource(action.msg)
        }
        if (action.msg.cmd === "periphery load") {
          this._procPeripheryLoad(action.msg)
        }
        if (action.msg.cmd === "update") {
          this._procDeviceUpdate(action.msg)
        }
        break;
      }
      default: {
        // nothing to be done here
        break;
      }
    }
  }
}

const facilitiesStore = new FacilitiesStore();
dispatcher.register(facilitiesStore._handleActions.bind(facilitiesStore));
export default facilitiesStore;
