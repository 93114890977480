import * as SessionActions from "./SessionActions";
import * as WebsocketActions from "./WebsocketActions";
import * as ServiceActions from "./ServiceActions";

class ActionCreators {
	// -----------------------------------------------------
	// Session scope
	SessionLifecycle(status, options) {
		const action_data = {
			status: status,
			user_name: options.user_name || "",
            platform_identifier: options.platform_identifier || "",
            client_uuid: options.client_uuid || "",
            token: options.token || "",
            email: options.email || "",
            given_name: options.given_name || "",
            family_name: options.family_name || "",
            department: options.department || "",
            city: options.city || "",
            admin_rights: options.admin_rights || "",
            
    };
		SessionActions.SessionLifecycle(action_data);
	}

	// -----------------------------------------------------
	// Websocket dispatcher
	// status = [ list, description]
	WebsocketLifecycle(status, options) {
		const action_data = {
			status: status
		};
		WebsocketActions.WebsocketLifecycle(action_data);
	}

	// -----------------------------------------------------
	// Dispatcher: dispatcher.
	// status = {change}
	ActiveServiceChange(status, options) {
		const action_data = {
			status: status,
			service_name: options.service_name || "",
			service_uuid: options.service_uuid || ""
		};
		ServiceActions.changeActiveService(action_data);
	}


    ActiveServiceClose() {
        const action_data = {
            status: "close"
        }
        ServiceActions.closeActiveService(action_data);
    }

}

const actionCreators = new ActionCreators();
export default actionCreators;
