import React from "react";
//import config from "./../config";

import VisibilityIcon from '@material-ui/icons/Visibility';
import Crop75Icon from '@material-ui/icons/Crop75';
import CropDinIcon from '@material-ui/icons/CropDin';
import SpeedIcon from '@material-ui/icons/Speed';


class MenuEntryView extends React.Component {

  constructor() {
    super();

    this.state = { color: "black",
                   color_bg: "transparent" };

    this._toggleColor = this._toggleColor.bind(this);
  }


  _setView(event, size) {
    this.props.callback(size)
  }


  _buildViewSelection() {
    let selLargeView = 
        <CropDinIcon style={{width: 15, height: 15, marginRight: 8, marginBottom: 2}}
                     onClick={(event) => this._setView(event, "large")} />

      if (this.props.entries === "restricted") selLargeView = <span /> 
    const view = (
      <div>
        <Crop75Icon style={{width: 16, height: 16, marginRight: 8, marginBottom: 0}}
        onClick={(event) => this._setView(event, "compact")} />
        {selLargeView}
        <SpeedIcon style={{width: 18, height: 18, marginRight: 8, marginBottom: 0}}
                   onClick={(event) => this._setView(event, "panel")} />
      </div>
    )

    const entry = (
      <div style={{display: "flex"}} >
        <div style={{paddingTop: 2}} >View:</div>
        <div style={{display: "flex", paddingLeft: 8, paddingTop: 0}} >{view}</div>
      </div> )

    return entry;
  }


  _toggleColor() {
    const newColor = ( this.state.color === "black") ? "black" : "black";
    let newColor_bg = ( this.state.color_bg === "transparent") ? "WhiteSmoke" : "transparent";
    this.setState({color: newColor, color_bg: newColor_bg})
  }

  render() {

    const panel = (
      <div style={{paddingTop: 5, paddingBottom: 1, display: "flex", flexDirection: "row", alignItems: "center",
                   color: this.state.color, backgroundColor: this.state.color_bg,
                   marginRight: 1}}
           onMouseEnter = { () => this._toggleColor() }
           onMouseLeave = { () => this._toggleColor() } >
        <div style={{width: 10}} />
        <div> <VisibilityIcon style={{width: 16, height: 16}} /> </div>
        <div style={{width: 10}} />
        {this._buildViewSelection()}
      </div>
    )

    return panel
  }

}

export default MenuEntryView;
