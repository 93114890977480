import React from "react";
//import { withRouter } from "react-router-dom";
//import { compose } from "redux";

//import DevSidebar from "./../common/DevSidebar";
import DevHeader from "./../EditorFrame/DevHeader";
import ServiceGraph from "./../../components/ServiceGraph/ServiceGraph";
import SideBar from "./SideBar";
import CanvasNav from "./../../components/ServiceGraph/CanvasMap/CanvasNav_NG";
import Divider from "./Divider_NG";
import CodeDev from "./../../components/CodeDev/CodeDev";
//import InfoView from "./../../components/InfoView/InfoView";
import NotificationSign from "./../../components/NotificationSign";
import CreateSOButton from "./../../components/ServiceGraph/CreateSOButton";
//import DagreGraphModel from "./../../components/ServiceGraph/DagreGraphModel";
import aSMS from "./../../stores/ActiveServiceMgmtStore";
//import activeServiceDataStore from "./../../stores/ActiveServiceDataStore";
import uS from "./../../stores/UtilityStore";
import Authenticate from "./../../components/Authenticate";
import CreateSoDialog from "./Dialog_Create_SO";
import DeleteSoDialog from "./Dialog_Delete_SO";
import SaveSoDialog from "./Dialog_Save_SO";
import LoadSoDialog from "./Dialog_Load_SO";
import config from "./../../components/config";
//import Button from "@material-ui/core/Button";
import ServiceIcon from '@material-ui/icons/AccountTree';

class ServiceDevPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.STATUSPANELHEIGHT = config.setup.layout.statusPanelHeight;
    this.INFOPANELWIDTH = 350;

    this.canvas = { "width": config.setup.canvas.width, "height": config.setup.canvas.height,
                    "viewX": 0, "viewY": 0,
                    "viewWidth": window.innerWidth-config.setup.layout.routingPanelNav,
                    "viewHeight": window.innerHeight-config.setup.layout.headerPanelHeight-config.setup.layout.statusPanelHeight,
                    "scale": config.setup.canvas.scaleFactor, "isNavActive": true }


    this.state = {
      svg_graph_scale_factor: 1.0,
      svg_graph_orientation: "LR",
      showCreateSoDialogOpen: false,
      showDeleteSoDialogOpen: false,
      showSaveSoDialogOpen: false,
      showLoadSoDialogOpen: false,

      infoPanelWidth: 0,

      viewX: 0,   // offset x scroll position of svg canvas
      viewY: 0,   // offset y scroll position of svg canvas
      //isNavActive: false,
      serviceName: ""
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.increaseScaleFactor = this.increaseScaleFactor.bind(this);
    this.decreaseScaleFactor = this.decreaseScaleFactor.bind(this);
    this.resetScaleFactor = this.resetScaleFactor.bind(this);
    //this.showHideNav = this.showHideNav.bind(this);
    this.setGraphOrientationLR = this.setGraphOrientationLR.bind(this);
    this.setGraphOrientationTB = this.setGraphOrientationTB.bind(this);
    this.openCreateSoDialog = this.openCreateSoDialog.bind(this);
    this.closeCreateSoDialog = this.closeCreateSoDialog.bind(this);
    this.openDeleteSoDialog = this.openDeleteSoDialog.bind(this);
    this.closeDeleteSoDialog = this.closeDeleteSoDialog.bind(this);
    this.openSaveSoDialog = this.openSaveSoDialog.bind(this);
    this.closeSaveSoDialog = this.closeSaveSoDialog.bind(this);
    this.openLoadSoDialog = this.openLoadSoDialog.bind(this);
    this.closeLoadSoDialog = this.closeLoadSoDialog.bind(this);
    this._windowDidResize = this._windowDidResize.bind(this);
    this._onScroll = this._onScroll.bind(this);
    this.debugPanelResize = this.debugPanelResize.bind(this);
    this._windowDidResize = this._windowDidResize.bind(this);
  }

  componentDidMount() {
    uS.on("window_size_changed", this._windowDidResize);
    this.setState({serviceName: aSMS.getActiveServiceName()});
    // hack for react router 4
    // need to call the following two functions to ensure
    // redraw of component when this page is called
    // with react router history mechanism
  //activeServiceDataStore.emitChange();
    //WindowManagerStore.emitChange();
  //DagreGraphModel.modelUpdate();
  }

  componentWillUnmount() {
    uS.removeListener("window_size_changed", this._windowDidResize);
  }

  increaseScaleFactor() {
    this.setState({ svg_graph_scale_factor: this.state.svg_graph_scale_factor * 1.1 });
  }

  decreaseScaleFactor() {
    this.setState({ svg_graph_scale_factor: this.state.svg_graph_scale_factor / 1.1 });
  }

  resetScaleFactor() { this.setState({svg_graph_scale_factor: 1}); }

  //showHideNav() { this.setState({isNavActive: !this.state.isNavActive}); }

  setGraphOrientationLR() {
    this.setState({
      svg_graph_orientation: "LR"
    });
  }

  setGraphOrientationTB() {
    this.setState({
      svg_graph_orientation: "TB"
    });
  }

  debugPanelResize(newPos) { this.setState({infoPanelWidth: newPos }); }

  _windowDidResize() { this.forceUpdate(); }

  _onScroll(event) {
    let element = document.getElementById("container-svg-graph")
    if (element) {
      this.setState({viewX: element.scrollLeft, viewY: element.scrollTop});
    }
  }

  setCanvasPos(newPosition) {
    let element = document.getElementById("container-svg-graph")
    element.scrollTo(newPosition.x, newPosition.y);
  }

  openCreateSoDialog() { this.setState({ showCreateSoDialogOpen: true }); }
  closeCreateSoDialog() { this.setState({ showCreateSoDialogOpen: false }); }
  openDeleteSoDialog() { this.setState({ showDeleteSoDialogOpen: true }); }
  closeDeleteSoDialog() { this.setState({ showDeleteSoDialogOpen: false }); }
  openSaveSoDialog() { this.setState({ showSaveSoDialogOpen: true }); }
  closeSaveSoDialog() { this.setState({ showSaveSoDialogOpen: false }); }
  openLoadSoDialog() { this.setState({ showLoadSoDialogOpen: true }); }
  closeLoadSoDialog() { this.setState({ showLoadSoDialogOpen: false }); }


  render() {

    // prepare data for CanvasNav_NG
    if (document.getElementById("container-svg-graph")) {
      //this.canvas.viewWidth = document.getElementById('container-svg-graph').offsetWidth;

      this.canvas.viewWidth = window.innerWidth - config.setup.layout.routingPanelNav - this.state.infoPanelWidth;
      this.canvas.viewX = this.state.viewX;
      this.canvas.viewY = this.state.viewY;
      //this.canvas.viewHeight = document.getElementById('container-svg-graph').offsetHeight;
      //this.canvas.viewHeight = window.innerHeight - 90
      this.canvas.viewHeight = window.innerHeight - config.setup.layout.headerPanelHeight-config.setup.layout.statusPanelHeight;
      this.canvas.scale = this.state.svg_graph_scale_factor;
      //this.canvas.isNavActive = this.state.isNavActive;
    }

    // calculate lower right position (se - southeast) of nav panel
    this.sePosNavPanel = {"x": this.state.infoPanelWidth+6, "y": this.STATUSPANELHEIGHT }

    return (
      <div id="main-column-frame" style={{height: 100 + "%",width: 100 + "%",minWidth: 800}} >
        <Authenticate />

        <CreateSoDialog
          open={this.state.showCreateSoDialogOpen}
          closeHandle={this.closeCreateSoDialog}
        />
        <DeleteSoDialog
          open={this.state.showDeleteSoDialogOpen}
          closeHandle={this.closeDeleteSoDialog}
        />
        <SaveSoDialog
          open={this.state.showSaveSoDialogOpen}
          closeHandle={this.closeSaveSoDialog}
        />
        <LoadSoDialog
          open={this.state.showLoadSoDialogOpen}
          closeHandle={this.closeLoadSoDialog}
        />

        <div style={{height: config.setup.layout.headerPanelHeight}} />

        <div>
          <NotificationSign />
        </div>

        <div id ="test" style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", height: 100+"%" }}>

          <div style={{ width: config.setup.layout.routingPanelNav, minWidth: config.setup.layout.routingPanelNav,
                        backgroundColor: "white",borderRightColor: "gray", borderRightWidth: 2, borderRightStyle:"solid" }}>
            <SideBar />
          </div>
          <DevHeader />

          <div id="container-svg-graph"
               onScroll = {this._onScroll}
               style={{ flexGrow: 1,  overflow: "scroll", width: 100+"%", minWidth: 400, height: window.innerHeight-config.setup.layout.headerPanelHeight-config.setup.layout.statusPanelHeight}}>
            <ServiceGraph
                svg_graph_orientation={this.state.svg_graph_orientation}/>
          </div>

          <Divider />

          <div id="info-setup" style={{backgroundColor: "white", width: this.state.infoPanelWidth, minWidth: this.state.infoPanelWidth }}>
            <CodeDev xOffset={this.state.infoPanelWidth+2} resizeCallback={this.debugPanelResize} />
          </div>


        </div>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", height: this.STATUSPANELHEIGHT, backgroundColor: "#FF8001", borderTop: "1px solid gray", color: "white"}}>
          <div style={{paddingLeft: 10, paddingTop: 4, paddingRight: 6}} >
            <ServiceIcon />
          </div>
          <div style={{fontFamily: "Roboto", fontSize: 14, fontWeight: "bold", paddingBottom: 1, color: "White"}} >
            {this.state.serviceName}
          </div>
          <div style={{minHeight: this.STATUSPANELHEIGHT, width: 2, marginLeft: 15, backgroundColor: "MintCream"}} />

        </div>

        <CanvasNav canvas={this.canvas}
                   setCanvasPosCallback={this.setCanvasPos}
                   sePos={this.sePosNavPanel} />



        <CreateSOButton ePosNavPanel={this.state.infoPanelWidth} callback={this.openCreateSoDialog} />

      </div>
    );
  }
}

export default (ServiceDevPage);
