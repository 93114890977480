import React from "react";
import websocketCom from '../../../webcom/WebsocketCom';
import aSDS from '../../../stores/ActiveServiceDataStore';
import dragDropStore from '../DragDropStore';
import selectionManager from '../../SelectionManager/SelectionManager';
import pM from '../../PopUp/PopupManager';
import config from '../../config'

;
class PortNG extends React.Component {

  constructor(props) {
    super(props);
    this.mouseDown = false; // helper to detect when a drag operation starts on the port
    this.id = "port "+this.props.soName+" "+this.props.port.name;

    this.state = ({ isDragOngoing: false,
      dragX: 0, dragY: 0, isSelect: false, isHover: false,
      detailViewPort: ""
    });

    this._handleMouseOver = this._handleMouseOver.bind(this);
    this._handleMouseLeave = this._handleMouseLeave.bind(this);
    this._handleMouseDown = this._handleMouseDown.bind(this);
    this._handleMouseMove = this._handleMouseMove.bind(this);
    this._handleMouseUp = this._handleMouseUp.bind(this);
    this._responseDrag = this._responseDrag.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this._drawDetailsBackground = this._drawDetailsBackground.bind(this);
    this._drawPort = this._drawPort.bind(this);
    this._cE_SelectionUpdate = this._cE_SelectionUpdate.bind(this);
  }

  componentDidMount() {
    window.addEventListener("mousemove", this._handleMouseMove);
    selectionManager.on( "ChangeEvent_Selection", this._cE_SelectionUpdate );
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this._handleMouseMove);
    selectionManager.removeListener( "ChangeEvent_Selection", this._cE_SelectionUpdate );
  }


  _handleMouseMove(event) {
    const drawerWidth = config.setup.layout.routingPanelNav;
    const headerHeight = config.setup.layout.headerPanelHeight;
    if (this.state.isDragOngoing) {
      this.setState({dragX: event.x-drawerWidth, dragY: event.y-headerHeight});
    }
  }

  _cE_SelectionUpdate() {
    const resp = selectionManager.checkSelect(this.id);
    const isSelected = resp[0]; const scope = resp[1];

    if (isSelected) {
      this.setState({isSelect: true });
      this.selectionScope = scope;
    } else {
      this.setState({isSelect: false });
      this.selectionScope = null;
    }
    const detailViewPort = selectionManager.getPortViewSelection(this.props.soName);
    this.setState({detailViewPort: detailViewPort})
  }

  _handleMouseDown(event) {
    if (event.button === 0) {
      this.mouseDown = true;
      event.stopPropagation();
    }
  }

  _handleMouseUp(event) {
    event.stopPropagation();
    this.mouseDown = false;
    dragDropStore.connectionDrop(this.props.soName, this.props.port.name)
  }

  _handleClick(event) {
    event.stopPropagation();
    const scope = event.shiftKey ? "secondary" : "primary";
    selectionManager.toggle(this.id, scope, "port" );
  }

  _handleMouseLeave() {
    if (this.mouseDown) {
      const dragData = {"callback": this._responseDrag}
      dragDropStore.startConnectionDrag(dragData);
      this.mouseDown = false;
      this.setState({isDragOngoing: true});
    }
    this.setState({isHover: false});
  }

  _handleMouseOver() {
    this.setState({isHover: true});
  }


  _handleContextMenu(event) {
    event.preventDefault();
    let element = document.getElementById("container-svg-graph")
    if (!element) { return }
    pM.deletePopups();
    const location = {"x": ((element.scrollLeft+event.clientX-80)/this.props.scaleFactor),
                      "y": ((element.scrollTop+event.clientY-150)/this.props.scaleFactor),
                      "scaleFactor": this.props.scaleFactor }
    pM.addPopup(this.props.soName, this.props.port.name, location, "contextport", "MediumSeaGreen")
  }


  _responseDrag(response) {
    this.setState({isDragOngoing: false})
    if (response.status === "cancel") {
      console.log("connection drag canceled")
    } else if (response.status === "success") {

      let msg = ""
      // add source -> target connection
      // onle send if target is not a server port
      const soData1 = aSDS.getSmartObjectData(this.props.soName);
      if (soData1 === null) {return}
      if (!soData1.get("ports").has(this.props.port.name)) {return}
      const status1 = soData1.get("ports").get(this.props.port.name).get("connectstatus");
      if (status1 !== "nil") {
        msg = this.props.soName+" addcon "+this.props.port.name+" "+response.soName+"::"+response.portName
        websocketCom.sendServiceMessage(msg);
      }
      // add target -> source connection
      // onle send if target is not a server port
      const soData2 = aSDS.getSmartObjectData(response.soName);
      if (soData2 === null) {return}
      if (!soData2.get("ports").has(response.portName)) {return}
      const status2 = soData2.get("ports").get(response.portName).get("connectstatus");
      if (status2 !== "nil") {
        msg = response.soName+" addcon "+response.portName+" "+this.props.soName+"::"+this.props.port.name
        websocketCom.sendServiceMessage(msg);
      }
    }
  }


  _drawPortLabel(location) {
    const port = this.props.port;
    const graph_data = this.props.graphData.graph_data;
    const alignment = this.props.port.alignment;
    const x = graph_data.x;
    const y = graph_data.y;
    const length = port.name.length;
    const width = length*7 + 16
    let xoffset = -((length/2)*7)-8; let yoffset = 15;

    if (location === "inside") {
      if (alignment === "left") {
        xoffset = 20; yoffset = -10;
      } else if (alignment === "right") {
        xoffset = -(length*7)-35; yoffset = -10;
      }
    }

    let labelColor_bg = this.state.isSelect ? "blue" : "#424242";
    if (this.state.isDragOngoing) {labelColor_bg = "red"}

    const portLabel = (
      <g key={"portlabel-"+port.name} style={{cursor: "default"}}
         onClick={(event) => this._handleClick(event)}
      >
        <rect x={x+port.xpos+xoffset} y={y+port.ypos+yoffset} rx="5" ry="5" width={width} height="22"
              fill={labelColor_bg} stroke={labelColor_bg} fillOpacity="0.9" strokeOpacity="0.5" />
        <text x={x+port.xpos+xoffset+8} y={y+port.ypos+yoffset+15} textLength={width-16} fontSize="14"
              style={{fontFamily: "Roboto", fill: "white", fontWeight: "bold" }}>
          {port.name}
        </text>
      </g>
    );
    return portLabel;
  }


  _drawDetailsBackground() {
    const view = this.props.graphData.graph_extension.view;
    const port = this.props.port;
    const graph_data = this.props.graphData.graph_data;
    const alignment = this.props.port.alignment;
    const x = graph_data.x;
    const y = graph_data.y;

    const selectColor = this.props.so_ac_col;

    //const detailViewPort = selectionManager.getPortViewSelection(this.props.soName);

    if (this.state.detailViewPort === port.name && view==="large") {

    const x_offset = alignment === "left" ? 6 : -21


      const portDetailsBackground = (
        <g key={this.props.soName+"-"+port.name+"-detail"}
           onContextMenu={(event) => this._handleContextMenu(event)}
        >
          <rect x={x+port.xpos+x_offset} y={y+port.ypos-25} width="15" height="50" fill={selectColor} />
          <line x1={x+port.xpos+x_offset} y1={y+port.ypos-26} x2={x+port.xpos+x_offset+15} y2={y+port.ypos-26} style={{strokeWidth: 1, stroke: "LightGray"}}  />
          <line x1={x+port.xpos+x_offset} y1={y+port.ypos-24+50} x2={x+port.xpos+x_offset+15} y2={y+port.ypos-24+50} style={{strokeWidth: 1, stroke: "LightGray"}}  />
          <circle cx={x+port.xpos} cy={y+port.ypos} r="25"  fill={selectColor} fillOpacity="1" />
        </g> );
      return portDetailsBackground;
    }
    return <g key={this.props.soName+"-"+port.name+"-detail"} />
  }



  _drawPort() {
    let portContainer = []
    const port = this.props.port
    //const soName = this.props.soName;
    const graph_data = this.props.graphData.graph_data;
    const x = graph_data.x;
    const y = graph_data.y;
    const issue = this.props.port.issue;
    const portStatus = this.props.port.status;

    let selectColor = this.state.isSelect ? "blue" : "transparent";
    if (this.state.isDragOngoing) {selectColor = "red"}

    let portColor;
    switch (portStatus) {
      case "nil": portColor="black"; break;
      case "empty": portColor="red"; break;
      default: portColor="yellow"; break
    }

    // draw port background if this port is currently selected for detailded view
    const portBackground = this._drawDetailsBackground();
    portContainer.push(portBackground);

    const portFigure = (
        <g key={port.name}
           onMouseDown={event => this._handleMouseDown(event)}
           onMouseUp={(event) => this._handleMouseUp(event)}
           onMouseOver={(event) => this._handleMouseOver(event)}
           onMouseLeave={() => this._handleMouseLeave()}
           onClick={(event) => this._handleClick(event)}
           onContextMenu={(event) => this._handleContextMenu(event)}
        >
          <circle cx={x+port.xpos} cy={y+port.ypos} r="16"  fill={selectColor} fillOpacity="1" />
          <circle cx={x+port.xpos} cy={y+port.ypos} r="9" stroke="white" strokeWidth="2" fill="white" />
          <circle cx={x+port.xpos} cy={y+port.ypos} r="7" stroke="black" strokeWidth="2" fill={portColor} />
        </g> );

    const issueMarker= (
      <g key={port.name+"-issue-marker"} >
          <circle cx={x+port.xpos+8} cy={y+port.ypos-8} r="5" stroke="white" strokeWidth="1" fill="red" />
      </g> );

    if (this.state.isDragOngoing) {
      const element = document.getElementById("container-svg-graph")
      const offsetX = element.scrollLeft; const offsetY = element.scrollTop
      //const scaleFactor = gLS.getScaleFactor();

      const x1 = (x+port.xpos); const y1 = (y+port.ypos);
      const x2 = (this.state.dragX+offsetX)/this.props.scaleFactor; const y2 = (this.state.dragY+offsetY)/this.props.scaleFactor;
      const freeCursorX = x1>=x2 ? 5 : -5 ;
      const freeCursorY = y1>=y2 ? 5 : -5 ;

      const dragLine = (
        <g key={port.name+"-dragline"} >
          <line x1={x1} y1={y1} x2={x2+freeCursorX} y2={y2+freeCursorY} stroke="red" strokeWidth="4" />
        </g>
      )
      portContainer.push(dragLine);
    }

    portContainer.push(portFigure);
    if (issue.length > 0) {portContainer.push(issueMarker); } // issue is array with error messages

    //const detailViewPort = selectionManager.getPortViewSelection(this.props.soName);
    const view = this.props.graphData.graph_extension.view;

    if (view === "large") {
      if (this.props.showPortDetails){
        if ( (this.state.isHover || this.state.isDragOngoing) && (this.state.detailViewPort !== port.name)  ) {
          portContainer.push(this._drawPortLabel("outside"))
        }
      } else {
        portContainer.push(this._drawPortLabel("inside"))
      }
    } else {
      if ( this.state.isHover || this.state.isSelect || this.state.isDragOngoing) { portContainer.push(this._drawPortLabel("outside")) }
    }
    return portContainer
  }


  render() {
    return this._drawPort();
  }
}

export default PortNG;
