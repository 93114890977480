import * as comActions from "./../actions/ComActions";

import infoMsgParser from "./MsgParser/info";
import responseMsgParser from "./MsgParser/resp";
import logMsgParser from "./MsgParser/log";
import userMsgParser from "./MsgParser/user";
import facilityMsg from "./MsgParser/facility";

import facStore from "./../stores/FacilitiesStore";

class MessageParser {
  constructor() {
    // incoming messages that have the following string as substring in their message will
    // not be displayed in the command line interface
    this.filterCLI = ["facility active table", "facility device table", "actual set", "attribute modified graph_data"];

    //const testmsg = "resp service prototypes [{\"blank SO\": [{\"regular\":{\"uuid\":\"75ea6b51-c3b9-524f-b57d-6afcf4d08585\"}},\n" +
    //    "                                        {\"reliable\":{\"uuid\":\"40aa6f4c-0d0b-55ff-8d90-45cfcaf9bd40\"}}]},\n" +
    //    "                            {\"category1\":[{\"<name1>\":{\"uuid\":\"<uuid1>\",\"desc\":\"optional short description\"}},\n" +
    //    "                                         {\"<name2>\":{\"uuid\":\"<uuid2>\",\"desc\":\"optional short description\"}}]},\n" +
    //    "                            {\"category2\":[{\"<namedrei<\":{\"uuid\":\"<uuiddrei>\",\"desc\":\"optional short description drei\"}}]}]"

    //this.parseMessage("Service", "abc", testmsg)
    //console.log("parsing testmsg")
    this.filterIncomingCLI = this.filterIncomingCLI.bind(this);
  }

  filterIncomingCLI(msg) {
    let found = false;
    for (let index = 0; index < this.filterCLI.length; index++) {
      found = msg.includes(this.filterCLI[index]);
      if (found) break;
    }
    if (!found) comActions.msgRecv(msg);
  }

  parseMessage(msgType, msgSource_uuid, msg) {
    // send received message to CommandLine display
    this.filterIncomingCLI(msg);
    //comActions.msgRecv(msg);
    const splitMessage = msg.split(" ");
    let messageWords = [];
    // remove empty strings
    for (let i = 0; i < splitMessage.length; i++) {
      if (splitMessage[i] !== "") {
        messageWords.push(splitMessage[i]);
      }
    }

    // console.log("Incoming message from", msgType, messageWords)
    switch (msgType) {
      case "Service":
        this._processServiceMessage(messageWords, msg);
        break;

      case "User":
        this._processUserMessage(messageWords);
        break;

      case "Platform":
        this._processPlatformMessage(messageWords);
        break;

      case "OutOfBand":
        this._processOutOfBandMessage(messageWords, msgSource_uuid, msg);
        break;

      default:
    }
  }

  _processServiceMessage(messageWords, msg) {
    const MESSAGE_TYPE = 0;

    // create dispatch event according to received message type
    const messageType = messageWords[MESSAGE_TYPE].toLowerCase();

    switch (String(messageType)) {
      case "info":
        const infoMsg = infoMsgParser.processMsg(messageWords);
        infoMsg.forEach(function(element) {
          if (!element || element === "null") {
            //console.log(
            //  "ERROR  MessageParser: Unknown or wrong formatted info message received!"
            //);
          } else {
            comActions.infoMsgRecv(element);
          }
        });
        break;

      case "resp":
        const responseMessage = responseMsgParser.processMsg(messageWords, msg);
        //console.log("response message =", responseMessage)
        responseMessage.forEach(function(element) {
          if (!element || element === "null") {
            console.log(
              "ERROR  MessageParser: Unknown or wrong formatted resp message received!"
            );
            console.log(messageWords)
          } else {
            if (element.scope === "notification")
              comActions.notificationMsgRecv(element);
            else if (element.scope === "stats") comActions.infoMsgRecv(element);
            else if (element.scope === "so_core" && element.cmd === "owner")
              comActions.infoMsgRecv(element);
            else if (element.scope === "so_core" && element.cmd === "location")
              comActions.infoMsgRecv(element);
            else if (element.scope === "service")
              comActions.respServiceMsgRecv(element);
            else if (element.scope === "history")
              comActions.respHistoryMsgRecv(element);
            else comActions.respMsgRecv(element);
          }
        });
        break;

      case "log":
        const logMessage = logMsgParser.processMsg(messageWords);

        logMessage.forEach(function(element) {
          if (!element || element === "null") {
            console.log(
              "ERROR  MessageParser: Unknown or wrong formatted log message received!"
            );
          } else {
            comActions.logMsgRecv(element);
          }
        });
        break;

      default:
        break;
    }
  }

  _processUserMessage(messageWords) {
    const userMessage = userMsgParser.processMsg(messageWords);

    if (!userMessage || userMessage === "null") {
      return;
    }

    userMessage.forEach(function(element) {
      if (element.scope === "notification") {
        comActions.notificationMsgRecv(element);
      } else if (element.scope === "facilities") {
        comActions.facilitiesMsgRecv(element);
      } else comActions.userMsgRecv(element);
    });
  }

  _processPlatformMessage(messageWords) {
    console.log("Process platform message");
  }

  _processOutOfBandMessage(messageWords, msgSource_uuid, msg) {
    // check if the received message is from a valid SO uuid
    if (facStore.isFacility(msgSource_uuid)) {
      facilityMsg.process(messageWords, msgSource_uuid, msg);
    } else {
      console.log(
        "out of band message from unknown source - message dropped..."
      );
    }
  }
}

const messageParser = new MessageParser();
export default messageParser;
