
import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";
import pM from "./PopupManager";
import aSDS from "./../../stores/ActiveServiceDataStore";
//import gLS from "./../../stores/GraphLayoutStore";
import InletMethod from "./InletMethod";

class ConfOutlet  extends React.Component {

  constructor() {
    super();
    // set size for this configuration panel
    this.width = 160; this.height = 60;

    this.selectView = this.selectView.bind(this);
    this._buildConfOutletMenu = this._buildConfOutletMenu.bind(this);
  }



  selectView(method) {
    console.log("method = ", method);
    console.log("port =", this.props.port);
    pM.deletePopup(this.props.id);

    // NOTE: this.props.port holds port name and inlet name separated by one space!!!
    const msg = this.props.soName+" setoutlet "+this.props.port+" "+method;
    console.log("msg =", msg);
    websocketCom.sendServiceMessage(msg);
  }


  _buildConfOutletMenu() {

    const methods = aSDS.getSmartObjectData(this.props.soName).get("methods");
    const numberMethods = methods.size;
    let methodContainer = [];
    methodContainer.push(<div key="top-outlet-methods-menu" style={{height: 3}} />)

    if (methods.has("SETUP")) {
      this.height = (numberMethods-1)*22+2
    } else {
      this.height = (numberMethods*22)+2
    }

    for (const method of methods.keys()) {
      if (method !== "SETUP") {
        const methodEntry = (
        <div key={this.props.soName+" "+this.props.port+" "+method} style={{cursor: "default"}} >
          <InletMethod text={method} callback={this.selectView} />
        </div> )
        methodContainer.push(methodEntry);
      }
    }
    return methodContainer;
  }


  render() {
    // Content of panel is defined here
    const view = (<div key={"content-outlet-conf-"+this.props.soName}> {this._buildConfOutletMenu()} </div>)

    let  geometry = this.props.location;
    geometry["width"] = this.width; geometry["height"] = this.height;

    const panel = ( <Popup geometry={geometry} content={view}
                           id={this.props.id} elementClass={this.props.elementClass}
                           delCallback={this.props.delCallback} /> )
    return panel
  }

}

export default ConfOutlet;
