
import React from "react";
//import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';

class CreateSOButton extends React.Component {

  constructor() {
    super();

    this.SIZE = 80;

    this.colOnHover_bg="#fff"
    this.col_bg="#eee"
    this.colOnHover = "orange"
    this.colNotSel = "#aaa"

    this.state = { isHover: false }

    this.background= this.col_bg;;
    this.foreground = this.colNotSel;

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);
  }


  onMouseEnter() {
    this.setState({isHover: true});
    this.foreground = this.colOnHover;
    this.background = this.colOnHover_bg;
  }
  onMouseLeave() {
    this.setState({isHover: false});
    this.foreground = this.colNotSel;
    this.background = this.col_bg;
  }

  onClick() {
    this.props.callback();
  }

  render() {

    const button = (
      <svg id="create-so-button" key="create-so-button" style={{ width: this.SIZE, height: this.SIZE }}>
        <g onMouseEnter={() => this.onMouseEnter() }
           onMouseLeave={() => this.onMouseLeave() }
           onClick={() => this.onClick()} >

          <circle cx="40" cy="40" r="35" strokeWidth="0" fill={this.background} />
          <AddCircleOutlineIcon x={0} y={0} width={this.SIZE} height={this.SIZE} style={{color: this.foreground}} />

        </g>
      </svg> )

    const panel = (
      <div style={{ position: "fixed", top: 130, right: this.props.ePosNavPanel+80,
                    width: this.SIZE, height: this.SIZE, backgroundColor: "transparent"}}>
      <Tooltip title={<span style={{fontSize: 11}}>Create SmartObject</span>} aria-label="add-smartobject" enterDelay={300} leaveDelay={200}  >
          {button}
        </Tooltip>
      </div>)

      return panel;
  }

}
export default CreateSOButton;
