import { EventEmitter } from "events";
import dispatcher from "../dispatcher/dispatcher";
import logger from "./../components/Logging/Logger";

class ActiveServiceMgmtStore extends EventEmitter {
  constructor() {
    super();

    this._resetStore();

    this._resetStore = this._resetStore.bind(this);
    this._checkServiceList = this._checkServiceList.bind(this);

    this.LOG_ORIGIN = "ActiveServiceMgmtStore";
    logger.log(this.LOG_ORIGIN, "INFO", "Initialized.", "");
  }

  _resetStore() {
    this.active_service_name = "";
    this.active_service_uuid = "";
    this.facility = "";
    this.prototypes = "";
  }

  _checkServiceList(msg) {
    const serviceList = JSON.parse(msg.payload);
    if (this.active_service_uuid !== "") {
      let activeServiceInServiceList = false;
      for (let service in serviceList) {
        if (serviceList[service] === this.active_service_uuid) {
          activeServiceInServiceList = true;
          break;
        }
      }
      if (!activeServiceInServiceList) {
        logger.log(
          this.LOG_ORIGIN,
          "INFO",
          "Active service no longer exists on CenoLabs IoT platform:",
          this.active_service_name
        );
        this._resetStore();
        logger.log(this.LOG_ORIGIN, "INFO", "Service closed in GUI.", {});
        this.emit("ChangeEvent_ActiveServiceClosed");
      }
    }
  }

  _processDispatchActions(msg) {
    if (msg.status === "change") {
      this.active_service_uuid = msg["service_uuid"];
      this.active_service_name = msg["service_name"];
      logger.log(
        this.LOG_ORIGIN,
        "INFO",
        "Active service changed to",
        this.active_service_name
      );
      this.emit("ChangeEvent_ActiveServiceChanged");
    } else {
      logger.log(
        this.LOG_ORIGIN,
        "ERROR",
        "ActiveServiceMgmtStore received message with unknown status in _processDispatchActions.",
        ""
      );
    }
  }


  _closeActiveService(msg) {
    if (msg.status === "close") {
        this.active_service_uuid = ""
        this.active_service_name = ""
        this.emit("ChangeEvent_ActiveServiceClosed");
    } else {
      logger.log(this.LOG_ORIGIN, "ERROR", "Unknown status in ACTION_CloseActiveService.","");
    }
  }


  _processUserMessage(msg) {
    switch (msg.scope) {
      case "services": {
        this._checkServiceList(msg);
        break;
      }
      default:
    }
  }

  _processFacilitySetMessage(msg) {
    this.facility = msg.facility;
    this.emit("ChangeEvent_ServiceFacilityChanged");
  }

  _processPrototypesMessage(msg) {
    //console.log("prototypes stored in database!", msg.data)
    this.prototypes = msg.data;
  }

  handleDispatcherActions(action) {
    switch (action.type) {
      case "ACTION_ChangeActiveService": {
        this._processDispatchActions(action.msg);
        break;
      }
      case "ACTION_CloseActiveService": {
        this._closeActiveService(action.msg)
        break
      }
      case "ACTION_UserMessageReceived": {
        this._processUserMessage(action.msg);
        break;
      }
      case "RESP_SERVICE_MSG_RECV": {
        //console.log("action", action)
        if (
          action.msg.cmd === "facility" &&
          action.msg.cmdOperation === "set"
        ) {
          this._processFacilitySetMessage(action.msg);
        } else if ( action.msg.cmd === "prototypes") {
          this._processPrototypesMessage(action.msg);
        }
        break;
      }
      default: {
      }
    }
  }

  // --------------------------------------------------------------------------
  /**
   **    Public interface
   **/
  getActiveService() {
    return this.active_service_uuid;
  }

  getActiveServiceName() {
    return this.active_service_name;
  }

  getFacility() {
    return this.facility;
  }

  getPrototypes() {
    return this.prototypes;
  }
  // --------------------------------------------------------------------------
}

const activeServiceMgmtStore = new ActiveServiceMgmtStore();
dispatcher.register(
  activeServiceMgmtStore.handleDispatcherActions.bind(activeServiceMgmtStore)
);

export default activeServiceMgmtStore;
