import React from "react";
import MethodSelector from "./MethodSelector";
import mM from "./MethodManager";
import Editor from "./Editor";

class Edit extends React.Component {

  constructor() {
    super();

    this.state=({activeCode: "" })

    this._cE_MMUpdate = this._cE_MMUpdate.bind(this);
  }

  componentDidMount() {
    this._cE_MMUpdate();
    mM.on("MM_dataChange", this._cE_MMUpdate );
  }
  componentWillUnmount() {
    mM.removeListener( "MM_dataChange", this._cE_MMUpdate );
  }

  _cE_MMUpdate() {
    this.setState({activeCode: mM.getActiveCode()})
  }




  setCode(code) {
    mM.setActiveCode(code);
  }

  render() {

    // make editor read only if no method is selected
    let readOnly = false;
    const activeMethodName = mM.getActiveMethod();
    if (activeMethodName === "") {readOnly=true}

    const view = (
      <div style={{flexGrow: 3, backgroundColor: "white", padding: 2}} >
      <div style={{height: 100+"%", backgroundColor: "WhiteSmoke", color: "DimGray", fontSize: 15, fontWeight: "bold", paddingLeft: 0}} >
        <MethodSelector />
        <Editor width={this.props.width} offset={this.props.offset}
                code={this.state.activeCode} codeCallback={this.setCode}
                methodName={activeMethodName} readOnly={readOnly} />
      </div>
      </div> )
    return view;
  }

}

export default Edit;
