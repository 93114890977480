import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import activeServiceDataStore from "./../../stores/ActiveServiceDataStore";
//import uSS from "./../../stores/UserServicesStore";
//import fS from "./../../stores/FacilitiesStore";
// -------------------------------------------------------------
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
// -------------------------------------------------------------

const styles = theme => ({
  soFormControl: {
    margin: theme.spacing.unit,
    minWidth: 450
  }
});

class DialogSaveSo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSo: "",
      descriptionText: "",
      tag: "",
      tagError: false,
      soSelectError: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this._handleDescriptionTextChange = this._handleDescriptionTextChange.bind(
      this
    );
    this._handleChangeSo = this._handleChangeSo.bind(this);
    this._handleTagChange = this._handleTagChange.bind(this);
  }

  handleClose() {
    this.setState({
      selectedSo: "",
      descriptionTest: "",
      tag: "",
      tagError: false,
      soSelectError: false
    });
    this.props.closeHandle();
  }

  handleSave() {
    if (!this.state.tagError) {
      if (this.state.tag === "") {
        this.setState({ tagError: true });
        return;
      }

      let msg = "";
      if (this.state.selectedSo !== "") {
        msg =
          this.state.selectedSo +
          " save " +
          this.state.tag +
          " " +
          this.state.descriptionText;
        websocketCom.sendServiceMessage(msg);
        this.handleClose();

        this.setState({
          selectedSo: "",
          descriptionTest: "",
          tag: "",
          tagError: false,
          soSelectError: false
        });
      } else {
        this.setState({ soSelectError: true });
      }
    }
  }

  _buildSoList() {
    const soTemplates = activeServiceDataStore.getSoList();

    let MenuItemContainer = [];
    let menuItem = "";
    soTemplates.forEach(function(item, index) {
      menuItem = (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      );
      MenuItemContainer.push(menuItem);
    });
    return MenuItemContainer;
  }

  _handleChangeSo(event) {
    const soName = event.target.value;
    this.setState({ selectedSo: soName });
    this.setState({ soSelectError: false });
  }

  _handleDescriptionTextChange(event) {
    const descriptionText = event.target.value;
    this.setState({ descriptionText: descriptionText });
  }

  _handleTagChange(event) {
    this.setState({
      tag: event.target.value
    });
    // eslint-disable-next-line no-useless-escape
    let letterNumber = /^[\-0-9a-zA-Z.\_\:\#]+$/;
    if (event.target.value.match(letterNumber) || event.target.value === "") {
      this.setState({ tagError: false });
    } else {
      this.setState({ tagError: true });
    }
  }

  render() {
    const { classes } = this.props;

    const dialog = (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Checkpoint SmartObject
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This dialog allows to checkpoint the chosen SmartObject.
            </DialogContentText>

            <div style={{ height: 8 + "px" }} />

            <FormControl className={classes.soFormControl} fullWidth>
              <div>
                <InputLabel htmlFor="serviceTemplate-simple">
                  SmartObject to be checkpointed
                </InputLabel>
                <Select
                  value={this.state.selectedSo}
                  error={this.state.soSelectError}
                  onChange={this._handleChangeSo}
                  inputProps={{
                    name: "serviceTemplate",
                    id: "serviceTemplate-simple"
                  }}
                  fullWidth
                >
                  {this._buildSoList()}
                </Select>
              </div>

              <TextField
                value={this.state.tag}
                onChange={this._handleTagChange}
                id="tag-input"
                label="Checkpoint Tag"
                margin="dense"
                error={this.state.tagError}
                style={{ marginTop: 20 }}
              />

              <div style={{ marginTop: 30, marginBottom: 10 }}>
                Checkpoint Description
              </div>

              <TextareaAutosize
                rowsmin={4}
                rowsmax={4}
                aria-label="description"
                placeholder="Give a short text to describe this checkpoint."
                value={this.state.descriptionText}
                onChange={this._handleDescriptionTextChange}
              />
            </FormControl>

            <div style={{ minHeight: 20 + "px" }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );

    return dialog;
  }
}

export default withStyles(styles)(DialogSaveSo);
