import websocketDispatcher from "./../dispatcher/WebsocketDispatcher";

/*export function ServiceUpdate(msg) {
  //console.log("Dispatching ServiceUpdate action");
  websocketDispatcher.dispatch({
    type: "ACTION_Websocket_ServiceUpdate",
    msg
  });
}*/

export function WebsocketLifecycle(msg) {
  //console.log("Dispatching WebsocketLifecycle action");
  websocketDispatcher.dispatch({
    type: "ACTION_WebsocketLifecycle",
    msg
  });
}

