import dispatcher from "./../../../dispatcher/dispatcher";
import { EventEmitter } from "events";

class OutputDataStore extends EventEmitter {
  constructor() {
    super();

    this.log_queue = []
    this.follow_output = false
  }

  getLogs() {
    return this.log_queue;
  }

  clearLogs() {
    this.log_queue = []
    this.emit("ChangeEvent_OutputDataStore");
  }


  setFollowOutput(state) {
    this.follow_output = state
  }


  _shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }


  _handleActions(action) {
    switch (action.type) {
      case "LOG_MSG_RECV":
        // extract date and time from message
        let logMsg = action.msg.log
        let logEntry = {}
        const date = logMsg.substr(0, logMsg.indexOf(" "));
        logMsg = logMsg.substr(logMsg.indexOf(" ") + 1);
        const time = logMsg.substr(0, logMsg.indexOf(" "));
        logMsg = logMsg.substr(logMsg.indexOf(" ") + 1);

        logEntry.date = date;
        logEntry.time = time;
        logEntry.msg = logMsg;
        logEntry.so = action.msg.so;
        logEntry.level = action.msg.level;

        // special handling for ceno.print logs
        if (logMsg.substring(0, 1) === ">") {
          logEntry.level = "print"
          logEntry.msg = logMsg.substr(logMsg.indexOf(" ") + 1);
        }

        // ---------------------
        // DIRTY HACK: check for double entries due to soft state issues with gui uuid
        if (this.log_queue.length > 0) {
          const lastEntry = this.log_queue[this.log_queue.length - 1];
          if (this._shallowEqual(lastEntry, logEntry)) {
            return
          }
        }
        // ---------------------

        this.log_queue.push(logEntry)
        if (this.log_queue.length > 300) {
          if (this.follow_output) this.log_queue.shift()
        }
        if (this.log_queue.length > 800) this.log_queue = this.log_queue.slice(-300)
        this.emit("ChangeEvent_OutputDataStore");
        break;
      default:
        break;
    }
  }

}

const outputDataStore = new OutputDataStore();
dispatcher.register(
  outputDataStore._handleActions.bind(outputDataStore)
);
export default outputDataStore;


