import { EventEmitter } from "events";
import websocketCom from "./../webcom/WebsocketCom";
import activeServiceMgmtStore from "./../stores/ActiveServiceMgmtStore";
import logger from "./../components/Logging/Logger";

class ServiceManager extends EventEmitter {
  constructor(props) {
    super(props);

    this.LOG_ORIGIN = "ServiceManager";

    this.active_service = "";
    this.infoSubscribeTimer = null;
    this.INFO_SOFTSTATE_TIMER = 145000;

    this._sendInfoSubscribeMessage = this._sendInfoSubscribeMessage.bind(this);
    this._subscribeNewService = this._subscribeNewService.bind(this);

    activeServiceMgmtStore.on("ChangeEvent_ActiveServiceChanged", () => {
      this._subscribeNewService();
    });
    activeServiceMgmtStore.on("ChangeEvent_ActiveServiceClosed", () => {
      this._cleanupClosedService();
    });

    logger.log(this.LOG_ORIGIN, "INFO", "Started.", "");
  }

  _sendInfoSubscribeMessage() {
    const message = "set subscribe info ";
    websocketCom.sendServiceMessage(message);
  }

  _cleanupClosedService() {
    // stop info subscribe timer
    if (this.infoSubscribeTimer) {
      clearInterval(this.infoSubscribeTimer);
      logger.log(
        this.LOG_ORIGIN,
        "DEBUG",
        "Remove info subscribe timer for closed service.",
        ""
      );
    }
    // get service uuid of the new service from the database
    this.active_service = activeServiceMgmtStore.getActiveService();
    // remove service from websocket communication
    websocketCom.updateServiceUuid(this.active_service);
  }

  _subscribeNewService() {
    // stop sending info subcribe messages to the old service
    if (this.infoSubscribeTimer) {
      clearInterval(this.infoSubscribeTimer);
      logger.log(
        this.LOG_ORIGIN,
        "DEBUG",
        "Remove info subscribe timer for closed service.",
        ""
      );
    }

    // get service uuid of the new service from the database
    this.active_service = activeServiceMgmtStore.getActiveService();

    logger.log(
      this.LOG_ORIGIN,
      "DEBUG",
      "Subscribe to service.",
      this.active_service
    );

    // update the session communication part of the websocket with the new service uuid
    websocketCom.updateServiceUuid(this.active_service);

    logger.log(
      this.LOG_ORIGIN,
      "DEBUG",
      "Start info subscribe timer for new service.",
      ""
    );

    // start sending out info subscribe messages every 10 seconds
    this._sendInfoSubscribeMessage();
    this.infoSubscribeTimer = window.setInterval(
      this._sendInfoSubscribeMessage,
      this.INFO_SOFTSTATE_TIMER
    );

    let message = "";
    // send full service info request to new service
    message = "get fullserviceinfo ";
    websocketCom.sendServiceMessage(message);

    // request facility info for new service
    // will be stored in ActiveServiceMgmtStore
    message = "service getfacility ";
    websocketCom.sendServiceMessage(message);
  }
}

const serviceManager = new ServiceManager();
export default serviceManager;
