import { useD3 } from "./../../../../hooks/useD3";
import React from "react";
import * as d3 from "d3";


let absMaxValue = 0;

function PerformanceChart(props) {

  let data = props.data;
  const currentMaxValue = d3.max(data, (d) => (d.util));

  if (currentMaxValue>absMaxValue) absMaxValue = currentMaxValue
  if (absMaxValue*0.2>currentMaxValue) absMaxValue = currentMaxValue

  const ref = useD3(
    (svg) => {
      const height = props.height;
      const width = 400;
      const margin = { top: 20, right: 10, bottom: 20, left: 70 };

      const x1 = d3
        .scaleLinear()
        .domain([0, absMaxValue])
        .range([margin.left, width-margin.right])

      const x1Axis = (g) =>
        g
        .style("font-size", "14px" )
        .style("font-weight", "bold")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x1).ticks(4));

      const y1 = d3
        .scaleBand()
        .domain(data.map((d) => d.name))
        .range([height-margin.bottom, margin.top])
        .padding(0.1);

      const y1Axis = (g) =>
        g
                .style("font-size", "14px" )
        //.style("font-weight", "bold")

          .attr("transform", `translate(${margin.left},0)`)
          .call(d3.axisLeft(y1))



      svg.select(".x-axis").call(x1Axis);
      svg.select(".y-axis").call(y1Axis);


      svg
        .select(".plot-area")
        .selectAll(".bar")
        .data(data)
        .join("rect")
        .transition()
        .duration(1980)
        .attr("fill", (d) => d.status)
        .attr("class", "bar")
        .attr("x", (d) => (x1(0)))
        .attr("width", (d) => (x1(d.util)-x1(0)))
        .attr("y", (d) => (y1(d.name)))
        .attr("height", y1.bandwidth())
    },
    [data]
  );


  return (
    <svg
      ref={ref}
      style={{
        height: props.height,
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );
}



export default PerformanceChart;
