import React from "react";

import gLS from '../../../stores/GraphLayoutStore'

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import StopIcon from '@material-ui/icons/Stop';

class ZoomButtons extends React.Component {

  constructor() {
    super();

    this.colOnHover_bg="white"//"#f0f0f0"
    this.colSelected_bg="white"//"#e0e0e0"

    //this.colOnHover = "#000"
    this.colOnHover = "orange"
    this.colSel = "#000"
    this.colNotSel = "#aaa"//"#000"

    this.state = {  //isPressed: false,
      isHover: false
    }

    this.background= "transparent";
    this.foreground = this.colNotSel;

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);

  }


  onMouseEnter() { this.setState({isHover: true}); }
  onMouseLeave() { this.setState({isHover: false}); }

  onClick() {
    switch (this.props.name) {
      case "zoomout": gLS.decreaseScaleFactor();  break;
      case "zoomreset": gLS.resetScaleFactor(); break;
      case "zoomin": gLS.increaseScaleFactor(); break;
      default: break;
    }
  }


  render() {
    if (this.state.isHover) {this.background = this.colOnHover_bg; this.foreground = this.colOnHover;
    } else { this.background= "transparent"; this.foreground = this.colNotSel }
    if (this.state.isHover) {this.foreground = this.colOnHover}

    let icon=[]
    let offset = 0;
    if (this.props.icon) {
      switch (this.props.icon) {
        case "remove": icon =( <RemoveIcon x={7} y={0} width={26} style={{color: this.foreground}}/>); break;
        case "stop": offset = 40; icon=( <StopIcon x={7+offset} y={0} width={26} style={{color: this.foreground}} />); break;
        case "add": offset = 80; icon=( <AddIcon x={7+offset} y={0} width={26} style={{color: this.foreground}} />); break;
        default: break;
      }
    }

    const panel =
      (<g key={this.props.text}
        onMouseEnter={() => this.onMouseEnter() }
        onMouseLeave={() => this.onMouseLeave() }
        onClick={() => this.onClick()} >
          <rect x={this.props.x-19+offset} y={this.props.y-38} width="39" height="36" stroke={this.background} fill={this.background} />
          {icon}
      </g>)

   return panel
  }
}
export default ZoomButtons;
