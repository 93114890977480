import React from "react";

import LensIcon from '@material-ui/icons/Lens';


class InterfaceRep extends React.Component {

//  constructor(props) {
//    super(props);
//  }

  componentDidMount() {
    //selectionManager.on( "ChangeEvent_Selection", this._cE_SelectionUpdate );
  }

  componentWillUnmount() {
    //selectionManager.removeListener( "ChangeEvent_Selection", this._cE_SelectionUpdate );
  }


  render() {

    let valueIndicator = <div />
    let textColor = "White";
    //let boxColor = "LimeGreen";
    let ledcolor = "";
    let pinName = "";
    let secondaryInterfaceDescription = "";
    let value = "";

    if (typeof this.props.data === 'undefined') {
      ledcolor="DimGray"; textColor="Gray";
      //boxColor="Darkgreen";
      pinName=this.props.interface;
      value = "";
    } else {
      ledcolor = this.props.data.value==="true" ? "RED" : "LightGray"
      pinName=this.props.data.name;
      secondaryInterfaceDescription = this.props.interface;

      if (this.props.interface!=="COM" && this.props.interface!=="GND" && this.props.interface!=="PWR" && this.props.interface !=="NC") {

      if (pinName === undefined ) {
          pinName=this.props.interface;
          secondaryInterfaceDescription = ""
        }
      } else {
            secondaryInterfaceDescription = ""
      }
      value = this.props.data.value;
    }

    if (this.props.interface.includes("DIN") || this.props.interface.includes("DO")) {
      valueIndicator = <LensIcon style={{height: 13, paddingTop: 2,paddingLeft: 0,  color: ledcolor}} />
    }

    if ( this.props.interface.includes("AI") || this.props.interface.includes("AO") || this.props.interface.includes("PT") ) {
      valueIndicator = <div style={{ fontFamily: "Roboto", fontSize: 11, fontWeight: "bold", paddingTop: 2,
                                     maxWidth: 35, overflowX: "hidden"}} >
                         {value}
                       </div>
    }

    let pinView = <div />

    if (this.props.orientation === "left") {
      pinView =
            <div style={{display: "flex"}}>
              <div style={{width: 35, height: 15, textAlign: "right", marginRight: 3}}>
                {valueIndicator}
              </div>
              <div style={{ width: 25, height: 15,
                            borderLeft: "2px solid LimeGreen",
                            borderRight: "2px solid LimeGreen",
                            borderTop: "1px solid LimeGreen",
                            borderBottom: "1px solid LimeGreen",
                            backgroundColor: "DarkGreen" }}
              >
                <div style={{fontFamily: "Roboto", fontSize: 9, fontWeight: "bold", color: "LimeGreen", textAlign: "center", paddingTop: 3}}>
                  {secondaryInterfaceDescription}
                </div>
              </div>
              <div style={{width: 5}} />
              <div style={{fontFamily: "Roboto", fontSize: 11, fontWeight: "bold", color: textColor,
                           paddingTop: 2, minWidth: 40, maxWidth: 40, overflowX: "hidden" }}>
                {pinName}
              </div>
            </div>

    } else {

      pinView =
            <div style={{display: "flex"}}>
              <div style={{ fontFamily: "Roboto", fontSize: 11, fontWeight: "bold", color: textColor,
                            paddingTop: 2, minWidth: 40, maxWidth: 40, textAlign: "right"}}>{pinName}</div>
              <div style={{width: 5}} />
              <div style={{ width: 25, height: 15,
                            borderLeft: "2px solid LimeGreen",
                            borderRight: "2px solid LimeGreen",
                            borderTop: "1px solid LimeGreen",
                            borderBottom: "1px solid LimeGreen",
                            backgroundColor: "DarkGreen" }}
              >
              <div style={{fontFamily: "Roboto", fontSize: 9, fontWeight: "bold", color: "LimeGreen", textAlign: "center", paddingTop: 3}}>
              {secondaryInterfaceDescription}
              </div>
              </div>

              <div style={{marginLeft: 3,height: 15, width: 35}}> {valueIndicator} </div>
            </div>

    }
    return pinView;

  }
}

export default InterfaceRep;
