import React from "react";
import hS from "./../../stores/HistoryStore";

import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import websocketCom from "./../../webcom/WebsocketCom";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
//import SettingsIcon from "@material-ui/icons/Settings";
import StarIcon from "@material-ui/icons/Star";
import StopIcon from "@material-ui/icons/Stop";

class CheckpointHistory extends React.Component {
  constructor(props) {
    super(props);
    this.selectedSo = this.props.selectedSo;
    this.state = { selectedHistory: "", checkpointList: [] };

    this._requestHistory();

    this._renderCheckpointList = this._renderCheckpointList.bind(this);
    this._loadHistory = this._loadHistory.bind(this);
    this._selectHistory = this._selectHistory.bind(this);
    this._reloadHistory = this._reloadHistory.bind(this);
  }

  componentDidMount() {
    hS.addListener("history_update", this._loadHistory);
  }

  componentWillUnmount() {
    hS.removeListener("history_update", this._loadHistory);
  }

  _loadHistory() {
    const history = hS.getHistory();
    if (history.soName !== this.selectedSo) {
      console.log("Wrong history loaded");
      return;
    }
    this.setState({ checkpointList: history.items });
  }

  _selectHistory(date) {
    this.setState({ selectedHistory: date });
    this.props.callbackHistoryChange(date);
  }

  _requestHistory() {
    if (this.selectedSo !== "") {
      let msg = this.selectedSo + " history";
      websocketCom.sendServiceMessage(msg);
    }
  }

  _reloadHistory() {
    // clear current view
    this.setState({ selectedHistory: "", checkpointList: [] });
    this._requestHistory();
  }

  _renderCheckpointList() {
    let view = [];
    let checkpointItem = "";
    let iconStyle = { background: "#b0b0b0", color: "#fff" };
    let contentStyle = {};
    if (this.state.selectedHistory === "") {
      iconStyle = { background: "#e64a19", color: "#fff" };
      contentStyle = { color: "#e64a19" };
    }
    checkpointItem = (
      <VerticalTimelineElement
        key="end"
        contentStyle={contentStyle}
        className="vertical-timeline-element--education"
        date="now"
        icon={<StopIcon />}
        iconStyle={iconStyle}
        iconOnClick={() => this._selectHistory("")}
      >
        <div onClick={() => _this._selectHistory("")}>
          <h5 className="vertical-timeline-element-title">
            SmartObject: {this.props.selectedSo}
          </h5>
          <h6 className="vertical-timeline-element-subtitle">
            Live Platform State
          </h6>
        </div>
      </VerticalTimelineElement>
    );
    view.push(checkpointItem);

    let _this = this;
    this.state.checkpointList.forEach(function(item, index) {
      let icon = <SettingsBackupRestoreIcon />;
      let iconStyle = { background: "#b0b0b0", color: "#fff" };
      let contentStyle = {};
      let drawTag = "";
      if (item.tag === "quicksave") {
        icon = <SettingsBackupRestoreIcon />;
        drawTag = "Quicksave Checkpoint";
      } else {
        drawTag = "Tag: " + item.tag;
      }
      if (item.release === _this.state.selectedHistory) {
        iconStyle = { background: "#e64a19", color: "#fff" };
        contentStyle = { color: "#e64a19" };
      }
      checkpointItem = (
        <VerticalTimelineElement
          key={item.date}
          contentStyle={contentStyle}
          date={new Date(item.date).toString()}
          icon={icon}
          iconStyle={iconStyle}
          iconOnClick={() => _this._selectHistory(item.release)}
        >
          <div onClick={() => _this._selectHistory(item.release)}>
            <h5 className="vertical-timeline-element-title">{drawTag}</h5>
            <p>{item.comment}</p>
          </div>
        </VerticalTimelineElement>
      );
      view.push(checkpointItem);
    });

    checkpointItem = (
      <VerticalTimelineElement
        key="start"
        className="vertical-timeline-element--education"
        icon={<StarIcon />}
        iconStyle={{ background: "#9ccc65", color: "#fff" }}
      ></VerticalTimelineElement>
    );
    view.push(checkpointItem);

    if (view.length === 0) {
      view = <div>There are no checkpoints for this SmartObject!</div>;
    }

    return view;
  }

  render() {
    if (this.selectedSo !== this.props.selectedSo) {
      console.log("Change in SO");
      this.selectedSo = this.props.selectedSo;
      this._reloadHistory();
    }

    let view = "";

    view = <VerticalTimeline>{this._renderCheckpointList()}</VerticalTimeline>;

    return view;
  }
}

export default CheckpointHistory;
