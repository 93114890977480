import React from "react";
import { Route } from "react-router-dom";
import WelcomePage from "./pages/Welcome/WelcomePage";
import MainPage from "./pages/Main/Main";
import ServiceDevelopment from "./pages/ServiceDev/ServiceDevelopment";
import * as UtilityActions from "./actions/UtilityActions";
//import "typeface-roboto";
import "fontsource-roboto";
// create material=ui theme
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

// ---------------------------------------------
// create the theme for material-ui
const theme = createTheme({
  palette: {
    type: "light"
  },
  typography: {
    //useNextVariants: false,
    fontFamily: [
'Roboto',

    ],
    fontSize: 12
  }
});


class App extends React.Component {
  constructor() {
    super();
    document.body.style.backgroundColor = "WhiteSmoke"//"#f5f5f5";
//document.body.style.postion = "fixed"
document.body.style.overflow = "hidden"
document.body.style.height = "100%"
window.addEventListener(
      "resize",
      function() {
        UtilityActions.windowResize("");
      },
      true
    );

    this.PrimaryLayout = () => (
      <div className="primary-layout" style={{width: 100+"%", height: 100+"%" }}>
        <MuiThemeProvider theme={theme}>
          <main>
            <Route path="/" exact component={WelcomePage} />
            <Route path="/main" component={MainPage} />
            <Route path="/servicedev" component={ServiceDevelopment} />
            {/*<Route path="/editor/:type" component={EditorFrame} />*/}
            {/*<Route path="/editor/:type" component={() => <EditorFrame /> } /> */}
          </main>
        </MuiThemeProvider>
      </div>
    );
  }

  render() {
    return <this.PrimaryLayout />;
  }
}





export default App;
