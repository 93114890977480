import React from "react";
import ConfigMenuItem from "./ConfigMenuItem";
import config from "./../config"
//import gLS from "../../stores/GraphLayoutStore";
import Problems from "./Problems/Problems";
import Debug from "./Debug";
import State from "./State";
import Terminal from "./Terminal";

class ConfigPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = { selection: "",
        //               soGlobalView: [],
        //               soDebugView : []
        }


        this.isTracking = false;
        this.trackCal= {"y": 0}

        //this._updatePanelProps = this._updatePanelProps.bind(this);
        this._handleMouseMove = this._handleMouseMove.bind(this);
        this.callbackSetSelection = this.callbackSetSelection.bind(this);
    }

    componentDidMount() {
        window.addEventListener("mousemove", this._handleMouseMove);
        //gLS.on("ChangeEvent_GraphLayoutUpdate", this._updatePanelProps);
    }

    componentWillUnmount() {
        window.removeEventListener("mousemove", this._handleMouseMove);
        //gLS.removeListener("ChangeEvent_GraphLayoutUpdate", this._updatePanelProps);
    }

    //_updatePanelProps() {
    //    const global = gLS.getGlobalView();
    //    const soGlobalView = global.length > 0 ? global[0] : "";
    //    this.setState({soGlobalView: soGlobalView})
    //}

    _handleMouseDown(event) {
        if (event.button === 0) {
            this.trackCal = {"y": event.clientY};
            this.isTracking = true;
        }
    }

    _handleMouseUp(event) {
        if (event !== null) { event.stopPropagation(); }
        this.isTracking = false;
        this.trackCal = {"y": 0}
    }

    _handleMouseMove(event) {
        event.stopPropagation();
        event.preventDefault();

        if (this.isTracking) {

            let delta = {"y": (event.clientY - this.trackCal.y) };
            this.trackCal = {"y": event.clientY}

            const maxHeight = window.innerHeight-config.setup.layout.headerPanelHeight-config.setup.layout.statusPanelHeight - 150
            if (this.props.height-delta.y < 56 ) { this._handleMouseUp(null); this.props.callbackDeltaHeight(100); return }
            if (this.props.height-delta.y > maxHeight) { this._handleMouseUp(null); this.props.callbackDeltaHeight(-maxHeight); return }

                this.props.callbackDeltaHeight(delta.y);

        }
    }

    callbackSetSelection(selection) {
        this.setState({selection: selection});
        this.props.callback(selection);
    }

    render() {

        const height = this.props.height-config.setup.layout.configMenuHeight;
        const debugView = <Debug height={height-25} />
        const stateView = <State height={height-25} width={this.props.width*0.35} soName={null} />

        const viewBox = (
            <div style={{height: height-25}} >
                <div id="view-box" style={{display: "flex", flexDirection: "row", alignItems: "stretch", height: 100+"%" }} >
                    {stateView}
                    {debugView}
                </div>
            </div>
        )




        let content = <div />
        switch (this.state.selection) {
            case "Problems": content = <Problems height={height}/>
                break;
            case "Debug": content = viewBox;
                break;
            //case "Commandline": content = <Terminal height={height} />;
            //    break;
            default:
                break;

        }


        let configPanel = <div style={{ height: 5}} />;
        let configHeadline = <div />;


        if (this.state.selection !== "") {

            configHeadline = <div style={{ height: 21, paddingLeft: 10, paddingTop: 4, backgroundColor: "#646464",
                                           fontFamily: "Roboto", fontSize: 14, fontWeight: "bold", color: "White",
                                           }}>{this.state.selection}</div>


            configPanel = (
            <div style={{ width: this.props.width-8, height: height-6, margin: 2,
                          backgroundColor: "White", border: "1px solid Gray",
                          userSelect: "text" }} >

                <div style={{ display: "flex", backgroundColor:"LightGray", color: "Black"}}>
                    {configHeadline}
                    <div style={{flexGrow: 1, cursor: "ns-resize", color: "White", backgroundColor: "#646464" }}
                         onMouseUp={(event) => this._handleMouseUp(event) }
                         onMouseDown={(event) => this._handleMouseDown(event) }
                    />
                </div>

                {content}

            </div> )
        }

        //<ConfigMenuItem text={"Commandline"} selection={this.state.selection} callback={this.callbackSetSelection} />
        const menu = (
            <div style={{ display: "flex", flexDirection: "row", height: config.setup.layout.configMenuHeight,
                          cursor: "default", userSelect: "none"}}>
                <ConfigMenuItem text={"Debug"} selection={this.state.selection} callback={this.callbackSetSelection} />
                <ConfigMenuItem text={"Problems"} selection={this.state.selection} callback={this.callbackSetSelection} />

            </div>
        )

        return <div style={{ height: this.props.height }} > {configPanel}{menu}</div>

    }

}

export default ConfigPanel;
