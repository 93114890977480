import React from "react";

// -------------------------------------------------------------
// material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// -------------------------------------------------------------



class LastUserDrop extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  handleCancel() {
    this.props.handle_func_cancel_drop();
  }

  handleDrop() {
    this.props.handle_func_drop()
  }


  render() {
    const dialog = (
      <div>
        <Dialog
          open={this.props.isActive}
          onClose={this.handleClose}
          maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle> {this._renderDialogHeadline()} </DialogTitle>
          <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <Typography
              component="span"
              variant="body1"
              fontWeight="bold">
              You are the last user attached to this service. If you drop this service now, it will be deleted from the platform.
              Please be certain. There is no going back!
            </Typography>
            </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDrop} color="secondary">
              Drop
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
    return dialog;
  }



  // -----------------------------------------------------------------------------
  _renderDialogHeadline() {
    return (
      <div id="header" style={{ display: "flex", flexDirection: "row" }}>
        <div
          id="colorblotch"
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#c62828",
            boxShadow: "1px 4px 7px 2px #bdbdbd",
            borderRadius: 4
          }}
        >
          <DeleteForeverIcon
            style={{
              width: 30,
              height: 30,
              color: "white",
              position: "relative",
              top: 10,
              left: 10
            }}
          />
        </div>
        <div
          id="headline"
          style={{
            flexGrow: 1,
            textAlign: "left",
            paddingTop: 7,
            paddingLeft: 15
          }}
        >
          <div id="type">
            <Typography
              variant="overline"
              style={{ fontSize: 9, color: "#757575" }}
            >
              Drop Service
            </Typography>
          </div>
          <div id="text">
            <Typography
              variant="body1"
              fontWeight="bold"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                position: "relative",
                top: -9
              }}
            >
              Delete Service From Platform
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default LastUserDrop;
