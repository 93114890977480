import React from "react";
import NWG from "./NetworkGraph_NG";
import fS from "./../../../../stores/FacilitiesStore";
import FacPanel from "./FacPanel";
import CreateFacility from "./../Deployment/CreateFacility";
import Button from "@material-ui/core/Button";

import AddBoxIcon from '@material-ui/icons/AddBox';


class Facilities extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {selCluster: null, selDevice: null, facilities: new Map(),
            devices: new Map(), showDialog_Facility: false, facSelected: "genesis"}

        this.cb_topologySelect = this.cb_topologySelect.bind(this);
        this.cb_facSelect = this.cb_facSelect.bind(this);
        this._click_addFacility = this._click_addFacility.bind(this);
        this._cB_closeDialog = this._cB_closeDialog.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        //this._cE_FacilityDbUpdate();
        //fS.on("ChangeEvent_FacilityDbUpdate", this._cE_FacilityDbUpdate);

    }

    componentWillUnmount() {
        this._isMounted = false;
        //fS.removeListener(
        //    "ChangeEvent_FacilityDbUpdate",
        //    this._cE_FacilityDbUpdate
        //);
    }

    _cE_FacilityDbUpdate() {
        //console.log("Facility update")
        if (this._isMounted) {
            this.setState({
                facilities: fS.getFacilities(),
                devices: fS.getDevices(fS.getFacilityUuidByName("genesis"))
            });
        }
    }


    cb_facSelect(fac) {
        this.setState({facSelected: fac})
    }

    cb_topologySelect(cluster,device) {
        this.setState({selCluster: cluster, selDevice: device})
    }

    _click_addFacility() {
        this.setState({showDialog_Facility: true})
    }

    _cB_closeDialog() {
        this.setState({showDialog_Facility: false})
    }

    _renderFacTable() {
        let facList = [];
        // eslint-disable-next-line
        for (const [key, value] of this.props.facilities) {
            const facName = value.get("name");
            const entry = (
                <FacPanel key={facName} data={value} facSelected={this.state.facSelected} cb_facSelect={this.cb_facSelect} />
            );
            facList.push(entry);
        }
        return facList
    }


    render() {

        const width_graph = this.props.panelSize.width/2;

        const facTable = this._renderFacTable();

        const page = <div style={{height: this.props.panelSize.height}} >

            <CreateFacility
                isActive={this.state.showDialog_Facility}
                handle_func_closeDialog={this._cB_closeDialog}
            />


            <div style={{display: "flex"}} >
                <div style={{width: width_graph, height: this.props.panelSize.height, border: "1px solid black"}}>
                    <NWG
                        devices={this.props.devices}
                        facilities={this.props.facilities}
                        panelSize={this.props.panelSize}
                        viewMode={"facility"}
                        handle_func_setSelectedFacility={this.cb_facSelect}
                        cb_topologySelect={this.cb_topologySelect}
                        selectedFacility = {this.state.facSelected}
                    />
                </div>
                <div style={{height: this.props.panelSize.height, flexGrow: 1, border: "1px solid black"}}>
                    <div style={{fontFamily: "Roboto", fontSize: 11, fontWeight: "normal"}} >
                    <div style={{backgroundColor: "DimGray", color: "White", height: 40 }}>
                        <div style={{display: "flex"}} >
                        <div style={{fontSize: 18, fontWeight: "bold", paddingLeft: 15, paddingTop: 6}} >
                        Your Facilities
                        </div>
                        <div style={{flexGrow: 1}}/>
                            <Button color="inherit"
                                    style={{marginTop: 3, maxHeight: 30, paddingRight: 25}}
                                    onClick={() => this._click_addFacility() } >
                                <AddBoxIcon style={{height: 22, width: 22, paddingRight: 5}}/>
                                Add
                            </Button>
                        </div>
                    </div>
                        <div style={{height: this.props.panelSize.height-40, overflowY: "scroll"}} >
                    {facTable}
                    </div>
                    </div>
                </div>

            </div>

        </div>


        return page;
    }

}
export default Facilities;
