import { EventEmitter } from "events";
import dispatcher from "../dispatcher/dispatcher";

class CmdLineStore extends EventEmitter {
  HISTORY_BACKLOG = 400;
  HISTORY_SQUEEZE_OUT = 200;
  messageNumber = 0;
  history = this._resetHistory();
  cmdHistory = [];

  getHistory() {
    return this.history;
  }

  getOldCmd(cmdHistoryDepth) {
    const numberEntries = this.cmdHistory.length;
    if (numberEntries === 0) return "";

    if (cmdHistoryDepth > numberEntries)
      return this.cmdHistory[numberEntries - 1];

    if (cmdHistoryDepth < 1) return "";

    return this.cmdHistory[cmdHistoryDepth - 1];
  }

  _resetHistory() {
    const startHistory = [
      [" ", " ", 0],
      ["CenoLabs IoT Platform", " ", 1],
      ["The IoT Operating System That Works For You.", " ", 2],
      ["Copyright 2018-2019 - Cenolabs GmbH", " ", 3],
      ["Terminal Interface", " ", 4],
      [" ", " ", 5]
    ];
    this.messageNumber = 6;
    this.cmdHistory = [];
    return startHistory;
  }

  _addToHistory(message) {
    this.history.push(message);
    if (this.history.length > this.HISTORY_BACKLOG) {
      this.history = this.history.slice(this.HISTORY_SQUEEZE_OUT);
    }
  }

  _extractFilterFirstWord(msg) {
    // statistics and global messages get filtered out
    if (msg.includes("statistics set") || msg.includes("statistics table"))
      return null;
    if (msg.includes("global set") || msg.includes("global table")) return null;

    const spacePos = String(msg).indexOf(" ");
    let firstWord = "";
    let remainMsg = "";
    if (spacePos === -1) {
      firstWord = " ";
      remainMsg = msg;
    } else {
      firstWord = msg.substr(0, spacePos).toUpperCase();
      remainMsg = msg.substr(spacePos + 1);
    }

    // all messages but INFO and RESP get filtered out
    if (firstWord === "INFO" || firstWord === "RESP") {
      return [firstWord, remainMsg];
    } else {
      return null;
    }
  }

  handleActions(action) {
    switch (action.type) {
      case "EXT_CHAT_CMD": {
        /* a new command was entered on the GUI and this command
         * needs to be added to the command line history */
        this._addToHistory([action.id, "CMD", this.messageNumber]);
        this.messageNumber = this.messageNumber + 1;
        // add this command to the command history
        this.cmdHistory.unshift(action.id);
        break;
      }

      case "MSG_RECV": {
        // new message received from the platform
        const resp = this._extractFilterFirstWord(action.msg);

        if (resp) {
          this._addToHistory([resp[1], resp[0], this.messageNumber]);
          this.messageNumber = this.messageNumber + 1;
          //}
        }
        break;
      }

      case "ACTION_ChangeActiveService": {
        this.history = this._resetHistory();
        break;
      }

      case "LOGOUT_DONE": {
        // user logged out on GUI --> reset state variables
        this.history = this._resetHistory();
        break;
      }

      default:
        // nothing to be done here
        break;
    } // --> end switch (action.type)
    // tell the world about the change that just happened in this data store
    this.emit("cmdHistoryUpdate");
  } // --> end handleActions
} // --> end class CmdLineStore

const cmdLineStore = new CmdLineStore();
dispatcher.register(cmdLineStore.handleActions.bind(cmdLineStore));

export default cmdLineStore;
