import React from "react";
import websocketCom from "./../../../webcom/WebsocketCom"

import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import problemsStore from "./ProblemsStore";

class Problems extends React.Component {

  constructor(props) {
    super(props);

    this.state = { serviceStatus: {},
                   numberIssues: 0}

    this._updateStatusView = this._updateStatusView.bind(this);
  }

  componentDidMount() {
    this._updateStatusView();
    problemsStore.on("StatusUpdate", this._updateStatusView);
  }
  componentWillUnmount() {
    problemsStore.removeListener("StatusUpdate", this._updateStatusView);
  }

  _updateStatusView() {
    const status = problemsStore.getStatus();
    const numberIssues = problemsStore.getNumberIssues();
    this.setState({ serviceStatus: status, numberIssues: numberIssues});
  }

  _click_resolveUnknownRE(issue, uuid) {
    let msg = ""
    if (uuid) {
      const delEntry = issue.issue.substr(issue.issue.indexOf(":") + 1);
      const temp = delEntry.substr(delEntry.indexOf("::") + 2)
      const delPort = temp.substr(0, temp.indexOf("//uuid"))
      msg = issue.so + " delcon " + issue.port + " " + uuid + "::" + delPort;
    } else {
      const delEntry = issue.issue.substr(issue.issue.indexOf(":")+2)
      msg = issue.so + " delcon " + issue.port + " " + delEntry
    }
    websocketCom.sendServiceMessage(msg);
  }

  render() {

    const status = this.state.serviceStatus;
    let viewContainer = [];
    for (const soName in status) {
      const soHeader = <div key={"issue-"+soName} style={{fontSize: 13, fontWeight: "bold", paddingLeft: 10,paddingBottom: 2, paddingTop: 4, backgroundColor: "WhiteSmoke" }}>SmartObject {soName}</div>
      viewContainer.push(soHeader);

      const soIssues = status[soName];
      for (const nextIssue in soIssues) {
        // assign warning or error icon
        let icon = (<div />)
        if (soIssues[nextIssue].level === "warning") {
          icon = <WarningIcon style={{ height: 14, color: "DarkOrange" }} />
        } else if (soIssues[nextIssue].level === "error") {
          icon = <ErrorIcon style={{ height: 14, color: "red" }} />
        }

        let entry = (<div />)
        if (soIssues[nextIssue].type === "method") {
          entry = (
          <div key={"issue-"+nextIssue+"-"+soName} style={{display: "flex", flexDirection: "row", alignItems: "center" }} >
            <div style={{marginLeft: 20, paddingLeft: 10, borderLeftColor: "gray", borderLeftStyle: "solid", borderWidth: 1}}>
              {icon}
            </div>
            <div>
              Method {soIssues[nextIssue].method} - {soIssues[nextIssue].issue}
            </div>
          </div> );
        } else {
          let resolve =<div />
          let issue = ""
          let uuid = null


          if (soIssues[nextIssue].issue.includes("Unknown remote endpoint:")) {
            const locUuid = soIssues[nextIssue].issue.indexOf("//uuid");
            if (locUuid > 0) { issue = soIssues[nextIssue].issue.substring(0,locUuid)
              uuid=soIssues[nextIssue].issue.substring(locUuid+6)}
            else { issue=soIssues[nextIssue].issue }

              resolve =
                  <button style={{color: "black", fontWeight: "bold"}}
                          onClick={() => {
                            this._click_resolveUnknownRE(soIssues[nextIssue], uuid)
                          }}
                  >
                    Auto-Resolve
                  </button>

          } else {
            issue = soIssues[nextIssue].issue;
          }



          entry = (
            <div key={"issue-"+nextIssue+"-"+soName} style={{display: "flex", flexDirection: "row", alignItems: "center",
              marginLeft: 20, paddingLeft: 10, borderLeftColor: "gray", borderLeftStyle: "solid", borderWidth: 1}} >
              <div >
                {icon}
              </div>
              <div>
                Port {soIssues[nextIssue].port} - {issue}
              </div>
              <div  style={{marginLeft: 10}}>{resolve}</div>
            </div> );

        }
        viewContainer.push(entry);
      }
      const spaceEntry = ( <div key={"space-"+soName} style={{height: 7}} /> );
      viewContainer.push(spaceEntry);
    }

    return ( <div style={{fontFamily: "Roboto", fontSize: 12}} >
                      <div style={{height: this.props.height-30, overflow: "auto"}}>
                        {viewContainer}
                      </div>
                    </div>)
  }
}

export default Problems;

