import React from "react";
import websocketCom from '../../../webcom/WebsocketCom.js';
import pM from '../../PopUp/PopupManager';
import selectionManager from '../../SelectionManager/SelectionManager';
import mM from '../../CodeDev/MethodManager';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import EditIcon from '@material-ui/icons/Edit';


class MethodItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {runButtonColor: "WhiteSmoke", isSelect: false, isEdit: false};

    this.id = "method "+this.props.soName+" "+this.props.methodName;

    this._clickSelectMethod = this._clickSelectMethod.bind(this);
    this._onMouseOver = this._onMouseOver.bind(this);
    this._onMouseLeave = this._onMouseLeave.bind(this);
    this._clickEditMethod = this._clickEditMethod.bind(this);
    this._onContextMenu = this._onContextMenu.bind(this);
    this._cE_SelectionUpdate = this._cE_SelectionUpdate.bind(this);
    this._cE_MMUpdate = this._cE_MMUpdate.bind(this);
  }

  componentDidMount() {
    selectionManager.on( "ChangeEvent_Selection", this._cE_SelectionUpdate );
    mM.on("MM_dataChange", this._cE_MMUpdate );
  }

  componentWillUnmount() {
    selectionManager.removeListener( "ChangeEvent_Selection", this._cE_SelectionUpdate );
    mM.removeListener( "MM_dataChange", this._cE_MMUpdate );
  }

  _cE_SelectionUpdate() {
    const resp = selectionManager.checkSelect(this.id);
    const isSelected = resp[0]; //const scope = resp[1];

    if (isSelected) {
      this.setState({isSelect: true });
    } else {
      this.setState({isSelect: false });
    }
  }

  _cE_MMUpdate() {
    const isOpen = mM.checkOpen(this.props.soName, this.props.methodName);
    this.setState({isEdit: isOpen});
  }


  _clickSelectMethod(event) {
    event.stopPropagation();
    const scope = event.shiftKey ? "secondary" : "primary";
    selectionManager.toggle(this.id, scope, "inlet" );
  }

  _runMethodClick(event) {
    event.stopPropagation();
    const msg = this.props.soName+" call "+this.props.methodName;
    websocketCom.sendServiceMessage(msg);
  }

  _preventBubble(event) {
    event.stopPropagation();
  }

  _onMouseOver() {
    this.setState({runButtonColor: "orange" })
  }

  _onMouseLeave() {
    this.setState({runButtonColor: "WhiteSmoke" })
  }

  _clickEditMethod(event) {
    event.stopPropagation();
    mM.openMethod(this.props.soName, this.props.methodName);
  }

  _onContextMenu(event) {
    event.stopPropagation();
    event.preventDefault();

    pM.deletePopups();
    const element = document.getElementById("container-svg-graph")
    if (!element) {return}
    const location = {"x": ((element.scrollLeft+event.clientX-60)/this.props.scaleFactor),
                      "y": ((element.scrollTop+event.clientY-70)/this.props.scaleFactor),
                      "scaleFactor": this.props.scaleFactor }


    //const popup = this.props.direction==="inlet" ? "contextinlet" : "contextoutlet";

    pM.addPopup(this.props.soName, this.props.methodName, location, "contextmethod", "MediumSeaGreen")
  }


  _createRunButton() {
    const SHOW_RUN_BUTTON = false;

    if (! SHOW_RUN_BUTTON) return ( <div style={{paddingLeft: 7}} />)

    const runButton = (
    <PlayCircleFilledIcon style={{color: this.state.runButtonColor, paddingLeft: 3, paddingRight: 5, height: 17}}
                          onClick={(event) => this._runMethodClick(event)}
                          onMouseOver={(event) => this._onMouseOver(event)}
                          onMouseLeave={(event) => this._onMouseLeave(event)} />)
    return runButton
  }



  render() {

    const method_bg = this.state.isSelect===true ? "blue":"#444";

    let edit_bg = this.state.isEdit===true ? "WhiteSmoke" : "#444";
    let edit_fg = this.state.isEdit===true ? "#444" : "WhiteSmoke";

    if (this.state.isSelect && ! this.state.isEdit) {
      edit_bg = "blue";
    }


    let issueIndicator = (<div />);
    if (this.props.status === "ERROR") {
      issueIndicator = ( <div style={{ backgroundColor: "red", minWidth: 8, minHeight: 8, marginTop:-10,
                                       border: "1px solid white", borderRadius: 4 }} /> )
    }

    const showRunButton = this._createRunButton();

    const methodItem = (
      <div  style={{display: "flex", flexDirection: "row", overflow: "hidden", alignItems: "center",
                    backgroundColor: method_bg, color: "white", borderRadius: 6,
                    fontFamily: "Roboto", fontSize: 12, fontWeight: "bold",
                    width: this.props.width, height: 22, marginBottom: 5, }}
                    onClick={(event) => this._clickSelectMethod(event)}
                    onMouseDown={(event) => this._preventBubble(event)}
                    onContextMenu= {(event) => this._onContextMenu(event)}
      >

      {showRunButton}

      <div style={{flexGrow: 1, cursor: "default", userSelect: "none", overflow: "hidden", marginRight: 4 }} >
          {this.props.methodName}
        </div>
        {issueIndicator}
        <div style={{paddingTop: 3, paddingLeft: 4, width: 22, color: edit_fg, backgroundColor: edit_bg}}
             onClick={(event) => this._clickEditMethod(event)} >
          <EditIcon style={{height: 17}} />
        </div>
      </div>
    )

    return methodItem
  }

}

export default MethodItem;

