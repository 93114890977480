import React from "react";

import Port from '@material-ui/icons/Brightness1';
import Delete from '@material-ui/icons/Delete';
import Mapping from '@material-ui/icons/LocationOn';
import ShowPortDetails from '@material-ui/icons/Fullscreen';
import HidePortDetails from '@material-ui/icons/FullscreenExit';
import SetPortConstrained from '@material-ui/icons/Adjust';
import SetPortOpen from '@material-ui/icons/Brightness1';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Run from '@material-ui/icons/PlayCircleFilled';
import SaveIcon from '@material-ui/icons/Save';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import StatsIcon from '@material-ui/icons/Assignment';
import ExportIcon from '@material-ui/icons/PresentToAll';

class MenuEntry  extends React.Component {

  constructor() {
    super();

    this.state = { color: "black",
                   color_bg: "transparent" };

    this._toggleColor = this._toggleColor.bind(this);
  }


  _toggleColor() {
    const newColor = ( this.state.color === "black") ? "black" : "black";
    this.setState({color: newColor})
    let newColor_bg = ( this.state.color_bg === "transparent") ? "WhiteSmoke" : "transparent";
    if (this.props.iconName === "delete" && newColor_bg === "WhiteSmoke") {
      newColor_bg = "rgba(220,20,60,0.15)";
    }
    this.setState({color_bg: newColor_bg})
  }

  _getIcon() {
    let icon = null;
    switch (this.props.iconName) {
      case "addport":
        icon = <Add style={{width: 15}} />
        break;
      case "port":
        icon = <Port style={{width: 15}} />
        break;
      case "addmethod":
        icon = <Add style={{width: 15}} />
        break;
      case "setmapping":
        icon = <Mapping style={{width: 17, paddingTop: 2}} />
        break;
      case "delete":
        icon = <Delete style={{width: 21, marginLeft: -3, marginRight: -3}} />
        break;
      case "snapshot":
        icon = <SaveIcon style={{width: 21, marginLeft: -3, marginRight: -3}} />
        break;
      case "restore":
        icon = <RestoreIcon style={{width: 21, marginLeft: -3, marginRight: -3}} />
        break;
      case "export":
        icon = <ExportIcon style={{width: 19, marginBottom: -2, marginLeft: -2, marginRight: -1}} />
        break;
      case "stats":
        icon = <StatsIcon style={{width: 19, marginLeft: -1, marginRight: -1}} />
        break;


      case "showportdetails":
        icon = <ShowPortDetails style={{width: 15}} />
        break;
      case "hideportdetails":
        icon = <HidePortDetails style={{width: 15}} />
        break;
      case "setportconstrained":
        icon = <SetPortConstrained style={{width: 15}} />
        break;
      case "setportopen":
        icon = <SetPortOpen style={{width: 15}} />
        break;
      case "addinlet":
        icon = <Add style={{width: 15}} />
        break;
      case "addoutlet":
        icon = <Add style={{width: 15}} />
        break;

      case "runmethod":
        icon = <Run style={{width: 15}} />
        break;
      case "editmethod":
        icon = <Edit style={{width: 15}} />
        break;


      default:
        icon = null
        break;
    }
    return icon

  }


  render() {

    const show = this.props.iconName!==null ? "block" : "none"
    const panel = (
      <div style={{display: "flex", flexDirection: "row", alignItems: "center",
                   color: this.state.color, backgroundColor: this.state.color_bg,
                   marginRight: 1, paddingTop: 2}}
           onMouseEnter = { () => this._toggleColor() }
           onMouseLeave = { () => this._toggleColor() }
           onClick = { () => this.props.callback(this.props.iconName)} >
        <div style={{display: show, width: 10}} />
        <div>{this._getIcon()}</div>
        <div style={{width: 10}} />
        <div>{this.props.text}</div>
      </div>
    )

    return panel
  }

}

export default MenuEntry;

