import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";

import OpenPort from '@material-ui/icons/Brightness1';
import ConstrainedPort from '@material-ui/icons/TripOrigin';
import Delete from '@material-ui/icons/Delete';


class PortEntry  extends React.Component {

  constructor() {
    super();

    this.state = { color: "black",
      color_bg: "transparent",
      color_status: "black",
      color_delete: "black" };

    this._toggleColor = this._toggleColor.bind(this);
    this._getIcon = this._getIcon.bind(this);
  }

  _deletePort() {
    const msg = this.props.portData.soName + " delport " + this.props.portData.portName
    websocketCom.sendServiceMessage(msg);
  }

  _setConPort() {
    let msg = ""
    if (this.props.portData.status === "nil") {
      msg = this.props.portData.soName+" setcon "+this.props.portData.portName+" empty";
    } else {
      msg = this.props.portData.soName+" setcon "+this.props.portData.portName+" nil";
    }
    websocketCom.sendServiceMessage(msg);
  }

  _toggleColor() {
    const newColor = ( this.state.color === "black") ? "black" : "black";
    this.setState({color: newColor})
    let newColor_bg = ( this.state.color_bg === "transparent") ? "WhiteSmoke" : "transparent";
    if (this.props.iconName === "delete" && newColor_bg === "WhiteSmoke") {
      newColor_bg = "rgba(220,20,60,0.15)";
    }
    this.setState({color_bg: newColor_bg})
  }

  _getIcon() {
    let icon = null;
    switch (this.props.portData.status) {
      case "nil":
        icon = <OpenPort style={{width: 15, color: this.state.color_status}}
                         onMouseEnter = { () => this.setState({color_status: "Orange" }) }
                         onMouseLeave = { () => this.setState({color_status: "Black" }) }
                         onClick = { () => this._setConPort()} />
        break;
      default:
        icon = <ConstrainedPort style={{width: 15, color: this.state.color_status}}
                                onMouseEnter = { () => this.setState({color_status: "Orange" }) }
                                onMouseLeave = { () => this.setState({color_status: "Black" }) }
                                onClick = { () => this._setConPort()} />
        break;
    }
    return icon
  }


  render() {

    const deleteIcon = <Delete style={{width: 21, marginLeft: -3, marginRight: -3, color: this.state.color_delete}}
                               onMouseEnter = { () => this.setState({color_delete: "Red" }) }
                               onMouseLeave = { () => this.setState({color_delete: "Black" }) }
                               onClick = { () => this._deletePort() } />

    const show = this.props.iconName!==null ? "block" : "none"

    const panel = (
      <div style={{display: "flex", flexDirection: "row", alignItems: "center",
                   color: this.state.color, backgroundColor: this.state.color_bg,
                   marginRight: 0, paddingTop: 2}}
           onMouseEnter = { () => this._toggleColor() }
           onMouseLeave = { () => this._toggleColor() } >

        <div style={{display: show, width: 10}} />
        <div>{this._getIcon()}</div>
        <div style={{width: 10}} />
        <div style={{flexGrow: 1}} >{this.props.portData.portName}</div>
        <div style={{paddingRight: 25}} >{deleteIcon}</div>

      </div> )

    return panel
  }

}

export default PortEntry;

