
import React from "react";
import ServiceTerminal from "./../Panels/ServiceTerminal/ServiceTerminal";
class Terminal extends React.Component {

  //constructor() {
  //  super();
  //}

  render() {

    const view = (
        <div style={{flexGrow: 2, backgroundColor: "white", padding: 2}} >
<ServiceTerminal height={this.props.height} />
    </div>
    )


    return view;
  }

}

export default Terminal;
