class Config {


  // canvas realated configuration
  setup  = {"canvas" : {"width": 10000, "height": 10000, "scaleFactor" : 0.9,
                        "borderWidth": 40, "border_col": "White"},
            "layout" : {"navigationPanelWidth": 200, "globalWidthPercentage": 0.35,
                        "headerPanelHeight": 50, "statusPanelHeight": 25, "configMenuHeight": 25,  "routingPanelNav": 50}}



  so_default_col = "green";

  so_col = { "green"  : {"main": "rgb(56,142,60)", "accent": "rgb(76,175,80)", "text": "White" },
             "blue"   : {"main": "rgb(25,118,210)", "accent": "rgb(33,150,243)", "text": "White" },
             "violet" : {"main": "rgb(123,31,162)", "accent": "rgb(156,39,176)", "text": "White" },
             "orange" : {"main": "rgb(255,111,0)", "accent": "rgb(255,143,0)", "text": "White" },
             "red"    : {"main": "rgb(211,47,47)", "accent": "rgb(244,67,54)", "text": "White" }, }

  so_dead_col = "Gainsboro";


}

const config = new Config();
export default config
