import React from "react";

import sMS from "./../../../../stores/SessionManagementStore";// -------------------------------------------------------------
import Error from "./Error";

// material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";

// -------------------------------------------------------------


class DeleteMember extends React.Component {
  constructor(props) {
    super(props);

    this.state = {comError: false}
    this.errorMessage = ""

    this._deleteUser = this._deleteUser.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.closeErrorDialog = this.closeErrorDialog.bind(this);
  }



 _deleteUser() {
    const tokenRequestData = sMS.getAuthUpdateData()
    const url = "https://" + tokenRequestData.url + "/api/deluser";
    const delUserData = { "login" : this.props.member}
    const dataSet = {"auth":tokenRequestData.reAuthData,"deluser":delUserData}
    const dataSet_json = JSON.stringify(dataSet)
    const isAsynchronous = true;
    let response;
    let xhr = new XMLHttpRequest();

    const _this = this;
    xhr.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        response = JSON.parse(xhr.responseText);
        if (response.status === "error") {
          //console.log("ERROR");
          //console.log(response.message);
          _this.errorMessage = (<div>
                                <div>
                                The request to delete member {_this.props.member} failed!
                                </div>
                                <div style={{height: 15}} />
                                <div>
                                Details:
                                </div>
                                <div>
                                {response.message}
                                </div>
                                </div>)
          _this.setState({comError: true})
        } else {
          //delete response.status
          //_this.setState({usersData: response})
          console.log("User successfully deleted from platform");
        }
      }
    };
    xhr.onerror = function() {
      //response = {
      //  status: "error",
      //  description: "Could not connect to api authority."
      //};
      //console.log(response);
      _this.errorMessage="Could not connect to Platform Management Server."
      _this.setState({comError: true})
    };
    xhr.open("POST", url, isAsynchronous);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.send(dataSet_json);
  }


  handleClose() {
    this.props.handle_func_closeDialog();
  }


  handleDelete() {
    this._deleteUser();
    this.handleClose();
  }

  closeErrorDialog() {
    this.setState({comError: false})
    this.errorMessage=""
  }

  render() {
    const dialog = (
      <div>

        <Error isActive={this.state.comError}
               handle_func_closeErrorDialog={this.closeErrorDialog}
               errorMessage={this.errorMessage}
        />

        <Dialog
          open={this.props.isActive}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle> {this._renderDialogHeadline()} </DialogTitle>
          <DialogContent>
            <DialogContentText>
            This will delete the member {this.props.member} and all its exclusively owned services from the platform.
            There is no going back, once you deleted this user.
            </DialogContentText>
            <div style={{ minHeight: 20 + "px" }} />

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
    return dialog;
  }



  // -----------------------------------------------------------------------------
  _renderDialogHeadline() {
    return (
      <div id="header" style={{ display: "flex", flexDirection: "row" }}>
        <div
          id="colorblotch"
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#9c27b0",
            boxShadow: "1px 4px 7px 2px #bdbdbd",
            borderRadius: 4
          }}
        >
          <PersonIcon
            style={{
              width: 26,
              height: 26,
              color: "white",
              position: "relative",
              top: 12,
              left: 12
            }}
          />
        </div>
        <div
          id="headline"
          style={{
            flexGrow: 1,
            textAlign: "left",
            paddingTop: 7,
            paddingLeft: 15
          }}
        >
          <div id="type">
            <Typography
              variant="overline"
              style={{ fontSize: 9, color: "#757575" }}
            >
              People
            </Typography>
          </div>
          <div id="text">
            <Typography
              variant="body1"
              fontWeight="bold"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                position: "relative",
                top: -9
              }}
            >
              Delete {this.props.member} From Platform
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteMember;
