// noinspection DuplicatedCode

import React from "react";
//import aSDS from "./../../stores/ActiveServiceDataStore";
//import gLS from "./../../stores/GraphLayoutStore";
import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";
import pM from "./PopupManager";
//import PortEntry from "./PortEntry";


class AddSnapshot extends React.Component {

    constructor(props) {
        super(props);
        // set size for this configuration panel
        this.width = 300; this.height = 240;
        this.tagNameError = true;
        //this.createButtonIsEnabled = false;

        this.state = { tagName: "", tagHelpText: "",
                       descriptionName: "",
                       moveOffset: {"x":0, "y":0} }

        this.moveCallback = this.moveCallback.bind(this);
        this._createSnapshotClick = this._createSnapshotClick.bind(this);
        this._handleTagChange = this._handleTagChange.bind(this);
        this._handleDescriptionChange = this._handleDescriptionChange.bind(this);
    }


    _panelClick(event) {event.stopPropagation() }

    moveCallback(delta) {
        this.setState({moveOffset: delta})
    }

    _createSnapshotClick(event) {
        // event.stopPropagation();
        const msg = this.props.soName + " save " + this.state.tagName + " " + this.state.descriptionName;
        console.log("msg =", msg);
        websocketCom.sendServiceMessage(msg);
        this.setState({ tagName: "", descriptionName: "" });
        pM.deletePopup(this.props.id);
    }


    _handleTagChange(event) {
        this.setState({ tagName: event.target.value });

        // eslint-disable-next-line no-useless-escape
        const letterNumber = /^[_\-0-9a-zA-Z]+$/;
        if ( !(event.target.value.match(letterNumber)) && event.target.value !== "") {
            this.tagNameError = true;
            //this.createButtonIsEnabled = false;
            this.setState({tagHelpText: "Unvalid character"})
            return
        }
        if (event.target.value === "") {
            this.tagNameError = true;
            //this.createButtonIsEnabled = false;
            this.setState({tagHelpText: "Empty name"});
            return
        }
        if (event.target.value.length < 3) {
            this.tagNameError = true;
            //this.createButtonIsEnabled = false;
            this.setState({tagHelpText: "Minimum length 3 characters"});
            return
        }
        this.tagNameError = false;
        this.setState({tagHelpText: ""});
    }

    _handleDescriptionChange(event) {
        this.setState({descriptionName: event.target.value});
    }



    _buildHeadline() {
        return (
            <div id="headline-snapshot" style={{ paddingBottom: 5, paddingLeft: 15 }} >
                <div id="type">
                    <div style={{display: "flex", fontFamily: "Roboto", fontSize: 13, color: "DimGray", paddingTop: 3}} >
                        <div style={{width: 9, height: 9, backgroundColor: "DimGray", marginTop: 4, marginRight: 4}} />
                        <div> {this.props.soName}</div>
                    </div>
                </div>
                <div id="text"
                     style={{ fontSize: 16, fontWeight: "bold", textAlign: "left", paddingTop: 2}} >
                    Snapshot
                </div>
            </div>)
    }


    _buildConfPortsMenu() {

        const buttonBorder = this.createButtonIsEnabled ? "1px solid Gray" : "1px solid LightGray";
        //const buttonFontWeight = this.createButtonIsEnabled ? "bold" : "normal";

        const panel = (
            <div style={{ cursor: "default" }} onClick={(event) => this._panelClick(event) }>
                {this._buildHeadline()}
                <div style={{ paddingLeft: 15, paddingTop: 12, paddingRight: 20}}  >

                    <div style={{paddingBottom: 5}} >Tag:</div>

                    <input style={{fontFace: "Roboto", fontSize: 14, width: 245 }}
                           onChange={(event)=>this._handleTagChange(event)}
                           type="text" id="tag" name="tag" autoFocus />
                    <div style={{minHeight: 20, color: "Red", paddingLeft: 0, paddingTop: 3, fontSize: 10 }} >
                        {this.state.tagHelpText}
                    </div>

                    <div style={{paddingBottom: 5}} >Description:</div>

                    <input style={{fontFace: "Roboto", fontSize: 14, width: 245 }}
                           onChange={(event)=>this._handleDescriptionChange(event)}
                           type="text" id="description" name="description" />
                    <div>
                        <button style={{ fontFace: "Roboto", fontSize: 14,  backgroundColor: "WhiteSmoke", fontWeight: "normal",  border: buttonBorder,
                            height: 22, float: "right", marginRight: 15, marginTop: 25}}
                                type="button"
                                disabled={this.tagNameError}
                                onClick={(event)=>this._createSnapshotClick(event)} >
                            Create
                        </button>
                    </div>

                </div>

            </div> )
        return panel

    }


    render() {

        // Content of panel is defined here
        const view = (<div key={"content-ports-conf-"+this.props.soName}> {this._buildConfPortsMenu()} </div>)

        let  geometry = this.props.location;
        //const scaleFactor = this.props.location.scaleFactor;
        geometry["x"] = geometry["x"]+this.state.moveOffset.x; geometry["y"] = geometry["y"]+this.state.moveOffset.y;
        geometry["width"] = this.width; geometry["height"] = this.height;

        const panel = ( <Popup geometry={geometry} content={view}
                               id={this.props.id} elementClass={this.props.elementClass}
                               delCallback={this.props.delCallback}
                               moveCallback={this.moveCallback}
        /> )
        return panel
    }

}

export default AddSnapshot;
