import {useD3} from './../../../../../hooks/useD3';
import React from 'react';
import * as d3 from 'd3';

let maxValue = -Infinity;
let minValue = Infinity;
let barWidth = 0;

function BarChart(props) {
  if (props.wipeChart) {
    maxValue = -Infinity;
    minValue = Infinity;
    barWidth = 0;
  }

  maxValue = props.data > maxValue ? props.data : maxValue;
  minValue = props.data < minValue ? props.data : minValue;
  const minMax = [minValue, maxValue];
  const height = 95;
  const width = 325;
  const margin = {top: 5, right: 5, bottom: 25, left: 25};

  const ref = useD3(
    svg => {
      const x1 = d3
        .scaleLinear()
        .domain([0, maxValue * 1.1])
        .range([margin.left, width]);
      const x1Axis = g =>
        g
          .attr('transform', `translate(0,${height - margin.bottom})`)
          .attr('font-size', '14px')
          .style("font-weight", "bold")
          .call(d3.axisBottom(x1))


      barWidth = x1(props.data);
      svg.select('.x-axis').call(x1Axis);

      svg
        .select('.plot-area')
        .selectAll('.bar')
        .data([props.data])
        .join('rect')
        .attr('fill-opacity', '0.3')
        .attr('fill', 'rgb(237,231,246)')
        .attr('class', 'bar')
        .attr('x', x1(minValue)) //(d) => (x1(minValue)-x1(0)))
        .attr('width', d => x1(maxValue) - x1(minValue))
        .attr('y', '25')
        .attr('height', '30');

      svg
        .select('.plot-area')
        .selectAll('.marker')
        .data(minMax)
        .join('rect')
        .attr('fill-opacity', '0.7')
        .attr('fill', 'white')
        .attr('class', 'marker')
        .attr('x', d => x1(d)-2)
        .attr('width', '4')
        .attr('y', '25')
        .attr('height', '30');

      svg
        .select('.plot-area')
        .selectAll('.target')
        .data([props.data])
        .join('rect')
        .attr('fill', 'white')
        .attr('class', 'target')
        .transition()
        .duration(350)
        .attr('x', d => x1(d) - 2)
        .attr('width', '5')
        .attr('y', '15')
        .attr('height', '50')


    },
    [props.data, props.wipeChart],
  );

  return (
    <svg
      ref={ref}
      style={{
        height: 100,
        width: '100%',
        marginRight: '0px',
        marginLeft: '0px',
      }}>
      <g className="x-axis" />

      <g className="plot-area" />
    </svg>
  );
}

export default BarChart;
