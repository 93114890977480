import React from "react";
import websocketCom from "./../../../../webcom/WebsocketCom";
import aSDS from "../../../../stores/ActiveServiceDataStore";

import SettingsIcon from "@material-ui/icons/Settings";
import ReturnIcon from "@material-ui/icons/KeyboardReturn";
import LineChart from "./ChartGraph/LineChart";

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import WipeIcon from '@material-ui/icons/RemoveFromQueue';


class Line extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeView: "chart", // can be chart or config
      metricName: props.chartSetup.name,
      smoothAlgo: "linear", // can be: linear, step-after, cardinal
    };
    // chart config data in: props.chartSetup
    // chart plot data in: props.chartData

    this.chart_data = [];
    this.state_change = 0;

    this._buildConfigView = this._buildConfigView.bind(this);
    this._buildChartView = this._buildChartView.bind(this);
    this._changeActiveView = this._changeActiveView.bind(this);
    this._handleNameChange = this._handleNameChange.bind(this);
    this._processNewChartData = this._processNewChartData.bind(this);
    this._wipeChart = this._wipeChart.bind(this)
  }

  componentDidMount() {
    this.state.metricName = this.props.chartSetup.name;
  }

  //componentWillUnmount() {}

  _handleNameChange(event) {
    this.setState({ metricName: event.target.value });
  }
  //_handleSmoothAlgoChange(event) {
  //  event.stopPropagation();
  //  this.setState({ smoothAlgo: event.target.value });
  //}

  _changeActiveView(event, newView) {
    event.stopPropagation();
    this.setState({ activeView: newView });

    if (newView === "chart") {
      let temp_chartSetup = this.props.chartSetup;
      temp_chartSetup.name = this.state.metricName;
      const newSetup = JSON.stringify(temp_chartSetup);
      const msg = this.props.soName + " setattribute chart_setup " + newSetup;
      websocketCom.sendServiceMessage(msg);
    }
  }

  _wipeChart( event) {
  event.stopPropagation();
  this.chart_data = [];
    this.forceUpdate();
  }


  _processNewChartData() {
    this.stateChange = 0;
    if (this.chart_data.length === 0) {
      this.chart_data.push(this.props.chartData)
      this.stateChange = Math.random();
    } else {
      const lastElementTimestamp = this.chart_data[this.chart_data.length-1][0]
      const newElementTimestamp = this.props.chartData[0]
      if (lastElementTimestamp !== newElementTimestamp) {
        this.chart_data.push(this.props.chartData);
        this.stateChange=Math.random();
      }
    }
    if (this.chart_data.length > 50) this.chart_data.shift()
  }

  _buildConfigView() {

    this._processNewChartData();

    let linearRadioButton = <RadioButtonUncheckedIcon
      onClick = {(e) => {e.stopPropagation();  this.setState({smoothAlgo: "linear"})} } />
    let stepAfterRadioButton = <RadioButtonUncheckedIcon
      onClick = {(e) => {e.stopPropagation();  this.setState({smoothAlgo: "step-after"})} } />
    let cardinalRadioButton = <RadioButtonUncheckedIcon
      onClick = {(e) => {e.stopPropagation();  this.setState({smoothAlgo: "cardinal"})} } />
    const selectRadioButton = <RadioButtonCheckedIcon
      onClick = {(e) => e.stopPropagation()} />

    switch (this.state.smoothAlgo) {
      case "linear":
        linearRadioButton = selectRadioButton;
        break;
      case "step-after":
        stepAfterRadioButton = selectRadioButton;
        break;
      case "cardinal":
        cardinalRadioButton = selectRadioButton;
        break;
    }

    const panel = (
      <div
        style={{
          fontFamily: "Roboto",
          fontSize: 14,
          fontWeight: "bold",
          paddingTop: 10,
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }} />
          <div style={{ fontSize: 15 }}>Time Series Chart - Configuration</div>
          <div style={{ flexGrow: 1 }} />
        </div>

        <div style={{ height: 20 }} />
        <div style={{ display: "flex", paddingLeft: 35 }}>
          <div style={{ fontSize: 15 }}>Metric Name:</div>
          <div style={{ width: 12 }} />
          <input
            style={{
              fontFace: "Roboto",
              fontSize: 13,
              width: 160,
              minHeight: 14,
            }}
            onChange={(event) => this._handleNameChange(event)}
            onClick={(event) => event.stopPropagation()}
            value={this.state.metricName}
            type="text"
            id="name"
            name="name"
            autoFocus
          />
        </div>
        <div style={{ height: 25 }} />

        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }} />
          <div style={{ fontSize: 15 }}>Graph Smoothing Algorithm</div>
          <div style={{ flexGrow: 1 }} />
        </div>

        <div style={{ height: 10 }} />
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          {linearRadioButton}
          <div style={{paddingLeft: 5, paddingRight: 15}}
          onClick = {(e) => {e.stopPropagation();  this.setState({smoothAlgo: "linear"})} }>
        LINEAR</div>
          {stepAfterRadioButton}
      <div style={{paddingLeft: 5, paddingRight: 15}}
          onClick = {(e) => {e.stopPropagation();  this.setState({smoothAlgo: "step-after"})} }>
    STEP AFTER</div>
          {cardinalRadioButton}
 <div style={{paddingLeft: 5}}
          onClick = {(e) => {e.stopPropagation();  this.setState({smoothAlgo: "cardinal"})} }>
  CARDINAL</div>
        </div>

      </div>
    );

    return panel;
  }

  _buildChartView() {
    // received chartData JSON convertion failed
    if (this.props.chartData === null) {
      const issuePanel = (
        <div style={{ textAlign: "center", fontFamily: "Roboto" }}>
          <div style={{ height: 48 }} />
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            No data for metric {this.props.chartSetup.name} available.
          </div>
        </div>
      );
      return issuePanel;
    }

    // ============================================================================
    // Number: check for valid data
    if (!Array.isArray(this.props.chartData)) {
      const issuePanel = (
        <div style={{ textAlign: "center", fontFamily: "Roboto" }}>
          <div style={{ height: 48 }} />
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            Unknown data format for metric {this.props.chartSetup.name}.
          </div>
        </div>
      );
      return issuePanel;
    }
    // ============================================================================

    // ============================================================================
    // Time: draw panel

    let errorMsg = "";
    let error = false;


    this._processNewChartData();
    const length = this.chart_data.length;

    if (length > 50) {
      errorMsg = (
        <div>
          <div>Unvalid array size length.</div>
          <div style={{ fontSize: 16, fontWeight: "400", paddingTop: 5 }}>
            1 &lt;= valid &lt;= 50
          </div>
        </div>
      );
      error = true;
    }

    if (length < 1) {
      errorMsg = <div>Received data structure empty.</div>;
      error = true;
    }

    if (error) {
      const chart = (
        <div style={{ textAlign: "center", fontFamily: "Roboto" }}>
          <div style={{ height: 24 }} />
          <div style={{ fontWeight: "Bold", fontSize: 32 }}>ERROR</div>
          <div style={{ height: 10 }} />
          <div style={{ fontSize: 18, fontWeight: "Bold" }}>{errorMsg}</div>
        </div>
      );
      return chart;
    }

    let chartPanel = (
      <LineChart
      data={this.chart_data}
      metrikName={this.state.metricName}
      smoothAlgo={this.state.smoothAlgo}
      stateChange={this.stateChange}
      />
    );

    // ============================================================================

    return chartPanel;
  }

  render() {
    let content = <div />;
    let footer = <div />;
    let metric = "";

    switch (this.state.activeView) {
      case "config":
        content = this._buildConfigView();
        footer = (
          <ReturnIcon
            style={{ paddingRight: 5 }}
            onClick={(event) => {
              this._changeActiveView(event, "chart");
            }}
          />
        );
        break;
      case "chart":
        content = this._buildChartView();
        metric = "Metric: " + this.state.metricName;
        footer = (

<div>

          <WipeIcon
            style={{ paddingRight: 15 }}
            onClick={(event) => { this._wipeChart(event)} }
        />


          <SettingsIcon
            style={{ paddingRight: 5 }}
            onClick={(event) => {
              this._changeActiveView(event, "config");
            }}
        />
          </div>
        );
        break;
      default:
        content = <div>Unexpected Error</div>;
        break;
    }

    const panel = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: 180,
        }}
      >
        <div style={{ flexGrow: 1 }}>{content}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              paddingLeft: 10,
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {metric}
          </div>
          <div style={{ flexGrow: 1 }} />
          <div> {footer} </div>
        </div>
      </div>
    );

    return panel;
  }
}

export default Line;
