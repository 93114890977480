import React from "react";
import cmdLineStore from "./../../../stores/CmdLineStore";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  cmdContainer: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.white
  },
  cmdRow: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 7 + "px",
    borderTopStyle: "solid",
    borderWidth: 1 + "px",
    borderColor: "#bdbdbd",
    fontSize: 13 + "px"
  },
  odd: {
    backgroundColor: "#fafafa",
    fontWeight: 400
  },
  even: {
    backgroundColor: "#eeeeee",
    fontWeight: 400
  },
  oddCmd: {
    backgroundColor: "#e8eaf6",
    fontWeight: 500
  },
  evenCmd: {
    backgroundColor: "#e8eaf6",
    fontWeight: 500
  }
});

class TerminalHistory extends React.Component {
  _isMounted = false;
  _scrollToBottom = null;
  _scrollListenerInstantiated = false;

  constructor(props) {
    super(props);

    this.state = {
      history: this._buildHistoryView()
    };

    this._cE_cmdHistoryUpdate = this._cE_cmdHistoryUpdate.bind(this);
    this._scrolled = this._scrolled.bind(this);
    this._buildHistoryView = this._buildHistoryView.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._scrollToBottom = true;
    cmdLineStore.on("cmdHistoryUpdate", this._cE_cmdHistoryUpdate);
  }

  componentWillUnmount() {
    this._isMounted = false;
    cmdLineStore.removeListener("cmdHistoryUpdate", this._cE_cmdHistoryUpdate);
    if (this._scrollListenerInstantiated) {
      const historyArea = document.getElementById("terminal-scroll-box");
      historyArea.addEventListener("scroll", this._scrolled);
      this._scrollListenerInstantiated = false;
    }
  }

  _cE_cmdHistoryUpdate() {
    if (this._isMounted) {
      this.setState({
        history: this._buildHistoryView()
      });
      // scroll cmdhistory down so that new command is shown
      const historyArea = document.getElementById("terminal-scroll-box");

      if (!this._scrollListenerInstantiated) {
        historyArea.addEventListener("scroll", this._scrolled);
        this._scrollListenerInstantiated = true;
      }

      if (this._scrollToBottom)
        historyArea.scrollTop = historyArea.scrollHeight;
    }
  }

  /**
   * [_scrolled: This function is called whenever a scroll event happened in the history area]
   * @return -
   */
  _scrolled() {
    const historyArea = document.getElementById("terminal-scroll-box");
    if (this._isMounted) {
      if (
        historyArea.scrollTop >=
        historyArea.scrollHeight - historyArea.offsetHeight
      ) {
        this._scrollToBottom = true;
      } else {
        this._scrollToBottom = false;
      }
    }
  }

  _buildHistoryView() {
    const { classes } = this.props;

    const currentHistory = cmdLineStore.getHistory();
    let historyView = [];

    for (const number in currentHistory) {
      const idNumber = currentHistory[number][2];
      let bgStyle = null;
      if (idNumber % 2 === 0) {
        if (currentHistory[number][1] === "CMD") bgStyle = classes.evenCmd;
        else bgStyle = classes.even;
      } else {
        if (currentHistory[number][1] === "CMD") bgStyle = classes.oddCmd;
        else bgStyle = classes.odd;
      }
      //if (currentHistory[number][1] === "CMD") console.log("cmd");

      const entry = (
        <div
          key={"frame_" + String(idNumber)}
          className={[classes.cmdRow, bgStyle].join(" ")}
        >
          <div
            key={"num_" + String(idNumber)}
            style={{ width: 50 + "px", textAlign: "center" }}
          >
            {currentHistory[number][2]}
          </div>

          <div
            key={"type_" + String(idNumber)}
            style={{ width: 100 + "px", textAlign: "center" }}
          >
            {currentHistory[number][1]}
          </div>

          <div key={"msg_" + String(idNumber)} style={{ width: 100 + "%" }}>
            {currentHistory[number][0]}
          </div>
        </div>
      );
      historyView.push(entry);

      // restrict cmd line history to 200 lines
      if (number > 200) historyView.shift();
    }
    return historyView;
  }

  render() {
    const { classes } = this.props;

    const history = (
      <div
        id="cmdhistory"
        className={classes.cmdContainer}
        onScroll={key => this._scrolled(key)}
      >
        {this.state.history}
      </div>
    );

    return history;
  }
}

export default withStyles(styles)(TerminalHistory);
