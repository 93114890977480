import React from "react";
import { Redirect } from "react-router-dom";
import sessionManagementStore from "./../stores/SessionManagementStore";

class Authenticate extends React.Component {
  _isMounted = false;

  constructor() {
    super();

    this.state = {
      isSessionAuthenticated: sessionManagementStore.isSessionAuthenticated()
    };

    this._cE_SessionClosed = this._cE_SessionClosed.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    sessionManagementStore.on(
      "ChangeEvent_SessionClosed",
      this._cE_SessionClosed
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    sessionManagementStore.removeListener(
      "ChangeEvent_SessionClosed",
      this._cE_SessionClosed
    );
  }

  _cE_SessionClosed() {
    if (this._isMounted) {
      this.setState({
        isSessionAuthenticated: sessionManagementStore.isSessionAuthenticated()
      });
    }
  }

  render() {
    let page = <div />;
    if (!this.state.isSessionAuthenticated) {
      //page = <Redirect to="/login" />;
      page = <Redirect to="/" />;
    }
    return page;
  }
}
export default Authenticate;
