import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import activeServiceDataStore from "./../../stores/ActiveServiceDataStore";
//import uSS from "./../../stores/UserServicesStore";
//import fS from "./../../stores/FacilitiesStore";
// -------------------------------------------------------------
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// -------------------------------------------------------------

const styles = theme => ({
  soFormControl: {
    margin: theme.spacing.unit,
    minWidth: 450
  }
});

class DialogDeleteSo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSo: "",
      history: true
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this._handleChangeSo = this._handleChangeSo.bind(this);
  }

  handleClose() {
    this.setState({
      selectedSo: "",
      history: true
    });
    this.props.closeHandle();
  }

  handleDelete() {
    let msg = "";
    if (this.state.selectedSo !== "") {
      msg = "delete " + this.state.selectedSo;
      websocketCom.sendServiceMessage(msg);
      this.handleClose();
    }
  }

  _buildSoList() {
    const soTemplates = activeServiceDataStore.getSoList();

    let MenuItemContainer = [];
    let menuItem = "";
    soTemplates.forEach(function(item, index) {
      menuItem = (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      );
      MenuItemContainer.push(menuItem);
    });
    return MenuItemContainer;
  }

  _handleChangeSo(event) {
    const soName = event.target.value;
    this.setState({ selectedSo: soName });
  }

  _handleHistoryChange(event) {}

  render() {
    const { classes } = this.props;

    const dialog = (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Delete SmartObject</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This dialog allows to delete the chosen SmartObject. Please be
              certain. there is no possiblity to revert your deletion.
            </DialogContentText>

            <div style={{ height: 15 + "px" }} />

            <FormControl className={classes.soFormControl} fullWidth>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <InputLabel htmlFor="serviceTemplate-simple">
                    SmartObject to be deleted
                  </InputLabel>
                  <Select
                    value={this.state.selectedSo}
                    onChange={this._handleChangeSo}
                    inputProps={{
                      name: "serviceTemplate",
                      id: "serviceTemplate-simple"
                    }}
                    style={{ minWidth: 360, marginRight: 50 }}
                  >
                    {this._buildSoList()}
                  </Select>
                </div>{" "}
                <div style={{ marginTop: 10 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        checked={this.state.history}
                        onChange={this._handleHistoryChange}
                        value="history"
                        color="primary"
                      />
                    }
                    label="Delete History"
                  />
                </div>
              </div>
            </FormControl>

            <div style={{ minHeight: 20 + "px" }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );

    return dialog;
  }
}

export default withStyles(styles)(DialogDeleteSo);
