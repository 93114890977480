
import React from "react";
import aSDS from "./../../stores/ActiveServiceDataStore";
import gLS from "./../../stores/GraphLayoutStore";
import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";
import pM from "./PopupManager";
import PortEntry from "./PortEntry";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import BlockIcon from '@material-ui/icons/Block';

class ConfPorts extends React.Component {

  constructor(props) {
    super(props);
    // set size for this configuration panel
    this.width = 280; this.height = 305;
    this.portNameError = false;
    this.createButtonIsEnabled = false;

    this.state = {portName: "", portHelpText: ""}

    this._cE_GraphLayoutUpdate = this._cE_GraphLayoutUpdate.bind(this);
    this._createNewPortClick = this._createNewPortClick.bind(this);
    this._handlePortNameChange = this._handlePortNameChange.bind(this);
  }

componentDidMount() {
    window.addEventListener("mousemove", this._handleMouseMove);
    gLS.on(
      "ChangeEvent_GraphLayoutUpdate",
      this._cE_GraphLayoutUpdate
    );
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this._handleMouseMove);
    gLS.removeListener(
      "ChangeEvent_GraphLayoutUpdate",
      this._cE_GraphLayoutUpdate
    );
  }

  _cE_GraphLayoutUpdate() {
    if (aSDS.checkSoExist(this.props.soName)) { this.forceUpdate(); }
    else {pM.deletePopup(this.props.id)}
  };

  _createNewPortClick() {
    const msg = this.props.soName+" addport "+this.state.portName;
    //console.log("Create Port: ", msg)
    websocketCom.sendServiceMessage(msg);
    this.setState({portName: "" });
  }


  _handlePortNameChange(event) {
    this.setState({ portName: event.target.value });

    // eslint-disable-next-line no-useless-escape
    const letterNumber = /^[_\-0-9a-zA-Z]+$/;
    if ( !(event.target.value.match(letterNumber)) && event.target.value !== "") {
      this.portNameError = true;
    this.createButtonIsEnabled = false;
      this.setState({portHelpText: "Unvalid character"})
      return
    }
    if (event.target.value === "") {
        this.portNameError = true;
        this.createButtonIsEnabled = false;
        this.setState({portHelpText: "Empty name"});
        return
    }
      if (event.target.value.length < 3) {
        this.portNameError = true;
        this.createButtonIsEnabled = false;
        this.setState({portHelpText: "Minimum length 3 characters"});
        return
      }
      this.portNameError = false;
      this.createButtonIsEnabled = true;
      this.setState({portHelpText: ""});
  }


  _buildConfPortsMenu() {
    // get ports for so from database
    const portData = aSDS.getSmartObjectData(this.props.soName).get("ports");

    let portContainer = [];

    if (portData.size === 0) {
      console.log("This SO has no ports")
      const noPortsDefined = (
        <div key="no-port-defined" style={{display: "flex", flexDirection: "column", alignItems: "center",
          paddingTop: 50, color: "LightGray" }}>
          <BlockIcon style={{fontSize: 80 }} />
          <div>No Ports defined.</div>
        </div> );

        portContainer.push(noPortsDefined)
    } else {

      for (let [key, value] of portData ) {
        const portData = {"soName": this.props.soName, "portName": key, "status": value.get("connectstatus")}
        const portEntry = (<PortEntry key={this.props.soName+"-"+key} portData={portData} callback={this.selectView} />)
        portContainer.push(portEntry);
      }

    }

    const panel = (
      <div style={{cursor: "default"}} >
        <div style={{padding: 7, fontFamily: "Roboto", fontWeight: "bold", fontSize: 14}} >
          {this.props.soName} - Ports Management
        </div>
        <div style={{height: 190, overflowY: "scroll", marginRight: 10 }} >
            {portContainer}
        </div>


       <div style={{display: "flex", flexDirection: "row", alignItems: "center"}} >
         <TextField
          value = {this.state.portName}
          onChange = {this._handlePortNameChange}
          error = {this.portNameError}
          id="new-port-name"
          style={{ margin: 10}}
          label = {"Create new port on "+this.props.soName}
          placeholder="Port Name"
          margin="dense"
          helperText = {this.state.portHelpText}

          InputLabelProps={{
            shrink: true,
          }}
        />

         <Button onClick={ () => this._createNewPortClick() }
                 size="small" disabled={! this.createButtonIsEnabled} >
          Create
        </Button>
      </div>
      </div>

    )
    return panel
  }


  render() {
    // Content of panel is defined here
    const view = (<div key={"content-ports-conf-"+this.props.soName}> {this._buildConfPortsMenu()} </div>)

    let  geometry = this.props.location;
    //const scaleFactor = this.props.location.scaleFactor;
    geometry["width"] = this.width; geometry["height"] = this.height;

    const panel = ( <Popup geometry={geometry} content={view}
                           id={this.props.id} elementClass={this.props.elementClass}
                           delCallback={this.props.delCallback} /> )
    return panel
  }

}

export default ConfPorts;

