import { EventEmitter } from "events";

class CodeDevStore extends EventEmitter {
  constructor() {
    super();

    this.selectorStatus = { editor: false}
  }

  reset() {
    this.selectorStatus = { editor: false}
  }

  getViewSelectStatus() {
    return this.selectorStatus;
  }

  setViewSelectStatus(selectorStatus) {
    this.selectorStatus = selectorStatus;
    this.emit("CodeDevViewUpdate");
  }

}

const codeDevStore = new CodeDevStore();
export default codeDevStore;
