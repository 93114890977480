import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import aSDS from "./../../stores/ActiveServiceDataStore";
import gLS from "../../stores/GraphLayoutStore";
import Tooltip from '@material-ui/core/Tooltip';
import SmartObjectIcon from '@material-ui/icons/CropDin';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ResetSoIcon from '@material-ui/icons/SettingsBackupRestore';
import TimerOffIcon from '@material-ui/icons/TimerOff';


class State extends React.Component {
  constructor(props) {
    super(props);

    this.state = ({
      soName: "",
      soList: [],
      icon_reset_col: "LightGray",
      icon_timer_col: "LightGray"
    })

    this.timer = null;

    this._updateSoList = this._updateSoList.bind(this);
    this._fetchGlobal = this._fetchGlobal.bind(this);
    this._soSelectClick = this._soSelectClick.bind(this);
    this._buildGlobalStateView = this._buildGlobalStateView.bind(this);
    this._buildSoSelectContainer = this._buildSoSelectContainer.bind(this);
    this._mouseOver = this._mouseOver.bind(this);
    this._mouseLeave = this._mouseLeave.bind(this);
  }

  componentDidMount() {
    this.timer = setInterval(this._fetchGlobal, 2000)
    gLS.on("ChangeEvent_GraphLayoutUpdate", this._updateSoList);
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    gLS.removeListener("ChangeEvent_GraphLayoutUpdate", this._updateSoList);
  }

  _updateSoList() {
    const soList = gLS.getDebugView();
    if (! soList.includes(this.state.soName)) {
      this.setState({soName: ""});
    }
    if ( (this.state.soName) === "" && (soList.length !== 0) ) {
      this.setState({soName: soList[0]})
    }
    this.setState({soList: soList})
  }


  _resetSo() {
    const msg = this.state.soName + " reset";
    websocketCom.sendServiceMessage(msg);
  }

  _timerOffSo() {
    const msg = this.state.soName + " stopalltimer";
    websocketCom.sendServiceMessage(msg);
  }

  _mouseOver(event, element) {
    switch (element) {
      case "reset": this.setState({icon_reset_col: "Black"})
        break;
      case "timer": this.setState({icon_timer_col: "Black"})
        break;
      default:
        break;
    }
  }

  _soSelectClick(event) {
    this.setState({soName: event.target.value})
  }


  _mouseLeave() {
    this.setState({ icon_reset_col: "LightGray",
                    icon_timer_col: "LightGray" })
  }


  _fetchGlobal() {
    if (this.state.soName !== "") {
      if (aSDS.checkSoExist(this.state.soName)) {
        const msg = this.state.soName + " getglobal";
        websocketCom.sendServiceMessage(msg);
        this.forceUpdate();
      }
    }
  }


  _buildGlobalStateView() {

    if (this.state.soName.length === 0) { return <div /> }

    const soData = aSDS.getSmartObjectData(this.state.soName);
    if (soData === null) { return <div /> }

    const soGlobalData = soData.get("global")
    let globalContainer = []
    for (let global of soGlobalData) {
      const line = <div key={global[0]+"-global"}
                        style={{ display: "flex", paddingBottom: 7, paddingLeft: 5, textIndent: 0, lineHeight: 1.05,
                                 fontFamily: "Roboto", fontSize: 12, fontWeight: "normal" }} >
                      {global[0]} = {global[1]}
                   </div>
        globalContainer.push(line);
    }
    return globalContainer
  }

  _buildSoSelectContainer() {
    const soContainer = [];

    for (const key in this.state.soList) {
      const soName = this.state.soList[key]
      const entry = <option key={soName+"_sel_entry"} value={soName} > {soName} </option>
      soContainer.push(entry)
    }
    return soContainer;
  }

  render() {

    let content = <div />

    if (this.state.soName === "") {
      if (this.props.height > 150) {
        const marginTop = (this.props.height - 8 - 120 - (this.props.height * 0.12)) / 2;
        content = <div style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "White",
          height: 100 + "%",
          width: 100 + "%"
        }}>
          <NotInterestedIcon style={{fontSize: 120, color: "WhiteSmoke", marginTop: marginTop}}/>
        </div>
      }
    } else {
      content =
        <div >
          <div key={this.state.soName+"-global-state"}
               style={{paddingRight: 0, fontFamily: "Roboto", fontSize: 14, fontWeight: "bold", width: this.props.width }} >

            <div style={{display: "flex", backgroundColor: "WhiteSmoke", paddingTop: 5, paddingBottom: 5,
                         marginBottom: 5, width: 100+"%"}} >
              <div style={{width: 3}} />
              <SmartObjectIcon style={{height: 18, paddingTop: 2}} />

              <div>
                <select name="debugso" id="debugso" value={this.state.soName} style={{width: 100+"%" }}
                        onChange={(event) => this._soSelectClick(event)}>
                  {this._buildSoSelectContainer()}
                </select>
              </div>

              <div style={{flexGrow: 1}} />
              <Tooltip title={<span style={{fontSize:11}}>Delete globals</span>} enterDelay={500} >
              <ResetSoIcon style={{height: 17, marginLeft: 5, marginRight: 2, paddingTop: 2, color: this.state.icon_reset_col}}
                           onClick={()=>this._resetSo()}
                           onMouseOver={(event) => this._mouseOver(event, "reset")}
                           onMouseLeave={() => this._mouseLeave()}

              />
              </Tooltip>
              <Tooltip title={<span style={{fontSize:11}} >Cancel timers</span>} enterDelay={500} >
              <TimerOffIcon style={{height: 17, paddingRight: 4, paddingTop: 2, color: this.state.icon_timer_col}}
                            onClick={()=>this._timerOffSo()}
                            onMouseOver={(event) => this._mouseOver(event, "timer")}
                            onMouseLeave={() => this._mouseLeave()}
              />
              </Tooltip>

          </div>
            <div style={{height: this.props.height-45, overflowY: "auto"}} >
          {this._buildGlobalStateView()}
            </div>
        </div>
        </div>
    }

    return (
      <div style={{width: this.props.width, backgroundColor: "white", padding: 0, height: this.props.height - 8 }} >
        <div style={{color: "Black", height: 100+"%",
                     border: "1px solid LightGray"}} >
        {content}
        </div>
      </div>
    )
  }

}

export default State;
