import React from "react";

import websocketCom from "./../../../../webcom/WebsocketCom";
// -------------------------------------------------------------
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import FacIcon from "@material-ui/icons/FilterNone";

// -------------------------------------------------------------

const styles = theme => ({
  serviceInputFormControl: {
    margin: theme.spacing.unit,
    minWidth: 220
  }
});

class CreateFacility extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedFacilityName: "" };

    this.handleClose = this.handleClose.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this._handleChangeFacilityName = this._handleChangeFacilityName.bind(this);
  }

  handleClose() {
    this.setState({
      selectedFacilityName: ""
    });

    this.props.handle_func_closeDialog();
  }

  handleCreate() {
    if (this.state.selectedFacilityName !== "") {
      const msg = "newfacility " + this.state.selectedFacilityName;
      websocketCom.sendUserMessage(msg);
      this.handleClose();
    }
  }

  handleAdd() {
    if (this.state.selectedFacilityName !== "") {
      const msg = "addfacility " + this.state.selectedFacilityName;
      websocketCom.sendUserMessage(msg);
      this.handleClose();
    }
  }

  _handleChangeFacilityName(event) {
    const facName = event.target.value;
    this.setState({ selectedFacilityName: facName });
  }

  render() {
    const dialog = (
      <div>
        <Dialog
          open={this.props.isActive}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle> {this._renderDialogHeadline()} </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can either create a new facility or add an existing facility.{" "}
              <br />
              To add an already existing facility to your set of facilities,
              enter the name of this facility and press the add button.
            </DialogContentText>
            <div style={{ minHeight: 20 + "px" }} />
            <TextField
              autoFocus
              margin="dense"
              id="facility-name"
              label="Facility Name"
              value={this.state.selectedFacilityName}
              onChange={this._handleChangeFacilityName}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>

            <Button onClick={this.handleCreate} color="primary">
              Create
            </Button>
            <Button onClick={this.handleAdd} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
    return dialog;
  }

  // -----------------------------------------------------------------------------
  // -----------------------------------------------------------------------------
  // -----------------------------------------------------------------------------
  _renderDialogHeadline() {
    return (
      <div id="header" style={{ display: "flex", flexDirection: "row" }}>
        <div
          id="colorblotch"
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#c62828",
            boxShadow: "1px 4px 7px 2px #bdbdbd",
            borderRadius: 4
          }}
        >
          <FacIcon
            style={{
              width: 26,
              height: 26,
              color: "white",
              position: "relative",
              top: 12,
              left: 12
            }}
          />
        </div>
        <div
          id="headline"
          style={{
            flexGrow: 1,
            textAlign: "left",
            paddingTop: 7,
            paddingLeft: 15
          }}
        >
          <div id="type">
            <Typography
              variant="overline"
              style={{ fontSize: 9, color: "#757575" }}
            >
              Facility
            </Typography>
          </div>
          <div id="text">
            <Typography
              variant="body1"
              fontWeight="bold"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                position: "relative",
                top: -9
              }}
            >
              Create New or Add Existing Facility
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CreateFacility);
