import { useD3 } from "./../../../../../hooks/useD3";
import React from "react";
import * as d3 from "d3";

function LineChart(props) {
  let data = props.data;
  let metrikName = "undefined";
  if (props.metrikName) metrikName = props.metrikName;

  let minDate = d3.min(props.data, (d) => new Date(d[0] * 1000));
  let maxDate = d3.max(props.data, (d) => new Date(d[0] * 1000));
  let maxValue = d3.max(props.data, (d) => d[1]);
  const numEntries = Object.keys(props.data).length;
  let smoothAlgo = null;
    let filltype = "empty";
    
  switch(props.smoothAlgo) {

    case "linear":
      smoothAlgo = d3.curveLinear;
      filltype = "fill";
      break;
    case "step-after":
      smoothAlgo = d3.curveStepAfter;
      filltype = "empty";
      break;
    case "cardinal":
      smoothAlgo = d3.curveCardinal;
      filltype = "empty";
      break;
    default:
      break;
  }

  const ref = useD3(
    (svg) => {
      const height = 120;
      const width = 295;
      const margin = { top: 5, right: 5, bottom: 35, left: 55 };

      const x = d3
        .scaleTime()
        .domain([minDate, maxDate])
        .range([margin.left, margin.left + 5 * numEntries]);

      const y = d3
        .scaleLinear()
        .domain([0, maxValue * 1.1])
        .range([height+10, 10]);

      const xAxis = (g) =>
        g
          .style("font-size", "14px")
          .style("font-weight", "bold")
          .attr("transform", `translate(0,${height+10})`)
          .call(d3.axisBottom(x).ticks(4));

      const yAxis = (g) =>
        g
          .attr("transform", `translate(${margin.left},0)`)
          .style("font-size", "14px")
          .style("font-weight", "bold")
          .call(d3.axisLeft(y).ticks(3))

      svg.select(".x-axis").call(xAxis);
      svg.select(".y-axis").call(yAxis);

      const myline = d3
        .line()
        .x((value) => x(new Date(value[0] * 1000)))
        .y((value) => y(value[1]))
        .curve(smoothAlgo);  // curveStepAfter curveCardinal


      if (filltype === "empty") {
	  svg
	    .select(".plot-area")
            .selectAll("path")
            .data([props.data])
            .join("path")
            .attr("d", (value) => myline(value))
            .attr("fill", "none")
            .attr("stroke", "GhostWhite")
            .attr("stroke-width", 2.0);
      } else {
	  svg
            .select(".plot-area")
            .selectAll("path")
            .data([props.data])
            .join("path")
            .attr("fill", "none")
            .attr("stroke", "GhostWhite")
            .attr("stroke-width", 1.0)
	    .attr("fill", "GhostWhite")
	    .attr("fill-opacity", ".2")
	    .attr("d", d3.area()
	    .x( (value) => x(new Date(value[0] * 1000)))
	    .y0(height+10)
	    .y1( (value) => y(value[1])));
      }
	    
	

      svg
        .select(".plot-area")
        .selectAll("circle")
        .data(props.data)
        .join("circle")
        .attr("cx", (value) => x(new Date(value[0] *1000)) )
        .attr("cy", (value) => y(value[1]) )
        .attr("r", 2.0)
        .attr("fill", "DarkGray")
        .attr("stroke", "White")
        .attr("stroke-width", 1.0);



	

      //      svg
      //  .append("text")
      //  .attr("x", margin.left)
      //  .attr("y", 174)
      //  .attr("fill", "white")
      //  .attr("font-size", "12px" )
      //  .text( "Metrik: "+metrikName )
    },
    [props.stateChange]
  );

  return (
    <svg
      ref={ref}
      style={{
        height: 150,
        width: 330,
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );
}

export default LineChart;


