import dispatcher from "../dispatcher/dispatcher";

export function windowResize(id) {
  dispatcher.dispatch({
    type: "WINDOW_RESIZE",
    id
  });
}

export function loginRequest(id) {
  dispatcher.dispatch({
    type: "LOGIN_REQUEST",
    id
  });
}

export function sessionStart(id) {
  dispatcher.dispatch({
    type: "SESSION_START",
    id
  });
}

export function logoutRequest(id) {
  dispatcher.dispatch({
    type: "LOGOUT_REQUEST",
    id
  });
}

export function logoutDone(id) {
  dispatcher.dispatch({
    type: "LOGOUT_DONE",
    id
  });
}
