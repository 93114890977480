import React from "react";
import Output from "./Debug/DebugOutput";

class Debug extends React.Component {

  //constructor() {
  //  super();
  //}

  render() {
    const content = ( <div> <Output height={this.props.height-8} />  </div> )
    const view = (
      <div style={{flexGrow: 3, backgroundColor: "SnowWhite", padding: 0}} >
        <div style={{height: this.props.height-8, border: "1px solid LightGray" }} >
        {content}
        </div>
      </div>
    )
    return view;
  }

}

export default Debug;
