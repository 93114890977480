
class DragDropStore {

  constructor() {
    this.connectionDrag = {"isActive": false }
  }

  // -------------------------------------------------------------------------------
  // connection drag and drop handling in combination with the involved Port objects.
  //
  startConnectionDrag(dragData) {
    this.connectionDrag = dragData;
    this.connectionDrag["isActive"] = true;
  }
  cancelConnectionDrag() {
    if (this.connectionDrag["isActive"] ) {
      this.connectionDrag["isActive"] = false;
      const callback = this.connectionDrag.callback;
      callback({"status": "cancel"})
    }
  }
  connectionDrop(soName, portName) {
      if (this.connectionDrag["isActive"] ) {
      const callback = this.connectionDrag.callback;
      callback({"status": "success",
                "soName": soName,
                "portName": portName })
      this.connectionDrag = {"isActive": false }
    }
  }
  // -------------------------------------------------------------------------------





  // collect Mouse up events from GUI
  mouseUpEventReceived(guiElement) {
    if (guiElement === "canvas") {
      if (this.connectionDrag) this.cancelConnectionDrag();
    }

  }

}

const dragDropStore = new DragDropStore();
export default dragDropStore;
