import { EventEmitter } from "events";
import aSDS from "./../../stores/ActiveServiceDataStore";
import gLS from "./../../stores/GraphLayoutStore";
import pM from "./../PopUp/PopupManager";
import selectionManager from "./../SelectionManager/SelectionManager";
import websocketCom from "./../../webcom/WebsocketCom.js";

class KeyEventManager extends EventEmitter {
  //constructor() {
  //  super();
  //}


  keyPressedEvent(keyName) {
    console.log("keyName =",keyName);
      switch (keyName) {

        case "Delete": this._handleDelete(); break;
        case "ctrl+x": this._handleDelete(); break;
        case "Escape": selectionManager.clearSelect(); break;
        case "ctrl+p": this._addPort(); break;
        case "ctrl+m": this._addMethod(); break;
        case "ctrl+i": this._addInlet(); break;
        case "ctrl+o": this._addOutlet(); break;
        default: break;
      }


    return
  }


  // ----------------------------------------------------
  _handleDelete() {

    const deleteOrder = ["inlet", "outlet", "method", "connection", "port", "so"]

    for (const type in deleteOrder) {
      const selection = selectionManager.getSelectFromType(deleteOrder[type]);
      for (const item in selection) {
        const data = selection[item].split(" ");
        selectionManager.delete_Id(selection[item]);
        this._delete(data);
      }
    }
    return
  }


  _delete(data) {
    const type = data[0]; let msg = [];
    let soName=null; let portName=null; let so1=null; let so2=null; let port1=null; let port2=null;
    let method=null; let inlet=null; let outlet=null;

    switch(type) {
      case "so":  // delete SO
        soName=data[1];
        msg.push("delete "+soName);
        break;

      case "port":  // delete port
        soName=data[1]; portName=data[2];
        msg.push(soName+" delport "+portName);
        break;

      case "inlet":  // delete port
        soName=data[1]; portName=data[2]; inlet=data[3];
        msg.push(soName+" delinlet "+portName+" "+inlet);
        break;

      case "outlet":  // delete port
        soName=data[1]; portName=data[2]; outlet=data[3];
        msg.push(soName+" deloutlet "+portName+" "+outlet);
        break;

      case "method":  // delete port
        soName=data[1]; method=data[2];
        msg.push(soName+" delmethod "+method);
        break;

      case "connection":  // delete connection entries that make this connection
        so1=data[1]; port1=data[2]; so2=data[3]; port2=data[4];
        // left side: check for server port
        const soData1 = aSDS.getSmartObjectData(so1);
        const status1 = soData1.get("ports").get(port1).get("connectstatus");
        if (status1 !== "nil") { msg.push(so1+" delcon "+port1+" "+so2+"::"+port2); }

        // right side: check for server port
        const soData2 = aSDS.getSmartObjectData(so2);
        const status2 = soData2.get("ports").get(port2).get("connectstatus");
        if (status2 !== "nil") { msg.push(so2+" delcon "+port2+" "+so1+"::"+port1); }
        break;

      default:
        break;
    }
    if (msg.length>0) {
      for (const nextMsg in msg) {
        websocketCom.sendServiceMessage(msg[nextMsg]);
      }
    }
    return
  }

  // ----------------------------------------------------

  _addPort() {
    const select = selectionManager.getSelectFromType("so");
    if ( select.length === 0 || select.length > 1 ) {return}
    const soName = select[0].split(" ")[1];
    const graphLayout = gLS.getGraphLayout();
    const soLayout = graphLayout[soName];
    const scaleFactor = gLS.getScaleFactor();
    console.log(graphLayout[soName]);

    const location = { "x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                       "y": (soLayout.graph_data.y),
                       "scaleFactor": scaleFactor }
    pM.addPopup(soName, "",  location, "addport", "MediumSeaGreen")

  }

  _addMethod() {
    console.log("_addMethod");
    const select = selectionManager.getSelectFromType("so");
    if ( select.length === 0 || select.length > 1 ) {return}
    const soName = select[0].split(" ")[1];
    const graphLayout = gLS.getGraphLayout();
    const soLayout = graphLayout[soName];
    const scaleFactor = gLS.getScaleFactor();
    console.log(graphLayout[soName]);

    const location = { "x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                       "y": (soLayout.graph_data.y),
                       "scaleFactor": scaleFactor }
    pM.addPopup(soName, "", location, "addmethod", "MediumSeaGreen")

  }

  _addInlet() {
    const select = selectionManager.getSelectFromType("port");
    if ( select.length === 0 || select.length > 1 ) {return}
    const soName = select[0].split(" ")[1];
    const portName = select[0].split(" ")[2];

    console.log("soName, portName", soName, portName);

    const graphLayout = gLS.getGraphLayout();
    const soLayout = graphLayout[soName];
    const scaleFactor = gLS.getScaleFactor();
    console.log(graphLayout[soName]);

    const location = { "x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                       "y": (soLayout.graph_data.y),
                       "scaleFactor": scaleFactor }
    pM.addPopup(soName, portName, location, "addinlet", "MediumSeaGreen")

  }

  _addOutlet() {
    const select = selectionManager.getSelectFromType("port");
    if ( select.length === 0 || select.length > 1 ) {return}
    const soName = select[0].split(" ")[1];
    const portName = select[0].split(" ")[2];

    const graphLayout = gLS.getGraphLayout();
    const soLayout = graphLayout[soName];
    const scaleFactor = gLS.getScaleFactor();
    console.log(graphLayout[soName]);

    const location = { "x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                       "y": (soLayout.graph_data.y),
                       "scaleFactor": scaleFactor }
    pM.addPopup(soName, portName, location, "addoutlet", "MediumSeaGreen")

  }

}

const keyEventManager = new KeyEventManager();
export default keyEventManager;
