import React from "react";
import MapIcon from '@material-ui/icons/Map';

class PressNavButton extends React.Component {
  constructor() {
    super();

    this.colOnHover_bg="white"
    this.colSelected_bg="white"
    this.col_bg="transparent"
    this.colOnHover = "orange"
    this.colSel = "#000"
    this.colNotSel = "#aaa"

    this.state = {  isPressed: false,
      isHover: false
    }

    this.background = this.col_bg;
    this.foreground = this.colNotSel;

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);
  }


  onMouseEnter() { this.setState({isHover: true}); }
  onMouseLeave() { this.setState({isHover: false}); }

  onClick() {
    this.props.callback(this.props.name, !this.state.isPressed)
    this.setState({isPressed: !this.state.isPressed})
  }


  render() {
if (this.state.isPressed) {this.foreground = this.colSel; this.background = this.colSelected_bg
    } else if (this.state.isHover) {this.foreground = this.colOnHover; this.background = this.colOnHover_bg;
    } else {
      this.foreground=this.colNotSel; this.background=this.col_bg
    }

    const icon = <MapIcon x={7+160} y={0} width={26} style={{color: this.foreground}}/>;

    const panel =
      (<g key={this.props.text}
        onMouseEnter={() => this.onMouseEnter() }
        onMouseLeave={() => this.onMouseLeave() }
        onClick={() => this.onClick()} >
          <rect x={this.props.x-20+160} y={this.props.y-38} width="38" height="36" stroke={this.background} fill={this.background} />
          {icon}
      </g>)

   return panel
  }
}
export default PressNavButton;

