import { EventEmitter } from "events";
import aSDS from "./../../../stores/ActiveServiceDataStore";
import gLS from "./../../../stores/GraphLayoutStore";


class ProblemsStore extends EventEmitter {

  _isMounted = false;

  constructor() {
    super();

    this.status = {};

    this._addIssue = this._addIssue.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.getNumberIssues = this.getNumberIssues.bind(this);
    this.collectStatus = this.collectStatus.bind(this);
  }



  _addIssue(soName, issue) {
    // check if so already in list
    if (!(soName in this.status)) {this.status[soName]=[]}
    // push issue into list
    this.status[soName].push(issue);
    return;
  }

  getStatus() { return this.status; }

  getNumberIssues() { return Object.keys(this.status).length }

  collectStatus(name, status) {
    this.status = {};

    // ----------------------------------------------
    // collect connection related issues...
    const gL= gLS.getGraphLayout();

    for (const soName in gL) {
      const ports = gL[soName]["ports"];

      for (const port in ports) {
        const issues = ports[port].issue

        for (const issue of issues) {
          const item = { "level": "warning", "type": "connection", "so": soName, "port": ports[port].name, "issue": issue }
          this._addIssue(soName, item)
        }
      }
    }
    // ----------------------------------------------
    const soList = aSDS.getSoList();

    for (const soName of soList) {

      // ----------
      // collect xlet related issues
      const soPortData = aSDS.getSmartObjectData(soName).get("ports");
      // iterate over all ports on SO
      for (const [portName, portData] of soPortData.entries()) {
        // iterate over all inlets
        const inlets = portData.get("inlets");
        for (const [inletName, inletData] of inlets.entries()) {
          const method = inletData.get("method");
          if (method === "null") {
            const issue = "No method for incoming message handling defined on inlet "+inletName;
            const item = { "level": "error", "type": "inlet", "so": soName, "port": portName, "issue": issue }
            this._addIssue(soName, item)
          }
          if (!aSDS.checkSmartObjectMethodExist(soName, method) && method!=="null") {
            const issue = "Unknown method defined on inlet "+inletName;
            const item = { "level": "error", "type": "inlet", "so": soName, "port": portName, "issue": issue }
            this._addIssue(soName, item)
          }
        }

        // iterate over all outlets
        const outlets = portData.get("outlets");
        for (const [outletName, outletData] of outlets.entries()) {
          const method = outletData.get("method");
          if (method === "null") {
            const issue = "No method for outgoing message handling defined on outlet "+outletName;
            const item = { "level": "error", "type": "outlet", "so": soName, "port": portName, "issue": issue }
            this._addIssue(soName, item)
          }
          if (!aSDS.checkSmartObjectMethodExist(soName, method) && method!=="null") {
            const issue = "Unknown method defined on outlet "+outletName;
            const item = { "level": "error", "type": "outlet", "so": soName, "port": portName, "issue": issue }
            this._addIssue(soName, item)
          }
        }
      }
      // ---------
      // collect method related issues
      const soMethods = aSDS.getSmartObjectData(soName).get("methods");
      for (const [methodName, methodData] of soMethods.entries() ) {
        if (methodData.get("status") === "ERROR") {
          let issue = methodData.get("resp");
          if (issue === "") {issue = "Error" }
          const item = { "level": "error", "type": "method", "so": soName, "method": methodName, "issue": issue }
          this._addIssue(soName, item);
        }
      }
    }

    // ----------------------------------------------
    this.emit("StatusUpdate");

  }

}

const statusStore = new ProblemsStore();
export default statusStore;

