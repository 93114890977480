import React from "react";
import codeDevStore from "./CodeDevStore";
import Edit from "./Edit";
import ConfigPanel from "./ConfigPanel";
//import Selector from "./CodeDevViewSelector";
import Selector from "./EditorSelector";
import config from "./../config"

class CodeDev extends React.Component {

  constructor(props) {
    super(props);

    this.configHeight = {"Debug": 300, "Problems": 200, "Commandline": 200}

    this.state = { "editor": false,
                   "debug": false,
                   "global": false,
                   "terminal": false,

                   "soGlobalView": [],
                   "soDebugView" : [],

                   configMenuSelect: "",
                   configHeight: 30,
                   activeTracking: true,
    }

    this._updateView = this._updateView.bind(this);
    //this._updatePanelProps = this._updatePanelProps.bind(this);
    this.callbackSetConfigChangeHeight = this.callbackSetConfigChangeHeight.bind(this);
    this._configMenuCallback = this._configMenuCallback.bind(this);
  }

  componentDidMount() {
    this._updateView();
    codeDevStore.on("CodeDevViewUpdate", this._updateView);
    //gLS.on("ChangeEvent_GraphLayoutUpdate", this._updatePanelProps);
  }
  componentWillUnmount() {
    codeDevStore.removeListener("CodeDevViewUpdate", this._updateView);
    codeDevStore.reset();
    //gLS.removeListener("ChangeEvent_GraphLayoutUpdate", this._updatePanelProps);
  }

  _updateView() {
    const status = codeDevStore.getViewSelectStatus();
    this.setState({ "editor"  : status["editor"] })
  }

  //_updatePanelProps() {
  //  const global = gLS.getGlobalView();
  //  const soGlobalView = global.length > 0 ? global[0] : "";
  //  this.setState({"soGlobalView": soGlobalView})
  //}

  callbackSetConfigChangeHeight(deltaHeight) {
    let newHeight = this.state.configHeight - deltaHeight;

    const maxHeight = window.innerHeight-config.setup.layout.headerPanelHeight-config.setup.layout.statusPanelHeight - 150
    if (newHeight<56) {newHeight = 56}
    if (newHeight>maxHeight) {newHeight = maxHeight}
    this.setState({configHeight: newHeight});
    this.configHeight[this.state.configMenuSelect] = newHeight;
  }

  _configMenuCallback(selection) {
    this.setState({"configMenuSelect": selection} )
    if (selection in this.configHeight) {
      this.setState({configHeight: this.configHeight[selection]})
    } else {
      this.setState({configHeight: 30})
    }
  }


  render()
  {


    const editView = this.state.editor ? <Edit width={this.props.xOffset} offset={this.state.configHeight } /> : <div />

    //let viewBoxHeight = 300;
    //if (!this.state.editor) {viewBoxHeight = window.innerHeight-config.setup.layout.headerPanelHeight-config.setup.layout.statusPanelHeight}

    //let stateWidth = this.props.xOffset;
    //if (this.state.debug) { stateWidth = Math.round(this.props.xOffset*config.setup.layout.globalWidthPercentage); }

    //const debugView = this.state.debug ? <Debug height={viewBoxHeight} /> : null
    //const stateView = this.state.global ? <State height={viewBoxHeight} width={stateWidth} soName={this.state.soGlobalView} /> : null
    //const terminalView = this.state.terminal ? <Terminal /> : <div />


    const configMenu = <ConfigPanel callback={this._configMenuCallback}
                                    callbackDeltaHeight={this.callbackSetConfigChangeHeight}
                                    width={this.props.xOffset}
                                    height={this.state.configHeight} />


    //let viewBox = <div />
    //if (stateView || debugView) {
    //  viewBox = (
    //    <div style={{height: viewBoxHeight}} >
    //      <div id="view-box" style={{display: "flex", flexDirection: "row", alignItems: "stretch", height: 100+"%" }} >
    //          {stateView}
    //          {debugView}
    //      </div>
    //    </div>
    //  )
    //}


    return (
        <div>
          <div style={{ display: "flex",  flexDirection: "column", alignItems: "stretch",
                        height: window.innerHeight-config.setup.layout.headerPanelHeight-config.setup.layout.statusPanelHeight,
                        backgroundColor: "WhiteSmoke" }} >
          {editView}
          {configMenu}
          </div>
          <Selector xOffset={this.props.xOffset} resizeCallback={this.props.resizeCallback}/>
        </div>)
  }
}

export default CodeDev;
