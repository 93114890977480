import React from "react";
import gLS from "./../../stores/GraphLayoutStore";
import selectionManager from "./../SelectionManager/SelectionManager";

//import Tooltip from '@material-ui/core/Tooltip';


class LinkNG extends React.Component {

  constructor(props) {
    super(props);

    this.so1 = this.props.link.so1; this.port1 = this.props.link.port1;
    this.so2 = this.props.link.so2; this.port2 = this.props.link.port2;

    this.id = "connection "+this.so1+" "+this.port1+" "+this.so2+" "+this.port2 ;
    this.label = this.so1+"::"+this.port1+"  -   "+this.so2+"::"+this.port2;
    this.selectionScope = null;

    this.state = { isHover: false, isSelect: false, xpos: 0, ypos: 0 };

    this._onClick=this._onClick.bind(this);
    this._onMouseOver=this._onMouseOver.bind(this);
    this._onMouseLeave=this._onMouseLeave.bind(this);
    this._cE_SelectionUpdate = this._cE_SelectionUpdate.bind(this);
  }

  componentDidMount() {
    selectionManager.on( "ChangeEvent_Selection", this._cE_SelectionUpdate );
  }

  componentWillUnmount() {
    selectionManager.removeListener( "ChangeEvent_Selection", this._cE_SelectionUpdate );
  }

  _cE_SelectionUpdate() {
    const resp = selectionManager.checkSelect(this.id);
    const isSelected = resp[0]; const scope = resp[1];
    if (isSelected) {
      this.setState({isSelect: true });
      this.selectionScope = scope;
    } else {
      this.setState({isSelect: false });
      this.selectionScope = null;
    }
  }

  _onMouseOver(event) { this.setState({isHover: true })
    const scaleFactor = gLS.getScaleFactor();
    const element = document.getElementById("container-svg-graph")
    const offsetX = element.scrollLeft; const offsetY = element.scrollTop;
    this.setState({xpos: (event.clientX+offsetX-50)/scaleFactor, ypos: (event.clientY+offsetY-65)/scaleFactor});
  };
  _onMouseLeave() { this.setState({isHover: false }) };

  _onClick(event) {
    event.stopPropagation();
    // add/remove link to/from SelectionManager
    const scope = event.shiftKey ? "secondary" : "primary";
    selectionManager.toggle(this.id, scope, "connection" );


    //const scaleFactor = gLS.getScaleFactor();
    //const element = document.getElementById("container-svg-graph")
    //const offsetX = element.scrollLeft; const offsetY = element.scrollTop;
    //this.setState({xpos: (event.clientX+offsetX-50)/scaleFactor, ypos: (event.clientY+offsetY-65)/scaleFactor});
  }

  _onContextMenu(event) {
    event.preventDefault();
    console.log("contextMenu")
  };



  _drawLinkLabel() {
    const length = this.label.length;
    const xoffset = -((length/2)*5)-4;;
    const width = length*5 + 8
    let labelColor_bg = this.state.isSelect ? "blue" : "#424242";

    const linkLabel = (
      <g key={"linklabel-"+this.id}
         onClick={ (event) => this._onClick(event) }
      >
        <rect x={this.state.xpos+xoffset} y={this.state.ypos-10} rx="5" ry="5" width={width} height="20"
              fill={labelColor_bg} stroke={labelColor_bg} fillOpacity="0.8" strokeOpacity="0.5" />
        <text x={(this.state.xpos+xoffset+4)} y={(this.state.ypos+14-10)} textLength={width-8} fontSize="12"
              style={{fontFamily: "Roboto", fill: "white", fontWeight: "bold" }}>
          {this.label}
        </text>
      </g>
    );
    return linkLabel;
  }


  render() {

    const selectColor = this.state.isSelect ? "blue" : "transparent";
    const linkColor = this.state.isHover ? "orange" : "gray";

    const linkOpacity = this.props.link.isbicon ? 0.9 : 0.3;
    const linePath = "M "+this.props.link.x1+" "+this.props.link.y1+" C "
                         +this.props.link.bez1x+" "+this.props.link.bez1y+", "
                         +this.props.link.bez2x+" "+this.props.link.bez2y+", "
                         +this.props.link.x2+" "+this.props.link.y2

    let linkContainer = []

    const linkFigure = (
      <g key={this.props.link.name+"-"+this.props.linkCounter}
         onMouseOver={ (event) => this._onMouseOver(event) }
         onMouseLeave={ () => this._onMouseLeave() }
         onClick={ (event) => this._onClick(event) }
         onContextMenu={ (event) => this._onContextMenu(event) }
      >
        <path d={linePath} stroke={selectColor} strokeWidth="10" strokeOpacity={1} fill="transparent" />
        <path d={linePath} stroke={linkColor} strokeWidth="6" strokeOpacity={linkOpacity} fill="transparent" />
      </g>
    )

    linkContainer.push(linkFigure);

//    if (this.state.isSelect) {
    if (this.state.isHover) {
      const label = this._drawLinkLabel();
      linkContainer.push(label);
    }


    return linkContainer;

  }
}

export default LinkNG;
