import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import aSDS from "./../../stores/ActiveServiceDataStore";
import aSMS from "./../../stores/ActiveServiceMgmtStore";
//import uSS from "./../../stores/UserServicesStore";
//import fS from "./../../stores/FacilitiesStore";
// -------------------------------------------------------------
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
//import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
//import Select from "@material-ui/core/Select";
//import InputLabel from "@material-ui/core/InputLabel";
import SoIcon from '@material-ui/icons/CropDin';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
// -------------------------------------------------------------

const styles = theme => ({
	soFormControl: {
		margin: theme.spacing.unit,
		minWidth: 220
	}
});

class DialogCreateSo extends React.Component {
	constructor(props) {
		super(props);



		this.state = {
			soName: "",
			mouseOverCategory: "",
			mouseOverTemplate: "",
			category: "blank SO",
			template: "regular",
		};


		this.handleClose = this.handleClose.bind(this);
		this.handleCreate = this.handleCreate.bind(this);
		this._handleChangeSoName = this._handleChangeSoName.bind(this);
		this._onMouseOver_Category = this._onMouseOver_Category.bind(this);
		this._onMouseLeave_Category = this._onMouseLeave_Category.bind(this);
		this._onMouseClick_Category = this._onMouseClick_Category.bind(this);
		this._onMouseOver_Template = this._onMouseOver_Template.bind(this);
		this._onMouseLeave_Template = this._onMouseLeave_Template.bind(this);
		this._onMouseClick_Template = this._onMouseClick_Template.bind(this);
	}

	componentDidMount() {
		// fetch available prototypes from core
		const msg = "service getprototypes"
		//console.log("send servicemessage:", msg)
		websocketCom.sendServiceMessage(msg);
	}

	handleClose() {
		this.setState({
			soName: "",
			category: "blank SO",
			template: "regular"
		});

		this.props.closeHandle();
	}

	handleCreate() {

		let jsonData = aSMS.getPrototypes()
		if (jsonData === "") {return}
		jsonData = JSON.parse( aSMS.getPrototypes() )

		const localServiceSoTemplate = this._createThisServiceTemplate();
		jsonData.push( localServiceSoTemplate );
		let templates
		for (const category in jsonData) {
			const foundCategory = Object.keys(jsonData[category])[0]
			if (foundCategory === this.state.category) {
				templates = jsonData[category][foundCategory]
				break;
			}
		}

		//let templatesContainer = [];
		let uuid = "";
		for (const templateEntry in templates) {
			const template = Object.keys(templates[templateEntry])[0]
			if (template === this.state.template) {
				uuid = templates[templateEntry][template]["uuid"];
			}
		}

		let msg = "";
		if (this.state.soName !== "") {
			msg = "create "+this.state.soName+" "+uuid
			websocketCom.sendServiceMessage(msg);
			this.handleClose();
		}
	}


	_handleChangeSoName(event) {
		const soName = event.target.value.trim();
		this.setState({ soName: soName });
	}

	_onMouseOver_Category(category) {
		this.setState({mouseOverCategory: category})
	}
	_onMouseLeave_Category() {
		this.setState({mouseOverCategory: ""})
	}
	_onMouseClick_Category(category) {
		this.setState({category: category})
	}
	_onMouseOver_Template(template) {
		this.setState({mouseOverTemplate: template})
	}
	_onMouseLeave_Template() {
		this.setState({mouseOverTemplate: ""})
	}
	_onMouseClick_Template(template) {
		this.setState({template: template})
	}

	_createThisServiceTemplate() {
		const soList = aSDS.getSoList();
		let templateEntries = [];
		for (const so in soList) {
			const soName = soList[so];
			let entry = [];
			entry[soName] = { "uuid": soList[so],  "desc": "SmartObject template from this service" }
			templateEntries.push(entry);
		}

		const entry = { "this service": templateEntries };
		return entry;
	}

	_buildCategories() {

		//const jsonData = JSON.parse(this.jsonDataString);
		let jsonData = aSMS.getPrototypes()
		if (jsonData === "") {return}
		//console.log("jsondata = \n", aSMS.getPrototypes() )
		jsonData = JSON.parse( aSMS.getPrototypes() )

		const localServiceSoTemplate = this._createThisServiceTemplate();
		jsonData.push( localServiceSoTemplate );

		let categories = []
		for (const category in jsonData) {
			const foundCategory = Object.keys(jsonData[category])[0]
			let bg_col = "White";
			if (this.state.mouseOverCategory === foundCategory) {bg_col = "WhiteSmoke"}
			let font_weight = "normal"
			if (this.state.category === foundCategory) {font_weight="bold"; bg_col="rgba(0,0,255,0.05"}
			const entry=(
				<div key={foundCategory} style={{padding: 5, backgroundColor: bg_col, cursor: "pointer", fontWeight: font_weight}}
				     onMouseOver={() => this._onMouseOver_Category(foundCategory) }
					 onMouseLeave={() => this._onMouseLeave_Category()}
				     onClick={() => this._onMouseClick_Category(foundCategory)} >
					{foundCategory}
				</div>
			)
			categories.push(entry)
		}
		return categories
	}

	_buildTemplates() {

		//const jsonData = JSON.parse(this.jsonDataString);
		//const jsonData = JSON.parse( aSMS.getPrototypes() )
		let jsonData = aSMS.getPrototypes()
		if (jsonData === "") {return}
		//console.log("jsondata = \n", aSMS.getPrototypes() )
		jsonData = JSON.parse( aSMS.getPrototypes() )
		const localServiceSoTemplate = this._createThisServiceTemplate();
		jsonData.push( localServiceSoTemplate );


		let templates
		for (const category in jsonData) {
			const foundCategory = Object.keys(jsonData[category])[0]
			//console.log(" found cat:", foundCategory)
			if (foundCategory === this.state.category) {
				templates = jsonData[category][foundCategory]
			}
		}

		let templatesContainer = [];

		for (const templateEntry in templates) {
			const template = Object.keys(templates[templateEntry])[0]
			//const uuid = templates[templateEntry][template]["uuid"];
			const desc = templates[templateEntry][template]["desc"]

			let bg_col = "White";
			if (this.state.mouseOverTemplate === template) {bg_col = "WhiteSmoke"}
			let font_weight = "normal"
			let description = "";
			if (this.state.template === template) {
				font_weight="bold"; bg_col="rgba(0,0,255,0.05"
				if (desc) {
					description = desc;
				}
			}

			const entry=(
				<div key={template} >
					<div style={{padding: 5, backgroundColor: bg_col, cursor: "pointer", fontWeight: font_weight}}
						 onMouseOver={() => this._onMouseOver_Template(template) }
						 onMouseLeave={() => this._onMouseLeave_Template()}
						 onClick={() => this._onMouseClick_Template(template)} >
						{template}
					</div>
					<div style={{fontFamily: "Roboto", fontSize: 11, paddingLeft: 15}}>
						{description}
					</div>
				</div>
			)

			templatesContainer.push(entry)
		}

		return templatesContainer
	}


	_buildTemplateManagement() {

		const categories = this._buildCategories();
		const templates = this._buildTemplates();

		const selection = (
			<div>
			<FormControl fullWidth>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container justifycontent="center" spacing={1}>
							<Grid key={"categories"} item xs={4}>
								<div style={{border: "1px solid LightGray", height: 150}}>
									<div style={{fontFamily: "Roboto", fontSize: 14, fontWeight: "bold",
										backgroundColor: "#424242", color: "White", padding: 4}}>
										Categories
									</div>
									<div style={{fontFamily: "Roboto", fontSize: 13, paddingLeft: 0, height: 125, overflowY: "scroll"}} >
										{categories}
									</div>
								</div>
							</Grid>
							<Grid key={"templates"} item xs={8}>
								<div style={{border: "1px solid LightGray", height: 150}} >
									<div style={{fontFamily: "Roboto", fontSize: 14, fontWeight: "bold",
										backgroundColor: "#424242", color: "White", padding: 4}}>
										Templates
									</div>
									<div style={{fontFamily: "Roboto", fontSize: 13, paddingLeft: 0, height: 125, overflowY: "scroll"}} >
										{templates}
									</div>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</FormControl>
			<div style={{height: 15}} />
			<FormControl fullWidth>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<div style={{display: "flex"}}>
							<div style={{fontFamily: "Roboto", fontSize: 14, fontWeight: "regular"}}>
							SmartObject will be created from template
							</div>
							<div style={{width: 5}} />
							<div style={{fontFamily: "Roboto", fontSize: 14, fontWeight: "bold"}}>
								{this.state.template}
							</div>
						</div>
					</Grid>
				</Grid>
			</FormControl>
		</div> )

		return selection

	}

	render() {
		//const { classes } = this.props;

		const templateManagement = this._buildTemplateManagement()
		const disabled = this.state.soName === "";

		const dialog = (
			<div>
				<Dialog
					open={this.props.open}
					onClose={this.handleClose}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">{this._renderDialogHeadline()}</DialogTitle>
					<DialogContent>
						<div style={{fontFamily: "Roboto", fontSize: 14, fontWeight: "regular"}}>
						<DialogContentText>

								Give a name for the new SmartObject and select the template it
							 	should be build from. Keep the Default template to
								create an empty SmartObject.

						</DialogContentText>
						</div>
						<div style={{ height: 0 + "px" }} />

						<FormControl fullWidth>
							<TextField autoFocus fullWidth
									   margin="dense" id="so-name" label="Name" value={this.state.soName}
									   onChange={this._handleChangeSoName}
							/>
						</FormControl>

						<div style={{ height: 25 + "px" }} />

						{templateManagement}

						<div style={{ height: 0 }} />

					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary"  >
							Cancel
						</Button>
						<Button onClick={this.handleCreate} color="primary" disabled={disabled} >
							Create
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);

		return dialog;
	}


// -----------------------------------------------------------------------------
	_renderDialogHeadline() {
		return (
			<div id="header" style={{ display: "flex", flexDirection: "row" }}>
				<div
					id="colorblotch"
					style={{ width: 50, height: 50,
						backgroundColor: "CornflowerBlue", boxShadow: "1px 4px 7px 2px #bdbdbd", borderRadius: 4 }} >
					<SoIcon style={{ width: 30, height: 30, color: "white", position: "relative", top: 10, left: 10}} />
				</div>
				<div
					id="headline"
					style={{ flexGrow: 1, textAlign: "left", paddingTop: 7, paddingLeft: 15}} >
					<div id="type">
						<Typography
							variant="overline"
							style={{ fontSize: 9, color: "#757575" }}
						>
							Lifecycle Management
						</Typography>
					</div>
					<div id="text">
						<Typography variant="body1" fontWeight="bold"
									style={{ fontSize: 16, fontWeight: "bold", textAlign: "left", position: "relative",
											 top: -9 }} >
							Create New SmartObject
						</Typography>
					</div>
				</div>
			</div>
		);
	}

}

export default withStyles(styles)(DialogCreateSo);
