import React from "react";
import { withRouter } from "react-router-dom";
import actionCreators from '../../actions/ActionCreators';
import codeDevStore from '../../components/CodeDev/CodeDevStore'
import HomeIcon from "@material-ui/icons/Home";
import CodeIcon from "@material-ui/icons/Code";



class SideBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {editor: false,
                 hoverHome: false,
                 hoverDev: false }

    this._updateView = this._updateView.bind(this);
    this._routeHome = this._routeHome.bind(this);
    this._toggleCodeDevPanel = this._toggleCodeDevPanel.bind(this);
    this._onMouseOver = this._onMouseOver.bind(this);
    this._onMouseLeave = this._onMouseLeave.bind(this);
  }

    componentDidMount() {
        this._updateView();
        codeDevStore.on("CodeDevViewUpdate", this._updateView);
        //gLS.on("ChangeEvent_GraphLayoutUpdate", this._updatePanelProps);
    }
    componentWillUnmount() {
        codeDevStore.removeListener("CodeDevViewUpdate", this._updateView);
        //gLS.removeListener("ChangeEvent_GraphLayoutUpdate", this._updatePanelProps);
    }

    _updateView() {
        const status = codeDevStore.getViewSelectStatus();
        this.setState({ editor  : status.editor} )
    }

  _routeHome() {
    actionCreators.ActiveServiceClose()
    this.props.history.push("/main");
  }

  _toggleCodeDevPanel() {
    let status = {}
    status.editor = !this.state.editor;
    codeDevStore.setViewSelectStatus(status);
  }

  _onMouseOver(event, item) {
      switch (item) {
          case "DevPanel": this.setState({hoverDev: true});
                           break;
          case "Home": this.setState({hoverHome: true});
              break;
          default: break;
      }

  }
  _onMouseLeave(event, item) {
      switch (item) {
          case "DevPanel":
              this.setState({hoverDev: false});
              break;
          case "Home":
              this.setState({hoverHome: false});
              break;
          default:
              break;
      }
  }


  render() {

    const editor_col = this.state.hoverDev ? "Black" : "Gray"
    const home_col = this.state.hoverHome ? "Black" : "Gray"

    return (
      <div>
          <div style={{ paddingTop: 5, textAlign: "center" }}
               onClick={this._routeHome}
               onMouseOver={ (event) => {this._onMouseOver(event, "Home")} }
               onMouseLeave={ (event) => {this._onMouseLeave(event, "Home")} }>
              <HomeIcon style={{ fontSize: 30, color: home_col }} />
          </div>

          <div style={{ paddingTop: 30, textAlign: "center" }}
               onClick={ this._toggleCodeDevPanel }
               onMouseOver={ (event) => {this._onMouseOver(event, "DevPanel")} }
               onMouseLeave={ (event) => {this._onMouseLeave(event, "DevPanel")} } >
              <CodeIcon style={{ fontSize: 30, color: editor_col }} />
          </div>
      </div>
    );
  }
}
export default withRouter (SideBar);
//export default compose(withRouter, withStyles(styles))(SideBar);

