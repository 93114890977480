import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";
import pM from "./PopupManager";
import mM from "./../CodeDev/MethodManager";
import selectionManager from "./../SelectionManager/SelectionManager";
//import aSDS from "./../../stores/ActiveServiceDataStore";
import gLS from "./../../stores/GraphLayoutStore";
import MenuEntry from "./MenuEntry";

class ContextMethod  extends React.Component {

  constructor(props) {
    super(props);
    // set size for this configuration panel
    this.width = 185; this.height = 112;
    this.id = "method "+this.props.soName+" "+this.props.port;

    this.selectView = this.selectView.bind(this);
  }



  selectView(view) {
    //const id = "port "+this.props.soName+" "+this.props.port;
    let msg = ""; let graphLayout=null; let soLayout=null; let scaleFactor=null; let location=null;

    switch (view) {

      case "editmethod":
        pM.deletePopup(this.props.id);
        mM.openMethod(this.props.soName, this.props.port);
        console.log("edit method", this.props.port);
        break;

      case "runmethod":
        pM.deletePopup(this.props.id);
        msg = this.props.soName+" call "+this.props.port;
        websocketCom.sendServiceMessage(msg);
        break;

      case "delete":
        pM.deletePopup(this.props.id);
        selectionManager.delete_Id(this.id)
        msg = this.props.soName + " delmethod " + this.props.port;
        websocketCom.sendServiceMessage(msg);
        break;

      case "stats":
        pM.deletePopup(this.props.id);
        graphLayout = gLS.getGraphLayout();
        soLayout = graphLayout[this.props.soName];
        scaleFactor = this.props.location.scaleFactor;
        location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
          "y": (soLayout.graph_data.y),
          "scaleFactor": scaleFactor }
        pM.addPopup(this.props.soName, this.props.port, location, "methodstats");
        break;

      default:
        break;
    }

  }


  _buildMethodContextMenu() {

    //const inletName = this.props.port.split(" ")[1];

      const panel = (
      <div style={{cursor: "default"}} >
       <MenuEntry iconName="editmethod" text={"Edit ... "} callback={this.selectView} />
       <MenuEntry iconName="runmethod" text={"Run "} callback={this.selectView} />
        <MenuEntry iconName="stats" text="Statistics..." callback={this.selectView} />
       <div style={{minHeight: 1, maxHeight: 1, marginTop: 2, marginBottom: 2, marginRight: 0,  backgroundColor: "gray"}} />
       <MenuEntry iconName="delete" text={"Delete "+this.props.port} callback={this.selectView} />
      </div>
    )
    return panel
  }


  render() {

    // Content of panel is defined here
    const view = (<div key={"content-context-method-"+this.props.soName}> {this._buildMethodContextMenu()} </div>)

    let  geometry = this.props.location;
    //const scaleFactor = this.props.location.scaleFactor;
    geometry["width"] = this.width; geometry["height"] = this.height;

    const panel = ( <Popup geometry={geometry} content={view}
                           id={this.props.id} elementClass={this.props.elementClass}
                           delCallback={this.props.delCallback} /> )
    return panel
  }

}

export default ContextMethod;
