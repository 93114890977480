import { EventEmitter } from "events";
import websocketCom from "./../webcom/WebsocketCom";
import actionCreators from "./../actions/ActionCreators";
// eslint-disable-next-line
import userServicesStore from "./../stores/UserServicesStore";
import logger from "./../components/Logging/Logger";
// eslint-disable-next-line
import serviceManager from "./ServiceManager";
import fS from "./../stores/FacilitiesStore";
import sessionManagementStore from "./../stores/SessionManagementStore";


class SessionManager extends EventEmitter {
  constructor() {
    super();

    // soft state update timer for user messages in ms
    this.USER_SOFTSTATE_TIMER = 145000;
    this.USER_REAUTH_TIMER = 300000;
    this.LOG_ORIGIN = "SessionManager";

    this.userSubscribeTimer = null;
    this.reauthSubscribeTimer = null;
    this._authenticateUser = this._authenticateUser.bind(this);
    this.prepareUserSession = this.prepareUserSession.bind(this);
    this._startSessionSubscribeMessages = this._startSessionSubscribeMessages.bind(
      this
    );
    this._stopSessionSubscribeMessages = this._stopSessionSubscribeMessages.bind(this);
    this._sendUserSubscribeMessage = this._sendUserSubscribeMessage.bind(this);
    this._reauthUser = this._reauthUser.bind(this);


    sessionManagementStore.on("ChangeEvent_SessionEstablished", () => {
      this._startSessionSubscribeMessages();
    });

    sessionManagementStore.on("ChangeEvent_SessionClosed", () => {
      this._stopSessionSubscribeMessages();
    });

    logger.log(this.LOG_ORIGIN, "INFO", "Started.", "");
  }

  _authenticateUser(loginData, platformUrl) {
    //var url = "https://" + platformUrl + ".cenolabs.net/api/login";
    var url = "https://" + platformUrl + "/api/login";
    var loginData_json = JSON.stringify(loginData);
    var isAsynchronous = false;
    var response;
    var xhr = new XMLHttpRequest();
    //let responseReceived = false;

    xhr.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        response = JSON.parse(xhr.responseText);
      } else {
        response = {
          status: "error",
          description: "Could not connect to authentication authority."
        };
      }
    };
    xhr.onerror = function() {
      response = {
        status: "error",
        description: "Could not connect to authentication authority."
      };
    };

    xhr.open("POST", url, isAsynchronous);
    xhr.setRequestHeader("Content-type", "application/json");
    try {
      xhr.send(loginData_json);
    } catch (error) {
      return { status: false, msg: response["description"] };
    }

    if (response["status"] === "authenticated") {
      var userDataInDb = {
        user_name: response.login,
        platform_uuid: "1000",
        userManager_uuid: response.user_manager_uuid,
        client_uuid: response.source_uuid,
        websocket_url: response.ws_connection_point,
        websocket_authSecret: response.access_token,
        email: response.email,
        given_name: response.given_name,
        family_name: response.family_name,
        department: response.department,
        city: response.city,
        admin_rights: response.admin_rights        
      };
      return { status: true, msg: userDataInDb };
    } else {
      return { status: false, msg: response["description"] };
    }
  }


  _reauthUser() {

    const resp = sessionManagementStore.getAuthUpdateData()

    let url = "https://" + resp.url + "/api/reauth";
    let loginData_json = JSON.stringify(resp.reAuthData);
    let isAsynchronous = false;
    let response;
    let xhr = new XMLHttpRequest();

    
   
    xhr.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        response = JSON.parse(xhr.responseText);
      } else {
        response = {
          status: "error",
          description: "Could not connect to authentication authority."
        };
      }
    };
    xhr.onerror = function() {
      response = {
        status: "error",
        description: "Could not connect to authentication authority."
      };
    };

    xhr.open("POST", url, isAsynchronous);
    xhr.setRequestHeader("Content-type", "application/json");
    try {
      xhr.send(loginData_json);
    } catch (error) {

      console.log(response["description"])
      //return { status: false, msg: response["description"] };
    }

    if (response["status"] === "authenticated") {
      logger.log("SessionManager", "DEBUG", "Re-Authentication success", "");
      // store relevant user data in sessionDb
      const user_data = {
        client_uuid: response['source_uuid'],
        token: response['access_token']
      };
      actionCreators.SessionLifecycle("update", user_data);
      websocketCom.updateClientUuid(user_data["client_uuid"])

      const message = "set subscribe info ";
      websocketCom.sendServiceMessage(message);
      this._getLogRegister()
      this._getFacilitiesData()
      this._sendUserSubscribeMessage()

      
    } else {
        logger.log("SessionManager", "ERROR", "Re-Authentication failed", "");
        actionCreators.SessionLifecycle("close", {});
    }
  }


  prepareUserSession(loginData, platformUrl) {
    // authenticate user
    const resp = this._authenticateUser(loginData, platformUrl);
    if (resp.status) {
      logger.log(
        this.LOG_ORIGIN,
        "INFO",
        "Successful authentication for user",
        loginData.login
      );
    } else {
      logger.log(this.LOG_ORIGIN, "ERROR", resp.msg, "");
      return resp;
    }

    // store relevant user data in sessionDb
    const user_data = {
      user_name: resp.msg["user_name"],
      platform_identifier: platformUrl,
      client_uuid: resp.msg["client_uuid"],
      token: resp.msg["websocket_authSecret"],
      email: resp.msg.email,
      given_name: resp.msg.given_name,
      family_name: resp.msg.family_name,
      department: resp.msg.department,
      city: resp.msg.city,
      admin_rights: resp.msg.admin_rights
    };
    actionCreators.SessionLifecycle("setup", user_data);

    // request setup of websocket connection that will serve the new session
    const com_data = resp.msg;
    websocketCom.establishWebsocket(com_data, true);

    const status_msg = "Websocket setup ongoing";
    const response = { status: true, msg: status_msg };
    return response;
  }

  _startSessionSubscribeMessages() {
//    logger.log(this.LOG_ORIGIN, "DEBUG", "Add session timers.", "");
    const message = "getlogregister";
    websocketCom.sendUserMessage(message);
    this._sendUserSubscribeMessage();

    this.userSubscribeTimer = window.setInterval(
      this._sendUserSubscribeMessage,
      this.USER_SOFTSTATE_TIMER
    );

    this.reauthSubscribeTimer = window.setInterval(
      this._reauthUser,
      this.USER_REAUTH_TIMER
    );


    this._getFacilitiesData();
    this._getLogRegister();
  }

  _stopSessionSubscribeMessages() {
//    logger.log(this.LOG_ORIGIN, "DEBUG", "Remove session timers.", "");
    if (this.userSubscribeTimer) {
      clearInterval(this.userSubscribeTimer);
    }
    if (this.reauthSubscribeTimer) {
      clearInterval(this.reauthSubscribeTimer);
    }    
  }
  

  _sendUserSubscribeMessage() {
    let message = "getservices";
    websocketCom.sendUserMessage(message);
    //message = "getlog";
    //websocketCom.sendUserMessage(message);
  }

  _getFacilitiesData() {
    // update facility + device tables
    const msg = "getfacilities";
    websocketCom.sendUserMessage(msg);
    window.setTimeout(this._updateDeviceData, 1000);
  }

  _updateDeviceData() {
    const facilities = fS.getFacilities();
    if (facilities) {
      // get device tables from all facilities
      // eslint-disable-next-line no-unused-vars
      for (let [facility, details] of facilities) {
        const msg = "facility devicetable";
        websocketCom.sendOutOfBandMessage(msg, facility);
      }
    }
  }

  _getLogRegister() {
    const msg = "getlogregister";
    websocketCom.sendUserMessage(msg);
  }
}
const sessionManager = new SessionManager();
export default sessionManager;
