import React from "react";
import pM from '../../PopUp/PopupManager';
import aSDS from '../../../stores/ActiveServiceDataStore';
import selectionManager from '../../SelectionManager/SelectionManager';
import LinkIcon from '@material-ui/icons/Link';


class InletItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {isSelect: false};

    //this.method_bg = "black";
    this.methodLabel = "";
    this.id = this.props.direction+" "+this.props.soName+" "+this.props.portName+" "+this.props.inletName;

    this._clickSelectInlet = this._clickSelectInlet.bind(this);
    this._cE_SelectionUpdate = this._cE_SelectionUpdate.bind(this);
  }

  componentDidMount() {
    selectionManager.on( "ChangeEvent_Selection", this._cE_SelectionUpdate );
  }

  componentWillUnmount() {
    selectionManager.removeListener( "ChangeEvent_Selection", this._cE_SelectionUpdate );
  }

  _cE_SelectionUpdate() {
    const resp = selectionManager.checkSelect(this.id);
    const isSelected = resp[0]; //const scope = resp[1];

    if (isSelected) {
      this.setState({isSelect: true });
    } else {
      this.setState({isSelect: false });
    }
  }

  _clickSelectInlet(event) {
    if (event.button === 0) {
    event.stopPropagation();
    const scope = event.shiftKey ? "secondary" : "primary";
    selectionManager.toggle(this.id, scope, "inlet" );

    }
  }


  _clickSelectMethod(event) {
    if (event.button === 0) {
    event.stopPropagation();
    const element = document.getElementById("container-svg-graph")
    if (!element) {return}
    const location = {"x": ((element.scrollLeft+event.clientX-50)/this.props.scaleFactor),
                      "y": ((element.scrollTop+event.clientY-65)/this.props.scaleFactor),
                      "scaleFactor": this.props.scaleFactor }


    const popup = this.props.direction==="inlet" ? "confinlet" : "confoutlet";

    pM.addPopup(this.props.soName, this.props.portName+" "+this.props.inletName, location, popup, "MediumSeaGreen")
  }}


  _onContextMenu(event) {
    event.stopPropagation();
    event.preventDefault();
    pM.deletePopups();
    const element = document.getElementById("container-svg-graph")
    if (!element) {return}
    const location = {"x": ((element.scrollLeft+event.clientX-60)/this.props.scaleFactor),
                      "y": ((element.scrollTop+event.clientY-70)/this.props.scaleFactor),
                      "scaleFactor": this.props.scaleFactor }


    const popup = this.props.direction==="inlet" ? "contextinlet" : "contextoutlet";

    pM.addPopup(this.props.soName, this.props.portName+" "+this.props.inletName, location, popup, "MediumSeaGreen")
  }


  render() {

    //let borderColor = this.props.direction==="inlet" ? "OrangeRed" : "DarkMagenta";
    let borderColor = this.props.direction==="inlet" ? "#444" : " #444";
    if (this.state.isSelect) { borderColor = "blue" };
    let method_bg = borderColor;

    let statusIcon = (<LinkIcon style={{width: 15, color: "white"}} /> );
    this.methodLabel = this.props.methodName;
    let issueIndicator = (<div />);
    let methodBorderStyle = "";
    if (this.props.methodName === "null") {
      statusIcon = (<div />) //(<LinkOffIcon style={{width: 15, color: "white", paddingRight: 10}} /> );
      this.methodLabel = " ";
      issueIndicator = ( <div style={{ backgroundColor: "red", minWidth: 8, minHeight: 8, marginTop:-10, marginLeft: -25,
                                       border: "1px solid white", borderRadius: 4 }} /> )
      methodBorderStyle = "1px dashed WhiteSmoke";
    } else if (!aSDS.checkSmartObjectMethodExist(this.props.soName, this.props.methodName)) {
      // check if assigned method is still present on the SO
      issueIndicator = ( <div style={{ backgroundColor: "red", minWidth: 8, minHeight: 8, marginTop:-10, marginLeft: -25,
                                       border: "1px solid white", borderRadius: 4 }} /> )
    }

    const inletItem = (
      <div style = {{ backgroundColor: borderColor,
                      marginBottom: 3, paddingRight: 10, borderRadius: 6, cursor: "default" }}
           onClick={(event)=>this._clickSelectInlet(event)}
           onContextMenu={(event)=>this._onContextMenu(event)} >
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center",
                      userSelect: "none" }} >
          <div style={{ width: 50+"%",
                        backgroundColor: borderColor, color: "white",
                        paddingLeft: 5, paddingTop: 0, fontFamily: "Roboto", fontSize: 13, fontWeight: "bold",
                        height: 18, minHeight: 18, overflow: "hidden" }}>

            {this.props.inletName}
          </div>
          {statusIcon}
          <div style= {{ width: 50+"%",backgroundColor: method_bg, color: "white",
                        fontFamily: "Roboto", fontSize: 13, fontWeight: "bold",
                        paddingLeft: 10, marginRight: 15, height: 18, minHeight: 18, overflow: "hidden",
                        border: methodBorderStyle, cursor: "copy" }}
                        onClick={(event)=>this._clickSelectMethod(event)}
          >

            {this.methodLabel}
          </div>
          {issueIndicator}
        </div>
      </div>
    )

    return inletItem
  }

}

export default InletItem;

