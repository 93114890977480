import dispatcher from "./../dispatcher/dispatcher";
import websocketCom from "../webcom/WebsocketCom";

import { EventEmitter } from "events";
//import dagre from "dagre";

class ActiveServiceDataStore extends EventEmitter {
  constructor() {
    super();

    //this.smartObjects = new Map();
    //this.service = new Map();
    //this.service.set("data", new Map());
    //this.service.set("user", new Map());

    //this.filter = new Map();
    //this.filter.set("so", ["data", "control"]);
    //this.filter.set("port", ["data", "control"]);

    //this.changeEventTimer = null;

    this._resetStore()

    this.cleanupModel = this.cleanupModel.bind(this);
    this.getSoList = this.getSoList.bind(this);
    this._fireChangeEvent = this._fireChangeEvent.bind(this);
    this.getSOMethodCode = this.getSOMethodCode.bind(this);
    this._resetStore = this._resetStore.bind(this);

  }

  _resetStore() {

    this.smartObjects = new Map();
    //this.connections = new Map();
    this.service = new Map();
    this.service.set("data", new Map());
    this.service.set("user", new Map());

    this.filter = new Map();
    this.filter.set("so", ["data", "control"]);
    this.filter.set("port", ["data", "control"]);

    this.changeEventTimer = null;
  }


  addSmartObject(soName) {
    this.smartObjects.set(soName, new Map());
    this.soCreateEmptyDataStructure(soName)
  }

  soCreateEmptyDataStructure(soName) {
    this.smartObjects.get(soName).set("name", soName);
    this.smartObjects.get(soName).set("info", new Map());
    this.smartObjects
      .get(soName)
      .get("info")
      .set("attributes", new Map());
    this.smartObjects
      .get(soName)
      .get("info")
      .set("owner", "");
    this.smartObjects
      .get(soName)
      .get("info")
      .set("major", []);
    this.smartObjects
      .get(soName)
      .get("info")
      .set("minor", []);
    this.smartObjects
      .get(soName)
      .get("info")
      .set("state", "");
    this.smartObjects
      .get(soName)
      .get("info")
      .set("type", "");
    this.smartObjects
      .get(soName)
      .get("info")
      .set("host", new Map());
    this.smartObjects
      .get(soName)
      .get("info")
      .get("host")
      .set("id", "");
    this.smartObjects
      .get(soName)
      .get("info")
      .get("host")
      .set("locations", []);

    this.smartObjects.get(soName).set("ports", new Map());
    this.smartObjects.get(soName).set("methods", new Map());
    this.smartObjects.get(soName).set("stats", new Map());
    this.smartObjects.get(soName).set("global", new Map());
    this.smartObjects.get(soName).set("log", new Map());
    //this.smartObjects
    //  .get(soName)
    //  .get("log")
    //  .set("number", "0");
    this.smartObjects
      .get(soName)
      .get("log")
      .set("currentLevel", "error");
  }

  removeSmartObject(soName) {
    this.smartObjects.delete(soName);
  }

  setOwner(ownerData) {
    this.smartObjects
      .get(ownerData.so)
      .get("info")
      .set("owner", ownerData.name);
  }

  setHierarchy(data) {
    if (data.operation === "added" || data.operation === "set") {
      let soHierarchy = this.smartObjects
        .get(data.so)
        .get("info")
        .get(data.cmd);

      if (soHierarchy.indexOf(data.name) < 0) {
        soHierarchy.push(data.name);
      }
      this.smartObjects
        .get(data.so)
        .get("info")
        .set(data.cmd, soHierarchy);
    }
    if (data.operation === "deleted") {
      let soHierarchy = this.smartObjects
        .get(data.so)
        .get("info")
        .get(data.cmd);
      const position = soHierarchy.indexOf(data.name);
      if (position !== -1) {
        soHierarchy.splice(position, 1);
      }
      this.smartObjects
        .get(data.so)
        .get("info")
        .set(data.cmd, soHierarchy);
    }
  }

  setAttribute(data) {
    if (
      data.operation === "added" ||
      data.operation === "modified" ||
      data.operation === "set"
    ) {
      this.smartObjects
        .get(data.so)
        .get("info")
        .get("attributes")
        .set(data.attribName, data.value);
    }
    if (data.operation === "deleted") {
      this.smartObjects
        .get(data.so)
        .get("info")
        .get("attributes")
        .delete(data.attribName);
    }
  }

  addConnection(connData) {
    if (!this.checkSmartObjectPortExist(connData.sourceSo, connData.sourcePort)) {
      console.log("ERROR - unknown port in add connection request.")
      return
    }

    // remove trailing comma from sinkPort
    if (connData.sinkPort.charAt(connData.sinkPort.length-1) === ",") {
      connData.sinkPort = connData.sinkPort.substring(0,connData.sinkPort.length-1)
      connData.sinkPort.trim()
    }

    if (!("connectionStatus" in connData)) {
      const sourceSO = connData.sourceSo;
      const sourcePort = connData.sourcePort;
      const sinkSO = connData.sinkSo;
      let sinkPort = connData.sinkPort;

      let uuid = null;
      if (this.checkSoExist(sinkSO)) {
        const sinkSoData = this.getSmartObjectData(sinkSO)
        if (sinkSoData.get("info").get("attributes").has("SOid")) {
          uuid = sinkSoData.get("info").get("attributes").get("SOid");
          uuid = uuid.substring(1);
          uuid = uuid.substring(0, uuid.length-2);
        }
      }

      // add connection to source port
      const shortSinkName = sinkSO + "::" + sinkPort;
      this.smartObjects
        .get(sourceSO)
        .get("ports")
        .get(sourcePort)
        .get("connections")
        .set(shortSinkName, new Map());
      this.smartObjects
        .get(sourceSO)
        .get("ports")
        .get(sourcePort)
        .get("connections")
        .get(shortSinkName)
        .set("so", sinkSO);
      this.smartObjects
        .get(sourceSO)
        .get("ports")
        .get(sourcePort)
        .get("connections")
        .get(shortSinkName)
        .set("port", sinkPort);

      if (uuid) {
        this.smartObjects
            .get(sourceSO)
            .get("ports")
            .get(sourcePort)
            .get("connections")
            .get(shortSinkName)
            .set("uuid", uuid);
      }


      this.smartObjects
        .get(sourceSO)
        .get("ports")
        .get(sourcePort)
        .set("connectstatus", "");
    } else {
      this.setConnectionStatus(connData);
    }
  }

  removeAllConnections(connData) {
    this.smartObjects
      .get(connData.so)
      .get("ports")
      .get(connData.port)
      .set("connectstatus", connData.connectionStatus);
    this.smartObjects
      .get(connData.so)
      .get("ports")
      .get(connData.port)
      .set("connections", new Map());
  }

  removeConnection(connData) {
    const sourceSO = connData.sourceSo;
    const sourcePort = connData.sourcePort;
    const sinkSO = connData.sinkSo;
    const sinkPort = connData.sinkPort;

    // remove connection from source port
    const shortSinkName = sinkSO + "::" + sinkPort;
    this.smartObjects
      .get(sourceSO)
      .get("ports")
      .get(sourcePort)
      .get("connections")
      .delete(shortSinkName);

    const numberConnections = this.smartObjects
      .get(sourceSO)
      .get("ports")
      .get(sourcePort)
      .get("connections").size;
    if (numberConnections === 0) {
      this.smartObjects
        .get(sourceSO)
        .get("ports")
        .get(sourcePort)
        .set("connectstatus", "empty");
    }
  }

  setConnectionStatus(connData) {
    this.smartObjects
      .get(connData.so)
      .get("ports")
      .get(connData.port)
      .set("connectstatus", connData.connectionStatus);

    this.smartObjects
      .get(connData.so)
      .get("ports")
      .get(connData.port)
      .set("connections", new Map());
  }

  addPort(portData) {
    this.smartObjects
      .get(portData.so)
      .get("ports")
      .set(portData.port, new Map());
    this.smartObjects
      .get(portData.so)
      .get("ports")
      .get(portData.port)
      .set("connections", new Map());
    this.smartObjects
      .get(portData.so)
      .get("ports")
      .get(portData.port)
      .set("inlets", new Map());
    this.smartObjects
      .get(portData.so)
      .get("ports")
      .get(portData.port)
      .set("outlets", new Map());
    this.smartObjects
      .get(portData.so)
      .get("ports")
      .get(portData.port)
      .set("attributes", new Map());
    this.smartObjects
      .get(portData.so)
      .get("ports")
      .get(portData.port)
      .set("stats", new Map());
    this.smartObjects
      .get(portData.so)
      .get("ports")
      .get(portData.port)
      .set("connectstatus", "empty");
  }

  setPort(portData) {
    // check if port does not already exists
    if (
      !this.smartObjects
        .get(portData.so)
        .get("ports")
        .has(portData.port)
    ) {
      // if port does not exits --> create it
      this.addPort(portData);
    }
  }

  delPort(portData) {
    this.smartObjects
      .get(portData.so)
      .get("ports")
      .delete(portData.port);
  }

  handlePortAttributes(portData) {
    if (
      portData.operation === "added" ||
      portData.operation === "set" ||
      portData.operation === "modified"
    ) {
      this.smartObjects
        .get(portData.so)
        .get("ports")
        .get(portData.port)
        .get("attributes")
        .set(portData.attribName, portData.value);
    }
    if (portData.operation === "deleted") {
      this.smartObjects
        .get(portData.so)
        .get("ports")
        .get(portData.port)
        .get("attributes")
        .delete(portData.attribName);
    }
  }

  handlePinInlet(inletData) {

    // check if port exists
    // if not there is a severe issue
    // is so drop message and request full dump of so to resolve database glitch
    if ( ! this.checkSmartObjectPortExist(inletData.so, inletData.port) ) {
      //console.log("ISSUE: Received Inlet command for non existing port!" )
      const msg = inletData.so + " getfullinfo"
      websocketCom.sendServiceMessage(msg);
      return
    }

    if (inletData.pinCmd === "set") {
      const inletInDatabase = this.smartObjects
        .get(inletData.so)
        .get("ports")
        .get(inletData.port)
        .get("inlets")
        .has(inletData.name);

      if (!inletInDatabase) {
        // set command for a inlet that is currently not in the database
        // --> we need to add it to the database
        inletData.pinCmd = "added";
      }
    }

    if (inletData.pinCmd === "added") {
      this.smartObjects
        .get(inletData.so)
        .get("ports")
        .get(inletData.port)
        .get("inlets")
        .set(inletData.name, new Map());
      this.smartObjects
        .get(inletData.so)
        .get("ports")
        .get(inletData.port)
        .get("inlets")
        .get(inletData.name)
        .set("method", inletData.method);
    }
    if (inletData.pinCmd === "modified") {
      //console.log('in modified');
      this.smartObjects
        .get(inletData.so)
        .get("ports")
        .get(inletData.port)
        .get("inlets")
        .get(inletData.name)
        .set("method", inletData.method);
    }
    if (inletData.pinCmd === "deleted") {
      this.smartObjects
        .get(inletData.so)
        .get("ports")
        .get(inletData.port)
        .get("inlets")
        .delete(inletData.name);
    }
  }
  handlePinOutlet(outletData) {
    if (outletData.pinCmd === "set") {
      //console.log("outlet set");
      const outletInDatabase = this.smartObjects
        .get(outletData.so)
        .get("ports")
        .get(outletData.port)
        .get("outlets")
        .has(outletData.name);

      //console.log("outletInDatabase =", outletInDatabase);

      if (!outletInDatabase) {
        //console.log("In !outletDatabase");
        // set command for a outlet that is currently not in the database
        // --> we need to add it to the database
        outletData.pinCmd = "added";
      }
    }

    if (outletData.pinCmd === "added") {
      this.smartObjects
        .get(outletData.so)
        .get("ports")
        .get(outletData.port)
        .get("outlets")
        .set(outletData.name, new Map());
      this.smartObjects
        .get(outletData.so)
        .get("ports")
        .get(outletData.port)
        .get("outlets")
        .get(outletData.name)
        .set("method", outletData.method);
    }
    if (outletData.pinCmd === "modified") {
      this.smartObjects
        .get(outletData.so)
        .get("ports")
        .get(outletData.port)
        .get("outlets")
        .get(outletData.name)
        .set("method", outletData.method);
    }
    if (outletData.pinCmd === "deleted") {
      this.smartObjects
        .get(outletData.so)
        .get("ports")
        .get(outletData.port)
        .get("outlets")
        .delete(outletData.name);
    }
  }

  handleState(initData) {
    if (initData.state_cmd === "set") {

      // check if state dead
      // if so cleanup data structure in so
      if (initData.state === "dead") {
        this.soCreateEmptyDataStructure(initData.so)
      }

      this.smartObjects
        .get(initData.so)
        .get("info")
        .set("state", initData.state);
    }
  }

  handleType(typeData) {
    if (typeData.type_cmd === "set") {
      this.smartObjects
        .get(typeData.so)
        .get("info")
        .set("type", typeData.type);
    }
  }

  handleLocation(locationData) {
    if (locationData.operation === "set") {
      locationData.name = locationData.name.trim();

      if (locationData.name === "nil") {
        locationData.name = "";
      }

      let locations = ["freefloat"];
      if (locationData.name !== "") {
        // not a frree floating SmartObject
        locations = locationData.name.split(" ").filter(function(el) {
          return el.length !== 0;
        });
      }
      this.smartObjects
        .get(locationData.so)
        .get("info")
        .get("host")
        .set("locations", locations);

      //------------------------------------
      // shortcut for the moment
      // make first location so host
      // must be replaced with actual set info message !
      // this.smartObjects
      //  .get(locationData.so)
      //  .get("info")
      //  .get("host")
      //  .set("id", locations[0])
      //.set("id", locationData.name);
      //-------------------------------------
    }
  }

  handleActualLocation(actualLocationData) {
    if (actualLocationData.cmd === "actual") {
      this.smartObjects
        .get(actualLocationData.so)
        .get("info")
        .get("host")
        .set("id", actualLocationData.name);
    }
  }

  handleMethod(methodData) {
    // handle info messages
    if (methodData.cmd === "set") {
      if (
        this.smartObjects
          .get(methodData.so)
          .get("methods")
          .has(methodData.name)
      ) {
        this.smartObjects
          .get(methodData.so)
          .get("methods")
          .get(methodData.name)
          .set("status", methodData.status);
      } else {
        methodData.cmd = "added";
      }
    }
    if (methodData.cmd === "added") {
      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .set(methodData.name, new Map());
      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("repoLink", methodData.link);

      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("attributes", new Map());

      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("stats", new Map());

      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("callresp", "not triggered");

      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("status", methodData.status);
      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("resp", "");
      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("code", "");
      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("codeChanged", false);
    }
    if (methodData.cmd === "deleted") {
      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .delete(methodData.name);
    }

    // handle resp messages
    if (methodData.cmd === "method" && methodData.cmdOperation === "loaded") {
      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("resp", methodData.status);
      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("codeChanged", true);
      this.emit("MethodCodeUpload");
    }

    if (methodData.cmd === "method" && methodData.cmdOperation === "source") {
      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("code", methodData.code);
      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("codeChanged", true);
      this.smartObjects
        .get(methodData.so)
        .get("methods")
        .get(methodData.name)
        .set("callresp", "not triggered");
      this.emit("MethodCodeChange");
    }


    if (methodData.cmd === "method" && methodData.cmdOperation === "actual") {
        this.smartObjects.get(methodData.so).set("actual", methodData.code)
        this.emit("ActualSetupChange");
      
      //this.smartObjects
      //  .get(methodData.so)
      //  .get("methods")
      //  .get(methodData.name)
      //  .set("code", methodData.code);
      //this.smartObjects
      //  .get(methodData.so)
      //  .get("methods")
      //  .get(methodData.name)
      //  .set("codeChanged", true);
      //this.emit("ActualSetupChange");
    }


  }

  handleRespCallMethod(respCallMethod) {
      this.smartObjects
        .get(respCallMethod.so)
        .get("methods")
        .get(respCallMethod.name)
        .set("callresp", respCallMethod.status);
  }

/*  handleSoLog(logMessage) {

    if (logMessage.cmd === "so_log") {
      if (! this.checkSoExist(logMessage.so)) {return}
      const numLogs = this.smartObjects
        .get(logMessage.so)
        .get("log")
        .get("number");


      // Dirty Hack to overcome issue with gui uuid (receive logs twice due to soft state transition)
      const newNumberLogEntries = (parseInt(numLogs, 10) + 1).toString();
      let numberEntries = parseInt(this.smartObjects.get(logMessage.so).get("log").size);
      let entry = numberEntries-2;
      const entryString = entry.toString();
      const lastEntry = this.smartObjects.get(logMessage.so).get("log").get(entryString)
      if ( lastEntry) {
        if ( (lastEntry.get("level") == logMessage.level) && lastEntry.get("msg").localeCompare(logMessage.log)=== 0) {
          return
        }
      }

      this.smartObjects
        .get(logMessage.so)
        .get("log")
        .set("number", newNumberLogEntries);

      this.smartObjects
        .get(logMessage.so)
        .get("log")
        .set(newNumberLogEntries, new Map());
      this.smartObjects
        .get(logMessage.so)
        .get("log")
        .get(newNumberLogEntries)
        .set("level", logMessage.level);
      this.smartObjects
        .get(logMessage.so)
        .get("log")
        .get(newNumberLogEntries)
        .set("msg", logMessage.log);
    }
  }*/

  setSoLogLevel(infoLogMessage) {
    if (infoLogMessage.log_cmd === "level") {
      this.smartObjects
        .get(infoLogMessage.so)
        .get("log")
        .set("currentLevel", infoLogMessage.log_level);
    }
  }

  handleServiceData(serviceData) {
    const dataCmd = serviceData.dataCmd;

    if (dataCmd === "set" || dataCmd === "added" || dataCmd === "modified")
      this.service.get("data").set(serviceData.key, serviceData.data);
    else if (dataCmd === "deleted")
      this.service.get("data").delete(serviceData.key);
  }

  handleServiceUser(userData) {
    const userCmd = userData.userCmd;
    if (userCmd === "set" || userCmd === "added")
      this.service.get("user").set(userData.userNick, userData.userRole);
    else if (userCmd === "deleted")
      this.service.get("user").delete(userData.userNick);
  }

  statisticsUpdate(statsData) {
    if (!this.smartObjects.has(statsData.so)) {
      return;
    }

    let statsDbHandle = null;
    if (statsData.cmd === "statistics")
      statsDbHandle = this.smartObjects.get(statsData.so).get("stats");
    else if (
      statsData.cmd === "portstatistics" &&
      this.smartObjects
        .get(statsData.so)
        .get("ports")
        .has(statsData.port)
    )
      statsDbHandle = this.smartObjects
        .get(statsData.so)
        .get("ports")
        .get(statsData.port)
        .get("stats");
    else if (
      statsData.cmd === "methodstatistics" &&
      this.smartObjects
        .get(statsData.so)
        .get("methods")
        .has(statsData.message)
    )
      statsDbHandle = this.smartObjects
        .get(statsData.so)
        .get("methods")
        .get(statsData.message)
        .get("stats");

    if (statsDbHandle !== null) {
      switch (statsData.cmdOperation) {
        case "set":
          statsDbHandle.set(statsData.key, statsData.value);
          break;
        case "table":
          const statsTable = JSON.parse(statsData.table);
          for (let key in statsTable) {
            statsDbHandle.set(key, statsTable[key]);
          }
          break;
        default:
      }
    }
  }

  globalUpdate(globalData) {
    if (!this.smartObjects.has(globalData.so)) {
      console.log(
        "ERROR: received global update with unknown SO. Message dropped!"
      );
      return;
    }
    // get SO global data handle from database
    const globalDbHandle = this.smartObjects.get(globalData.so).get("global");

    // store received data in database
    if (globalDbHandle !== null) {
      switch (globalData.cmdOperation) {
        case "set":
          globalDbHandle.set(globalData.key, globalData.value);
          break;
        case "table":
          // delete old table
          this.smartObjects.get(globalData.so).set("global", new Map() );
          // store new table
          const globalTable = JSON.parse(globalData.table);
          for (let key in globalTable) {
            //globalDbHandle.set(key, globalTable[key]);
            this.smartObjects.get(globalData.so).get("global").set(key, globalTable[key]);
          }
          break;
        default:
      }
    }
  }

  handleMethodAttribute(methodAttribute) {
    if (
      methodAttribute.operation === "added" ||
      methodAttribute.operation === "set" ||
      methodAttribute.operation === "modified"
    ) {
      this.smartObjects
        .get(methodAttribute.so)
        .get("methods")
        .get(methodAttribute.message)
        .get("attributes")
        .set(methodAttribute.attribName, methodAttribute.value);
    }
    if (methodAttribute.operation === "deleted") {
      this.smartObjects
        .get(methodAttribute.so)
        .get("methods")
        .get(methodAttribute.message)
        .get("attributes")
        .delete(methodAttribute.attribName);
    }
  }

  /** ---------------------------------------------------------------
   ** Helper functions
   **/
  _fireChangeEvent() {
    this.emit("change");
    this.changeEventTimer = null;
  }

  emitChange() {
    this.emit("change");
  }

  cleanupModel() {
    //console.log("Platform store resets platform model.");

    this.smartObjects = new Map();
    this.connections = new Map();
    this.service = new Map();
    this.service.set("data", new Map());
    this.service.set("user", new Map());

    this.filter = new Map();
    this.filter.set("so", ["data", "control"]);
    this.filter.set("port", ["data", "control"]);

    this.emit("change");
  }

  /** ---------------------------------------------------------------
   ** Get'er for service scope data
   **/
  // Get all users which are registered for the service
  //
  // Returns:
  //          Map() :     userNick, userRole
  //                      userNick, userRole
  //                      ...
  //                      userNick, userRole
  //
  getUser() {
    return this.service.get("user");
  }
  //
  /* ----------------------------------------------------------------
   * Get'er for SmartObject scoped data
   */

  getPlatformState() {
    return this.smartObjects;
  }

  getSoList() {
    let soList = [];
    for (let so of this.smartObjects.keys()) {
      soList.push(so);
    }
    return soList;
  }

  getSmartObjectData(so_name) {
    let so = null
    if (this.smartObjects.has(so_name)) {
      so = this.smartObjects.get(so_name);
    }
    return so;
  }

  checkSoExist(searchName) {
    for (let so of this.smartObjects.keys()) {
      if (so === searchName) return true
    }
    return false
  }


  getSOActual(so_name) {
    let response = null;
    if ( this.smartObjects.has(so_name) ) {
      const so = this.smartObjects.get(so_name);
      if (so.has("actual")) {
        response = so.get("actual");
      }
    }
    return response
  }

  getSOType(so_name) {
    let type=null;
    if ( this.smartObjects.has(so_name) ) {
      type = this.smartObjects.get(so_name).get("info").get("type");
    }
    return type;
  }


  getMethodCode(so_name, method_name) {
    let response = null
    if (this.smartObjects.has(so_name)) {
      const so = this.smartObjects.get(so_name);
      const methods = so.get("methods");
      if (methods.has(method_name)) {
        const method = methods.get(method_name);
        if (method.get("codeChanged")===true) {
          response = method.get("code")
          this.smartObjects.get(so_name).get("methods").get(method_name).set("codeChanged", false);
        }
      }
    }
    return response;
  }


  getSOMethodCode(so_name) {
    const so = this.smartObjects.get(so_name);
    const methods = so.get("methods");
    let container = {}; //new Object();

    methods.forEach((item, name) => {
      if (methods.get(name).get("codeChanged")) {
        const code = methods.get(name).get("code");
        container[name] = code;

        this.smartObjects
          .get(so_name)
          .get("methods")
          .get(name)
          .set("codeChanged", false);
      }
    });
    return container;
  }

  getSOMethodCallResp(so_name, method_name) {
  if (this.checkSmartObjectMethodExist(so_name, method_name)) {
    return this.smartObjects
            .get(so_name)
            .get("methods")
            .get(method_name)
            .get("callresp");
    }
    return ""
  }

  getSoCallResponses(so_name) {
    let callResponses = {};
    if (this.smartObjects.has(so_name)) {

      const methods= this.smartObjects.get(so_name).get("methods");
      for (const [key, value] of methods) {
        if (value.get("callresp") !== "" && value.get("callresp") !== "not triggered") {
          callResponses[key] = value.get("callresp");
          this.smartObjects.get(so_name).get("methods").get(key).set("callresp", "");
        }
      }
    }
    return callResponses
  }


  getSmartObjectMajorData(soName) {
    let list = undefined;
    if (this.smartObjects.has(soName)) {
      list = this.smartObjects
        .get(soName)
        .get("info")
        .get("major");
    } else list = [];
    return list.slice();
  }

  getSmartObjectMinorData(soName) {
    let list = undefined;
    if (this.smartObjects.has(soName)) {
      list = this.smartObjects
        .get(soName)
        .get("info")
        .get("minor");
    } else list = [];
    return list.slice();
  }

  checkSmartObjectPortExist(soName, portName) {
    let found = false;
    if (this.smartObjects.has(soName)) {
      if (
        this.smartObjects
          .get(soName)
          .get("ports")
          .has(portName)
      ) {
        found = true;
      }
    }
    return found;
  }

  getPortData(soName, portName) {
    let found = null;
    if (this.checkSoExist(soName) && this.checkSmartObjectPortExist(soName, portName) ) {
      return this.smartObjects.get(soName).get("ports").get(portName)
    }
    return found
  }

  checkSmartObjectMethodExist(soName, methodName) {
    let found = false;
    if (this.smartObjects.has(soName)) {
      if (
        this.smartObjects
          .get(soName)
          .get("methods")
          .has(methodName)
      ) {
        found = true;
      }
    }
    return found;
  }

  // get so attribute
  getAttribute(soName, attribute) {
    if (this.smartObjects.has(soName)) {
      if (
        this.smartObjects
          .get(soName)
          .get("info")
          .get("attributes")
          .has(attribute)
      ) {
        const value = this.smartObjects
          .get(soName)
          .get("info")
          .get("attributes")
          .get(attribute);
        return value;
      }
    }
    return null;
  }

  // get statistic metric
  getStat(soName, metric) {
    if (this.smartObjects.has(soName)) {
      if (
        this.smartObjects
          .get(soName)
          .get("stats")
          .has(metric)
      ) {
        const value = this.smartObjects
          .get(soName)
          .get("stats")
          .get(metric);
        return value;
      }
    }
    return null;
  }

  getPortStat(soName, port, metric) {
    if (this.smartObjects.has(soName)) {
      if (
        this.smartObjects
          .get(soName)
          .get("ports")
          .has(port)
      ) {
        if (
          this.smartObjects
            .get(soName)
            .get("ports")
            .get(port)
            .get("stats")
            .has(metric)
        ) {
          const value = this.smartObjects
            .get(soName)
            .get("ports")
            .get(port)
            .get("stats")
            .get(metric);
          return value;
        }
      }
    }
    return null;
  }

  getMethodStat(soName, method, metric) {
    if (this.smartObjects.has(soName)) {
      if (
        this.smartObjects
          .get(soName)
          .get("methods")
          .has(method)
      ) {
        if (
          this.smartObjects
            .get(soName)
            .get("methods")
            .get(method)
            .get("stats")
            .has(metric)
        ) {
          const value = this.smartObjects
            .get(soName)
            .get("methods")
            .get(method)
            .get("stats")
            .get(metric);
          return value;
        }
      }
    }
    return null;
  }

  /* -----------------------------------------------------------------
   * Get'er for DagreGraphModel
   */
  getFilteredGraphDescription() {
    const soList = this.getFilteredSoList();
    const connectionsList = this.getFilteredConnectionsList(soList);
    return [soList, connectionsList];
  }

  getFilteredSoList() {
    let soList = [];
    for (let so of this.smartObjects.keys()) {
      soList.push(so);
    }
    soList = this.applySoFilter(soList);
    return soList;
  }

  getFilteredConnectionsList(soList) {
    this.connections = new Map();

    let portHandle = null;
    let connectionHandle = null;
    for (let so of this.smartObjects.keys()) {
      portHandle = this.smartObjects.get(so).get("ports");
      if (portHandle) {
        // the so has at least one port we have to investigate
        //console.log('portHandle = ', portHandle);
        for (let port of portHandle.keys()) {
          //console.log('Found port: ', so, port);
          connectionHandle = this.smartObjects
            .get(so)
            .get("ports")
            .get(port)
            .get("connections");
          for (let connection of connectionHandle.keys()) {
            //console.log('found connection: ', connection, connectionHandle.get(connection).get('so'), connectionHandle.get(connection).get('port'));
            const targetSo = connectionHandle.get(connection).get("so");
            const targetPort = connectionHandle.get(connection).get("port");

            if (this.smartObjects.has(targetSo)) {
              // check that target so exists
              if (
                this.smartObjects
                  .get(targetSo)
                  .get("ports")
                  .has(targetPort)
              ) {
                //console.log('found valid connection');
                const connectionName =
                  so + "." + port + "-" + targetSo + "." + targetPort;
                this.connections.set(connectionName, new Map());
                this.connections.get(connectionName).set("source", [so, port]);
                this.connections
                  .get(connectionName)
                  .set("sink", [targetSo, targetPort]);
              }
            }
          } // for (let connection ...
        } // for (let port ...
      } //  if (portHandle)
    } // for (let so ...

    let connList = this.connections;

    //console.log('connections = ', connList);

    connList = this.applyConnFilter(connList, soList);

    return connList;
  }

  applySoFilter(soList) {
    let filteredSoList = [];

    for (let so of soList) {
      const attributes = this.smartObjects
        .get(so)
        .get("info")
        .get("attributes");
      if (attributes.has("type")) {
        const soType = attributes.get("type");
        if (this.filter.get("so").includes(soType)) {
          filteredSoList.push(so);
        }
      } else {
        // no attribute type defined --> display smart object
        filteredSoList.push(so);
      }
    }
    return filteredSoList;
  }

  applyConnFilter(connList, soList) {
    let tempConnList = new Map();
    // check that source and sink so of the connection are in the filtered so list
    for (let connection of connList.keys()) {
      const sourceSo = connList.get(connection).get("source")[0];
      //const sourcePort = connList.get(connection).get('source')[1];
      const sinkSo = connList.get(connection).get("sink")[0];
      if (soList.includes(sourceSo) && soList.includes(sinkSo)) {
        tempConnList.set(connection, connList.get(connection));
      }
    }

    // check that port filter criteria is met
    let filteredConnList = [];
    for (let connection of tempConnList.keys()) {
      const sourceSo = tempConnList.get(connection).get("source")[0];
      const sourcePort = tempConnList.get(connection).get("source")[1];
      const sinkSo = tempConnList.get(connection).get("sink")[0];

      const portAttributes = this.smartObjects
        .get(sourceSo)
        .get("ports")
        .get(sourcePort)
        .get("attributes");
      if (portAttributes.has("type")) {
        const portType = portAttributes.get("type");
        if (this.filter.get("port").includes(portType)) {
          filteredConnList.push([sourceSo, sinkSo]);
        }
      } else {
        filteredConnList.push([sourceSo, sinkSo]);
      }
    }
    return filteredConnList;
  }
  // -----------------------------------------------------------------

  handleActions(action) {
    //console.log("handleActions:", action)
    switch (action.type) {

      case "ACTION_SessionLifecycle": {

        if (action.msg["status"] === "close") {
          this._resetStore()
          console.log("Reset ActionveServiceDataStore")
        }
        break
      }

      case "INFO_MSG_RECV": {
        // --------------------------------------------------------------------
        // Purpose: check if soName in info message is valid
        //
        // only check info messages that have a soName entry
        if (action.msg.so) {
          // check if soName is not in database
          if (!this.smartObjects.has(action.msg.so)) {
            const soCoreCmd = action.msg.cmd;
            if (soCoreCmd === "created" || soCoreCmd === "added") {
              // soName in info message is a new SO that goes into the db
              // nothing else to do here...
            } else {
              // soName unknown and not a request to instantiate a new so
              // need to drop this message
              console.log(
                "WARNING: Info message with unknown SO name. Message dropped."
              );
              break; // drop this message by leaving case "INFO_MSG_RECV" block!
            }
          }
        }
        // info message with no or valid soName received
        // --> continue processing the info message
        // --------------------------------------------------------------------

        const infoMsgScope = action.msg.scope;
        switch (String(infoMsgScope)) {
          case "so-core":
            const soCoreCmd = action.msg.cmd;
            const soName = action.msg.so;
            if (soCoreCmd === "created" || soCoreCmd === "added") {
              this.addSmartObject(soName);
            }
            if (soCoreCmd === "deleted") {
              this.removeSmartObject(soName);
            }
            if (soCoreCmd === "owner") {
              this.setOwner(action.msg);
            }
            if (soCoreCmd === "major" || soCoreCmd === "minor") {
              this.setHierarchy(action.msg);
            }
            if (soCoreCmd === "attribute") {
              this.setAttribute(action.msg);
            }
            if (soCoreCmd === "state") {
              this.handleState(action.msg);
            }
            if (soCoreCmd === "type") {
              this.handleType(action.msg);
            }
            if (soCoreCmd === "location") {
              this.handleLocation(action.msg);
            }
            if (soCoreCmd === "actual") {
              this.handleActualLocation(action.msg);
            }
            break; // --> case "so-core"
          case "connection":
            const conCmd = action.msg.cmd;
            if (conCmd === "set") {
              this.removeAllConnections(action.msg);
            }
            if (conCmd === "added") {
              this.addConnection(action.msg);
            }
            if (conCmd === "deleted") {
              this.removeConnection(action.msg);
            }
            break;
          case "port":
            const portCmd = action.msg.cmd;
            if (portCmd === "added") {
              this.addPort(action.msg);
            }
            if (portCmd === "set") {
              this.setPort(action.msg);
            }

            if (portCmd === "deleted") {
              this.delPort(action.msg);
            }
            if (portCmd === "portattribute") {
              this.handlePortAttributes(action.msg);
            }
            break;
          case "pin":
            const cmd = action.msg.cmd;
            if (cmd === "inlet") {
              this.handlePinInlet(action.msg);
            }
            if (cmd === "outlet") {
              this.handlePinOutlet(action.msg);
            }
            break;
          case "method":
            const methodCmd = action.msg.cmd;
            if (methodCmd === "methodattribute")
              this.handleMethodAttribute(action.msg);
            else this.handleMethod(action.msg);
            break;
          case "service":
            const serviceCmd = action.msg.cmd;
            if (serviceCmd === "servicedata") {
              this.handleServiceData(action.msg);
            }
            if (serviceCmd === "user") {
              this.handleServiceUser(action.msg);
            }
            break;
          case "stats":
            this.statisticsUpdate(action.msg);
            break;
          default: {
            console.log("message not processed")
          }
        }
        break; //  --> case "INFO_MSG_RECV"
      }

      case "RESP_MSG_RECV": {
        const respMsgScope = action.msg.scope;
        switch (String(respMsgScope)) {
          case "resp_method": {
            this.handleMethod(action.msg);
            break;
          }
          case "resp_call_method": {
            this.handleRespCallMethod(action.msg)
            break;
          }
          case "setup_so_log": {
            this.setSoLogLevel(action.msg);
            break;
          }
          case "global": {
            this.globalUpdate(action.msg);
            break;
          }
          case "location": {
            if (action.msg.cmd === "actual") {
              this.smartObjects
                .get(action.msg.so)
                .get("info")
                .get("host")
                .set("id", action.msg.name);
            }
            break;
          }

          case "so-core": {
            if (action.msg.cmd === "location") {
              this.handleLocation(action.msg);
            }
            if (action.msg.cmd === "state") {
              this.smartObjects
                .get(action.msg.so)
                .get("info")
                .set("state", action.msg.name);
            }
            if (action.msg.cmd === "type") {
              this.smartObjects
                .get(action.msg.so)
                .get("info")
                .set("type", action.msg.name);
            }
            if (action.msg.cmd === "loaded") {
              // add is ok as this will replace (and not create a new SO)
              // the no longer valid date for the SO
              this.addSmartObject(action.msg.so)
            }
            if (action.msg.cmd === "attribute") {
              this.setAttribute(action.msg);
            }
            break;
          }

          default: {
          }
        }
        break;
      }

      //case "LOG_MSG_RECV": {
      //  const logMsgScope = action.msg.scope;
      //  switch (String(logMsgScope)) {
      //    case "log_message": {
      //      this.handleSoLog(action.msg);
      //      break;
      //    }
      //    default: {
      //    }
      //  }
      //  break;
      //}

      case "LOGOUT_DONE": {
        this.cleanupModel();
        break;
      }
      case "ACTION_ChangeActiveService": {
        //window.setTimeout(this.cleanupModel, 100);
        this.cleanupModel();
        break;
      }

      default: {
      }
    }

    if (this.changeEventTimer === null) {
      this.changeEventTimer = setTimeout(this._fireChangeEvent, 150);
    } else {
      clearTimeout(this.changeEventTimer);
      this.changeEventTimer = setTimeout(this._fireChangeEvent, 150);
    }

    //this.emit("change");
  }
}

const activeServiceDataStore = new ActiveServiceDataStore();
dispatcher.register(
  activeServiceDataStore.handleActions.bind(activeServiceDataStore)
);
export default activeServiceDataStore;
