import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import actionCreators from "./../../../../actions/ActionCreators";
import websocketCom from "./../../../../webcom/WebsocketCom.js";
import ServiceCard from "./ServiceCard2";
import NewServiceCard from "./NewServiceCard2";
import userServicesStore from "./../../../../stores/UserServicesStore";
import sms from "./../../../../stores/SessionManagementStore";
import asds from "./../../../../stores/ActiveServiceDataStore";
import CreateServiceModal from "./CreateServiceModal";
import PersonalSettings from "./PersonalSettings";
import GlobalSettings from "./GlobalSettings";
import LastUserDrop from "./LastUserDrop";
// -------------------------------------------------------------
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
//import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
//import Divider from "@material-ui/core/Divider";
// -------------------------------------------------------------

const styles = theme => ({});

class Service extends React.Component {
  _isMounted = false;

  constructor() {
    super();

    this.state = {
      serviceTable: userServicesStore.getServiceList(),
      userList: new Map(),
      modalOpen: false,
      selectedService: "",
      tabSelected: 0,
      show_luDrop: false,
      usersData: {}
    };

    this._cE_NewServiceList = this._cE_NewServiceList.bind(this);
    this._cE_asdsChange = this._cE_asdsChange.bind(this);
    this.createNewServiceClicked = this.createNewServiceClicked.bind(this);
    this.openCreateServiceModal = this.openCreateServiceModal.bind(this);
    this.closeCreateServiceModal = this.closeCreateServiceModal.bind(this);
    this.serviceCardClicked = this.serviceCardClicked.bind(this);
    this._handleTabChange = this._handleTabChange.bind(this);
    this.dropUser = this.dropUser.bind(this);
    this.cancelDropUser = this.cancelDropUser.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._cE_NewServiceList();
    userServicesStore.on("ChangeEvent_NewServiceList", this._cE_NewServiceList);
    asds.on("change", this._cE_asdsChange);
  }

  componentWillUnmount() {
    this._isMounted = false;
    userServicesStore.removeListener(
      "ChangeEvent_NewServiceList",
      this._cE_NewServiceList
    );
  }


  openCreateServiceModal() {
    this.setState({ modalOpen: true });
  }

  closeCreateServiceModal() {
    this.setState({ modalOpen: false });
  }

  createNewServiceClicked() {
    this.openCreateServiceModal();
  }
  _cE_NewServiceList() {
    if (this._isMounted) {
      const newServiceList = userServicesStore.getServiceList();
      let found = false;
      for (let service in newServiceList) {
        if (service === this.state.selectedService) {
          found = true;
          break;
        }
      }
      if (!found) this.setState({ selectedService: "" });
      this.setState({
        serviceTable: userServicesStore.getServiceList()
      });
    }
  }

  _cE_asdsChange() {
    if (this._isMounted) {
      this.setState({ userList: asds.getUser() });
    }
  }

  _applyFilter(service) {
    const lowerCaseService = service.toLowerCase();
    const lowerCaseSearch = this.props.searchInput.toLowerCase();
    if (lowerCaseService.includes(lowerCaseSearch)) {
      return true;
    } else {
      return false;
    }
  }

  serviceCardClicked(serviceName) {
    this.setState({ selectedService: serviceName });

    // change active service
    const status = "change";
    const options = {
      service_name: serviceName,
      service_uuid: this.state.serviceTable[serviceName]
    };
    actionCreators.ActiveServiceChange(status, options);

    const message = "service getalluser";
    websocketCom.sendServiceMessage(message);
  }


  _deleteServicePressed() {
    if (this.state.userList.size === 1) {
      this.setState({show_luDrop: true})
    } else {
      this.dropUser()
    }
  }


  _startCodingPressed() {
    const status = "change";
    const options = {
      service_uuid: this.state.serviceTable[this.state.selectedService],
      service_name: this.state.selectedService
    };
    actionCreators.ActiveServiceChange(status, options);
    this.props.history.push("/servicedev");
  }


  _handleTabChange(event, value) {
    this.setState({ tabSelected: value });
  }


  dropUser() {
    this.setState({show_luDrop: false})
    const userName = sms.getUserName();
    const msg = "service deluser " + userName;
    websocketCom.sendServiceMessage(msg);
    this.setState({ selectedService: "" });
  }


  cancelDropUser() {
    this.setState({show_luDrop: false})
  }


  _buildPanelHeader() {
    const panelHeader = (
        <div style={{ marginTop: 15 }}>
          <div
              id="service-header"
              style={{
                backgroundColor: "White",
                border: "1px solid bdbdbd",
                boxShadow: "0px 0px 8px 0px #bdbdbd",
                width: this.width,
                maxWidth: this.width,
                borderRadius: 9
              }}
          >
            {this._renderHeadlineNG()}
            <div style={{height: 0, marginBottom: 20}} />
          </div>
        </div>
    );

    return panelHeader;
  }



  _buildServiceHeader() {
    //const { classes } = this.props;

    //let serviceControlPanel = <div />;
    let serviceName = "";
    let isDisabled = true;

    if (this.state.selectedService === "") {
      // serviceName = (
      //   <Typography variant="h5" style={{ color: "#bdbdbd" }}>
      //     No service selected!
      //   </Typography>
      // );
      serviceName = "No Service Selected";
    } else {
      //serviceName = (
      //  <Typography variant="h5">{this.state.selectedService}</Typography>
      //);
      serviceName = this.state.selectedService;
      isDisabled = false;
    }

    const serviceHeader = (
      <div style={{ marginTop: 25 }}>
        <div
          id="frame"
          style={{
            backgroundColor: "white",
            border: "1px solid bdbdbd",
            boxShadow: "0px 0px 8px 0px #bdbdbd",
            width: this.width,
            maxWidth: this.width,
            borderRadius: 0
          }}
        >
          {this._renderHeadline(serviceName, isDisabled)}
         <div style={{height: 5}} />
        </div>
      </div>
    );

    return serviceHeader;
  }

  _buildCardContainer() {
    //const { classes } = this.props;
    let CardContainer = [];

    for (let serviceName in this.state.serviceTable) {
      const showService = this._applyFilter(serviceName);

      let cardRaised = false;
      if (this.state.selectedService === serviceName) cardRaised = true;

      if (showService) {
        const serviceUuid = this.state.serviceTable[serviceName];
        let serviceDescription = new Map();
        serviceDescription.set("serviceName", serviceName);
        serviceDescription.set("serviceUuid", serviceUuid);
        const service_card = (
          <ServiceCard
            key={serviceName}
            serviceName={serviceName}
            callback={this.serviceCardClicked}
            raised={cardRaised}
          />
        );
        CardContainer.push(service_card);
      }
    }

    const newServiceCard = (
      <NewServiceCard
        key={"newServiceCard"}
        callback={this.createNewServiceClicked}
      />
    );
    CardContainer.push(newServiceCard);

    const cardContainer = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          overflowX: "auto",
          flexWrap: "wrap"
        }}
      >
        {CardContainer}
      </div>
    );
    return cardContainer;
  }

  _buildServiceView() {
    if (this.state.selectedService === "") return <div />;

    let serviceView = <div />;
    let serviceControlPanel = <div />;

    switch (this.state.tabSelected) {
      case 0:
        serviceControlPanel = (
          <PersonalSettings serviceName={this.state.selectedService} />
        );
        break;
      case 1:
        serviceControlPanel = (
          <GlobalSettings
            activeUser={sms.getUserName()}
            userList={this.state.userList}
          />
        );
        break;
      case 2:
        serviceControlPanel = <div>Logging</div>;
        break;
      default:
        break;
    }

    //let serviceName = "";
    //let isDisabled = true;

    //if (this.state.selectedService === "") {
    //  serviceName = "No service selected!";
    //} else {
    //  serviceName = this.state.selectedService;
    //  isDisabled = false;
    //}

    serviceView = (

        <div>
          <div style={{margin: 0, }}>
            <AppBar position="static" color="default" elevation={2}>
              <Tabs
                value={this.state.tabSelected}
                onChange={this._handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                //centered
              >
                <Tab label="Personal Settings" />
                <Tab label="Global Settings" />
                {/*<Tab label="Logging History" />*/}
              </Tabs>
            </AppBar>
            <div style={{ padding: 20 + "px" }}>{serviceControlPanel}</div>
          </div>
        </div>

    );
    return serviceView;
  }

  render() {
    const page = (
      <div>

        <LastUserDrop isActive={this.state.show_luDrop}
                      handle_func_drop = {this.dropUser}
                      handle_func_cancel_drop = {this.cancelDropUser}
        />

        <div>
          <CreateServiceModal
            open={this.state.modalOpen}
            closeHandle={this.closeCreateServiceModal}
          />
        </div>

        {this._buildPanelHeader()}
        <div>
            {this._buildCardContainer()}
          <Paper style={{ marginTop: 20 }}>
            {this._buildServiceHeader()}
            {this._buildServiceView()}
          </Paper>
        </div>
      </div>
    );

    return page;
  }

  // =============================================================================================================

  _renderHeadline(serviceName, isDisabled) {
    return (
      <div id="header" style={{ display: "flex", flexDirection: "row" }}>

        <div
          id="headline"
          style={{
            flexGrow: 1,
            textAlign: "left",
            paddingTop: 15,
            paddingLeft: 25
          }}
        >
          <div id="type">
            <Typography
              variant="overline"
              style={{ fontSize: 9, color: "#757575" }}
            >
              Service
            </Typography>
          </div>
          <div id="text">
            <Typography
              variant="body1"
              fontWeight="bold"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                position: "relative",
                top: -9
              }}
            >
              {serviceName}
            </Typography>
          </div>
        </div>

        <div style={{ paddingTop: 20 }}>
          <Button
            disabled={isDisabled}
            id="inputbutton"
            variant="outlined"
            color="primary"
            onClick={this._startCodingPressed.bind(this)}
          >
            Start Coding
          </Button>
        </div>

        <div
          style={{ paddingTop: 20, paddingLeft: 20 + "px", paddingRight: 20 }}
        >
          <Button
            disabled={isDisabled}
            id="inputbutton"
            variant="outlined"
            color="secondary"
            onClick={this._deleteServicePressed.bind(this)}
          >
            Drop Service
          </Button>
        </div>
      </div>
    );
  }


  _renderHeadlineNG() {
    return (
        <div id="header" style={{ display: "flex", flexDirection: "row" }}>
          <div
              id="colorblotch"
              style={{
                width: 50,
                height: 50,
                backgroundColor: "Orange",

                position: "relative",
                top: -15,
                left: 20,
                boxShadow: "1px 4px 7px 2px #bdbdbd",
                borderRadius: 4
              }}
          >
            <AccountTreeIcon
                style={{
                  width: 26,
                  height: 26,
                  color: "white",
                  position: "relative",
                  top: 12,
                  left: 12
                }}
            />
          </div>
          <div
              id="headline"
              style={{
                flexGrow: 1,
                textAlign: "left",
                paddingTop: -20,
                paddingLeft: 35
              }}
          >
            <div id="type">
              <Typography
                  variant="overline"
                  style={{ fontSize: 9, color: "#757575" }}
              >
                Management
              </Typography>
            </div>
            <div id="text">
              <Typography
                  variant="body1"
                  fontWeight="bold"
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    textAlign: "left",
                    position: "relative",
                    top: -9
                  }}
              >
                Services
              </Typography>
            </div>
          </div>
        </div>
    );
  }


}
export default compose(withRouter, withStyles(styles))(Service);
