import { EventEmitter } from "events";
import aSDS from "./ActiveServiceDataStore";
import config from "./../components/config";
import websocketCom from "./../webcom/WebsocketCom";
//import statusStore from "./../components/InfoView/StatusInfo/StatusStore";
import statusStore from "./../components/CodeDev/Problems/ProblemsStore"
import selectionManager from "./../components/SelectionManager/SelectionManager";

class GraphLayoutStore extends EventEmitter {
  constructor() {
    super();
    this.defaultWidth = 160; this.defaultHeight = 50;
    this.default_so_col = config.so_default_col;
    this.portPlacement = "fourside"// "leftright" or "fourside"
    this.graphData = {};
    this.linkList = [];
    this.blockCoreUpdate = {};  // core updates for so keys in this object are blocked
    this.graphZoomFactor = 0.9;

    this.showGlobal = [];
    this.showDebug = [];

    aSDS.on("change", () => {
      this._cE_updateGraphData();
    });
    //selectionManager.on( "ChangeEvent_Selection", this._cE_updateGraphData );

    this._cE_updateGraphData = this._cE_updateGraphData.bind(this);
    this._cE_SelectionUpdate = this._cE_SelectionUpdate.bind(this);
    this.updateSoPosition = this.updateSoPosition.bind(this);
    this.setSoColor = this.setSoColor.bind(this);
    this._updateLinkPlacement = this._updateLinkPlacement.bind(this);
  }


  _cE_SelectionUpdate() {
    this._cE_updateGraphData();
  }

  reset() {
    this.graphData = {};
    this.linkList = [];
    this.blockCoreUpdate = {};  // core updates for so keys in this object are blocked
    this.graphZoomFactor = 0.9;

    this.showGlobal = [];
    this.showDebug = [];
  }

  getGraphLayout() {
    return this.graphData;
  }

  getLinkList() {
    return this.linkList;
  }

  getScaleFactor() {
    return this.graphZoomFactor;
  }
  increaseScaleFactor() {
    this.graphZoomFactor = this.graphZoomFactor * 1.1;
    this.emit("ChangeEvent_GraphLayoutUpdate");
  }
  decreaseScaleFactor() {
    this.graphZoomFactor = this.graphZoomFactor / 1.1;
    this.emit("ChangeEvent_GraphLayoutUpdate");
  }
  resetScaleFactor() {
    this.graphZoomFactor = 0.9;
    this.emit("ChangeEvent_GraphLayoutUpdate");
  }

  //toggleGlobalView(soName) {
  //  if (this.showGlobal.includes(soName)) {
  //    this.showGlobal = [];
  //  } else {
  //    this.showGlobal = [];
  //    this.showGlobal.push(soName);
  //  }
  //  this.emit("ChangeEvent_GraphLayoutUpdate");
  //}
  toggleDebugView(soName) {
    if (this.showDebug.includes(soName)) {
      this.showDebug = this.showDebug.filter(item => item !== soName);
    } else {
      this.showDebug.push(soName);
    }
    this.emit("ChangeEvent_GraphLayoutUpdate");
  }
  //getGlobalView() {return this.showGlobal}
  getDebugView() {return this.showDebug}

  setView(soName, view) {
    this.graphData[soName].graph_extension.view = view ;
    this._cE_updateGraphData();
  }
  getView(soName) {
    return this.graphData[soName].graph_extension.view;
  }
  setSoColor(soName, color) {
    this.graphData[soName].graph_data.color = color;
    const graph_data = JSON.stringify(this.graphData[soName].graph_data);
    const msg = soName + " setattribute graph_data " + graph_data;
    websocketCom.sendServiceMessage(msg);
  }

  redraw() {this._cE_updateGraphData()}


  _cE_updateGraphData() {
    // update SOs assigned to GlobalView debug panel
    this._updateDebugViewSelection();
    // update all relevant service graph drawing data
    this._updateSoPlacement();
    this._updatePortsPlacement();
    this._updateLinkPlacement();
    statusStore.collectStatus();
    // inform ServiceGraph SO to trigger redraw of service graph
    this.emit("ChangeEvent_GraphLayoutUpdate");
  }

  _updateDebugViewSelection() {
    const temp = [];
    for (const index in this.showDebug) {
      if (aSDS.checkSoExist(this.showDebug[index])) {
        temp.push(this.showDebug[index])
      }
    }
    this.showDebug = temp;
  }

  // function is called during dragging of a SmartObject on the service graph
  updateSoPosition(soName, deltax, deltay) {
    let coordinates = this.graphData[soName].graph_data
    coordinates.x = coordinates.x + deltax
    coordinates.y = coordinates.y + deltay
    if (coordinates.x <= config.setup.canvas.borderWidth) coordinates.x = config.setup.canvas.borderWidth
    if (coordinates.y <= config.setup.canvas.borderWidth) coordinates.y = config.setup.canvas.borderWidth
    // TODO: lower right edge handling

    this.graphData[soName]["graph_data"] = coordinates;
    // need to recalculate ports placement during dragging a SmartObject
    this._updatePortsPlacement();
    // links must be recalculated and drawn according to new port placement
    this._updateLinkPlacement();
    // trigger redraw on canvas by informing ServiceGraph SO
    this.emit("ChangeEvent_GraphLayoutUpdate");
  }

  // during dragging phase of a SmartObject rerender due to data update from core
  // is blocked. (otherwise so would be redrawn at drag start position!)
  blockSoCoreUpdate(soName) {
    this.blockCoreUpdate[soName] = "move"
  }
  unblockSoCoreUpdate(soName){
    delete this.blockCoreUpdate[soName];
    // push new graph data into SmartObject data in the core
    const geometry = this.graphData[soName].graph_data
    const graph_data = JSON.stringify(geometry)
    const message = soName + " setattribute graph_data " + graph_data;
    websocketCom.sendServiceMessage(message);
  }

  // always called when the platform reports a change in SO configuration
  _updateSoPlacement() {
    // get list of so names from database
    const soList = aSDS.getSoList()
    // ----------------------------------------------------------------------------------
    // check if there are new to add or old to remove SO
    let soInDb = []
    for (let key in this.graphData) {
      soInDb.push(key)
    }
    // first remove SO that are no longer reported from core
    for (const key of soInDb) {
      if (! soList.includes(key) ) {
        delete this.graphData[key];
      }
    }
    // add new reported SO
    for (const key of soList) {
      if (! (key in this.graphData)) {
        this.graphData[key] = {};
        this.graphData[key]["graph_data"] = { "x": 0, "y": 0, "color": this.default_so_col,
                            "width": this.defaultWidth, "height": this.defaultHeight };
        this.graphData[key]["graph_extension"] = { "view": "compact",
                                                   "d_x": 0, "d_y":0, "d_w": 0, "d_h": 0,
                                                   "main_h": 80, "method_h": 0, "status_h": 0 };
      }
    }
    // ----------------------------------------------------------------------------------
    // iterate over all so and update SO placement info in the  graph data structure
    const self = this
    soList.forEach(function(soName){
      let soData = aSDS.getSmartObjectData(soName);
      // create graph_data attribute in SO if not already present
      if ( ! soData.get("info").get("attributes").has("graph_data") ) {

        let x = 50 + Math.ceil(Math.random() * 300)
        let y = 50 + Math.ceil(Math.random() * 300)
        const element = document.getElementById("container-svg-graph")
        if (element) {
          const width = element.clientWidth; const height = element.clientHeight;
          x=50+(element.scrollLeft+(width/2)-(160))/self.graphZoomFactor;
          y=50+(element.scrollTop+(height/2)-(130))/self.graphZoomFactor;
        }

        const geometry = {"x": x, "y": y, "color": self.default_so_col, "width": self.defaultWidth, "height": self.defaultHeight  }
        const graph_data = JSON.stringify(geometry)
        const message = soName + " setattribute graph_data " + graph_data;
        websocketCom.sendServiceMessage(message);
      } else {

        if ( ! self.blockCoreUpdate.hasOwnProperty(soName) ) {
          const data = JSON.parse(soData.get("info").get("attributes").get("graph_data"))
          //const dummyAdaptor = JSON.parse( data )
          //self.graphData[soName].graph_data = dummyAdaptor;
          self.graphData[soName].graph_data = data;

          // make dead so size compact
          const soState = soData.get("info").get("state");
          if (soState === "" || soState === "undef" || soState === "lost") {
            self.graphData[soName].graph_extension.view = "compact";
          }

          // check for view extensions
          const so_graph_ext = self.graphData[soName].graph_extension;
          switch (so_graph_ext.view) {
            case "compact":
              so_graph_ext.main_h = 80; so_graph_ext.method_h = 0; so_graph_ext.status_h = 0;
              so_graph_ext.d_w = 0;
              so_graph_ext.d_h = so_graph_ext.main_h + so_graph_ext.method_h + so_graph_ext.status_h;
              break;
            case "large":

              // calculate height of method box
              const numberMethods = soData.get("methods").size;
              const methodHeaderOffset = 50  +75;
              const singleMethodHeight = 27; const methodsPerLine = 2;
              const totalMethodHeight = methodHeaderOffset + (Math.round(numberMethods/methodsPerLine)*singleMethodHeight)

              // calculate height of inlets

              so_graph_ext.main_h = 100;

              const soPortView = selectionManager.getPortViewSelection(soName);
              //const portView = selectionManager.getPortViewSelection(so);
              //if (soName in portView) {
              if (soPortView && soData.get("ports").has(soPortView) ) {
                //const soPortView = portView[soName];
                const numberInlets = soData.get("ports").get(soPortView).get("inlets").size;
                const numberOutlets = soData.get("ports").get(soPortView).get("outlets").size;
                const numberXlets = numberInlets+numberOutlets;
                const xletHeaderOffset = 95; const singlexletHeight = 27;
                const totalxletHeight = xletHeaderOffset + (numberXlets*singlexletHeight);
                so_graph_ext.main_h = totalxletHeight > so_graph_ext.main_h ? totalxletHeight : so_graph_ext.main_h;
              }

              so_graph_ext.method_h = totalMethodHeight; so_graph_ext.status_h = 25;
              so_graph_ext.d_w = 170;
              so_graph_ext.d_h = so_graph_ext.main_h + so_graph_ext.method_h + so_graph_ext.status_h;
              break;


            case "panel":
              if ( soData.get("info").get("attributes").has("IO_device_setup") ) {
                const IO_device_setup = JSON.parse(soData.get("info").get("attributes").get("IO_device_setup"))

                switch (IO_device_setup.type) {
                  case "KingPigeon":
                    so_graph_ext.main_h = parseInt(IO_device_setup.height); //250;
                    so_graph_ext.method_h = 0; so_graph_ext.status_h = 0;
                    so_graph_ext.d_w = parseInt(IO_device_setup.width); //120;
                    so_graph_ext.d_h = so_graph_ext.main_h + so_graph_ext.method_h + so_graph_ext.status_h;
                  break;
                  case "OpenEdge":
                    so_graph_ext.main_h = parseInt(IO_device_setup.height); //250;
                    so_graph_ext.method_h = 0; so_graph_ext.status_h = 0;
                    so_graph_ext.d_w = parseInt(IO_device_setup.width); //120;
                    so_graph_ext.d_h = so_graph_ext.main_h + so_graph_ext.method_h + so_graph_ext.status_h;
                  break;

                  default: break;
                }

              } else if (soData.get("info").get("attributes").has("chart_setup")) {
                    so_graph_ext.main_h = 180; so_graph_ext.method_h = 0; so_graph_ext.status_h = 0;
                    so_graph_ext.d_w = 190;
                    so_graph_ext.d_h = so_graph_ext.main_h + so_graph_ext.method_h + so_graph_ext.status_h;
                    break;
              } else if (soData.get("info").get("attributes").has("virt_IO_setup")) {
                    so_graph_ext.main_h = 180; so_graph_ext.method_h = 0; so_graph_ext.status_h = 0;
                    so_graph_ext.d_w = 190;
                    so_graph_ext.d_h = so_graph_ext.main_h + so_graph_ext.method_h + so_graph_ext.status_h;
                    break;
	      } else {

                so_graph_ext.main_h = 180; so_graph_ext.method_h = 0; so_graph_ext.status_h = 0;
                  so_graph_ext.d_w = 190;
                  so_graph_ext.d_h = so_graph_ext.main_h + so_graph_ext.method_h + so_graph_ext.status_h;
                break;
              }
            // eslint-disable-next-line
            default: break;
          }
        }
      }
    });
    return;
  }

  _calcPortAlignment(sourceSo, destinationSo) {

    const source_width = this.graphData[sourceSo].graph_data.width+this.graphData[sourceSo].graph_extension.d_w;
    const source_height = this.graphData[sourceSo].graph_data.height+this.graphData[sourceSo].graph_extension.d_h;
    const dest_width = this.graphData[destinationSo].graph_data.width+this.graphData[destinationSo].graph_extension.d_w;
    const dest_height = this.graphData[destinationSo].graph_data.height+this.graphData[destinationSo].graph_extension.d_h;

    // calculate drawing line angle and derive port allingment from angle
    // get target so coordinates
    const xtarget = this.graphData[destinationSo].graph_data.x + (dest_width/2);
    const ytarget = this.graphData[destinationSo].graph_data.y + (dest_height/2);
    // get own so coordinates
    const xpos = this.graphData[sourceSo].graph_data.x + (source_width/2);
    const ypos = this.graphData[sourceSo].graph_data.y + (source_height/2);
    // calculate destination vector to decide on placement
    const x = xtarget-xpos; const y = -(ytarget-ypos)
    // calculate placement angle
    const angle = Math.atan2(y, x) * 180 / Math.PI;

    // calculate alignment
    let pP = this.portPlacement;
    if (this.graphData[sourceSo].graph_extension.view === "large") { pP="leftright"}

    let alignment = ""
    if (pP === "leftright") {
      // place ports left and right of the SmartObject
      const ref = 90;
      if ( angle >= -ref && angle <= ref ) { alignment="right" }
      if ( angle < -ref ||  angle > ref ) { alignment="left" }
    } else if (pP === "fourside") {
      // place ports on all four sides of the SmartObject
      // As each SO can have different size, portPlacementAngle must be calculated for each SO
      const ref= Math.atan2(source_height/2, source_width/2) * 180 / Math.PI;
      if ( angle >= -ref && angle <= ref ) { alignment="right" }
      else if ( angle>ref && angle < (180-ref) ) { alignment="top" }
      else if ( angle >= 180-ref || angle <= -180+ref ) { alignment="left" }
      else if ( angle < -ref && angle > (-180+ref) ) { alignment="bottom" }
    }
    return { alignment, angle }
  }


  _updatePortsPlacement() {
    // get list of so names from database
    const soList = aSDS.getSoList()
    let portsCollection = []
    const self = this
    soList.forEach(function(soName){

      // get ports for so from database
      const portData = aSDS.getSmartObjectData(soName).get("ports");
      if (portData.size === 0) {
        // console.log("This SO has no ports")

      } else {

        // now we have all ports, iterate over them and calculate placement edge for drawing
        for (let [key, value] of portData ) {

        let port = {
              "name"      : "",
              "alignment" : "",
              "angle"     : "",
              "xpos"      : "",
              "ypos"      : "",
              "status"    : "",
              "issue"     : [] }


          port["name"] = key;
          port["status"] = value.get("connectstatus");

          // get all connections that belong to this port
          const connectionData = value.get("connections")


          if (connectionData.size === 0) {


            // Check if other SmartObjects have a connection to this port
            let isRemoteConnection = false;
            let remoteSmartObjectWithConnection = ""
            for (const remoteSo of soList) {
              const remoteSoPortData = aSDS.getSmartObjectData(remoteSo).get("ports");
              if (remoteSoPortData.size > 0) {
                // iterate over all ports and get connections
                // eslint-disable-next-line
                for (let [key, value] of remoteSoPortData) {
                  const conData = value.get("connections");
                  if (conData.size > 0) {
                    // eslint-disable-next-line
                    for (let [key, targetSo] of conData) {
                      if (targetSo.get("so") === soName && targetSo.get("port") === port["name"]) {
                        isRemoteConnection = true;
                        remoteSmartObjectWithConnection = remoteSo;
                        break;
                      }
                    }
                  }
                  if (isRemoteConnection) { break }
                }   // end for remoteSoPortData
              }
              if (isRemoteConnection) { break }
            }   // end for soList

            if (isRemoteConnection) {
              let resp = self._calcPortAlignment(soName, remoteSmartObjectWithConnection);
              port["alignment"] = resp.alignment;
              port["angle"] = resp.angle;
            } else {
              // there is no remote connection for this port
              port["alignment"] = "bottom"
              port["angle"] = 180 - self.portPlacementAngle;

              if (self.graphData[soName].graph_extension.view === "large") {
                port["alignment"] = "left"
                port["angle"] = -180;
              }
            }
          } else {

            // iterate over all connections until first connected dest port found
            // eslint-disable-next-line
            for (let [key, targetSo] of connectionData) {
              // console.log("port connected to ", targetSo.get("so"), "destination port =", targetSo.get("port") )
              // check if target so and target port exist
              const targetPortDefined = aSDS.checkSmartObjectPortExist(targetSo.get("so"), targetSo.get("port"));
              if (targetPortDefined) {
                let resp = self._calcPortAlignment( soName, targetSo.get("so") );
                port["alignment"] = resp.alignment;
                port["angle"] = resp.angle;
                break;  //  Hack, align just based on the first connected port !!!

              } else {
                // connection destination is not defined
                //console.log("connection destination not defined")
                port["alignment"] = "bottom";
                port["angle"] = 180 - self.portPlacementAngle;

                if (self.graphData[soName].graph_extension.view === "large") {
                  port["alignment"] = "left"
                  port["angle"] = -180;
                }
              }

            } // end iterate over all ports
          }
          portsCollection.push(port)
        }
      }
      // save portsCollection
      if (soName in self.graphData) {
        self.graphData[soName]["ports"] = portsCollection
      }
      portsCollection = []
    })



    // finally we need to calculate the exact position of each port
    soList.forEach(function(soName) {
      if (soName in self.graphData) {
      let left=[]; let top=[]; let right=[]; let bottom=[];
      let finalPortContainer = [];

      const portData = self.graphData[soName]["ports"]
      for (const port of portData) {
        switch (port.alignment) {
          case "top":
            top.push(port)
            break;
          case "bottom":
            bottom.push(port)
            break;
          case "left":
            left.push(port)
            break;
          case "right":
            right.push(port)
            break;
          default:
            break;
        } // end switch
      } // end soList.forEach

      // sort
      function compare(a,b) {
        if (a.angle < b.angle) {return -1}
        if (a.angle > b.angle) {return 1}
        return 0
      }
      top.sort(compare); bottom.sort(compare); left.sort(compare); right.sort(compare);


      const view = self.graphData[soName].graph_extension.view;




      // check for and if needed assign sufficient space to draw ports
      if (view === "large") {
        const distributionHeight = self.graphData[soName].graph_extension.main_h
        //left side
        const portsLeft = left.length;
        const distLeft = Math.round(distributionHeight/(portsLeft+1))
        const portsRight = right.length;
        const distRight = Math.round(distributionHeight/(portsRight+1))
        const dist = distLeft < distRight ? distLeft : distRight;
        const ports = dist===distLeft ? portsLeft : portsRight;
        if (dist < 50) {
          const extension = (50-dist)*(ports+1);
          self.graphData[soName].graph_extension.d_h += extension;
          self.graphData[soName].graph_extension.main_h += extension;
        }
      }






      const width = self.graphData[soName].graph_data.width+self.graphData[soName].graph_extension.d_w;
      const height = self.graphData[soName].graph_data.height+self.graphData[soName].graph_extension.d_h;

      // left right destribution for large display of so with detailed ports info
      const portDistributionHeight = self.graphData[soName].graph_extension.main_h;
      const portOffsetBottom = self.graphData[soName].graph_extension.method_h+self.graphData[soName].graph_extension.status_h;

      // distribute bottom
      let numberPorts = bottom.length;
      let spaceBetween = Math.round(width / (numberPorts+1));
      let loop = 1
      for (const port of bottom) {
        let tempport = port;
        tempport.xpos = loop*spaceBetween;
        tempport.ypos = height;
        finalPortContainer.push(tempport)
        loop = loop + 1;
      }
      // distribute top
      numberPorts = top.length;
      spaceBetween = Math.round(width / (numberPorts+1));
      loop = 1
      for (const port of top) {
        let tempport = port;
        tempport.xpos = width - (loop*spaceBetween);
        tempport.ypos = 0;
        finalPortContainer.push(tempport)
        loop = loop + 1;
      }
      // distribute left   - draw bottom up
      numberPorts = left.length;
      if (view === "large") { spaceBetween = Math.round(portDistributionHeight / (numberPorts+1));
      } else { spaceBetween = Math.round(height / (numberPorts+1)); }
      loop = 1
      for (const port of left) {
        let tempport = port
        tempport.xpos = 0;
        tempport.ypos = height - portOffsetBottom - (loop*spaceBetween);
        finalPortContainer.push(tempport)
        loop = loop + 1;
      }
      // distribute right   - draw bottom up
      numberPorts = right.length;
      if (view === "large") { spaceBetween = Math.round(portDistributionHeight / (numberPorts+1));
      } else { spaceBetween = Math.round(height / (numberPorts+1)); }
      loop = 1
      for (let port of right) {
        port.xpos = width;
        port.ypos = height - portOffsetBottom - (loop*spaceBetween);
        finalPortContainer.push(port)
        loop = loop + 1;
      }
      self.graphData[soName]["ports"] =finalPortContainer
      }
    });
    return;
  }


  _getPortCoordinates(soName, portName) {
    const portData = this.graphData[soName].ports;
    let x1=0; let y1=0; let alignment="";
    if (portData) {
      for (const port in portData) {
        const myPort=portData[port]
        if (myPort.name === portName) {
          x1 = this.graphData[soName].graph_data.x + myPort.xpos;
          y1 = this.graphData[soName].graph_data.y + myPort.ypos;
          alignment = myPort.alignment;
          break;
        }
      }
    }
    return {x1, y1, alignment}
  }


  _calculatePortBezierCoordinate( soName, portName) {
    const resp = this._getPortCoordinates(soName, portName);
    let xbez = 0; let ybez = 0; const bezStrength = 70;
    switch (resp.alignment) {
      case "top":
        xbez = 0; ybez = -bezStrength;
        break;
      case "bottom":
        xbez = 0; ybez = bezStrength ;
        break;
      case "left":
        xbez = - bezStrength; ybez = 0;
        break;
      case "right":
        xbez = bezStrength; ybez = 0;
        break;
     default:
        xbez = 0; ybez = 0;
        break;
    }
    const x1 = resp.x1 + xbez; const y1 = resp.y1 + ybez
    return {x1, y1}
  }


  setPortIssue(soName, portName, status, destUuid) {
    const portData = this.graphData[soName].ports;
    if (portData) {
      let counter = 0;
      for (const port in portData) {
        const myPort=portData[port]
        if (myPort.name === portName) {
          myPort.issue.push(status   +destUuid)
          this.graphData[soName].ports[counter] = myPort;
          break;
        }
        counter++;
      }
    }
  }


  _checkReverseConDefined(remoteSo, remotePort, so, port) {
    const remPort = aSDS.getSmartObjectData(remoteSo).get("ports").get(remotePort);
    if (remPort.get("connectstatus") === "nil") {return true} // no reverse connection on server port is ok
    const remotePortData = remPort.get("connections");
    const searchString = so+"::"+port;
    if (remotePortData.has(searchString)) {return true} // reverse connection is defined --> ok
    return false;
  }


  _updateLinkPlacement() {

    let links = []; let handledLinks = []
    const soList = aSDS.getSoList()

    for (const soName of soList) {
      const soPortData = aSDS.getSmartObjectData(soName).get("ports");
      if (soPortData.size > 0) {
        // iterate over all ports and get connections
        for (let [portName, portData] of soPortData) {
          const source = this._getPortCoordinates(soName, portName);
          const bez1 = this._calculatePortBezierCoordinate(soName, portName)
          const conData = portData.get("connections");
          if (conData.size > 0) {
            // eslint-disable-next-line
            for (let [conName, conDest] of conData) {
              if ( ! (aSDS.checkSoExist(conDest.get("so")) && aSDS.checkSmartObjectPortExist(conDest.get("so"), conDest.get("port")) ) ) {
                // console.log("Destination SO or port not existent");
                // mark port with issue
                const errorMessage = "Unknown remote endpoint: "+conDest.get("so")+"::"+conDest.get("port")
                let destUuid = "";
                if (conDest.has("uuid")) {
                  destUuid = "//uuid"+conDest.get("uuid")
                }
                this.setPortIssue(soName,portName, errorMessage, destUuid);
              } else {

                // check if the line for this connection is already in th links container
                const resp  = this._checkLinkInList(soName, portName, conDest.get("so"), conDest.get("port"), handledLinks);
                const isNewEntry = resp[0];
                handledLinks = resp[1];

                if (isNewEntry) {

                  // check if reverse connection is defined in target SO
                  const isbicon = this._checkReverseConDefined(conDest.get("so"), conDest.get("port"), soName, portName);
                  if (! isbicon) {
                    const errorMessage = "Blocked connection on remote endpoint: "+conDest.get("so")+"::"+conDest.get("port")
                    let destUuid = "";
                    //if (conDest.has("uuid")) {
                    //  destUuid = conDest.get("uuid")
                    //}
                    this.setPortIssue(soName,portName, errorMessage, destUuid);
                  }

                  const dest = this._getPortCoordinates(conDest.get("so"), conDest.get("port") );
                  const bez2 = this._calculatePortBezierCoordinate(conDest.get("so"), conDest.get("port"))

                  links.push( {"name": soName+"-"+portName+"-"+conDest.get("so")+"-"+conDest.get("port"),
                                "x1": source.x1, "y1": source.y1, "x2": dest.x1, "y2": dest.y1,
                                "bez1x": bez1.x1, "bez1y": bez1.y1, "bez2x": bez2.x1, "bez2y": bez2.y1,
                                "isbicon": isbicon,
                                "so1": soName, "port1": portName,
                                "so2": conDest.get("so"), "port2": conDest.get("port") }

                  )
                }
              }
            }
          }
        }   // end for soPortData
      }
    }
    this.linkList = links;
  }


  _checkLinkInList(so, port, destSo, destPort, handledLinks) {

    const searchString = destSo+":"+destPort+"-"+so+":"+port;
    if (handledLinks.includes(searchString)) { return [false, handledLinks] }
    else {
      const entry = so+":"+port+"-"+destSo+":"+destPort;
      handledLinks.push(entry);
      return [true, handledLinks];
    }
  }



  //_processLayoutMessage(action) {
    //this.emit("graph_layout_update", this.history);
  //}

  //handleActions(action) {
  //  switch (action.type) {
  //    case "ACTION_SoGraphLayoutReceived": {
  //      this._processLayoutMessage(action);
  //      break;
  //    }
  //    default: {
  //    }
  //  }
  //}
}

const graphLayoutStore = new GraphLayoutStore();
//dispatcher.register(historyStore.handleActions.bind(historyStore));

export default graphLayoutStore;
