import React from "react";
import aSDS from "./../../stores/ActiveServiceDataStore";
//import gLS from "./../../stores/GraphLayoutStore";
import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";
//import pM from "./PopupManager";
//import PortEntry from "./PortEntry";


class ConfPorts extends React.Component {

  constructor(props) {
    super(props);
    // set size for this configuration panel
    this.width = 400; this.height = 290;



    this.state = { exportCode: "", moveOffset: {"x":0, "y":0} }

    this.moveCallback = this.moveCallback.bind(this);
    this._cE_ActualSetupChange = this._cE_ActualSetupChange.bind(this);
  }


  componentDidMount() {
    const msg = this.props.soName + " retrieveactual SETUP";
    websocketCom.sendServiceMessage(msg)

    aSDS.on("ActualSetupChange", this._cE_ActualSetupChange)

  }

  componentWillUnmount() {
    aSDS.removeListener("ActualSetupChange", this._cE_ActualSetupChange);
  }

  _cE_ActualSetupChange() {
      const actualSetup = aSDS.getSOActual(this.props.soName)
      this.setState({exportCode: actualSetup})
  }


  _panelClick(event) {event.stopPropagation() }

  moveCallback(delta) {
    this.setState({moveOffset: delta})
  }


  _clickCopy() {
      //console.log("stop")

      //event.stopPropagation();
      document.querySelector("textarea").select();
      document.execCommand('copy');
      //document.querySelector("textarea").
  }



  _buildHeadline() {
    return (
        <div id="headline" style={{ paddingBottom: 5, paddingLeft: 15 }} >
          <div id="type">
              <div style={{display: "flex", fontFamily: "Roboto", fontSize: 13, color: "DimGray", paddingTop: 3}} >
                <div style={{width: 9, height: 9, backgroundColor: "DimGray", marginTop: 4, marginRight: 4}} />
                <div> {this.props.soName}</div>
              </div>
          </div>
          <div id="text"
              style={{ fontSize: 16, fontWeight: "bold", textAlign: "left", paddingTop: 2}} >
              Export SmartObject
          </div>

        </div>)
  }


  _buildConfPortsMenu() {

    const panel = (
      <div style={{ cursor: "default" }} onClick={(event) => this._panelClick(event) }>
        {this._buildHeadline()}


        <div style={{height: 15}} />
        <div style={{paddingLeft: 15}}>
          <textarea id="export-code" cols="45" rows="10" readOnly
                    style={{ color: "Gray", resize: "none", cursor: "default", fontFamily: "Roboto", fontSize: 13,
                             width: 400-45, height: 290-130}}
                    value={this.state.exportCode}>
          </textarea>
        </div>
        <div style={{height: 15}} />
        <div style={{display: "flex"}}>
          <div style={{flexGrow: 1}} />
          <button style={{textAlign: "center", marginRight: 25, fontSize: 14}}
                  onClick={()=>this._clickCopy()}>
                      Copy to clipboard
          </button>
        </div>


      </div> )

    return panel

  }


  render() {

    // Content of panel is defined here
    const view = (<div key={"content-so-export-"+this.props.soName}> {this._buildConfPortsMenu()} </div>)

    let  geometry = this.props.location;
    //const scaleFactor = this.props.location.scaleFactor;
    geometry["x"] = geometry["x"]+this.state.moveOffset.x; geometry["y"] = geometry["y"]+this.state.moveOffset.y;
    geometry["width"] = this.width; geometry["height"] = this.height;

    const panel = ( <Popup geometry={geometry} content={view}
                           id={this.props.id} elementClass={this.props.elementClass}
                           delCallback={this.props.delCallback}
                           moveCallback={this.moveCallback}
                    /> )
    return panel
  }

}

export default ConfPorts;
