import React from "react";
import { withRouter } from "react-router-dom";
//import { compose } from "redux";
//import PropTypes from "prop-types";
import actionCreators from "./../../actions/ActionCreators";
//import sMS from "./../../stores/SessionManagementStore";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
//import CssBaseline from "@material-ui/core/CssBaseline";
import cenoIoTPlatformLogo from "./../../images/CenoIoTLoge-whitetext.svg";
//import NotificationSign from "./../../components/NotificationSign";

//const drawerWidth = 140;
const logoWidth = 110;
//const logoHeight = 40;
const logoMin = logoWidth;

class DevHeader extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
    };

  }

  logoutUser() {
    actionCreators.SessionLifecycle("close", {});
    actionCreators.ActiveServiceClose()
  }


  render() {

    return (

      <div style={{display: "flex"}}>
      <AppBar
    	style={{zIndex: 3,backgroundColor: "#424242"}}
          position="fixed"
          //style={{ backgroundColor: "#424242" }}
        >

          <Toolbar variant="dense" >
            <div style={{ minWidth: logoMin, width: logoWidth, marginRight: 20, marginTop: 6 }}>
              <source srcSet={cenoIoTPlatformLogo} type="image/svg+xml" />
              <img src={cenoIoTPlatformLogo} alt="CenoLabs Logo" />
	    </div>

            <div style={{ flexGrow: 1 }} />

            <Button color="inherit" onClick={this.logoutUser}>
              Sign Out
            </Button>
          </Toolbar>
        </AppBar>
        </div>
      )}
    }


export default withRouter (DevHeader);
