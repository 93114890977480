import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import actionCreators from "./../../../../actions/ActionCreators";
import userServicesStore from "./../../../../stores/UserServicesStore";
// -------------------------------------------------------------
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import grey from "@material-ui/core/colors/grey";
//import purple from "@material-ui/core/colors/purple";
// -------------------------------------------------------------

const styles = theme => ({
  serviceListEntry: {
    borderColor: "#d7d7d7", //grey[400],
    borderStyle: "solid",
    borderWidth: 1 + "px",
    color: grey[900],
    padding: 8 + "px",
    marginLeft: 28 + "px",
    textAlign: "center"
  },
  heading: {},
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  //style for font size
  resize: {
    fontSize: 14
  }
});

class QuickAccess extends React.Component {
  _isMounted = false;

  constructor(props, context) {
    super(props, context);

    this.state = {
      servicesList: userServicesStore.getServiceList()
      //searchInput: ""
    };

    this._cE_NewServiceList = this._cE_NewServiceList.bind(this);
    this._handleSelect = this._handleSelect.bind(this);
    this._createServiceList = this._createServiceList.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._cE_NewServiceList();
    userServicesStore.on("ChangeEvent_NewServiceList", this._cE_NewServiceList);
  }

  componentWillUnmount() {
    this._isMounted = false;
    userServicesStore.removeListener(
      "ChangeEvent_NewServiceList",
      this._cE_NewServiceList
    );
  }

  _cE_NewServiceList() {
    if (this._isMounted) {
      this.setState({
        servicesList: userServicesStore.getServiceList()
      });
    }
  }

  _handleSelect(selectKey) {
    const status = "change";

    let options = {};
    if (this.state.servicesList[selectKey] == null) {
      options = {
        service_uuid: selectKey,
        service_name: selectKey
      };
    } else {
      options = {
        service_uuid: this.state.servicesList[selectKey],
        service_name: selectKey
      };
    }
    actionCreators.ActiveServiceChange(status, options);
    this.props.history.push("/servicedev");
  }

  _applyFilter(service) {
    const lowerCaseService = service.toLowerCase();
    const lowerCaseSearch = this.props.searchInput.toLowerCase();
    if (lowerCaseService.includes(lowerCaseSearch)) {
      return true;
    } else {
      return false;
    }
  }

  _createServiceList() {
    let ServiceContainer = [];
    for (let service in this.state.servicesList) {
      const showService = this._applyFilter(service);

      if (showService) {
        const entry = (
          <div
            id="service-list-entry"
            key={"div_" + service}
            onClick={key => this._handleSelect(service)}
          >
            <Button style={{ width: 100 + "%", fontSize: 11 }}>{service}</Button>
          </div>
        );
        ServiceContainer.push(entry);
      }
    }
    return ServiceContainer;
  }

  _renderQuickAccess() {
    const serviceList = this._createServiceList();
    const page = (
      <div>
        <Paper elevation={0}>
          <div style={{ paddingBottom: 10 + "px", textAlign: "center" }}>

            <Typography variant="caption" style={{fontWeight: "bold"}}>
              <div>
              Development
              </div>
              <div style={{marginTop: -3}}>
              Quick Access
              </div>
            </Typography>
          </div>
          {serviceList}
        </Paper>
      </div>
    );
    return page;
  }

  render() {
    return this._renderQuickAccess();
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(QuickAccess);
