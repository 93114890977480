import { EventEmitter } from "events";
import gLS from "./../../stores/GraphLayoutStore";

class SelectionManager extends EventEmitter {
  constructor() {
    super();


    // general selection handling
    this.selection = {};
    // entry in this.selection:
    //    {"id": item1, "id": item2, ... }
    //
    //     item = { "id": id,        // defines the item eg. soName.portName.methodName
    //              "scape": scope,  // primary or secondary  secondary only used for delete operations
    //              "type": type,    // so, connection, method, port, aggregator, pin, ...
    //            }
    //     id = string  "soname" || "soname  portname" ||
    //     scope =  "primary" || "secondary"
    //     type = so" || "connection" || "port"


    // specific port selection to display port status in so "large" view
    this.portViewSelection = {}
    // entry in this.portViewSelection:
    //
    // {soName : selectedPort}
    //
    this.setMaxListeners(50)

    this.checkSelect = this.checkSelect.bind(this);
  }

  reset() {
    this.selection = {};
    this.portViewSelection = {}
  }

  toggle(id, scope, type) {

    // a new primary element deleted all selections
    if (scope === "primary" && (Object.keys(this.selection).length > 1) ) {
      this.selection = {};
    }

    const item = { "id": id, "scope": scope, "type": type };

    if (id in this.selection) {
      this._removeItem_Id(id);
      //this.deletePortViewSelection(id, type);
      this.emit("ChangeEvent_Selection");
      return;
    }

    if (scope === "primary") {
      this._removeItem_Scope("primary");
      this._removeItem_Scope("secondary");
    }

    this.selection[id] = item;
    this.addPortViewSelection(id);
    this.emit("ChangeEvent_Selection");
    gLS.redraw()
  }

  checkSelect(id) {
    if (! (id in this.selection) ) {return [false, "" ]}
    const scope = this.selection[id].scope;
    return [true, scope]
  }

  getPrimarySelect() {
    for (const item in this.selection) {
      const entry = this.selection[item];
      if (entry.scope === "primary") { return entry }
    }
    return null;
  }

  delete_Id(id) {
    this._removeItem_Id(id);
    this.deletePortViewSelection(id);
    this.emit("ChangeEvent_Selection");
  }

  clearSelect() {
    this.selection={}
    this.emit("ChangeEvent_Selection");
  }

  getAllSelect() {

  }

  // returns all enties of type type
  getSelectFromType(type) {
    let found = [];
    for (const item in this.selection) {
      const entry = this.selection[item];
      if (entry.type === type) {found.push(entry.id)}
    }
    return found;
  }

  // returns all enties from SO so
  getSelectFromSo(so) {
    let found = [];
    for (const item in this.selection) {
      const entry = this.selection[item];
      const foundSo = item.split(" ")[1];
      if (foundSo === so) {found.push(entry)}
    }
    return found;
  }

  // returns all enties of type port from SO so
  getSelectPortFromSo(so) {
    let found = [];
    const soSelected = this.getSelectFromSo(so);
    for (const item in soSelected) {
      const entry = soSelected[item];
      if (entry.type === "port") { found.push(entry) }
    }
    return found;
  }


  _removeItem_Scope(scope) {
    let found = [];
    for (const item in this.selection) {
      const entry = this.selection[item];
      if (entry.scope === scope) {found.push(entry.id)}
    }
    // delete all found entries
    for (const item of found) {delete this.selection[item]}
  }

  _removeItem_Id(id) {
    delete this.selection[id]
  }



  // --------------------------------------------------------------------------------------
  // handle portViewSelection

  getPortViewSelection(so) {
    if ( so in this.portViewSelection) {
      return this.portViewSelection[so];
    }
    return null;
  }

  addPortViewSelection(id) {
    const idType = id.split(" ")[0];
    if (idType !== "port" && idType !== "connection") { return }

    if (idType === "port") {
      const soName = id.split(" ")[1]; const portName = id.split(" ")[2];
      this.portViewSelection[soName] = portName;
    }

    if (idType === "connection") {
      const data = id.split(" ");
      const so1 = data[1]; const port1 = data[2];
      const so2 = data[3]; const port2 = data[4];
      this.portViewSelection[so1] = port1;
      this.portViewSelection[so2] = port2;
    }
  }

  deletePortViewSelection(id, type) {
    const idType = id.split(" ")[0];
    if (idType !== "port" && idType !== "so") {return}

    if (idType === "so") {
      const soName = id.split(" ")[1]; //const portName = id.split(" ")[2];
      delete this.portViewSelection[soName];
    }

    if (idType === "port") {
      const soName = id.split(" ")[1];
      const portName = id.split(" ")[2];
      if (this.portViewSelection[soName] === portName) {
        delete this.portViewSelection[soName];
      }
    }
  }

}


const selectionManager = new SelectionManager();
export default selectionManager;


