import React from "react";
//import aSDS from "./../../stores/ActiveServiceDataStore";
import mM from "./MethodManager";
import CloseIcon from '@material-ui/icons/Close';
import SmartObjectIcon from '@material-ui/icons/CropDin';


class MethodSelector extends React.Component {

  constructor() {
    super();

    this.state = ({activeMethod: "", openMethods: {} })

    this._buildMethodPanels = this._buildMethodPanels.bind(this);
    this._cE_MMUpdate = this._cE_MMUpdate.bind(this);
  }

  componentDidMount() {
    this._cE_MMUpdate();
    mM.on("MM_dataChange", this._cE_MMUpdate );
    //aSDS.on("change", this._cE_ASDS);
  }
  componentWillUnmount() {
    mM.removeListener( "MM_dataChange", this._cE_MMUpdate );
    //aSDS.removeListener("change", this._cE_ASDS);
  }

  //_cE_ASDS() {
  //  console.log("_cE_ASDS");
  //  mM.checkConsistency();
  //}


  _cE_MMUpdate() {
    const activeMethod = mM.getActiveMethod();
    const openMethods = mM.getMethods();
    this.setState({activeMethod: activeMethod, openMethods: openMethods})
  }

  _methodClick(event, soName, methodName) {
    mM.openMethod(soName, methodName);
  }
  _closeMethodClick(event, soName, methodName) {
    event.stopPropagation();
    mM.closeMethod(soName, methodName);
  }

  _buildMethodPanels() {

    let soList = [];
    // derive SmartObjects
    for (const methodId in this.state.openMethods) {
      const soName = methodId.split(" ")[0];
      if (!soList.includes(soName)) {soList.push(soName)}
    }

    let panelContainer = [];
    let soContainer = [];
    let methodsContainer = [];
    let soPanel = <div />
    // iterate over so and build methodpanels
    for (const soName of soList) {
      for (const methodId in this.state.openMethods) {
        const tempSoName = methodId.split(" ")[0];
        const methodName = methodId.split(" ")[1];
        if (tempSoName === soName) {

          //let color = "DimGray";
          let color_bg = "WhiteSmoke"; let fontWeight = "normal"; let color_txt="Gray";
          if (methodId === this.state.activeMethod) {
            //color = "black";
            color_bg = "white"; color_txt="#175fc8"; fontWeight = "normal"
          }
          if (this.state.openMethods[methodId].changed) { fontWeight = "bold" } // display changed methods in bold

          let issueIndicator = <div />
          if (this.state.openMethods[methodId].loadStatus === "ERROR") {
            issueIndicator = ( <div style={{ backgroundColor: "red", width: 8, height: 8, marginTop: -2, marginLeft: 3, marginRight: -10,
                                       border: "1px solid white", borderRadius: 4 }} /> )
          }

          const methodPanel = (
            <div key={methodId} style={{display: "flex",
                     maxHeight: 20, height: 20,  borderRadius: 0, paddingLeft: 5, paddingRight: 3, paddingTop: 4,
                     fontFamily: "Roboto", fontSize: 12, fontWeight: fontWeight, border: "1px solid LightGray",
                     backgroundColor: color_bg, color: color_txt, marginRight: 5 }}
                 onClick={(event) => this._methodClick(event, soName, methodName) }
            >
              <div style={{paddingRight: 10, display: "flex"}} >
                {methodName}{issueIndicator}
              </div>
              <div style={{width: 15, marginTop: -2, marginBottom: 3,  paddingBottom: 0 }}
                   onClick={(event) => this._closeMethodClick(event, soName, methodName)}
              >
                <CloseIcon style={{width: 13, paddingLeft: 1, paddingRight: 2, color: color_txt}} />
              </div>
            </div> )
          methodsContainer.push(methodPanel);
        }

      }


      soPanel = (
        <div key={soName+"-panel"} style={{paddingRight: 15,
                     fontFamily: "Roboto", fontSize: 14, fontWeight: "bold",
        }} >
          <div style={{display: "flex"}} >
            <SmartObjectIcon style={{height: 15, paddingTop: 1}} />
            {soName}
          </div>
          <div style={{height: 2, backgroundColor: "DimGray", width: 100+"%", marginTop: 1, marginBottom: 5 }} />
          <div style={{display: "flex", flexDirection: "row" }}>
            {methodsContainer}
          </div>
        </div>
      )
      soContainer.push(soPanel);
      methodsContainer = []
    }

    if (soContainer.length === 0) {
      const emptyMessage = (
          <div key="no-method-selected" style={{ paddingTop: 20, paddingLeft: 20,
                        color: "DarkGray",
                        fontFamily: "Roboto", fontWeight: "normal", fontSize: 13}} >
            No method selected.
          </div>
      )
      soContainer.push(emptyMessage)
    }

    const panel = (
      <div key="methodsPanel" style={{display: "flex", flexDirection: "row", cursor: "default", userSelect: "none" }}>
        {soContainer}
      </div>)
    panelContainer.push(panel)

    return panelContainer
  }


  render() {

    const panel = this._buildMethodPanels();

    const view = (
      <div style={{height: 70}} >
      <div style={{height: 7}} />
      <div style={{overflowX: "auto", paddingBottom: 15}} >
        {panel}
      </div>
    </div>
)
    return view;
  }

}

export default MethodSelector;
