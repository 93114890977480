class InfoMessage {
  constructor() {
    // the following const define the scope a message can have
    this.SCOPE_SO = 100;
    this.SCOPE_PORT = 101;
    this.SCOPE_CONNECTION = 102;
    this.SCOPE_PIN = 103;
    this.SCOPE_METHOD = 104;

    // the following const define which word has which meaning in a message based on the scope of a message
    this.TYPE = 0;
    this.IDENTIFYER = 1;
    this.SCOPE_PORT_COMMAND = 2; // for messages with scope port the command is the 3rd word in the message
    this.SCOPE_METHOD_COMMAND = 2; // for messages with message scope the command is the 4th word in the message
    this.SCOPE_SO_COMMAND = 2; // for messages with SO scope the command is the 3rd word in the message
    this.SCOPE_PIN_COMMAND = 3;
    this.SCOPE_CONNECTION_COMMAND = 3;
  }

  processMsg(msg) {
    let parsedMsg = null;
    parsedMsg = this.parseSmartObjectScope(msg);
    return parsedMsg;
  }

  _handleCreateDeletePort(msg) {
    // message format:  info "SOnick" port added/deleted "port"
    //                   0      1      2        3          4
    const SO_NICK = 1;
    const PORT_OPERATION = 3;
    const PORT_NAME = 4;

    if (
      msg[PORT_OPERATION] === "set" ||
      msg[PORT_OPERATION] === "added" ||
      msg[PORT_OPERATION] === "deleted"
    ) {
      //console.log("Port Operation:", msg[PORT_OPERATION]);
      const platformStoreUpdate = {
        scope: "port",
        cmd: msg[PORT_OPERATION],
        so: msg[SO_NICK],
        port: msg[PORT_NAME]
      };
      return [platformStoreUpdate];
    } else {
      console.log("ERROR: Unknown Port Operation - Port", msg[PORT_OPERATION]);
      return null;
    }
  }

  _handleInlet(msg) {
    // message format:   info "SOnick" inlet set/addeded/modified "port" "inletName" "MethodName"
    //                    0      1       2            3             4         5           6
    
    const SO_NICK = 1;
    const PORT_OPERATION = 2;
    const INLET_OPERATION = 3;
    const PORT_NAME = 4;
    const INLET_NAME = 5;
    const METHOD_NAME = 6;

    if (
      msg[INLET_OPERATION] === "set" ||
      msg[INLET_OPERATION] === "added" ||
      msg[INLET_OPERATION] === "modified"
    ) {
      //console.log("Port Operation: Inlet", msg[INLET_OPERATION]);

      const platformStoreUpdate = {
        scope: "pin",
        cmd: msg[PORT_OPERATION],
        pinCmd: msg[INLET_OPERATION],
        so: msg[SO_NICK],
        port: msg[PORT_NAME],
        name: msg[INLET_NAME],
        method: msg[METHOD_NAME]
      };

      return [platformStoreUpdate];
    } else if (msg[INLET_OPERATION] === "deleted") {
      //console.log("Port Operation: Inlet", msg[INLET_OPERATION]);
      const platformStoreUpdate = {
        scope: "pin",
        cmd: msg[PORT_OPERATION],
        pinCmd: msg[INLET_OPERATION],
        so: msg[SO_NICK],
        port: msg[PORT_NAME],
        name: msg[INLET_NAME]
      };
      return [platformStoreUpdate];
    } else {
      console.log(
        "ERROR: Unknown Port Operation - Inlet ",
        msg[PORT_OPERATION]
      );
      return null;
    }
  }

  _handleOutlet(msg) {
    // message format:   info "SOnick" outlet set/addeded/modified "port" "outletName" "MethodName"
    //                    0      1       2            3              4         5           6
    const SO_NICK = 1;
    const PORT_OPERATION = 2;
    const OUTLET_OPERATION = 3;
    const PORT_NAME = 4;
    const OUTLET_NAME = 5;
    const METHOD_NAME = 6;

    if (
      msg[OUTLET_OPERATION] === "set" ||
      msg[OUTLET_OPERATION] === "added" ||
      msg[OUTLET_OPERATION] === "modified"
    ) {
      //console.log("Port Operation: Outlet", msg[OUTLET_OPERATION]);

      const platformStoreUpdate = {
        scope: "pin",
        cmd: msg[PORT_OPERATION],
        pinCmd: msg[OUTLET_OPERATION],
        so: msg[SO_NICK],
        port: msg[PORT_NAME],
        name: msg[OUTLET_NAME],
        method: msg[METHOD_NAME]
      };

      return [platformStoreUpdate];
    } else if (msg[OUTLET_OPERATION] === "deleted") {
      //console.log("Port Operation: Outlet", msg[OUTLET_OPERATION]);
      const platformStoreUpdate = {
        scope: "pin",
        cmd: msg[PORT_OPERATION],
        pinCmd: msg[OUTLET_OPERATION],
        so: msg[SO_NICK],
        port: msg[PORT_NAME],
        name: msg[OUTLET_NAME]
      };
      return [platformStoreUpdate];
    } else {
      console.log(
        "ERROR: Unknown Port Operation - outlet ",
        msg[PORT_OPERATION]
      );
      return null;
    }
  }

  _handlePortAttributes(msg) {
    // message format:   info "SOnick" portattribute set/added/modified "port" "key" "value"
    //                    0      1          2                3            4      5      6
    const SO_NICK = 1;
    const PORT_OPERATION = 2;
    const ATTRIBUTE_OPERATION = 3;
    const PORT_NAME = 4;
    const KEY = 5;
    //const VALUE = 6;

    let platformStoreUpdate = "";
    if (
      msg[ATTRIBUTE_OPERATION] === "set" ||
      msg[ATTRIBUTE_OPERATION] === "added" ||
      msg[ATTRIBUTE_OPERATION] === "modified"
    ) {
      const length = msg.length;
      const numberValues = length - 6;
      let value = "";
      for (let i = 0; i < numberValues; i++) {
        value = value + msg[6 + i] + " ";
      }

      platformStoreUpdate = {
        scope: "port",
        cmd: msg[PORT_OPERATION],
        so: msg[SO_NICK],
        port: msg[PORT_NAME],
        operation: msg[ATTRIBUTE_OPERATION],
        attribName: msg[KEY],
        value: value
      };
    }
    if (msg[ATTRIBUTE_OPERATION] === "deleted") {
      platformStoreUpdate = {
        scope: "port",
        cmd: msg[PORT_OPERATION],
        so: msg[SO_NICK],
        port: msg[PORT_NAME],
        operation: msg[ATTRIBUTE_OPERATION],
        attribName: msg[KEY]
      };
    }
    return [platformStoreUpdate];
  }

  _handleMethodAttributes(msg) {
    // message format:   info "SOnick" methodattribute set/added/modified "method" "key" "value"
    //                    0      1          2                3               4       5      6
    const SO_NICK = 1;
    const METHOD_OPERATION = 2;
    const ATTRIBUTE_OPERATION = 3;
    const METHOD_NAME = 4;
    const KEY = 5;
    //const VALUE = 6;

    //console.log("msg with port attribute scope");
    let platformStoreUpdate = "";
    if (
      msg[ATTRIBUTE_OPERATION] === "set" ||
      msg[ATTRIBUTE_OPERATION] === "added" ||
      msg[ATTRIBUTE_OPERATION] === "modified"
    ) {
      const length = msg.length;
      const numberValues = length - 6;
      let value = "";
      for (let i = 0; i < numberValues; i++) {
        value = value + msg[6 + i] + " ";
      }

      platformStoreUpdate = {
        scope: "method",
        cmd: msg[METHOD_OPERATION],
        so: msg[SO_NICK],
        message: msg[METHOD_NAME],
        operation: msg[ATTRIBUTE_OPERATION],
        attribName: msg[KEY],
        value: value
      };
    }
    if (msg[ATTRIBUTE_OPERATION] === "deleted") {
      platformStoreUpdate = {
        scope: "method",
        cmd: msg[METHOD_OPERATION],
        so: msg[SO_NICK],
        message: msg[METHOD_NAME],
        operation: msg[ATTRIBUTE_OPERATION],
        attribName: msg[KEY]
      };
    }
    return [platformStoreUpdate];
  }

  _handleConnection(msg) {
    // message format:     info "SOnick" connection set/added "port" "rSOnick:rPort" ... "rSOnick:rPort"
    //                      0      1         2         3        4          5         ...       n

    //                     info "SOnich" connection set "port" empty || nil
    //                      0      1         2       3    4         5

    const SO_NICK = 1;
    //    const OPERATION = 2;
    const CON_OPERATION = 3;
    const PORT_NAME = 4;

    if (
      msg[CON_OPERATION] === "set" ||
      msg[CON_OPERATION] === "added" ||
      msg[CON_OPERATION] === "deleted"
    ) {
      const length = msg.length;
      if (length < 6) {
        console.log("ERROR: Wrong formatted connection message.");
        msg[5] = "nil"
        //return null;
      }

      let platformStoreUpdate = [];
      const so1 = msg[SO_NICK];
      const port1 = msg[PORT_NAME];
      let cmd = msg[CON_OPERATION];
      let so2 = "";
      let port2 = "";
      let conMessage = "";
      const numberConnections = length - 5;

      const testConnectionStatusMessag = msg[5];
      if (
        testConnectionStatusMessag === "empty" ||
        testConnectionStatusMessag === "nil"
      ) {
        conMessage = {
          scope: "connection",
          cmd: cmd,
          so: so1,
          port: port1,
          connectionStatus: testConnectionStatusMessag
        };
        platformStoreUpdate.push(conMessage);
      } else {
        // if cmd == set remove all entries in database before adding received entries
        if (cmd === "set") {
          conMessage = {
            scope: "connection",
            cmd: "set",
            so: so1,
            port: port1,
            connectionStatus: "empty"
          };
          platformStoreUpdate.push(conMessage);
          cmd = "added";
        }

        for (let i = 0; i < numberConnections; i++) {
          const nextConnection = msg[5 + i];
          const destCon = nextConnection.split("::");
          so2 = destCon[0];
          port2 = destCon[1];

          conMessage = {
            scope: "connection",
            cmd: cmd,
            sourceSo: so1,
            sourcePort: port1,
            sinkSo: so2,
            sinkPort: port2
          };

          platformStoreUpdate.push(conMessage);
        }
      }
      return platformStoreUpdate;
    }
  }

  _handleMethod(msg) {
    //console.log("in _handleMethod");
    // message format:      info "SOnick" method set/added "method" "OK/NIL/ERROR"
    //                       0      1       2        3        4           5

    // message format:      info "SOnick" method deleted "method"
    //                       0      1       2       3       4
    const SO_NICK = 1;
    //    const OPERATION = 2;
    const METHOD_OPERATION = 3;
    const METHOD_NAME = 4;
    const STATUS = 5;

    const so = msg[SO_NICK];
    const cmd = msg[METHOD_OPERATION];
    const methodName = msg[METHOD_NAME];
    let platformStoreUpdate = "";

    if (msg[METHOD_OPERATION] === "set" || msg[METHOD_OPERATION] === "added") {
      platformStoreUpdate = {
        scope: "method",
        cmd: cmd,
        so: so,
        name: methodName,
        link: "",
        status: msg[STATUS]
      };
      return [platformStoreUpdate];
    }

    if (msg[METHOD_OPERATION] === "deleted") {
      platformStoreUpdate = {
        scope: "method",
        cmd: cmd,
        so: so,
        name: methodName
      };
      return [platformStoreUpdate];
    }
  }

  _handleState(msg) {
    // message format:     info "SOnick" state set "ready/reliable/cloned/joned"
    //                      0      1       2    3             4

    const SO_NICK = 1;
    const OPERATION = 2;
    const STATE_OPERATION = 3;
    const STATE = 4;

    const so = msg[SO_NICK];
    const cmd = msg[OPERATION];
    const state_cmd = msg[STATE_OPERATION];
    const state = msg[STATE];

    let platformStoreUpdate = "";
    if (msg[STATE_OPERATION] === "set") {
      platformStoreUpdate = {
        scope: "so-core",
        cmd: cmd,
        so: so,
        state_cmd: state_cmd,
        state: state
      };
      return [platformStoreUpdate];
    }
  }

  _handleServiceData(msg) {
    // message format:     info service servicedata added/modified/set "key" "value1" "value2" ...
    //                      0      1         2               3           4       5       6     ...
    const OPERATION = 2;
    const DATA_OPERATION = 3;
    const KEY = 4;

    const length = msg.length;
    const numberValues = length - 5;

    let valueContainer = [];

    for (let i = 0; i < numberValues; i++) {
      const nextValue = msg[5 + i];
      valueContainer.push(nextValue);
    }

    const platformStoreUpdate = {
      scope: "service",
      cmd: msg[OPERATION],
      dataCmd: msg[DATA_OPERATION],
      key: msg[KEY],
      data: valueContainer
    };
    return [platformStoreUpdate];
  }

  _handleUser(msg) {
    // message format:     info  service  user  added/set  "userSo_nick"  "user || admin || visitor"
    //                      0       1      2       3             4                  5

    // message format:     info  service  user  deleted  "userSo_nick"
    //                      0       1      2       3             4

    // const OPERATION = 2;
    const USER_OPERATION = 3;
    const USER_NICK = 4;
    const USER_ROLE = 5;

    let platformStoreUpdate = "";

    switch (msg[USER_OPERATION]) {
      case "set":
      case "added":
        platformStoreUpdate = {
          scope: "service",
          cmd: "user",
          userCmd: msg[USER_OPERATION],
          userNick: msg[USER_NICK],
          userRole: msg[USER_ROLE]
        };
        break;

      case "deleted":
        platformStoreUpdate = {
          scope: "service",
          cmd: "user",
          userCmd: msg[USER_OPERATION],
          userNick: msg[USER_NICK]
        };
        break;
      default:
        platformStoreUpdate = null;
    }
    return [platformStoreUpdate];
  }

  _handleAttributes(msg) {
    // new protocol implementation
    const cmd = msg[this.SCOPE_SO_COMMAND];
    const operation = msg[3];
    let platformStoreUpdate = "";

    if (
      operation === "set" ||
      operation === "added" ||
      operation === "modified"
    ) {
      //const value = msg[5];

      const length = msg.length;
      const numberValues = length - 5;

      let value = "";

      for (let i = 0; i < numberValues; i++) {
        value = value + msg[5 + i] + " ";
      }

      platformStoreUpdate = {
        scope: "so-core",
        so: msg[this.IDENTIFYER],
        cmd: cmd,
        operation: operation,
        attribName: msg[4],
        value: value
      };
    }
    if (operation === "deleted") {
      platformStoreUpdate = {
        scope: "so-core",
        so: msg[this.IDENTIFYER],
        cmd: cmd,
        operation: operation,
        attribName: msg[4]
      };
    }
    return [platformStoreUpdate];
  }

  _handleFacility(msg) {
    // message format:     info  <soNick>  facility  set  <facility>
    //                      0        1         2      3       4

    const SO_NICK = 1;
    const CMD = 2;
    const CMD_OPERATION = 3;
    const FACILITY = 4;

    const soName = msg[SO_NICK];
    const cmd = msg[CMD];
    const operation = msg[CMD_OPERATION];
    const facility = msg[FACILITY];

    const platformStoreUpdate = {
      scope: "so-core",
      so: soName,
      cmd: cmd,
      operation: operation,
      facility: facility
    };
    return [platformStoreUpdate];
  }

  _handleLocation(msg) {
    const SO_NICK = 1;
    const CMD = 2;
    const CMD_OPERATION = 3;
    const soName = msg[SO_NICK];
    const cmd = msg[CMD];
    const operation = msg[CMD_OPERATION];

    const length = msg.length;
    const numberValues = length - 4;
    
    let value = "";
    let temp = ""
    for (let i=0; i<numberValues;i++){
      temp = msg[4+i].replace(",", "")
      msg[4+i] = temp
    }
    
    for (let i = 0; i < numberValues; i++) {
      value = value + msg[4 + i] + " ";
    }

    const platformStoreUpdate = {
      scope: "so-core",
      so: soName,
      cmd: cmd,
      operation: operation,
      //name: msg[4]
      name: value
    };
    return [platformStoreUpdate];
  }

  _handleActual(msg) {
    const platformStoreUpdate = {
      scope: "so-core",
      so: msg[1],
      cmd: msg[2],
      operation: msg[3],
      name: msg[4].replace(",","")
    };
    return [platformStoreUpdate];
  }

  parseSmartObjectScope(msg) {
    //let msgScope = null;
    const cmd = msg[this.SCOPE_SO_COMMAND];
    if (cmd === "port") {
      const platformStoreUpdate = this._handleCreateDeletePort(msg);
      return platformStoreUpdate;
    } else if (cmd === "inlet") {
      const platformStoreUpdate = this._handleInlet(msg);
      return platformStoreUpdate;
    } else if (cmd === "outlet") {
      const platformStoreUpdate = this._handleOutlet(msg);
      return platformStoreUpdate;
    } else if (cmd === "portattribute") {
      const platformStoreUpdate = this._handlePortAttributes(msg);
      return platformStoreUpdate;
    } else if (cmd === "methodattribute") {
      const platformStoreUpdate = this._handleMethodAttributes(msg);
      return platformStoreUpdate;
    } else if (cmd === "connection") {
      const platformStoreUpdate = this._handleConnection(msg);
      return platformStoreUpdate;
    } else if (cmd === "method") {
      const platformStoreUpdate = this._handleMethod(msg);
      return platformStoreUpdate;
    } else if (cmd === "state") {
      const platformStoreUpdate = this._handleState(msg);
      return platformStoreUpdate;
    } else if (cmd === "type") {
      // message format:     info  <soNick>  type  set  <type>
      //                      0        1      2     3     4
      const platformStoreUpdate = {
        scope: "so-core",
        cmd: msg[2],
        so: msg[1],
        type_cmd: msg[3],
        type: msg[4]
      };
      return [platformStoreUpdate];
    } else if (cmd === "servicedata") {
      const platformStoreUpdate = this._handleServiceData(msg);
      return platformStoreUpdate;
    } else if (cmd === "user") {
      const platformStoreUpdate = this._handleUser(msg);
      return platformStoreUpdate;
    } else if (cmd === "created" || cmd === "added" || cmd === "deleted") {
      const platformStoreUpdate = {
        scope: "so-core",
        so: msg[this.IDENTIFYER],
        cmd: cmd
      };
      return [platformStoreUpdate];
    } else if (cmd === "owner") {
      // Message Format: info "SOnick" owner set "ownerSOnick"
      const platformStoreUpdate = {
        scope: "so-core",
        so: msg[this.IDENTIFYER],
        cmd: cmd,
        name: msg[4]
      };
      return [platformStoreUpdate];
    } else if (cmd === "major" || cmd === "minor") {
      // so entry was added or deleted in either major or minor SO reference
      const platformStoreUpdate = {
        scope: "so-core",
        so: msg[this.IDENTIFYER],
        cmd: cmd,
        operation: msg[3],
        name: msg[4]
      };
      return [platformStoreUpdate];
    } else if (cmd === "location") {
      const platformStoreUpdate = this._handleLocation(msg);
      return platformStoreUpdate;
    } else if (cmd === "actual") {
      const platformStoreUpdate = this._handleActual(msg);
      return platformStoreUpdate;
    } else if (cmd === "facility") {
      const platformStoreUpdate = this._handleFacility(msg);
      return platformStoreUpdate;
    } else if (cmd === "attribute") {
      const platformStoreUpdate = this._handleAttributes(msg);
      return platformStoreUpdate;
    } else {
      if (cmd !== "name" && cmd !== "fullinfo") {
        console.log("ERROR  InfoMessage: Unknown command: ", cmd);
        console.log(msg);
      }
      return [null];
    }
  }
}

const infoMessage = new InfoMessage();
export default infoMessage;
