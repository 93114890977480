import React from "react";

import DevicePanel from "./DevicePanel";
import ClusterIcon from '@material-ui/icons/DeviceHub';


class ClusterPanel extends React.Component {
    _isMounted = false;

    //constructor(props) {
    //    super(props);
    //}

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }



    _buildClusterHeader(cluster) {
        let numberDevices = 0; let onlineDevices = 0; let offlineDevices = 0;
        // eslint-disable-next-line
        for (const [uuid, data] of this.props.devices) {
            if (data.has("cluster")) {
                if (cluster === data.get("cluster")[0]) {
                    numberDevices++;
                    // eslint-disable-next-line
                    if (data.get("active") === "true") {onlineDevices++} else {offlineDevices++}
                }
            }
        }

        const bgHeader_col = this.props.selCluster === this.props.clusterName ? "rgba(0,0,255, 0.7)" : "DimGray";
        const clusterHeader = (
            <div key={cluster+"header"}>
                <div style={{height: 40, width: 100+"%", backgroundColor: bgHeader_col, marginBottom: 0}}>
                    <div style={{display: "flex", paddingTop: 5}}>
                        <div style={{width: 10}} />
                        <ClusterIcon style={{ width: 28, height: 28, color: "white" }} />
                        <div>
                            <div style={{fontFamily: "Roboto", fontSize: 10, fontWeight: "normal", color: "White", paddingLeft: 10 }}>
                                Cluster
                            </div>
                            <div style={{ fontFamily: "Roboto", fontSize: 16, fontWeight: "bold", color: "White",
                                paddingLeft: 10, marginTop: -4 }}>
                                {cluster}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{fontSize: 11, paddingBottom: 4}} >

                    <div style={{display: "flex"}} >
                        <div style={{width: 10}} />
                        <div style={{paddingLeft: 0, fontWeight: "bold"}}>Status:</div>
                        <div style={{width: 5}} />
                        <div>
                            {numberDevices} devices assigned to cluster
                        </div>
                        <div style={{width: 15}} />
                        <div>
                            {onlineDevices} devices online
                        </div>
                    </div>
                </div>
            </div>
        )
        return clusterHeader;
    }


    _buildSingleClusterTable(cluster) {

        let tableContainer = []
        const header = this._buildClusterHeader(cluster);
        tableContainer.push(header);

        // eslint-disable-next-line
        for (const [uuid, data] of this.props.devices) {

            let devicePanel = <div key={data.get("name")+"-device"} />;
            const isActive = data.get("active") === "true";
            if (cluster === data.get("cluster")[0]) {
                devicePanel = ( <DevicePanel key={data.get("name")+"-device"} deviceData={data} isActive={isActive}
                                             selDevice={this.props.selDevice} /> )
            }
            tableContainer.push(devicePanel)
        }

        return tableContainer
    }


    render() {
        const clusterTableView = this._buildSingleClusterTable(this.props.clusterName);
        return clusterTableView;
    }

}
export default ClusterPanel;
