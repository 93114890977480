import messageParser from "./MsgHandler";
import actionCreators from "./../actions/ActionCreators";
import logger from "./../components/Logging/Logger";

class WebsocketCom {
  constructor() {
    this.websocket_connection = null;
    this.com_data = null;
    this.websocket_ready = false;
    this.websocket_allowClose = false;

    this.LOG_ORIGIN = "WebsocketCom";

    logger.log(this.LOG_ORIGIN, "INFO", "Started.", "");
  }

  _createWebsocketConnection(com_data, isNewSession) {
    this.com_data = com_data;

    this.websocket_connection = new WebSocket(this.com_data["websocket_url"]);

    // update websocket state in SessionStateDb
    const websocket_status = "connecting";
    logger.log(this.LOG_ORIGIN, "DEBUG", "Websocket status: connecting.", "");
    
    if (isNewSession) {
      actionCreators.WebsocketLifecycle(websocket_status, {});
      this.com_data["previous_client_uuid"] = this.com_data["client_uuid"];
    }

    const _this = this;

    this.websocket_connection.onopen = function() {
      _this.websocket_ready = true;
      // register a route at ceno_router
      _this._sendMessage("hello", "");

      // update websocket state in SessionStateDb
      const websocket_status = "open";
      logger.log(_this.LOG_ORIGIN, "DEBUG", "Websocket status: open.", "");
      if (isNewSession) {
        actionCreators.WebsocketLifecycle(websocket_status, {});
      }
    };

    this.websocket_connection.onmessage = function(e) {
      const received_message = JSON.parse(e.data);
      if ((received_message["dest"] === _this.com_data["client_uuid"]) || (received_message["dest"] === _this.com_data["previous_client_uuid"])) {
        // TODO rename messageSource to messageType
        let messageType = "";
        if (received_message["source"] === _this.com_data["service_uuid"])
          messageType = "Service";
        else if (
          received_message["source"] === _this.com_data["userManager_uuid"]
        )
          messageType = "User";
        else if (received_message["source"] === _this.com_data["platform_uuid"])
          messageType = "Platform";
        else messageType = "OutOfBand";

        logger.log(
          _this.LOG_ORIGIN,
          "DEBUG",
          messageType + " message received.",
          e.data
        );
        messageParser.parseMessage(
          messageType,
          received_message["source"],
          received_message["payload"]
        );

        //--------------------
      } else {
        logger.log(
          _this.LOG_ORIGIN,
          "WARNING",
          "Message received. (with unknown destination address)",
          e.data
        );
      }
    };

    this.websocket_connection.onerror = function(error) {
      logger.log(_this.LOG_ORIGIN, "ERROR", "Websocket error.", error);

      // update websocket state in SessionStateDb
      const websocket_status = "error";
      actionCreators.WebsocketLifecycle(websocket_status, {});
    };

    this.websocket_connection.onclose = function() {
      logger.log(_this.LOG_ORIGIN, "INFO", "Websocket closed.", "");

      // update websocket state in SessionStateDb
      if (_this.websocket_allowClose) {
        const websocket_status = "closed";
        actionCreators.WebsocketLifecycle(websocket_status, {});
        _this.websocket_allowClose = false;
      } else {
        _this.websocket_ready = false;
        _this.establishWebsocket(_this.com_data, false)
      
      };
    }
  }

  closeWebsocket() {
    console.log("INFO: Websocket closed");
    this.websocket_allowClose = true;
    this.websocket_connection.close()
  }

  
  _sendMessage(receiver_uuid, msg) {
    let send_msg_json = "";
    try {
      const dest_uuid = receiver_uuid;
      const send_msg = {
        source: this.com_data["client_uuid"],
        dest: dest_uuid,
        payload: msg
      };
      send_msg_json = JSON.stringify(send_msg);
    } catch (err) {
      this._handleWebsocketContextMissing();
    }

    if (this.websocket_ready) {
      try {
        this.websocket_connection.send(send_msg_json);
        logger.log(this.LOG_ORIGIN, "DEBUG", "Message sent.", send_msg_json);
      } catch (err) {
        logger.log(this.LOG_ORIGIN, "ERROR", "Invalid Websocket state.", err);
      }
    } else {
      logger.log(this.LOG_ORIGIN, "ERROR", "Not ready to send message.", "");
    }
  }

  _handleWebsocketContextMissing() {
    logger.log(this.LOG_ORIGIN, "FATAL", "Websocket context missing.", "");
  }

  establishWebsocket(com_data, isNewSession) {
    this._createWebsocketConnection(com_data, isNewSession);
    return;
  }

  updateServiceUuid(service_uuid) {
    this.com_data["service_uuid"] = service_uuid;
  }

  updateClientUuid(client_uuid) {
    this.com_data["previous_client_uuid"] = this.com_data["client_uuid"];
    this.com_data["client_uuid"] = client_uuid;
  }

  sendPlatformMessage(msg) {
    try {
      const dest_uuid = this.com_data["platform_uuid"];
      this._sendMessage(dest_uuid, msg);
    } catch (e) {
      this._handleWebsocketContextMissing();
    }
  }

  sendUserMessage(msg) {
    try {
      const dest_uuid = this.com_data["userManager_uuid"];
      this._sendMessage(dest_uuid, msg);
    } catch (e) {
      this._handleWebsocketContextMissing();
    }
  }

  sendServiceMessage(msg) {
    try {
      const dest_uuid = this.com_data["service_uuid"];
      if (dest_uuid !== "") this._sendMessage(dest_uuid, msg);
      else
        logger.log(
          this.LOG_ORIGIN,
          "WARNING",
          "Websocket not connected to a service.",
          ""
        );
    } catch (e) {
      this._handleWebsocketContextMissing();
    }
  }

  sendOutOfBandMessage(msg, dest_uuid) {
    try {
      this._sendMessage(dest_uuid, msg);
    } catch (e) {
      this._handleWebsocketContextMissing();
    }
  }
}

const websocketCom = new WebsocketCom();
export default websocketCom;
