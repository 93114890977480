import dispatcher from "../dispatcher/dispatcher";

export function msgRecv(msg) {
  dispatcher.dispatch({
    type: "MSG_RECV",
    msg
  });
}

// ------------------------------------------------
// ceno_viz <-- ceno_core message interface
// the following actions are triggered depending on the message sent by ceno_viz

// Actions triggered for service related SmartObject messages
export function infoMsgRecv(msg) {
  dispatcher.dispatch({
    type: "INFO_MSG_RECV",
    msg
  });
}

//export function infoMsgServiceContextRecv(msg) {
//  dispatcher.dispatch({
//    type: "ACTION_InfoMessageServiceContextReceived",
//   msg
//  });
//}

export function respMsgRecv(msg) {
  dispatcher.dispatch({
    type: "RESP_MSG_RECV",
    msg
  });
}

// Action triggered by service related service messages
export function respServiceMsgRecv(msg) {
  dispatcher.dispatch({
    type: "RESP_SERVICE_MSG_RECV",
    msg
  });
}

export function logMsgRecv(msg) {
  dispatcher.dispatch({
    type: "LOG_MSG_RECV",
    msg
  });
}

// Actions triggered for user related messages
export function userMsgRecv(msg) {
  dispatcher.dispatch({
    type: "ACTION_UserMessageReceived",
    msg
  });
}

// action triggerd by user message 'facilities'
export function facilitiesMsgRecv(msg) {
  dispatcher.dispatch({
    type: "ACTION_FacilitiesMessageReceived",
    msg
  });
}

// Actions triggered for notification (right now only error) messages (service and user)
export function notificationMsgRecv(msg) {
  dispatcher.dispatch({
    type: "ACTION_NotificationReceived",
    msg
  });
}

// Actions triggered for notification (right now only error) messages (service and user)
export function respHistoryMsgRecv(msg) {
  dispatcher.dispatch({
    type: "ACTION_SoHistoryReceived",
    msg
  });
}

