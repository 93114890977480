import { EventEmitter } from "events";
import dispatcher from "../dispatcher/dispatcher";
//import websocketCom from "./../webcom/WebsocketCom";
//import logger from "./../components/Logging/Logger";

class NotificationsStore extends EventEmitter {
  constructor() {
    super();
    //this.LOG_ORIGIN = "ErrorMessagesStore";

    this._resetStore();

    //logger.log(this.LOG_ORIGIN, "INFO", "Initialized.", "");
  }

  getNextNotification() {
    return this.notifications.shift();
  }

  _resetStore() {
    this.notifications = [];
  }

  _processErrorNotification(msg) {
    this.notifications.push(msg);
    this.emit("ChangeEvent_NewNotification");
  }

  _processNotificationMessage(msg) {
    switch (msg.type) {
      case "error":
        this._processErrorNotification(msg);
        break;
      default: {
      }
    }
  }

  handleActions(action) {
    switch (action.type) {
      case "ACTION_NotificationReceived": {
        this._processNotificationMessage(action.msg);
        break;
      }
      default: {
      }
    }
  }
}

const notificationsStore = new NotificationsStore();
dispatcher.register(notificationsStore.handleActions.bind(notificationsStore));
export default notificationsStore;
