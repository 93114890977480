
import React from "react";

import sMS from "./../../../../stores/SessionManagementStore";// -------------------------------------------------------------
import Error from "./Error";
import Info from "./Info";

// material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";

// -------------------------------------------------------------


class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {  comError: false,
                    comSuccess: false
                 }
    this.errorMessage = ""
    this.infoMessage = ""

    this._resetPassword = this._resetPassword.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.closeInfoDialog = this.closeInfoDialog.bind(this);
    this.closeErrorDialog = this.closeErrorDialog.bind(this);
  }


 _resetPassword() {
    const tokenRequestData = sMS.getAuthUpdateData()
    const url = "https://" + tokenRequestData.url + "/api/resetpw";
    const resetUserData = { "login" : this.props.member}
    const dataSet = {"auth":tokenRequestData.reAuthData,"resetpw":resetUserData}
    const dataSet_json = JSON.stringify(dataSet)
    const isAsynchronous = true;
    let response;
    let xhr = new XMLHttpRequest();

    const _this = this;
    const user = this.props.member
    xhr.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        response = JSON.parse(xhr.responseText);
        if (response.status === "error") {
          _this.errorMessage = (<div>
                                <div>
                                The request to reset password for member {user} failed!
                                </div>
                                <div style={{height: 15}} />
                                <div>
                                Details:
                                </div>
                                <div>
                                {response.message}
                                </div>
                                </div>)
          _this.setState({comError: true})
        } else {
          _this.infoMessage = (<div>
                                <div>
                                Success: Password reset for member {user}
                                </div>
                                <div style={{height: 15}} />
                                <div>
                                New Password: {response.message}
                                </div>
                                </div>)
          _this.setState({comSuccess: true})

        }
      }
    };
    xhr.onerror = function() {
      //response = {
      //  status: "error",
      //  description: "Could not connect to api authority."
      //};
      //console.log(response);
      _this.errorMessage="Could not connect to Platform Management Server."
      _this.setState({comError: true})
    };
    xhr.open("POST", url, isAsynchronous);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.send(dataSet_json);
  }


  handleClose() {
    this.props.handle_func_closeDialog();
  }


  handleReset() {
    this._resetPassword();
    this.handleClose();
  }

  closeErrorDialog() {
    this.setState({comError: false})
    this.errorMessage=""
  }

  closeInfoDialog() {
    this.setState({comSuccess: false})
    this.infoMessage=""
  }


  render() {
    const dialog = (
      <div>

        <Error isActive={this.state.comError}
               handle_func_closeErrorDialog={this.closeErrorDialog}
               errorMessage={this.errorMessage}
        />
         <Info isActive={this.state.comSuccess}
               handle_func_closeInfoDialog={this.closeInfoDialog}
               infoMessage={this.infoMessage}
        />

        <Dialog
          open={this.props.isActive}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle> {this._renderDialogHeadline()} </DialogTitle>
          <DialogContent>
            <DialogContentText>
            Reset password for member {this.props.member}.
            </DialogContentText>
          </DialogContent>
            <div style={{ minHeight: 20 + "px" }} />
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>

            <Button onClick={this.handleReset} color="primary">
              Reset
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
    return dialog;
  }



  // -----------------------------------------------------------------------------
  _renderDialogHeadline() {
    return (
      <div id="header" style={{ display: "flex", flexDirection: "row" }}>
        <div
          id="colorblotch"
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#9c27b0",
            boxShadow: "1px 4px 7px 2px #bdbdbd",
            borderRadius: 4
          }}
        >
          <PersonIcon
            style={{
              width: 26,
              height: 26,
              color: "white",
              position: "relative",
              top: 12,
              left: 12
            }}
          />
        </div>
        <div
          id="headline"
          style={{
            flexGrow: 1,
            textAlign: "left",
            paddingTop: 7,
            paddingLeft: 15
          }}
        >
          <div id="type">
            <Typography
              variant="overline"
              style={{ fontSize: 9, color: "#757575" }}
            >
              People
            </Typography>
          </div>
          <div id="text">
            <Typography
              variant="body1"
              fontWeight="bold"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                position: "relative",
                top: -9
              }}
            >
              Reset Password
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
