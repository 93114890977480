import React from "react";

import sMS from "./../../../../stores/SessionManagementStore";
import Error from "./Error";
import Info from "./Info";
// -------------------------------------------------------------
// material-ui
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
//import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";

// -------------------------------------------------------------

class AddMember extends React.Component {
  constructor(props) {
    super(props);

    this.loginError = false;
    this.givenNameError = false;
    this.familyNameError = false;
    this.passwordError = false;
    this.passwordConfirmError = false;
    this.emailError = false;
    this.deparment = false;
    this.city = false;

    this.errorMessage = ""
    this.infoMessage = ""

    this.state = {
      login: "",
      givenName: "",
      familyName: "",
      password: "",
      passwordConfirm: "",
      email: "",
      department: "",
      city: "",
      isAdmin: false,

      loginHelpText: "Login",
      passwordHelpText: "Password",
      passwordConfirmHelpText: "Password",
      givenNameHelpText: "Members Given Name",
      familyNameHelpText: "Family Name",
      emailHelpText: "E-mail",
      departmentHelpText: "Department",
      cityHelpText: "City",

      comError: false,
      comSuccess: false
    };

    this._addUser = this._addUser.bind(this);
    this._buildAddUserDataSet = this._buildAddUserDataSet.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.closeErrorDialog = this.closeErrorDialog.bind(this);
    this.closeInfoDialog = this.closeInfoDialog.bind(this);
    this._checkInput = this._checkInput.bind(this);

    this._handleLoginChange = this._handleLoginChange.bind(this);
    this._handleAdminChange = this._handleAdminChange.bind(this);
    this._handlePasswordChange = this._handlePasswordChange.bind(this);
    this._handlePasswordConfirmChange = this._handlePasswordConfirmChange.bind(
      this
    );
    this._handleGivenNameChange = this._handleGivenNameChange.bind(this);
    this._handleFamilyNameChange = this._handleFamilyNameChange.bind(this);
    this._handleEmailChange = this._handleEmailChange.bind(this);
    this._handleDepartmentChange = this._handleDepartmentChange.bind(this);
    this._handleCityChange = this._handleCityChange.bind(this);
    this._handlePasswordConfirmChange = this._handlePasswordConfirmChange.bind(
      this
    );
  }


 _addUser() {
    const tokenRequestData = sMS.getAuthUpdateData()
    const url = "https://" + tokenRequestData.url + "/api/adduser";
    const addUserData = this._buildAddUserDataSet()
    const dataSet = {"auth":tokenRequestData.reAuthData,"adduser":addUserData}
    const dataSet_json = JSON.stringify(dataSet)
    const isAsynchronous = true;
    let response;
    let xhr = new XMLHttpRequest();

    const _this = this;
    xhr.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        response = JSON.parse(xhr.responseText);
        if (response.status === "error") {
          //console.log("ERROR");
          //console.log(response.message);
          _this.errorMessage = (<div>

                                <div>
                                The request to add member {_this.props.member} failed!
                                </div>
                                <div style={{height: 15}} />
                                <div>
                                Details:
                                </div>
                                <div>
                                {response.message}
                                </div>
                                </div>)
          _this.setState({comError: true})
        } else {
          //delete response.status
          //_this.setState({usersData: response})
          //console.log("User successfully injected into platform:", response.message);

          _this.infoMessage = (<div>
                                <div>
                                Success: Member {_this.props.member} created on Platform!
                                </div>
                                <div style={{height: 15}} />
                                <div>
                                Password: {response.message}
                                </div>
                                </div>)
          _this.setState({comSuccess: true})
        }
      }
    };
    xhr.onerror = function() {
      //response = {
      //  status: "error",
      //  description: "Could not connect to api authority."
      //};
      //console.log(response);
      _this.errorMessage="Could not connect to Platform Management Server."
      _this.setState({comError: true})
    };
    xhr.open("POST", url, isAsynchronous);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.send(dataSet_json);
  }


  _buildAddUserDataSet() {
    let admin = ""
    if (this.state.isAdmin) admin = "full"
    const dataSet =
      { "login" :               this.state.login,
        "gui_serving_router" :  "",
        "given_name" :          this.state.givenName,
        "family_name" :         this.state.familyName,
        "password" :            "server_assign", //this.state.password,
        "email" :               this.state.email,
        "department" :          this.state.department,
        "city" :                this.state.city,
        "admin_rights" :        admin
      }
    return dataSet
  }


  handleCloseDialog() {
    this.setState({
      login: "",
      password: "",
      passwordConfirm: "",
      isAdmin: false,
      givenName: "",
      familyName: "",
      email: "",
      department: "",
      city: ""
    });

    this.loginError = false;
    this.passwordError = false;
    this.passwordConfirmError = false;
    this.givenNameError = false;
    this.familyNameError = false;
    this.emailError = false;
    this.departmentError = false;
    this.cityError = false;

    this.props.handle_func_closeDialog();
  }


  handleAdd() {
    const status = this._checkInput()
    if (status === "ok") {
        this._addUser()
        this.handleCloseDialog();
    }
  }

  closeErrorDialog() {
    this.setState({comError: false})
    this.errorMessage=""
  }


  closeInfoDialog() {
    this.setState({comSuccess: false})
    this.infoMessage=""
  }

  _checkInput() {
    if (this.loginError) { return "error" }
    if (this.state.login.length === 0) {
      this.setState({loginHelpText: "Set Login Name"})
      this.loginError = true;
      return "error"
    }
/*
    if (this.passwordError || this.passwordConfirmError) {
      return "error"
    }

    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({passwordHelpText: "Set And Confirm Password"})
      this.passwordError = true;
      return "error"
    }

    if (this.state.password.length === 0) {
      this.setState({passwordHelpText: "Set Password"})
      this.passwordError = true;
      return "error"
    }
*/
    if (this.givenNameError) { return "error" }
    if (this.state.givenName.length === 0) {
      this.setState({givenNameHelpText: "Set Given Name"});
      this.givenNameError = true;
      return "error"
    }

    if (this.familyNameError) { return "error" }
    if (this.state.familyName.length === 0) {
      this.setState({familyNameHelpText: "Set Family Name"});
      this.familyNameError = true;
      return "error"
    }

    if (this.emailError) { return "error" }
    if (this.state.email.length === 0) {
      this.setState({emailHelpText: "Set E-mail Address"});
      this.emailError = true;
      return "error"
    }

    return "ok"
  }


  _handleLoginChange(event) {
    this.setState({
      login: event.target.value
    });
    // eslint-disable-next-line no-useless-escape
    const letterNumber = /^[\-0-9a-zA-Z]+$/;
    if ( !(event.target.value.match(letterNumber)) && event.target.value !== "") {
      this.loginError = true;
      this.setState({loginHelpText: "Unvalid character"})
      return
    }
    if (event.target.value === "") {
        this.loginError = true;
        this.setState({loginHelpText: "Empty name"});
        return
    }
      if (event.target.value.length < 4) {
        this.loginError = true;
        this.setState({loginHelpText: "Minimum length 4 characters"});
        return
      }
      this.loginError = false
      this.setState({loginHelpText: "Login"});
  }

  _handleAdminChange(event) {
    this.setState({ isAdmin: !this.state.isAdmin });
  }


  _handlePasswordChange(event) {
    this.setState({
      password: event.target.value
    });
    if (event.target.value.length < 8) {
      this.passwordError = true
      this.setState({passwordHelpText: "Minimum length 8 characters"});
      return
    }
    this.passwordError = false
    this.setState({passwordHelpText: "Password"})

  }

  _handlePasswordConfirmChange(event) {
    this.setState({
      passwordConfirm: event.target.value
    });

    if (event.target.value === this.state.password) {
      this.passwordConfirmError = false;
      this.setState({ passwordConfirmHelpText: "Password Confirmed" });
    } else {
      this.passwordConfirmError = true;
      this.setState({ passwordConfirmHelpText: "Mismatch" });
    }
  }

  _handleGivenNameChange(event) {
    this.setState({
      givenName: event.target.value
    });
    // eslint-disable-next-line no-useless-escape
    const letterNumber = /^[\-0-9a-zA-Z]+$/;
    if (event.target.value.match(letterNumber) || event.target.value === "") {
      this.givenNameError = false;
    } else this.givenNameError = true;
  }

  _handleFamilyNameChange(event) {
    this.setState({
      familyName: event.target.value
    });
    // eslint-disable-next-line no-useless-escape
    const letterNumber = /^[\-0-9a-zA-Z]+$/;
    if (event.target.value.match(letterNumber) || event.target.value === "") {
      this.familyNameError = false;
    } else this.familyNameError = true;
  }

  _handleEmailChange(event) {
    this.setState({
      email: event.target.value
    });
    // eslint-disable-next-line no-useless-escape
    const letterNumber = /^[\-0-9a-zA-Z@.]+$/;
    if (event.target.value.match(letterNumber) || event.target.value === "") {
      this.emailError = false;
    } else this.emailError = true;
  }

  _handleDepartmentChange(event) {
    this.setState({
      department: event.target.value
    });
    // eslint-disable-next-line no-useless-escape
    const letterNumber = /^[\-0-9a-zA-Z]+$/;
    if (event.target.value.match(letterNumber) || event.target.value === "") {
      this.departmentError = false;
    } else this.departmentError = true;
  }

  _handleCityChange(event) {
    this.setState({
      city: event.target.value
    });
    // eslint-disable-next-line no-useless-escape
    const letterNumber = /^[\-0-9a-zA-Z]+$/;
    if (event.target.value.match(letterNumber) || event.target.value === "") {
      this.cityError = false;
    } else this.cityError = true;
  }

  render() {
    const dialog = (
      <div>

        <Error isActive={this.state.comError}
               handle_func_closeErrorDialog={this.closeErrorDialog}
               errorMessage={this.errorMessage}
        />
        <Info isActive={this.state.comSuccess}
               handle_func_closeInfoDialog={this.closeInfoDialog}
               infoMessage={this.infoMessage}
        />
        <Dialog
          open={this.props.isActive}
          onClose={this.handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle> {this._renderDialogHeadline()} </DialogTitle>
          <DialogContent>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                value={this.state.login}
                onChange={this._handleLoginChange}
                style={{ width: 200 }}
                id="login-input"
                label="Login Name"
                margin="dense"
                error={this.loginError}
                helperText={this.state.loginHelpText}
              />
              <div style={{ width: 30 }} />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isAdmin}
                    onChange={this._handleAdminChange}
                    color="primary"
                    label="admin"
                  />
                }
                label="Platform Administrator"
              />
            </div>

            {/* Password Input
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                value={this.state.password}
                onChange={this._handlePasswordChange}
                style={{ width: 200 }}
                id="password-input"
                label="Enter Password"
                margin="dense"
                error={this.passwordError}
                helperText={this.state.passwordHelpText}
                type="password"
              />
              <div style={{ width: 30 }} />
              <TextField
                value={this.state.passwordConfirm}
                onChange={this._handlePasswordConfirmChange}
                style={{ width: 200 }}
                id="password-confirm"
                label="Confirm Password"
                margin="dense"
                error={this.passwordConfirmError}
                helperText={this.state.passwordConfirmHelpText}
                type="password"
              />
            </div>*/}

            {/* given and family name Input */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                value={this.state.givenName}
                onChange={this._handleGivenNameChange}
                style={{ width: 200 }}
                id="given-name-input"
                label="Given Name"
                margin="dense"
                error={this.givenNameError}
                helperText={this.state.givenNameHelpText}
              />
              <div style={{ width: 30 }} />
              <TextField
                value={this.state.familyName}
                onChange={this._handleFamilyNameChange}
                style={{ width: 200 }}
                id="family-name-input"
                label="Family Name"
                margin="dense"
                error={this.familyNameError}
                helperText={this.state.familyNameHelpText}
              />
            </div>

            <TextField
              value={this.state.email}
              onChange={this._handleEmailChange}
              style={{ width: 300 }}
              id="email-input"
              label="E-mail Address"
              margin="dense"
              error={this.emailError}
              helperText={this.state.emailHelpText}
            />

            {/* department and city Input */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                value={this.state.department}
                onChange={this._handleDepartmentChange}
                style={{ width: 200 }}
                id="department-input"
                label="Department"
                margin="dense"
                error={this.departmentError}
                helperText={this.state.departmentHelpText}
              />
              <div style={{ width: 30 }} />
              <TextField
                value={this.state.city}
                onChange={this._handleCityChange}
                style={{ width: 200 }}
                id="city-input"
                label="City"
                margin="dense"
                error={this.cityError}
                helperText={this.state.cityHelpText}
              />
            </div>

            <div style={{ height: 20 }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              Cancel
            </Button>

            <Button onClick={this.handleAdd} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
    return dialog;
  }

  // -----------------------------------------------------------------------------
  _renderDialogHeadline() {
    return (
      <div id="header" style={{ display: "flex", flexDirection: "row" }}>
        <div
          id="colorblotch"
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#9c27b0",
            boxShadow: "1px 4px 7px 2px #bdbdbd",
            borderRadius: 4
          }}
        >
          <PersonIcon
            style={{
              width: 26,
              height: 26,
              color: "white",
              position: "relative",
              top: 12,
              left: 12
            }}
          />
        </div>
        <div
          id="headline"
          style={{
            flexGrow: 1,
            textAlign: "left",
            paddingTop: 7,
            paddingLeft: 15
          }}
        >
          <div id="type">
            <Typography
              variant="overline"
              style={{ fontSize: 9, color: "#757575" }}
            >
              People
            </Typography>
          </div>
          <div id="text">
            <Typography
              variant="body1"
              fontWeight="bold"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                position: "relative",
                top: -9
              }}
            >
              Add Member to Platform
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default AddMember;
