import React from "react";
//import codeDevStore from "./../CodeDev/CodeDevStore";

class DividerNG extends React.Component {


render() {
    const view = (
          <div id="divider" style={{display: "flex", flexDirection: "column",
            }} >

          <div
// mouse events here
            style={{flexGrow: 1, backgroundColor: "Gray", width: 2,
                      borderLeftColor: "#f8f8f8", borderLeftStyle: "solid", borderLeftWidth: 0,
                      borderRightColor: "white", borderRightStyle: "solid", borderRightWidth: 3 }} />

          </div> )
    return view;
  }

}

export default DividerNG;
