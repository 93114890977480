import React from "react";
import websocketCom from "./../../../../webcom/WebsocketCom";
import sMS from "./../../../../stores/SessionManagementStore";
import fS from "./../../../../stores/FacilitiesStore";

import Button from '@material-ui/core/Button';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LedDetectionIcon from '@material-ui/icons/WbIncandescent';


class DevicePanel extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.timer = null;
        this.genesisUuid = fS.getFacilityUuidByName("genesis");

        this.state = { isExtended: false, indicatorLedActive: false }

        this._click_extendedView = this._click_extendedView.bind(this);
        this._click_compactView = this._click_compactView.bind(this);
        this._click_indicatorLight = this._click_indicatorLight.bind(this);
        this._indicatorLightOff = this._indicatorLightOff.bind(this);
        this._buildDeviceHeader = this._buildDeviceHeader.bind(this);
        this._buildDevicePanel = this._buildDevicePanel.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.timer !== null) {
            clearTimeout(this.timer); this.timer = null
            this.setState({indicatorLedActive: false});
        }
    }

    _click_extendedView() {
        this.setState({isExtended: true});
    }

    _click_compactView() {
        this.setState({isExtended: false});
    }

    _click_indicatorLight() {
        if (this.timer !== null) { clearTimeout(this.timer); this.timer = null}
        this.timer = setTimeout(this._indicatorLightOff, 30000)
        this.setState({indicatorLedActive: true})
        // TODO: send command to core
    }

    _indicatorLightOff() {
        this.timer=null;
        this.setState({indicatorLedActive: false})
    }

    _click_haltDevice() {
        const msg = this.props.deviceData.get("uuid") + " shutdown"
        websocketCom.sendOutOfBandMessage(msg, this.genesisUuid);
    }

    _click_rebootDevice() {
        const msg = this.props.deviceData.get("uuid") + " reboot"
        websocketCom.sendOutOfBandMessage(msg, this.genesisUuid);
    }
    
    _click_updateDevice() {
        const message = this.props.deviceData.get("uuid") + " exec updateCore"
        websocketCom.sendOutOfBandMessage(message, this.genesisUuid);
        
        //const msg = this.props.deviceData.get("uuid") + " reboot"
        //websocketCom.sendOutOfBandMessage(msg, this.genesisUuid);
    }

    _click_removeDevice() {
        const msg = "facility removedevice "+this.props.deviceData.get("uuid");
        //console.log(msg, this.genesisUuid)
        websocketCom.sendOutOfBandMessage(msg, this.genesisUuid);
    }



    _buildDeviceHeader() {
        const data = this.props.deviceData;
        const isActive = this.props.isActive;
        let color = this.props.isActive ? "Black" : "DarkGray";
        if (this.props.isActive && (this.props.selDevice === data.get("uuid"))) {color="Blue"}
        const userRights = sMS.getProfileData()["admin_rights"];
        let buttonDisabled = ! isActive;
        if (userRights !== "full") {buttonDisabled = true}
        const bulbColor = this.state.indicatorLedActive ? "SeaGreen" : "LightGray"
        const bg_col = this.props.selDevice === data.get("uuid") ? "rgba(0,0,255, 0.04)" : "White"

        let extendButton = (
            <ArrowRightIcon style={{width: 35, height: 35, marginTop: 0, color: color}}
                            onClick={ () => this._click_extendedView() } /> )

        if (this.state.isExtended) {
            extendButton = (<ArrowDropDownIcon style={{width: 35, height: 35, marginTop: 0, color: color}}
                                            onClick={ () => this._click_compactView() } />)
        }

        let showPerfInd = <div />
        let manageButtons
        let status = <div />

        if (isActive) {
            let health = "";
            switch (data.get("devcon").get("status")) {
                case "green":
                    health = "ok";
                    break;
                case "yellow":
                    health = "device loaded";
                    break;
                case "red":
                    health = "performance issues on device";
                    break;
                default:
                    break;
            }

            const updateReady = data.get("coreupdateready");
            const swRelease = data.get("coreversion");
            let updateIndicator = <div/>
            //console.log("updatedata:", updateReady, swRelease)
            if ( (updateReady !== swRelease) && (updateReady !== "unknown") && (updateReady !== "error")
                 && (!this.state.isExtended) ) {
                updateIndicator =
                    <div style={{paddingLeft: 50, fontWeight: "bold", color: "Red"}}>New Platform Release available!</div>
            }


                status = <div style={{paddingBottom: 5}} >
            <div style={{display: "flex", fontFamily: "Roboto", fontSize: 12, fontWeight: "normal", color: "Black", marginTop: -12, paddingBottom: 2}} >
                <div style={{width: 50}} />
                <div style={{fontWeight: "bold"}} >Status: </div>
                <div style={{width: 5}} />
                <div style={{display: "flex"}}>
                    <div style={{width: 10, height: 10, marginTop: 3, backgroundColor: data.get("devcon").get("status")}} />
                    <div style={{width: 5}}/>
                    <div>{health}</div>
                </div>
            </div>

                {updateIndicator}
</div>
            showPerfInd = <div>{ this._buildPerformanceIndicator() } </div>
            manageButtons = (
                <div style = {{display: "flex"}} >
                    <Button size="small" color="primary" disabled={buttonDisabled}
                            style={{marginTop: 5, maxHeight: 30}}
                            onClick={() => this._click_haltDevice() } >
                        Halt
                    </Button>
                    <div style={{width: 0}} />
                    <Button size="small" color="primary" disabled={buttonDisabled}
                    style={{marginTop: 5, maxHeight: 30}}
                    onClick={() => this._click_rebootDevice() } >
                        Reboot
                    </Button>
                </div> )
            manageButtons = ( <div /> )
        } else {
            let removeButtonEnabled = false
            if (userRights !== "full") { removeButtonEnabled = true}
                manageButtons = (
                    <div style = {{display: "flex"}} >
                        <Button size="small" color="secondary" disabled={removeButtonEnabled}
                                style={{marginTop: 5, maxHeight: 30}}
                                onClick={() => this._click_removeDevice() } >
                            Remove
                        </Button>
                    </div> )
        }

        let ledIcon = <div />
        const role = data.get("role");
        if (role.includes("raspi") ) {
            ledIcon = (
                <div>
                <LedDetectionIcon style={{width: 18, color: bulbColor}}
                                  onClick = {() => this._click_indicatorLight() }/>
                </div> )
        }


        //const devName_col = this.props.selDevice === data.get("uuid") ? "Blue" : "Black";

        const deviceHeader = (
            <div key={data.get("name")+"-header"} style={{backgroundColor: bg_col}} >

                <div style={{display: "flex", paddingTop: 15}} >
                    <div style={{width: 10}} />

                    {extendButton}

                    <div style={{width: 5}} />
                    <div>
                        <div style={{fontFamily: "Roboto", fontSize: 10, fontWeight: "normal", color: color, paddingLeft: 0 }}>
                            Device
                        </div>

                        <div style={{display: "flex"}}>

                        <div style={{ fontFamily: "Roboto", fontSize: 17, fontWeight: "bold", color: color,
                            paddingLeft: 0, marginTop: -4 }}>
                            {data.get("name")}
                        </div>
                        <div style={{width: 7}} />
                        {ledIcon}
                        </div>
                    </div>
                    <div style={{flexGrow: 1}} />
                    <div style={{paddingTop: 0}} >
                        {showPerfInd}
                    </div>
                    <div style={{flexGrow: 1}} />

                    {manageButtons}
                    <div style={{width: 15}} />
                </div>
                {status}

            </div>)

        return deviceHeader;
    }


    _buildDevicePanel() {
        const data = this.props.deviceData;
        const isActive = this.props.isActive;
        let devicePanel = [];
        const bg_col = this.props.selDevice === data.get("uuid") ? "rgba(0,0,255, 0.04)" : "White"

        const statusPadding = isActive ? -23 : 0 ;
        const deviceHeader = this._buildDeviceHeader();
        devicePanel.push(deviceHeader);

        if (!isActive) {    // Device is offline
            const deviceStatus = (
                <div key={data.get("name")+"-body-not-active"} style={{marginTop: statusPadding, paddingBottom: 5, backgroundColor: bg_col}}>
                <div style={{display: "flex", fontFamily: "Roboto", fontSize: 12, fontWeight: "normal", color: "DarkGray"}} >
                    <div style={{width: 50}} />
                    <div style={{fontWeight: "bold"}} >Status: </div>
                    <div style={{width: 5}} />
                    <div>Device offline.</div>
                </div>
                </div> )

            devicePanel.push(deviceStatus);

            const divider = (
                <div key={data.get("name")+"-divider-offline"} style={{width: 100+"%", borderTop: "1px solid Gray"}} />
            )
            devicePanel.push(divider)

            return devicePanel
        }


        if ( ! this.state.isExtended) {


            const divider = (
                <div key={data.get("name")+"-divider-bot1"} style={{width: 100+"%", borderTop: "1px solid Gray"}} />
            )
            devicePanel.push(divider)

            return devicePanel
        }



        const deviceRole = data.get("role")[0];
        let hardware; let role;
        switch (deviceRole) {
            case "dockercore":
                hardware="CenoLabs Virtual Cloud Device"
                role="Processing"
                break;
            case "raspicore":
                hardware="CenoLabs Compute Node";
                role="Processing";
                break;
            case "raspicoreroute":
                hardware="CenoLabs Compute Node";
                role="Cluster Gateway & Processing";
                break;
            default: break;
        }
        const hw =
            <div key={data.get("name")+"-hw-info"} style={{display: "flex", paddingTop: 0, paddingBottom: 0, backgroundColor: bg_col}}>
                <div style={{flexGrow: 1}}/>
                <div style={{fontFamily: "Roboto", fontSize: 12, fontWeight: "bold"}}>Type:</div>
                <div style={{width: 5}} />
                <div style={{fontFamily: "Roboto", fontSize: 12, fontWeight: "normal"}}>{hardware}</div>
                <div style={{width: 30}}/>
                <div style={{fontFamily: "Roboto", fontSize: 12, fontWeight: "bold"}}>Cluster role:</div>
                <div style={{width: 5}} />
                <div style={{fontFamily: "Roboto", fontSize: 12, fontWeight: "normal"}}>{role}</div>
                <div style={{flexGrow: 1}}/>
            </div>
        devicePanel.push(hw);



        const uuid =
            <div  key={data.get("name")+"-uuid"} style={{display: "flex", paddingTop: 0, paddingBottom: 0, backgroundColor: bg_col}}>
                <div style={{flexGrow: 1}}/>
                <div style={{fontFamily: "Roboto", fontSize: 12, fontWeight: "bold"}}>Identifier:</div>
                <div style={{width: 5}} />
                <div style={{fontFamily: "Roboto", fontSize: 12, fontWeight: "normal"}}>{data.get("uuid")}</div>
                <div style={{flexGrow: 1}}/>
            </div>
        devicePanel.push(uuid);


        //const perfIndicator = this._buildPerformanceIndicator();
        //devicePanel.push(perfIndicator);

        const swHeader = (
            <div key={data.get("name")+"-sw-header"} style={{paddingTop: 12, backgroundColor: bg_col}} >
                <div style={{display: "flex", fontFamily: "Roboto", fontSize: 12, fontWeight: "bold"}}>
                    <div style={{flexGrow: 1}}/>
                    Software management
                    <div style={{flexGrow: 1}}/>
                </div>
            </div> )
        devicePanel.push(swHeader)

        //const userRights = sMS.getProfileData()["admin_rights"];

        const swReleasePanel =
                <div key={data.get("name")+"-sw-release"} style={{display: "flex", paddingTop: 0, paddingBottom: 0, backgroundColor: bg_col}}>
                    <div style={{flexGrow: 1}}/>
                    <div style={{fontFamily: "Roboto", fontSize: 12, fontWeight: "normal"}}>Installed Platform Release:</div>
                    <div style={{width: 5}} />
                    <div style={{fontFamily: "Roboto", fontSize: 12, fontWeight: "normal"}}>Version {data.get("coreversion")}</div>
                    <div style={{flexGrow: 1}}/>
                </div>
        devicePanel.push(swReleasePanel);


        const updateReady = data.get("coreupdateready");
        const swRelease = data.get("coreversion");
        if ( (updateReady !== swRelease) && (updateReady !== "unknown") && (updateReady !== "error") ) {
            const swUpdate = (
                <div key={data.get("name")+"-sw-update"} style={{display: "flex", paddingTop: 10, paddingBottom: 10, backgroundColor: bg_col}}>
                    <div style={{flexGrow: 1}}/>
                    <div style={{fontFamily: "Roboto", fontSize: 13, fontWeight: "bold", color: "Red", paddingTop: 8}}>
                        New Platform Release available: Version {updateReady}</div>
                    

                <div style={{width: 20}}/>
                    <Button size="small" color="secondary" variant="contained"
                            style={{}}
                            onClick={() => this._click_updateDevice() } >
                        UPDATE
                    </Button>
                <div style={{flexGrow: 1}}/>
                </div>
            );
            devicePanel.push(swUpdate);
        }

        const divider = (
            <div key={data.get("name")+"-divider-full"} style={{width: 100+"%", borderTop: "1px solid Gray", marginTop: 5}} />
        )
        devicePanel.push(divider)
        return devicePanel;
    }

    _buildPerformanceIndicator() {
        const data = this.props.deviceData;

        return (
            <div key={data.get("name")+"-performance"} style={{marginBottom: 15}} >
                <div
                    style={{display: "flex", fontFamily: "Roboto", fontSize: 12, fontWeight: "bold", paddingBottom: 3}}>
                    <div style={{flexGrow: 1}}/>
                </div>

                <div style={{display: "flex"}}>
                    <div style={{display: "flex", flexDirection: "column", textAlign: "center"}}>
                        <div style={{ fontFamily: "Roboto", fontSize: 15, fontWeight: "bold"}}>
                            {data.get("devcon").get("utilization")[0]}
                        </div>
                        <div style={{fontFamily: "Roboto", fontSize: 9, fontWeight: "normal", marginTop: -2}}>
                            Scheduled SOs
                        </div>
                    </div>
                    <div style={{width: 15}}/>
                    <div style={{display: "flex", flexDirection: "column", textAlign: "center"}}>
                        <div style={{fontFamily: "Roboto", fontSize: 15, fontWeight: "bold"}}>
                            {Math.round(data.get("devcon").get("utilization")[1] * 1000000) / 1000}
                        </div>
                        <div style={{fontFamily: "Roboto", fontSize: 9, fontWeight: "normal", marginTop: -2}}>
                            Avg. wait time [ms]
                        </div>
                    </div>
                    <div style={{width: 15}}/>
                    <div style={{display: "flex", flexDirection: "column", textAlign: "center"}}>
                        <div style={{fontFamily: "Roboto", fontSize: 15, fontWeight: "bold"}}>
                            {data.get("devcon").get("utilization")[2]}
                        </div>
                        <div style={{fontFamily: "Roboto", fontSize: 9, fontWeight: "normal", marginTop: -2}}>
                            Proc. events [1/s]
                        </div>
                    </div>
                    <div style={{flexGrow: 2}}/>
                </div>
            </div>

        )
    }


    render() {
        const devicePanel = this._buildDevicePanel();
        return devicePanel;
    }

}
export default DevicePanel;
