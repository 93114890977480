import React from "react";

class InletMethod  extends React.Component {
  constructor() {
    super();

    this.state = { color: "black",
                   color_bg: "transparent" };

    this._toggleColor = this._toggleColor.bind(this);
  }

  _toggleColor() {
    const newColor = ( this.state.color === "black") ? "black" : "black";
    this.setState({color: newColor})
    let newColor_bg = ( this.state.color_bg === "transparent") ? "WhiteSmoke" : "transparent";
    if (this.props.iconName === "delete" && newColor_bg === "WhiteSmoke") {
      newColor_bg = "rgba(220,20,60,0.15)";
    }
    this.setState({color_bg: newColor_bg})
  }

  render() {

    const panel = (
      <div style={{display: "flex", flexDirection: "row", alignItems: "center",
                   color: this.state.color, backgroundColor: this.state.color_bg,
                   marginRight: 1, paddingTop: 2}}
           onMouseEnter = { () => this._toggleColor() }
           onMouseLeave = { () => this._toggleColor() }
           onClick = { () => this.props.callback(this.props.text)} >
        <div style={{width: 10}} />
        <div style={{overflow: "hidden", height: 20}} >{this.props.text}</div>
        <div style={{width: 10}} />
      </div>
    )
    return panel
  }

}

export default InletMethod;

