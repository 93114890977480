import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import actionCreators from "./../../../../actions/ActionCreators";
import websocketCom from "./../../../../webcom/WebsocketCom.js";
import userServicesStore from "./../../../../stores/UserServicesStore";

// -------------------------------------------------------------
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Switch from '@material-ui/core/Switch';
//import FormControl from "@material-ui/core/FormControl";
//import Select from "@material-ui/core/Select";
//import InputLabel from "@material-ui/core/InputLabel";
//import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ListIcon from "@material-ui/icons/List";

// -------------------------------------------------------------

const styles = theme => ({
  root: {
    //marginTop: theme.spacing.unit * 3,
    overflowY: "auto"
  },
  serviceInputFormControl: {
    margin: theme.spacing.unit,
    minWidth: 200
  },
  table: {
    minWidth: 400
  },
  cellHeader: {
    fontSize: 18
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
});

class Dashboard extends React.Component {
  _isMounted = false;
  _hasEntry = false;

    _loggingLevels = {
        Off: "off",
        Info: "info",
        Warning: "warn",
        Error: "error",
        Fatal: "fatal"
    };

  constructor(props, context) {
    super(props, context);



    this.state = {
      logEntries: userServicesStore.getLogs(),
      serviceLogEntries: null,
      serviceList: userServicesStore.getServiceList(),
      selectedService: "",
        serviceListHeight: 0
    };

    this._cE_NewServiceList = this._cE_NewServiceList.bind(this);
    this._cE_UserLogUpdate = this._cE_UserLogUpdate.bind(this);
    this._cE_NewServiceList = this._cE_NewServiceList.bind(this);
    this._buildLogPanel = this._buildLogPanel.bind(this);
    this._buildIntroSection = this._buildIntroSection.bind(this);
    this._buildServiceList = this._buildServiceList.bind(this);
    this._switchServiceLogging = this._switchServiceLogging.bind(this);
    this._click_serviceSelect = this._click_serviceSelect.bind(this);
    this._handleChange = this._handleChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    //this._cE_UserLogUpdate();
    userServicesStore.fullLogRefresh();
    this._cE_NewServiceList();
    userServicesStore.on("ChangeEvent_UserLogUpdate", this._cE_UserLogUpdate);
    userServicesStore.on("ChangeEvent_NewServiceList", this._cE_NewServiceList);
      userServicesStore.on("ChangeEvent_LogLevelUpdate", this._cE_NewServiceList);
  }

  componentWillUnmount() {
    this._isMounted = false;
    userServicesStore.removeListener("ChangeEvent_UserLogUpdate", this._cE_UserLogUpdate);
    userServicesStore.removeListener("ChangeEvent_NewServiceList", this._cE_NewServiceList);
    userServicesStore.removeListener("ChangeEvent_LogLevelUpdate", this._cE_NewServiceList);
  }

  _cE_NewServiceList() {
    if (this._isMounted) {
        const sL = userServicesStore.getServiceList();
        let height = sL.length*40;
        if (height > 180) {height=180}
        this.setState({serviceListHeight: sL.length*32})
      this.setState({ serviceList: userServicesStore.getServiceList() });
    }
  }

  _cE_UserLogUpdate() {
    if (this._isMounted) {
      this.setState({ logEntries: userServicesStore.getLogs() });
    }
  }

  _handleChange(event) {
    const serviceName = event.target.value;
    this.setState({ selectedService: serviceName });
  }

  _deleteLogButtonPressed(event, service) {
    event.stopPropagation();

    if (service === "") service = "all";
    userServicesStore.clearLogs();
    //userServicesStore.fullLogRefresh();
    const chatMsg = "dellog " + service;
    websocketCom.sendUserMessage(chatMsg);

    this._cE_UserLogUpdate();
  }

  _buildHeader() {
    return <div> {this._renderHeadline()} </div>;
  }

  _buildIntroSection() {
    //const { classes } = this.props;


    //let button = <div />;
    //if (this.state.selectedService !== "") {
    //  button = (
    //    <div style={{ paddingLeft: 50 + "px" }}>
    //      <Button
    //        variant="contained"
    //        id="inputbutton"
    //        color="secondary"
    //        size="small"
    //        onClick={this._deleteLogButtonPressed.bind(this)}
    //      >
    //        Delete History
    //      </Button>
    //    </div>
    //  );
    //}


      const serviceListHeight = 180;


      const introSection = (
      <div>
        <div
          id="frame"
          style={{
            backgroundColor: "white",
            border: "1px solid bdbdbd",
            boxShadow: "0px 0px 8px 0px #bdbdbd",
            width: this.width,
            maxWidth: this.width,

            maxHeight: serviceListHeight,
            borderRadius: 0,
            marginTop: 15,
            paddingBottom: 0,
              overflowY: "scroll"
          }}
        >

          { this._buildServiceList() }

        </div>
      </div>
    );
    return introSection;
  }

  _switchServiceLogging(event, service, newState) {
      event.stopPropagation();
      console.log("service switch:", service, newState)
      if (newState==="on") {
          const msg = "registerlog " + service + " error";
          websocketCom.sendUserMessage(msg);
      } else {
          const msg = "deregisterlog " + service;
          websocketCom.sendUserMessage(msg);
          this.setState({ selectedService: ""})
      }
  }

  _click_serviceSelect(service) {
      this.setState({ selectedService: service });
  }

    _click_loglevelSelect(event, service) {
      event.stopPropagation();
        const msg = "registerlog " + service + " " + event.target.value;
        websocketCom.sendUserMessage(msg);
    }

    _buildLogSelectContainer() {
        const container = [];

        for (const key in this._loggingLevels) {
            const level = this._loggingLevels[key]
            const entry = <option key={level+"-entry"} value={level} > {key} </option>
            container.push(entry)
        }
        return container;
    }

  _buildServiceList() {

    const currentServiceList = this.state.serviceList;
    let ListContainer = [];
    for (let service in currentServiceList) {
        const loglevel = userServicesStore.getServiceLogLevel(service)
        const bg_color = this.state.selectedService === service ? "rgba(0,0,255, 0.04)" : "White";

        let listItem = <div />

        if (loglevel !== "off") {
            const numberLogs = userServicesStore.getNumberLogs(service)
            let warningBadge = <div />
            let errorBadge = <div />
            let clearButton = <div />

            if (numberLogs[2]) {
                warningBadge = ( <Badge badgeContent={numberLogs[2]} color="primary" style={{size:"small"}} > </Badge> )
                warningBadge = (
                    <div style={{backgroundColor: "Blue", color: "White", width: 14, height: 14, borderRadius: 7,
                                             fontFamily: "Roboto", fontSize: 11, fontWeight: "bold", textAlign: "center"}}>
                    {numberLogs[2]}
                    </div> )
            }
            if (numberLogs[3]) {
                errorBadge = ( <Badge badgeContent={numberLogs[3]} color="secondary" /> )
                errorBadge = (
                    <div style={{backgroundColor: "Red", color: "White", width: 14, height: 14, borderRadius: 7,
                        fontFamily: "Roboto", fontSize: 11, fontWeight: "bold", textAlign: "center"}}>
                        {numberLogs[3]}
                    </div>)
            }
            if (numberLogs[1]) {
                if (numberLogs[1] > 0) {
                    clearButton = (
                        <div style={{marginTop: -1, maxHeight: 18}} >
                            <Button id="inputbutton" color="secondary" size="small"
                                    onClick={(event) => this._deleteLogButtonPressed(event, service)}
                            >
                                Clear History
                            </Button>
                        </div>
                    )
                }
            }

            listItem = (
                <div key={service+"-switch"} onClick={ () => {this._click_serviceSelect(service) } }>
                    <div style={{display: "flex", paddingTop: 7, paddingBottom: 7, backgroundColor: bg_color, cursor: "pointer"}}>
                        <div style={{width: 20}} />
                        <Switch checked={true} size="small" color="primary" name={service} id={service}
                                onChange={(event) => {this._switchServiceLogging(event, service, "off")} }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <div style={{width: 15}} />
                        <div style={{minWidth: 170, display: "flex"}} >
                        <div style={{fontFamily: "Roboto", fontSize: 17, fontWeight: "normal", color: "Black", cursor: "pointer"}}>
                        {service}
                        </div>
                        <div style={{width: 3}} />
                        <div style={{paddingTop: 0}} >{warningBadge}</div>
                        <div style={{width: 3}} />
                        <div style={{paddingTop: 0}}>{errorBadge}</div>
                        </div>
                        <div style={{width: 60}} />


                        <select name="log-level" id="log-level" value={loglevel} style={{width: 80, fontSize: 14 }}
                                onChange={(event) => this._click_loglevelSelect(event, service)}>
                            {this._buildLogSelectContainer()}
                        </select>
                        <div style={{width: 30}} />
                        {clearButton}
                    </div>

                    <div style={{ borderBottom: "1px solid LightGray", width: 100+"%"}} />
                </div>
                    )
        } else {
            listItem = (
                <div key={service+"-switch"} >
                <div style={{display: "flex", paddingTop: 7, paddingBottom: 7}} >
                    <div style={{width: 20}} />
                    <Switch checked={false} size="small" color="primary" name={service} id={service} key={service}
                            onChange={(event) => {this._switchServiceLogging(event, service, "on")} }
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <div style={{width: 15}} />
                    <div style={{fontFamily: "Roboto", fontSize: 18, fontWeight: "regular", color: "LightGray"}}>
                        {service}
                    </div>

                </div>
                    <div style={{ borderBottom: "1px solid LightGray", width: 100+"%"}} />
                </div>
            )

        }


        //if (loglevel !== "off") {
        //    ListContainer.unshift(listItem);
        //} else {
            ListContainer.push(listItem);
        //}
    }
      return ListContainer
  }



  _buildLogPanel() {
      const height = window.innerHeight - 180 - 180;

    let panelContainer = [];

    for (let oneDayLogs of this.state.logEntries) {
      if (oneDayLogs[0] !== "seqNumber") {
        panelContainer.unshift(this._buildOneDayTable(oneDayLogs));
      }
    }

    let panelView = <div />;
    if (this.state.selectedService === "") {
      panelView = (
        <Paper style={{ padding: 20, marginTop: 20 }}>
          <Typography variant="h5" style={{ color: "#bdbdbd" }}>
            <div>Select a service to view its logging history!</div>
          </Typography>
        </Paper>
      );
      return panelView;
    }
    if (this._hasEntry === false) {
      panelView = (
        <Paper style={{ padding: 20, marginTop: 20 }}>
          <Typography variant="h5" style={{ color: "#bdbdbd" }}>
            <div>There are no logging events for this service!</div>
          </Typography>
        </Paper>
      );
      return panelView;
    }

    panelView = (
      <Paper style={{ padding: 10, marginTop: 20 , height: height}}>
        <div
          style={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
            overflowY: "scroll",
            height: height-20

          }}
        >
          <div style={{ paddingLeft: 10 + "px" }}>
            <div>{panelContainer}</div>
          </div>
        </div>
      </Paper>
    );
    this._hasEntry = false;
    return panelView;
  }

  logEntrySelected(service, uuid) {
    const status = "change";
    const options = {
      service_uuid: uuid,
      service_name: service
    };
    actionCreators.ActiveServiceChange(status, options);
    this.props.history.push("/servicedev");
  }

  _buildTableEntries(logs) {
    let counter = 0;
    let dayContainer = [];
    for (let logEntry of logs) {
      if (
        logEntry[1].get("service") ===
        this.state
          .selectedService /*||
        this.state.selectedService === "" */
      ) {
          const logLevel = logEntry[1].get("level");
          const bg_color = (logLevel === "error" || logLevel === "fatal") ? "rgba(255,0,0, 0.02)" : "White";

        const entry = (
          <TableRow
            key={logEntry[1].get("time") + String(counter)}
            style={{ height: 16, backgroundColor: bg_color}}
          >
            <TableCell component="th" scope="row" style={{ minWidth: 80}}>
              <Typography variant="body2">
                {logEntry[1].get("level")}
              </Typography>
            </TableCell>

            <TableCell
              align="left"
              style={{ minWidth: 80 + "px", cursor: "pointer" }}
              onClick={() =>
                this.logEntrySelected(
                  logEntry[1].get("service"),
                  logEntry[1].get("uuid")
                )
              }
            >
              <Typography variant="body2">
                {logEntry[1].get("service")}
              </Typography>
            </TableCell>

            <TableCell align="left" style={{ minWidth: 80 + "px" }}>
              <Typography variant="body2">{logEntry[1].get("so")}</Typography>
            </TableCell>

            <TableCell align="left" style={{ minWidth: 80 + "px" }}>
              <Typography variant="body2">{logEntry[1].get("time")}</Typography>
            </TableCell>

            <TableCell align="left" style={{ minWidth: 80 + "px" }}>
              <Typography variant="body2">{logEntry[1].get("data")}</Typography>
            </TableCell>
          </TableRow>
        );

        counter++;
        dayContainer.push(entry);
      }
    }
    if (counter > 0) {
      this._hasEntry = true;
    }
    return dayContainer;
  }

  _buildOneDayTable(oneDayLogs) {
    const day = oneDayLogs[0];
    const logs = this._buildTableEntries(oneDayLogs[1]);

    const drawTable = (
      <div key={"loglist" + day} style={{ paddingRight: 20 + "px" }}>
        <Paper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: "#616161", height: 24 }}>
                <TableCell>
                  <Typography variant="body1" style={{ color: "white" }}>
                    Level
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" style={{ color: "white" }}>
                    Service
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" style={{ color: "white" }}>
                    SmartObject
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" style={{ color: "white" }}>
                    time
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" style={{ color: "white" }}>
                    Message
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{logs}</TableBody>
          </Table>
        </Paper>
      </div>
    );

    let tableView = null;
    if (logs.length > 0) {
      tableView = (
        <div key={day} style={{ paddingTop: 25 + "px" }}>
          <Typography variant="h6">{day}</Typography>
          <div style={{ minHeight: 10 + "px" }} />
          {drawTable}
        </div>
      );
    } else {
      tableView = <div key={day + "_empty"} />;
    }

    return tableView;
  }

  render() {
    const introSection = this._buildIntroSection();
    const logPanel = this._buildLogPanel();
    const header = this._buildHeader();

    const page = (
      <div>
        <div id="frame-header"
             style={{
               backgroundColor: "white",
               border: "1px solid bdbdbd",
               boxShadow: "0px 0px 8px 0px #bdbdbd",
               borderRadius: 9,
               marginTop: 15,
               marginBottom: 25
             }} >
        {header}
        </div>


          {introSection}

      <div style={{marginTop: 10}}>
        {logPanel}
      </div>
      </div>
    );

    return page;
  }

  _renderHeadline() {
    return (
      <div id="header" style={{ display: "flex", flexDirection: "row" }}>
        <div
          id="colorblotch"
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#ff5722",

            position: "relative",
            top: -15,
            left: 20,
            boxShadow: "1px 4px 7px 2px #bdbdbd",
            borderRadius: 4
          }}
        >
          <ListIcon
            style={{
              width: 26,
              height: 26,
              color: "white",
              position: "relative",
              top: 12,
              left: 12
            }}
          />
        </div>
        <div
          id="headline"
          style={{
            flexGrow: 1,
            textAlign: "left",
            paddingTop: -20,
            paddingLeft: 35
          }}
        >
          <div id="type">
            <Typography
              variant="overline"
              style={{ fontSize: 9, color: "#757575" }}
            >
              Dashboard
            </Typography>
          </div>
          <div id="text">
            <Typography
              variant="body1"
              fontWeight="bold"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                position: "relative",
                top: -9
              }}
            >
              Offline Logging History
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withRouter, withStyles(styles))(Dashboard);
