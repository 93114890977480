import React from "react";
import { EventEmitter } from "events";
import aSDS from "./../../stores/ActiveServiceDataStore";
import ConfSO from "./ConfSO";
import ContextSoDead from "./ContextSoDead";
import ContextPort from "./ContextPort";
import ContextInlet from "./ContextInlet";
import ContextOutlet from "./ContextOutlet";
import ContextMethod from "./ContextMethod";
import ConfInlet from "./ConfInlet";
import ConfOutlet from "./ConfOutlet";
import ConfPorts from "./ConfPorts";
import AddPort from "./AddPort";
import AddMethod from "./AddMethod";
import AddInlet from "./AddInlet";
import AddOutlet from "./AddOutlet";
import SetMapping from "./SetMapping";
import AddSnapshot from "./AddSnapshot";
import RestoreSnapshot from "./RestoreSnapshot";
import SoStats from "./SoStats";
import PortStats from "./PortStats";
import MethodStats from "./MethodStats";
import ExportSo from "./ExportSo";
//import CancelIcon from '@material-ui/icons/Cancel';

class PopupManager extends EventEmitter {

  constructor() {
    super();

    this.activePopups = {};

    this._checkOpen = this._checkOpen.bind(this);
    this.deletePopup = this.deletePopup.bind(this);
  }

  _checkOpen(soName, port, type) {
    for (const id in this.activePopups) {
      const popup = this.activePopups[id];
      if (popup.soName === soName && popup.port === port && popup.type === type) {return true}
    }
    return false;
  }


  addPopup(soName, port, location, type, color) {

    // only one instance of each popup should be open
    const isOpen = this._checkOpen(soName, port, type);
    if (isOpen) {return}

    const id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
    let panel = null;
    let elementClass = null;

    switch(type) {

      case "confso":
        elementClass = "menu";
        panel = <ConfSO key={ "conf-so-" + id}
                        location={location}
                        soName={soName}
                        color={color}
                        id={id}
                        delCallback={this.deletePopup}
                        elementClass={elementClass}/>
        break;

      case "contextsodead":
        elementClass = "menu";
        panel = <ContextSoDead key={ "context-so-dead-" + id}
                        location={location}
                        soName={soName}
                        color={color}
                        id={id}
                        delCallback={this.deletePopup}
                        elementClass={elementClass}/>
        break;

      case "contextport":
        elementClass = "menu";
        panel = <ContextPort key={ "context-port-" + id}
                           location={location}
                           soName={soName}
                           port={port}   //   port = "portname"
                           color={color}
                           id={id}
                           delCallback={this.deletePopup}
                           elementClass={elementClass}/>
        break;

      case "contextinlet":
        elementClass = "menu";
        panel = <ContextInlet key={ "context-inlet-" + id}
                           location={location}
                           soName={soName}
                           port={port}   //   port = "portname inletname"
                           color={color}
                           id={id}
                           delCallback={this.deletePopup}
                           elementClass={elementClass}/>
        break;

      case "contextoutlet":
        elementClass = "menu";
        panel = <ContextOutlet key={ "context-outlet-" + id}
                           location={location}
                           soName={soName}
                           port={port}   //   port = "portname inletname"
                           color={color}
                           id={id}
                           delCallback={this.deletePopup}
                           elementClass={elementClass}/>
        break;

      case "contextmethod":
        elementClass = "menu";
        panel = <ContextMethod key={ "context-methiod-" + id}
                           location={location}
                           soName={soName}
                           port={port}   //   port = "methodname !!!"  TODO: change port to context
                           color={color}
                           id={id}
                           delCallback={this.deletePopup}
                           elementClass={elementClass}/>
        break;

      case "confinlet":
        elementClass = "menu";
        panel = <ConfInlet key={ "conf-inlet-" + id}
                           location={location}
                           soName={soName}
                           port={port}   //   port = "portname inletname"
                           color={color}
                           id={id}
                           delCallback={this.deletePopup}
                           elementClass={elementClass}/>
        break;

      case "confoutlet":
        elementClass = "menu";
        panel = <ConfOutlet key={ "conf-outlet-" + id}
                           location={location}
                           soName={soName}
                           port={port}   //   port = "portname outletname"
                           color={color}
                           id={id}
                           delCallback={this.deletePopup}
                           elementClass={elementClass}/>
        break;

      case "confports":
        elementClass = "panel";
        panel = <ConfPorts key={"conf-port-" + id }
                        location={location}
                        soName={soName}
                        color={color}
                        id={id}
                        delCallback={this.deletePopup}
                        elementClass={elementClass}/>
        break;

      case "addport":
        elementClass = "panel";
        panel = <AddPort key={"add-port-" + id }
                        location={location}
                        soName={soName}
                        color={color}
                        id={id}
                        delCallback={this.deletePopup}
                        elementClass={elementClass}/>
        break;

      case "addmethod":
        elementClass = "panel";
        panel = <AddMethod key={"add-method-" + id }
                        location={location}
                        soName={soName}
                        color={color}
                        id={id}
                        delCallback={this.deletePopup}
                        elementClass={elementClass}/>
          break;

      case "addinlet":
        elementClass = "panel";
        panel = <AddInlet key={"add-inlet-" + id }
                        location={location}
                        soName={soName}
                        port={port}
                        color={color}
                        id={id}
                        delCallback={this.deletePopup}
                        elementClass={elementClass}/>
          break;

      case "addoutlet":
        elementClass = "panel";
        panel = <AddOutlet key={"add-outlet-" + id }
                        location={location}
                        soName={soName}
                        port={port}
                        color={color}
                        id={id}
                        delCallback={this.deletePopup}
                        elementClass={elementClass}/>
          break;

      case "setmapping":
        elementClass = "panel";
        panel = <SetMapping key={"set-mapping-" + id }
                        location={location}
                        soName={soName}
                        color={color}
                        id={id}
                        delCallback={this.deletePopup}
                        elementClass={elementClass}/>
          break;

      case "snapshot":
        elementClass = "panel";
        panel = <AddSnapshot key={"set-snapshot-" + id }
                            location={location}
                            soName={soName}
                            color={color}
                            id={id}
                            delCallback={this.deletePopup}
                            elementClass={elementClass}/>
        break;

      case "restore":
        elementClass = "panel";
        panel = <RestoreSnapshot key={"restore-snapshot-" + id }
                             location={location}
                             soName={soName}
                             color={color}
                             id={id}
                             delCallback={this.deletePopup}
                             elementClass={elementClass}/>
        break;

      case "stats":
        elementClass = "panel";
        panel = <SoStats key={"so-stats-" + id }
                                 location={location}
                                 soName={soName}
                                 color={color}
                                 id={id}
                                 delCallback={this.deletePopup}
                                 elementClass={elementClass}/>
        break;

        case "portstats":
        elementClass = "panel";
        panel = <PortStats key={"port-stats-" + id }
                         location={location}
                         soName={soName}
                         port={port}
                         color={color}
                         id={id}
                         delCallback={this.deletePopup}
                         elementClass={elementClass}/>
        break;

      case "methodstats":
        elementClass = "panel";
        panel = <MethodStats key={"method-stats-" + id }
                           location={location}
                           soName={soName}
                           port={port}
                           color={color}
                           id={id}
                           delCallback={this.deletePopup}
                           elementClass={elementClass}/>
        break;

      case "exportso":
        elementClass = "panel";
        panel = <ExportSo key={"export-so-" + id }
                           location={location}
                           soName={soName}
                           color={color}
                           id={id}
                           delCallback={this.deletePopup}
                           elementClass={elementClass}/>
        break;

      default:
        break;
    }

    this.activePopups[id] = { "id": id, "soName": soName, "port": port, "type": type, "location": location,
                              "panel": panel, "elementClass": elementClass }
    this.emit("popupUpdate");
  }

  // used by Popup to clase popup if close icon pressed by user
  // called via callback
  deletePopup(id) {
    delete this.activePopups[id];
    this.emit("popupUpdate");
  }

  // close all active menu popups. Called by ServiceGraph if user clicks canvas background
  deletePopups() {

    let markForDelete = [];

    for (const entry in this.activePopups) {
      if (this.activePopups[entry].elementClass === "menu") { markForDelete.push(entry)};
    }

    for (const entry of markForDelete) {
      delete this.activePopups[entry]
    }

    this.emit("popupUpdate");
  }


  getPopups() {
    let popsContainer = []; let markForDelete = [];

    // loop over all ative popups and build render array
    for (const pop in this.activePopups) {
      if (aSDS.checkSoExist(this.activePopups[pop].soName)) {
        popsContainer.push(this.activePopups[pop].panel);
      } else {
        markForDelete.push(pop);
      }
    }

    for (const entry of markForDelete) {
      delete this.activePopups[entry]
    }

    return popsContainer;
  }

}

const  popupManager = new PopupManager();
export default popupManager;
