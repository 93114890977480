import React from "react";
import codeDevStore from "./CodeDevStore";


class EditorSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state={isSelect: false, isHover: false}

        this.width = 20; this.height = 161;
        this.selectorStatus = [];
        this.selectorStatus = codeDevStore.getViewSelectStatus()

        this.resizeIsActive = false; // rescale is ongoing
        this.dividerIsActive = false; // divider is active such size of dev panel can be changed
        this.lastPosition = 450;

        //this.statusCallback = this.statusCallback.bind(this);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._onClick = this._onClick.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this._updateView = this._updateView.bind(this);
        this.resize= this.resize.bind(this);
        this.stopResize=this.stopResize.bind(this);
    }


    componentDidMount() {
        this._updateView();
        codeDevStore.on("CodeDevViewUpdate", this._updateView);
    }
    componentWillUnmount() {
        codeDevStore.removeListener("CodeDevViewUpdate", this._updateView);
    }

    _updateView() {
        const status = codeDevStore.getViewSelectStatus();
        const showStatus = ( status.editor )

        this.setState({isSelect: status.editor})

        if (showStatus !== this.dividerIsActive) {
            if (showStatus) {
                this.dividerIsActive = true
                this.props.resizeCallback(this.lastPosition);
            } else {
                this.dividerIsActive = false;
                this.props.resizeCallback(0);
            }
            this.forceUpdate();
        }
    }

    resize(event) {
        if (!this.dividerIsActive) {return}
        let  newPos = window.innerWidth - event.pageX;

        if (newPos < 450) {newPos=450}
        if (newPos > window.innerWidth - 460) {
            newPos=window.innerWidth-458}
        this.lastPosition = newPos;
        if (this.resizeIsActive) { this.props.resizeCallback(newPos) }
    }


    stopResize() {
        this.resizeIsActive = false;
        window.removeEventListener("mousemove", this.resize)
        window.removeEventListener("mouseup", this.stopResize)
    }

    onMouseDown() {
        if (!this.dividerIsActive) {return}
        this.resizeIsActive = true;
        window.addEventListener("mousemove", this.resize)
        window.addEventListener("mouseup", this.stopResize)
    }

    getButtonStatus() {return this.selectorStatus}

    _onMouseOver() {
        this.setState({isHover: true})
    }
    _onMouseLeave() {
        this.setState({isHover: false})
    }
    _onClick() {
        this.selectorStatus["editor"] = !this.state.isSelect;
        codeDevStore.setViewSelectStatus(this.selectorStatus)
        //this.setState({isSelect: !this.state.isSelect})
    }

    render() {

        const sB = (window.innerHeight)/2 - (this.height/2) - 25;
        const cursor = this.dividerIsActive ? "ew-resize" : "pointer";
        const col = this.state.isHover ? "Black" : "Gray";

        return (
            <div style={{ position: "fixed", bottom: sB, right: this.props.xOffset+1, width: this.width, height: this.height,
                          backgroundColor: "White", opacity: 1,
                          border: "2px solid DimGray", borderRight: "2px solid White", borderTopLeftRadius: 7, borderBottomLeftRadius: 7,
                          cursor: cursor, writingMode: "vertical-lr", textOrientation: "mixed",
                          fontFamily: "Roboto", fontWeight: "bold", fontSize: 13, color: col,
                          paddingTop: 50}}
                 onMouseOver={ () => {this._onMouseOver()} }
                 onMouseLeave={ () => {this._onMouseLeave()} }
                 onMouseDown={() => this.onMouseDown()}
                 onClick={ () => {this._onClick()} }
            >

                <div style={{paddingLeft: 5, userSelect: "none" }}>Development Tools</div>

            </div> )
    }
}
export default EditorSelector;
