import * as comActions from "./../../actions/ComActions";

class FacilityMsg {
  process(msgWords, uuid, msg) {
    // decide on how to process message
    const msgFirstWord = msgWords[0];

    switch (msgFirstWord) {
      case "resp":
        this._procResp(msgWords, uuid, msg);
        break;
      case "info":
        this._procInfo(msgWords, uuid);
        break;
      case "error":
        this._procError(msgWords, uuid);
        break;
      default:
        // check if msg starts with device name
        //
        //

        // no device => error
        console.log("unknown facility msg... dropped");
        break;
    }
  }

  // RESP
  // check if RESP message has scope FACILITY or DEVICE
  _procResp(msgWords, uuid, msg) {
    // resp messages can have facility or <device> scope
    if (msgWords[1] === "facility") this._procRespFac(msgWords, uuid);
    else this._procRespDevice(msgWords, uuid, msg);
  }


  // ----------------------------------------------------------------------------
  // RESP FACILITY messages
  _procRespFac(msgWords, uuid) {
    if (msgWords[2] === "device" && msgWords[3] === "table") {
      const msg = {
        scope: "facility",
        cmd: "device_table",
        fac: uuid,
        payload: msgWords.slice(4)
      };
      comActions.facilitiesMsgRecv(msg);
    } else if (msgWords[2] === "active" && msgWords[3] === "table") {
      const msg = {
        scope: "facility",
        cmd: "active_table",
        fac: uuid,
        payload: msgWords.slice(4)
      };
      comActions.facilitiesMsgRecv(msg);
    } else if (msgWords[2] === "autodiscovery" && msgWords[3] === "set") {
      const msg = {
        scope: "facility",
        cmd: "autodiscovery",
        fac: uuid,
        payload: msgWords[4]
      };
      comActions.facilitiesMsgRecv(msg);
    }
  }


  // ----------------------------------------------------------------------------
  // RESP DEVICE messages
  _procRespDevice(msgWords, uuid, fullmsg) {
    
    if (msgWords[2] === "devcon" && msgWords[3] === "set") {
      const msg = {
        scope: "device",
        cmd: "devcon_set",
        fac: uuid,
        device: msgWords[1],
        payload: msgWords.slice(4)
      };
      comActions.facilitiesMsgRecv(msg);
    }
    else if (msgWords[2] === "coreversion" && msgWords[3] === "set") {
      const msg = {
        scope: "device",
        cmd: "coreversion",
        fac: uuid,
        device: msgWords[1],
        payload: msgWords[4]
      };
      comActions.facilitiesMsgRecv(msg);
    } else if (msgWords[2] === "cluster" && msgWords[3] === "set") {
      const msg = {
        scope: "device",
        cmd: "cluster",
        device: msgWords[1],
        fac: uuid,
        payload: msgWords.slice(4)
      };
      comActions.facilitiesMsgRecv(msg);
    } else if (msgWords[2] === "devrole" && msgWords[3] === "set") {
      const msg = {
        scope: "device",
        cmd: "devrole",
        device: msgWords[1],
        fac: uuid,
        payload: msgWords.slice(4)
      };
      comActions.facilitiesMsgRecv(msg);
    } else if (msgWords[2] === "periphery" && msgWords[3] === "loaded") {
      // handle case if code is empty
      if (msgWords.length <= 4) msgWords[4] = "none"

      const msg = {
        scope: "device",
        cmd: "periphery load",
        status: "OK",
        device:  msgWords[1],
        payload: msgWords.slice(4).join(' ')
      }
      comActions.facilitiesMsgRecv(msg)

    } else if (msgWords[2] === "periphery" && msgWords[3] === "error") {

      const msg = {
        scope: "device",
        cmd: "periphery load",
        status: "ERROR",
        device:  msgWords[1],
        payload: msgWords.slice(4).join(' ')
      }
      comActions.facilitiesMsgRecv(msg)

    } else if (msgWords[2] === "periphery" && msgWords[3] === "source") {

      // handle case if code is empty
      if (msgWords.length <= 4) msgWords[4] = ""
      
      const msg = {
        scope: "device",
        cmd: "periphery source",
        device: msgWords[1],
        payload: msgWords.slice(4).join(' ')
      }
      comActions.facilitiesMsgRecv(msg)

    } else if (msgWords[2] === "update") {
        
        const msg = {
        scope: "device",
        fac: uuid,
        cmd: "update",
        status: msgWords[3],
        device:  msgWords[1],
        payload: msgWords[4]
      }
      comActions.facilitiesMsgRecv(msg)


    } else if (msgWords[2] === "exec") {
        const start = fullmsg.indexOf('{')-1
        const end = fullmsg.lastIndexOf('}')+ 1
        let payload = ""
        // due to echo in script need to add "
        fullmsg = fullmsg.substring(start, end)
        let finalmsg = fullmsg+'"';
        try {
            payload = JSON.parse(finalmsg)
        } catch(e) {
            payload = "{}"
        }
console.log(msgWords)
        if (msgWords[3] === "update_core") {
            const msg = {
                scope: "device",
                fac: uuid,
                cmd: "update",
                status: msgWords[3],
                device:  msgWords[1],
                payload: payload
            }
            comActions.facilitiesMsgRecv(msg)
        }
    }

  }
  
  // ----------------------------------------------------------------------------
  // INFO messages  
  _procInfo(msgWords, uuid) {
    // info messages can have facility or <device> scope
    if (msgWords[1] === "facility") this._procInfoFac(msgWords, uuid);
    else this._procInfoDevice(msgWords, uuid);
  }

  _procInfoFac(msgWords, uuid) {
    if (msgWords[2] === "autodiscovery" && msgWords[3] === "set") {
      const msg = {
        scope: "facility",
        cmd: "autodiscovery",
        fac: uuid,
        payload: msgWords[4]
      };
      comActions.facilitiesMsgRecv(msg);
    } else if (msgWords[2] === "device" && msgWords[3] === "added") {
      const msg = {
        scope: "facility",
        cmd: "device_added",
        fac: uuid,
        payload: msgWords.slice(4)
      };
      comActions.facilitiesMsgRecv(msg);
    }
  }

  _procInfoDevice(msgWords, uuid) {}

  // ----------------------------------------------------------------------------
  // ERROR messages
  _procError(msgWords, uuid) {
     console.log("error periphery:", msgWords)

    // error message from facility received
    if (msgWords[2] === "loadperiphery") this._procErrorPeriphery(msgWords, uuid);
  }

  _procErrorPeriphery(msgWords, uuid) {
    console.log("processing periphery error message:", msgWords)
  }

}

const facilityMsg = new FacilityMsg();
export default facilityMsg;
