import { EventEmitter } from "events";
import websocketCom from "./../webcom/WebsocketCom";
import dispatcher from "../dispatcher/dispatcher";
import websocketDispatcher from "../dispatcher/WebsocketDispatcher";
import logger from "./../components/Logging/Logger";

class SessionManagementStore extends EventEmitter {
  constructor() {
    super();

    this._resetSessionStateDb();

    this._resetSessionStateDb = this._resetSessionStateDb.bind(this);
    this.getUserName = this.getUserName.bind(this);
    //this.setPlatformIdentifier = this.setPlatformIdentifier.bind(this);
    this.getPlatformIdentifier = this.getPlatformIdentifier.bind(this);
    this.LOG_ORIGIN = "SessionManagementStore";
    logger.log(this.LOG_ORIGIN, "INFO", "Initialized.", "");
  }

  _resetSessionStateDb() {
    this.session_status = "closed"; //  closed -> setup -> open -> closed
    this.session_data = { user_name: "", platform_identifier: "", client_uuid: "", token: "",
                          email: "", given_name: "", family_name: "", department: "", city: "",
                          admin_rights: ""};
    this.websocket_status = "";
    //this.platform_identifier = "";
  }

  _setSessionState_Setup() {
    if (this.session_status === "open" || this.session_status === "setup") {
      this._setSessionState_Closed();
    }
    this.session_status = "setup";
    logger.log(
      this.LOG_ORIGIN,
      "DEBUG",
      "Session status set to",
      this.session_status
    );
  }

  _setSessionState_Open() {
    if (this.session_status === "open") {
      this._setSessionState_Closed();
    }
    this.session_status = "open";
    logger.log(
      this.LOG_ORIGIN,
      "INFO",
      "Session status set to",
      this.session_status
    );
    this.emit("ChangeEvent_SessionEstablished");
  }

  _setSessionState_Closed() {
    this.session_status = "closed";
    this._resetSessionStateDb();
    logger.log(
      this.LOG_ORIGIN,
      "INFO",
      "Session status set to",
      this.session_status
    );
    websocketCom.closeWebsocket();    
    this.emit("ChangeEvent_SessionClosed");
  }

  _processSessionLifecycleActions(msg) {
    if (msg["status"] === "setup") {
      this._setSessionState_Setup();
      this.session_data["user_name"] = msg["user_name"];
      this.session_data["platform_identifier"] = msg["platform_identifier"];
      this.session_data["client_uuid"] = msg["client_uuid"];
      this.session_data["token"] = msg["token"];
      this.session_data["email"] = msg["email"];
      this.session_data["given_name"] = msg["given_name"];
      this.session_data["family_name"] = msg["family_name"];
      this.session_data["department"] = msg["department"];
      this.session_data["city"] = msg["city"];
      this.session_data["admin_rights"] = msg["admin_rights"];
    } else if (msg['status'] ==="update") {
      this.session_data["client_uuid"] = msg["client_uuid"];
      this.session_data["token"] = msg["token"];
    } else if (msg["status"] === "close") {
      this._setSessionState_Closed();
    } else {
      logger.log(
        this.LOG_ORIGIN,
        "ERROR",
        "Unknown SessionLifecycle message.",
        {}
      );
    }
  }

  _processWebsocketLifecycleActions(msg) {
    if (msg["status"] === "connecting") {
      this.websocket_status = msg["status"];
    } else if (msg["status"] === "open") {
      this.websocket_status = msg["status"];
      this._setSessionState_Open();
    } else if (msg["status"] === "error") {
      if (this.session_status === "setup") {
        // error during session setup happened
        // send out change event that will be used by login page
        // to indicate login error to the user.
        this.emit("ChangeEvent_SessionEstablishment_Failed");
        this._setSessionState_Closed();
      } else {
        logger.log(
          this.LOG_ORIGIN,
          "ERROR",
          "Websocket communication failed. Terminating Session.",
          ""
        );
        this._setSessionState_Closed();
      }
    } else if (msg["status"] === "closed") {
      this.websocket_status = msg["status"];
    } else {
      logger.log(
        this.LOG_ORIGIN,
        "ERROR",
        "Unknown WebsocketLifecycle message.",
        {}
      );
    }
  }

  handleActions(action) {
    switch (action.type) {
      case "ACTION_SessionLifecycle": {
        this._processSessionLifecycleActions(action.msg);
        break;
      }
//      case "ACTION_WebsocketLifecycle": {
//        this._processWebsocketLifecycleActions(action.msg);
//        break;
//      }

      default: {
      }
    }
  }

  handleWebsocketActions(action) {
    switch (action.type) {
      case "ACTION_WebsocketLifecycle": {
        this._processWebsocketLifecycleActions(action.msg);
        break;
      }
      default: {
      }
    }
  }

  getUserName() {
    return this.session_data["user_name"];
  }

  getToken() {
    return this.session_data["token"]
  }


  getAuthUpdateData() {
    const reAuthData = {
            login : this.session_data["user_name"],
            source_uuid : this.session_data["client_uuid"],
            access_token: this.session_data["token"]
        }
    const url = this.session_data["platform_identifier"]
        
    return { reAuthData:reAuthData, url: url}
  }

  getProfileData() {
    const profileData = { "user_name": this.session_data.user_name,
                    "given_name": this.session_data.given_name,
                    "family_name": this.session_data.family_name,
                    "email": this.session_data.email,
                    "department": this.session_data.department,
                    "city": this.session_data.city,
                    "admin_rights": this.session_data.admin_rights
                  }
    return profileData
  }

  getPlatformIdentifier() {
    return this.session_data["platform_identifier"];
  }

  isSessionAuthenticated() {
    if (this.session_status === "open") {
      return true;
    }
    return false;
  }

  getAdminRights() {
    return this.session_data.admin_rights;
  }

}

const sessionManagementStore = new SessionManagementStore();

dispatcher.register(
  sessionManagementStore.handleActions.bind(sessionManagementStore)
);
websocketDispatcher.register(
  sessionManagementStore.handleWebsocketActions.bind(sessionManagementStore)
);

export default sessionManagementStore;
