import React from "react";
//import sMS from "./../../../../stores/SessionManagementStore";
import NWG from "./NetworkGraph_NG";
import ClusterPanel from "./ClusterPanel";
import PerformanceGraph from "./PerformanceGraph";
//import PlatformIcon from "@material-ui/icons/Language";
//import ClusterIcon from '@material-ui/icons/DeviceHub';


class Topology extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {selCluster: null, selDevice: null}

        this.cb_topologySelect = this.cb_topologySelect.bind(this);
        this._buildClusterTableView = this._buildClusterTableView.bind(this);
        this._deriveClusters = this._deriveClusters.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    cb_topologySelect(cluster,device) {
        this.setState({selCluster: cluster, selDevice: device})
    }


    _buildClusterTableView() {
        const clusterTable =[];
        const clusters = this._deriveClusters()
        for (const id in clusters) {
            const singleClusterTable = (<ClusterPanel key={clusters[id]+"-cluster"} clusterName={clusters[id]} devices={this.props.devices}
                                                      selCluster={this.state.selCluster} selDevice={this.state.selDevice} />);
            clusterTable.push(singleClusterTable);
        }
        return clusterTable;
    }

    // helper function to find all clusters in the deployment
    _deriveClusters() {
        let clusters = [];
        // eslint-disable-next-line
        for (const [uuid, data] of this.props.devices) {
            if (data.has("cluster")) {
                const cluster = data.get("cluster")[0];
                if (cluster !== "") {
                    if (! clusters.includes(cluster)) {clusters.push(cluster)}
                }
            }
        }
        return clusters
    }


    render() {
        const clusterTableView = this._buildClusterTableView();
        const width_graph = this.props.panelSize.width/2;

        const page = <div style={{height: this.props.panelSize.height}} >
            <div style={{display: "flex"}} >
                <div style={{width: width_graph, height: this.props.panelSize.height, border: "1px solid Gray"}}>
                    <NWG
                        devices={this.props.devices}
                        facilities={this.props.facilities}
                        panelSize={this.props.panelSize}
                        viewMode={"topology"}
                        cb_topologySelect={this.cb_topologySelect}
                        handle_func_setSelectedFacility={this.setSelectedFacility}
                        facSelected = {"genesis"}
                    />

                </div>
                <div style={{height: this.props.panelSize.height, flexGrow: 1, border: "1px solid Gray"}}>
                  <div style={{display:"flex", flexDirection:"column", height: this.props.panelSize.height}}>
                    <div style={{flexGrow: 1, overflowY: "auto" }}>  
                    {clusterTableView}
                  </div>
                  <div style={{display: "flex", paddingBottom: 10}}>
                    <div style={{flexGrow: 1}}/>
                    <div>
                      <PerformanceGraph devices={this.props.devices} />
                      <div style={{textAlign: "center", fontFamily: "Roboto", fontSize: 12, fontWeight: "bold"}}>
                        Average wait time [ms]
                      </div>
                    </div>
                    <div style={{flexGrow: 1}}/>
                  </div>

                  </div>
                </div>

            </div>

        </div>



        return page;
    }

}
export default Topology;
