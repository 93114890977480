import { EventEmitter } from "events";
import dispatcher from "./../dispatcher/dispatcher";

class HistoryStore extends EventEmitter {
  constructor() {
    super();
    this.history = { soName: "", items: [] };
  }

  getHistory() {
    return this.history;
  }

  _processHistoryMessage(action) {
    this.history = { soName: "", items: [] };

    if (action.msg.message ==="empty") return

    this.history.soName = action.msg.so;
    let completeHistory = action.msg.message;
    let messages = completeHistory.split("\n");
    const _this = this;
    messages.forEach(function(item, index) {
      let checkpointItem = {};

      checkpointItem.release = item.substr(0, item.indexOf(" "));
      let date = Math.ceil(parseInt(checkpointItem.release) / 1000000);
      checkpointItem.date = date;
      item = item.substr(item.indexOf(" ") + 1);
      item.trim();
      checkpointItem.tag = item.substr(0, item.indexOf(" "));
      item = item.substr(item.indexOf(" ") + 1);
      checkpointItem.comment = item.trim();
      _this.history.items.unshift(checkpointItem);
    });

    this.emit("history_update", this.history);
  }

  handleActions(action) {
    switch (action.type) {
      case "ACTION_SoHistoryReceived": {
        this._processHistoryMessage(action);
        break;
      }
      default: {
      }
    }
  }
}

const historyStore = new HistoryStore();
dispatcher.register(historyStore.handleActions.bind(historyStore));

export default historyStore;
