import React from "react";
import websocketCom from "./../../../../webcom/WebsocketCom";
import fS from "./../../../../stores/FacilitiesStore";

import ForceGraph2D from "react-force-graph-2d";

import CircularProgress from "@material-ui/core/CircularProgress";

class NetworkGraph extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.temp = { nodes: [], links: [] };

    this.drawGraphTimer = null;
    this.updateInfoTimer = null;

    this.procEventsGraph = {}

    this.state = {
      graph: this.temp,

      hoverCluster: null,
      hoverNode: null,
      clickCluster: null,
      clickNode: null,
      numberClusters: "n/a",
      numberNodes: "n/a",
      clusters: {},
      showFsp: this.props.viewMode==="facility",
    };

    this._setSelFac = this._setSelFac.bind(this);
    this._drawGraph = this._drawGraph.bind(this);
    this.drawNode = this.drawNode.bind(this);
    this._renderCollectInfo = this._renderCollectInfo.bind(this);
    this._renderGraph = this._renderGraph.bind(this);

  }

  componentDidMount() {
    this._isMounted = true;
    this.drawGraphTimer = setInterval(this._drawGraph, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.drawGraphTimer);
    this._isMounted = false;
  }


  _setSelFac(facility) {
    this.props.handle_func_setSelectedFacility(facility)
  }


  _toggleFacNode(node) {
      if (this.props.selectedFacility !== "genesis") {
        const facility_uuid = fS.getFacilityUuidByName(this.props.selectedFacility);
        if (this.props.facilities.get(facility_uuid).has("devices")) {
          const devices = this.props.facilities.get(facility_uuid).get("devices");
          let operation = ""
          devices.has(node) ? operation = "removedevice" : operation = "adddevice"
          const nodeName = this.props.devices.get(node).get("name") // get name for uuid from genesis entry of device
          let msg = "facility " + operation + " " + nodeName
          console.log(msg, facility_uuid)
          websocketCom.sendOutOfBandMessage(msg, facility_uuid)
          msg = "facility devicetable"
          websocketCom.sendOutOfBandMessage(msg, facility_uuid)
        }
      }
  }


  _updatePerfInd()  {
    let perfInd = {}
    let sumProcEvents = 0

    let minProcEvents = 0
    let maxProcEvents = 0
    let firstIteration = true

    // eslint-disable-next-line no-unused-vars
    for (const [key, device] of this.props.devices.entries()) {
      const deviceProcEvents =  parseFloat(device.get("devcon").get("utilization")[2])
      if (firstIteration) {
        minProcEvents = deviceProcEvents
        maxProcEvents = deviceProcEvents
        firstIteration = false
      } else {
        if (deviceProcEvents < minProcEvents) minProcEvents = deviceProcEvents
        if (deviceProcEvents >= maxProcEvents) maxProcEvents = deviceProcEvents
      }
      sumProcEvents = sumProcEvents + deviceProcEvents

    }
    const meanProcEvents = sumProcEvents / this.props.devices.size
    const lowerScale = (meanProcEvents-minProcEvents) / 4
    const higherScale = (maxProcEvents-meanProcEvents) / 3

    for (const [key, device] of this.props.devices.entries()) {
      const deviceProcEvents =  parseFloat(device.get("devcon").get("utilization")[2])
      if (deviceProcEvents < meanProcEvents) {
        perfInd[key] =4- Math.round(( (meanProcEvents-   deviceProcEvents)  / lowerScale))+2
        if (perfInd[key] < 2) perfInd[key] = 2
      }
      else if (deviceProcEvents >= meanProcEvents) {
        perfInd[key] = Math.round(((deviceProcEvents   -meanProcEvents)  / higherScale))+4
        if (perfInd[key] > 8) perfInd[key] = 8
      }
    }
    return perfInd
  }


  _drawGraph() {
    let nodes = [];
    let links = [];
    let clusters = {};

    this.procEventsGraph = this._updatePerfInd()

    //console.log("devices =", this.props.devices)
    for (const [key, value] of this.props.devices.entries()) {
      let id = key;
      let name = value.get("name");
      let cluster = value.get("cluster")[0];
      let uplink = value.get("cluster")[1];
      let role = value.get("role");

      if (cluster) {
        if (!(cluster in clusters)) {
          clusters[cluster] = { router: "", nodes: [], uplink: uplink };
        }
        clusters[cluster].nodes.push(id);

        if (uplink) {
          if (!(uplink in clusters)) {
            clusters[uplink] = { router: "", nodes: [], uplink: cluster };
          }
        }

        if (role === "raspicore") {
          clusters[cluster].router = id;
          clusters[cluster].role = "raspicore";
        } else {
          clusters[cluster].role = "dedicated";
        }
      } else {
        // device is online and we do not know its associated cluster
        // thus we must add it to the graph here!
        nodes.push({ id: id, name: name, nodeLabel: name });
      }
    }

    this.setState({
      numberClusters: Object.keys(clusters).length,
      numberNodes: this.props.devices.size,
      clusters: clusters
    });

    // iterate over all clusters
    let cluster = {};

    for (cluster in clusters) {
      let cl = clusters[cluster];
      if (cl.router === "") {
        nodes.push({
          id: cluster,
          name: cluster,
          nodeLabel: cluster + "_router"
        });
      } else {
        nodes.push({
          id: cluster,
          name: cluster,
          nodeLabel: cluster + "_router"
        });
      }

      for (const device of cl.nodes) {
        const handle = this.props.devices.get(device);
        nodes.push({
          id: device,
          name: handle.get("name"),
          nodeLabel: handle.get("name")
        });
        links.push({ source: cluster, target: device });
      }
      // TODO: additional uplinks could be provided in cluster[2] ...
      //console.log("cl.uplink =", cl.uplink)
      //console.log("cl =", cl)
      //console.log("nodes =", nodes)
      if (cl.uplink) {
        links.push({ source: cluster, target: cl.uplink });
      }
    }
    //console.log("links =", links)
    let temp = { nodes: nodes, links: links };
    if (!(temp.nodes.length === this.state.graph.nodes.length)) {
      this.setState({ graph: temp });
    }

    if (!(temp.links.length === this.state.graph.links.length)) {
      this.setState({ graph: temp });
    }
  }

  //_cE_WindowSizeUpdate() {
  //  if (this._isMounted) {
  //    this.forceUpdate();
  //  }
  //}

  linkColor(d) {
    //let linkColor = "#757575";
    let linkColor = "#bdbdbd";
    if (this.props.viewMode === "topology") {
      if (d.source.id === this.state.clickCluster) {
        //linkColor = "#424242";
        linkColor = "Blue"
      }
      if (d.source.id === this.state.hoverCluster) {
        //linkColor = "#212121";
        linkColor = "#ff8a65";
      }
    }
    return linkColor;
  }

  drawNode({ id, x, y }, ctx, globalScale) {
    let draw = "";

    const baseSize = 10;
    let zoom = 1;

    let border = "#bdbdbd";
    //let center = "#e0e0e0";
    let center = "#ffffff";
    let outer = "white";
    let textColor = "#212121";
    let textStyle = "6px Courier";
    let clusterStyle = "7px Courier";

    let cluster = id;
    let node = "";
    if (this.props.devices.has(id)) {
      cluster = this.props.devices.get(id).get("cluster")[0];
      node = id;
    }

    if (cluster === this.state.clickCluster) {
      //border = "#424242";
      border = "Blue"
      if (zoom === 1) zoom = 1.0;
    }

    if (node === this.state.clickNode) {
      //border = "#424242";
      border = "Blue"
      if (zoom === 1) zoom = 1.0;
    }

    if (cluster === this.state.hoverCluster) {
      border = "#ff8a65";
      zoom = 1.0;
    }
    if (node === this.state.hoverNode) {
      border = "#ff8a65";
      zoom = 1.0;
    }

    let alpha = 1.0;

    if (this.props.devices.has(id)) {
      const status = this.props.devices
        .get(id)
        .get("devcon")
        .get("status");

      switch (status) {
        case "green":
          center = "green";
          break;
        case "yellow":
          center = "#ffee58";
          break;
        case "red":
          center = "#f44336";
          break;
        default:
          center = "#e0e0e0";
      }

      // draw offline devices white in center
      const isActive = this.props.devices.get(id).get("active");
      if (isActive === "false") {
        center = "white";
        alpha = 0.5;
      }
    }

    if (this.props.viewMode === "facility") {
      border = "#bdbdbd";
      center = "white";
      alpha = 1;

      const facility_uuid = fS.getFacilityUuidByName(
        this.props.selectedFacility
      );

      if (!this.props.facilities.has(facility_uuid)) {return}

      const devices = this.props.facilities.get(facility_uuid).get("devices");

      if (node === this.state.hoverNode) border = "#ff8a65";

      if (devices && devices.has(id)) {
        center = "white";
        outer = center;
        node === this.state.hoverNode ? border = "#ff8a65" : border = "Blue"

        if (this.props.selectedFacility === "genesis") border = "black"

      }
      if (this.props.devices.has(id)) {
      this.props.devices.get(id).get("active") ? alpha=1 : alpha=0.5
    }
    }



    if (this.props.devices.has(id)) {
      //console.log("id=", id)
      const innerCircleWidth = this.procEventsGraph[id]
      //console.log(innerCircleWidth)
      draw =
        ((ctx.fillStyle = border),
        (ctx.globalAlpha = alpha),
        ctx.beginPath(),
        ctx.arc(x, y, baseSize * zoom, 0, 2 * Math.PI, false),
        ctx.fill(),
        (ctx.fillStyle = outer),
        ctx.beginPath(),
        ctx.arc(x, y, baseSize * zoom - 2, 0, 2 * Math.PI, false),
        ctx.fill(),
        (ctx.fillStyle = center),
        ctx.beginPath(),

        ctx.arc(x, y, innerCircleWidth, 0, 2 * Math.PI, false),
        //ctx.arc(x, y, baseSize * zoom - 5, 0, 2 * Math.PI, false),
        ctx.fill(),
        (ctx.fillStyle = textColor),
        (ctx.font = textStyle),
        ctx.fillText(this.props.devices.get(id).get("name"), x - 6, y + 15),
        (ctx.globalAlpha = 1));
    } else {
      draw =
        ((ctx.fillStyle = border),
        ctx.fillRect(
          x - (baseSize / 1.2) * zoom + 2,
          y - (baseSize / 1.2) * zoom + 2,
          ((2 * baseSize) / 1.2) * zoom - 4,
          ((2 * baseSize) / 1.2) * zoom - 4
        ),
        (ctx.fillStyle = center),
        ctx.fillRect(
          x - (baseSize / 1.2) * zoom + 3,
          y - (baseSize / 1.2) * zoom + 3,
          ((2 * baseSize) / 1.2) * zoom - 6,
          ((2 * baseSize) / 1.2) * zoom - 6
        ),
        (ctx.fillStyle = textColor),
        (ctx.font = clusterStyle),
        ctx.fillText(
          String(id),
          x - 2 * baseSize * zoom + 2,
          y + 2 * baseSize * zoom - 2
        ));
    }
    return draw;
  }

  onNodeHover(d, pd) {
    if (d === null) {
      this.setState({ hoverCluster: null, hoverNode: null });
    } else {
      if (this.props.devices.has(d.id)) {
        // hover over device
        const node = this.props.devices.get(d.id).get("uuid");
        this.setState({ hoverNode: node });
      } else {
        // hover over router
        if (this.props.viewMode === "topology") {
          const cluster = d.id;
          this.setState({ hoverCluster: cluster });
        }
      }
    }
  }

  onNodeClick(d, e) {
    if (this.props.viewMode === "topology") {
      if (this.props.devices.has(d.id)) {
        const node = this.props.devices.get(d.id).get("uuid");
        if (this.state.clickNode === node) {
          this.setState({ clickNode: null });
        } else {
          this.setState({ clickNode: node, clickCluster: null });
        }
      } else {
        const cluster = d.id;
        if (this.state.clickCluster === cluster) {
          this.setState({ clickCluster: null });
        } else {
          this.setState({ clickCluster: cluster, clickNode: null });
        }
      }
    } else {
      if (this.props.devices.has(d.id)) {
        this._toggleFacNode(this.props.devices.get(d.id).get("uuid"));
      }
    }
    this.props.cb_topologySelect(this.state.clickCluster, this.state.clickNode);
  }

  onNodeRightClick(d, e) {
    if (this.props.devices.has(d.id)) {
      //console.log("right click on node", d)
    }
  }


  _renderCollectInfo() {
    let collectInfo = <div />;

    if (this.state.graph.nodes.length === 0 && this.state.graph.links.length === 0) {
      collectInfo = (
        <div style={{position: "absolute", top: (this.props.panelSize.height*0.4)+240, left: (this.props.panelSize.width/4)+130}}>

            <CircularProgress />

          <div style={{ marginTop: 25, marginLeft: -80, fontFamily: "Roboto", fontSize: 15, color: "Gray" }}>
            Collecting Platform Information...
          </div>
        </div>
      );
    }
    return collectInfo;
  }


  _renderGraph() {
    let graph = <div></div>;

    // calculate new panel size of network graph window
    let width = this.props.panelSize.width/2-3;
    let height = this.props.panelSize.height;

    graph = (
      <ForceGraph2D
        graphData={this.state.graph}
        width={width}
        height={height}
        nodeCanvasObject={({ id, x, y }, ctx, globalScale) =>
          this.drawNode({ id, x, y }, ctx, globalScale)
        }
        linkColor={d => this.linkColor(d)}
        onNodeHover={(d, pd) => this.onNodeHover(d, pd)}
        onNodeClick={(d, e) => this.onNodeClick(d, e)}
        onNodeRightClick={(d, e) => this.onNodeRightClick(d, e)}
        linkOpacity={0.2}
        linkWidth={7}
        warmupTicks={300}
        cooldownTime={500}
        nodeLabel={() => ""}
      />
    );

    return graph;
  }



  render() {
    let view = (
      <div>
        <div id="graph" style={{ display: "flex", flexDirection: "row" }}>
          {this._renderGraph()}
          {this._renderCollectInfo()}
        </div>
      </div>
    );

    return view;
  }
}

export default NetworkGraph;
