import React from "react";
import KingPigeonM140T from './KingPigeon_M140T';
import KingPigeonM120T from './KingPigeon_M120T';
import KingPigeonM160T from './KingPigeon_M160T';

class KingPigeonDevice extends React.Component {

  //constructor(props) {
  //  super(props);
  //}

  componentDidMount() {
    //selectionManager.on( "ChangeEvent_Selection", this._cE_SelectionUpdate );
  }

  componentWillUnmount() {
    //selectionManager.removeListener( "ChangeEvent_Selection", this._cE_SelectionUpdate );
  }




  _buildDevice() {
    let deviceView = <div />
    switch(this.props.model) {
      case "M140T":
        deviceView = <KingPigeonM140T name={this.props.name} soName={this.props.soName} />;
        break;
      case "M120T":
        deviceView = <KingPigeonM120T name={this.props.name} soName={this.props.soName} />;
        break;
      case "M160T":
        deviceView = <KingPigeonM160T name={this.props.name} soName={this.props.soName} />;
        break;
      default:
        break;
    }
    return deviceView;
  }


  render() {
    const deviceView = this._buildDevice();
    return deviceView;
  }

}

export default KingPigeonDevice;
