import React from "react";
import PressButton from './PressButton';
import ZoomButton from './ClickButton';
import gLS from '../../../stores/GraphLayoutStore';
import config from '../../config'

class CanvasNav_NG extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {showNav: false,
                  scale: gLS.getScaleFactor(), }

    this.WIDTH = config.setup.layout.navigationPanelWidth;   // width of canvas navigator
    this.NAVPADDING = 10;
    this.BUTTONHEIGHT = 40;
    // calculate scale factor and dimension of nav panel
    this.scaleFactor = this.WIDTH/this.props.canvas.width;
    this.width = this.props.canvas.width*this.scaleFactor;
    this.height = this.props.canvas.height*this.scaleFactor;

    this._cE_GraphLayoutUpdate = this._cE_GraphLayoutUpdate.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this.navShowCallback = this.navShowCallback.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    gLS.on( "ChangeEvent_GraphLayoutUpdate", this._cE_GraphLayoutUpdate );
  }
  componentWillUnmount() {
    this._isMounted = false;
    gLS.removeListener("ChangeEvent_GraphLayoutUpdate",this._cE_GraphLayoutUpdate);
  }
  _cE_GraphLayoutUpdate() {
    if (this._isMounted) {
      this.setState({scale: gLS.getScaleFactor()});
      this.forceUpdate()
    }
  }

  _handleClick(event) {
 //const scale=this.scaleFactor/this.props.canvas.scale;
    const scale=this.scaleFactor/this.state.scale;
    const viewWidth=this.props.canvas.viewWidth*scale;
    const viewHeight=this.props.canvas.viewHeight*scale;
    // calculate x/y pos for click on absolute positioned nav
    const x = event.pageX-(window.innerWidth-(this.props.sePos.x+this.NAVPADDING+this.width));
    const y = event.pageY-(window.innerHeight-(this.props.sePos.y+2*this.NAVPADDING+this.BUTTONHEIGHT+this.height));
    // calculate left upper corner of viewing rect
    let viewX = x-(viewWidth/2); let viewY = y-(viewHeight/2)
    if (viewX < 0) {viewX=0}; if (viewY < 0) {viewY=0}
    if (viewX > this.width) {viewX=this.width-viewWidth}
    if (viewY > this.height) {viewY=this.height-viewHeight}
    // send new position to canvas (via callback function)
    this.props.setCanvasPosCallback( {"x": viewX/scale, "y": viewY/scale} )
  }

  _renderSOs_Frame_NG(scale) {
    const graphLayout = gLS.getGraphLayout();
    let soContainer = [];
    for (const so in graphLayout) {
      const soFrame = ( <g key={so+"_nav"}>
                          <rect x={graphLayout[so].graph_data.x*scale} y={graphLayout[so].graph_data.y*scale}
                                width={(graphLayout[so].graph_data.width+graphLayout[so].graph_extension.d_w)*scale}
                                height={(graphLayout[so].graph_data.height+graphLayout[so].graph_extension.d_h)*scale}

                                stroke="gray" strokeWidth="2" fill="transparent" />
                        </g>)
      soContainer.push(soFrame);
    }
    return soContainer
  }

  _renderLinkLayout_NG(scale) {
    let linkContainer = [];
    const linkList = gLS.getLinkList();

    let linkCounter = 0;
    for (const link of linkList) {
        const linkFigure = (
          <g key={link.name+"-"+linkCounter}>
            <line x1={link.x1*scale} y1={link.y1*scale} x2={link.x2*scale} y2={link.y2*scale}
                  stroke="gray" strokeWidth="1.5" fill="transparent" />
          </g>
        )
        linkContainer.push(linkFigure);
        linkCounter++;
    }
    return linkContainer;
  }

  navShowCallback(name, status) {
    switch (name) {
      case "show": this.setState({showNav: status}); break;

      default: break;

    }

  }


  render() {
    if (! this.props.canvas.isNavActive) { return [] }
    // calculate view rectangle
//const scale=this.scaleFactor/this.props.canvas.scale;
    const scale=this.scaleFactor/this.state.scale;
    const viewX=this.props.canvas.viewX*scale;
    const viewY=this.props.canvas.viewY*scale;
    const viewWidth=this.props.canvas.viewWidth*scale;
    const viewHeight=this.props.canvas.viewHeight*scale;

    const navFigure = (
      <svg id="svgPanelNav" key="svg-panel-nav" style={{ width: this.width, height: this.height }}>
        <g key="canvas-nav-panel" >
          <rect x="0" y="0" width={this.width} height={this.height} stroke="gray" strokeWidth="2" fill="white" />
          <rect x={viewX} y={viewY} width={viewWidth} height={viewHeight} stroke="blue" strokeWidth="2" fill="transparent" />
          {this._renderSOs_Frame_NG(this.scaleFactor)}
          {this._renderLinkLayout_NG(this.scaleFactor)}
        </g> )
      </svg>
    )




    const buttons = (
      <svg id="buttonPanel" key="buttonPanel" style={{ width: this.width, height: this.BUTTONHEIGHT }}>
        <g key="canvas-nav-buttons" >
          <rect x="0" y="0" width={this.width} height={this.BUTTONHEIGHT} stroke="gray" strokeWidth="2" fill="#eee" fillOpacity="0.9" strokeOpacity="0.3" />
           <ZoomButton id="zoom-out" x={"20"} y={"40"} name={"zoomout"} callback={this.statusCallback} icon={"remove"} />
           <ZoomButton id="zoom-reset" x={"20"} y={"40"} name={"zoomreset"} callback={this.statusCallback} icon={"stop"} />
           <ZoomButton id="zoom-in" x={"20"} y={"40"} name={"zoomin"} callback={this.statusCallback} icon={"add"} />

          <PressButton id="nav-show-button" x={"20"} y={"40"} name={"show"} callback={this.navShowCallback} />
      </g> )
      </svg>
    )

    const viewNavFigure = this.state.showNav ? "block" : "none"

    const panel = (
      <div>
        <div onClick={(event) => this._handleClick(event)}
           style={{ display: viewNavFigure, position: "fixed", bottom: this.props.sePos.y+2*this.NAVPADDING+this.BUTTONHEIGHT, right: this.props.sePos.x+this.NAVPADDING,
                   width: this.width, height: this.height}}>
        {navFigure}
        </div>

        <div style={{position: "fixed", bottom: this.props.sePos.y+this.NAVPADDING, right: this.props.sePos.x+this.NAVPADDING}} >
        {buttons}
        </div>

      </div>
    )
    return panel;
  }
}

export default CanvasNav_NG
