import React from "react";
import aSDS from "./../../stores/ActiveServiceDataStore";
import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";


class PortStats extends React.Component {

    constructor(props) {
        super(props);
        // set size for this configuration panel
        this.width = 250; this.height = 305;
        this.STATS_UPDATE_INTERVAL = 2500;
        this.updateStatsTimer = null;

        this.state = { stats: null, moveOffset: {"x":0, "y":0} }

        this._sendStatsUpdateRequest = this._sendStatsUpdateRequest.bind(this);
        this._cE_DataStoreUpdate = this._cE_DataStoreUpdate.bind(this);
        this.moveCallback = this.moveCallback.bind(this);
        this._buildSoStatsPanel = this._buildSoStatsPanel.bind(this);
    }

    componentDidMount() {
        aSDS.on("change", this._cE_DataStoreUpdate );
        this._sendStatsUpdateRequest();
        this.updateStatsTimer = window.setInterval(
            this._sendStatsUpdateRequest,
            this.STATS_UPDATE_INTERVAL
        );

    }
    componentWillUnmount() {
        clearInterval(this.updateStatsTimer);
        aSDS.removeListener("change", this._cE_DataStoreUpdate );
    }

    _sendStatsUpdateRequest() {
        const message = this.props.soName + " getportstatistics " + this.props.port;
        websocketCom.sendServiceMessage(message);
    }

    _cE_DataStoreUpdate() {
        const portData = aSDS.getPortData(this.props.soName, this.props.port);
        if (portData === null) {return}
        const stats = portData.get("stats")
        this.setState({stats: stats})
    }


    moveCallback(delta) {
        this.setState({moveOffset: delta})
    }

    _panelClick(event) {event.stopPropagation() }

    _buildHeadline() {
        return (
            <div id="headline" style={{ paddingBottom: 5, paddingLeft: 15 }} >
                <div id="type">
                    <div style={{display: "flex", fontFamily: "Roboto", fontSize: 13, color: "DimGray", paddingTop: 3}} >
                        <div style={{width: 9, height: 9, backgroundColor: "DimGray", marginTop: 4, marginRight: 4}} />
                        <div> {this.props.soName}</div>
                        <div style={{width: 20}} />
                        <div style={{width: 9, height: 9, backgroundColor: "DimGray", marginTop: 4, marginRight: 4, borderRadius: 5}} />
                        <div> {this.props.port}</div>
                    </div>
                </div>
                <div id="text"
                     style={{ fontSize: 16, fontWeight: "bold", textAlign: "left", paddingTop: 2}} >
                    Statistics
                </div>
            </div>)
    }

    _buildSoStatsPanel() {

        let statsContainer = [];
        if (this.state.stats !== null) {
            for (const [key, value] of this.state.stats) {
                const item = <div key={key}>{key} = {value}</div>
                statsContainer.push(item);
            }
        }

        const panel = (
            <div style={{ cursor: "default" }} onClick={(event) => this._panelClick(event) }>
                {this._buildHeadline()}
                <div style={{ paddingLeft: 15, paddingTop: 12, paddingRight: 20, marginRight: 10,
                    height: this.height-80, overflowY: "scroll"}} >
                    {statsContainer}
                </div>
            </div> )

        return panel
    }


    render() {

        // Content of panel is defined here
        const view = (<div key={"content-ports-conf-"+this.props.soName}> {this._buildSoStatsPanel()} </div>)

        let  geometry = this.props.location;
        //const scaleFactor = this.props.location.scaleFactor;
        geometry["x"] = geometry["x"]+this.state.moveOffset.x; geometry["y"] = geometry["y"]+this.state.moveOffset.y;
        geometry["width"] = this.width; geometry["height"] = this.height;

        const panel = ( <Popup geometry={geometry} content={view}
                               id={this.props.id} elementClass={this.props.elementClass}
                               delCallback={this.props.delCallback}
                               moveCallback={this.moveCallback}
        /> )
        return panel
    }
}

export default PortStats;

