import React from "react";
import uS from "./../../../../stores/UtilityStore";
import Topology from "./Topology";
import Facilities from "./Facilities";
import websocketCom from "../../../../webcom/WebsocketCom";
import fS from "../../../../stores/FacilitiesStore";

// -------------------------------------------------------------
// material-ui
import Typography from "@material-ui/core/Typography";
import PlatformIcon from '@material-ui/icons/Language';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
// -------------------------------------------------------------


class Platform extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.timer = null;
        this.timerDevCon = null;
        this.selectedFacility = "genesis"

        this.state ={selectedPanel: "", tabSelected: 0,
                      panelSize: this._calculatePanelSize(),
                      facilities: new Map(),
                      devices: new Map() };



        this._windowDidResize = this._windowDidResize.bind(this);
        this._cE_FacilityDbUpdate = this._cE_FacilityDbUpdate.bind(this);
        this._calculatePanelSize = this._calculatePanelSize.bind(this);
        this._triggerUpdate = this._triggerUpdate.bind(this);
        this._triggerDevConUpdate = this._triggerDevConUpdate.bind(this);
        this._handleTabChange = this._handleTabChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        uS.on("window_size_changed", this._windowDidResize);
        this.timer = setInterval(this._triggerUpdate, 1900);
        this.timerDevCon = setInterval(this._triggerDevConUpdate, 1900);

        this._cE_FacilityDbUpdate();
        fS.on("ChangeEvent_FacilityDbUpdate", this._cE_FacilityDbUpdate);
    }

    componentWillUnmount() {
        this._isMounted = false;
        uS.removeListener("window_size_changed", this._windowDidResize);
        fS.removeListener("ChangeEvent_FacilityDbUpdate", this._cE_FacilityDbUpdate );
        clearTimeout(this.timer);
        clearTimeout(this.timerDevCon);
    }

    _windowDidResize() {
        const panelSize = this._calculatePanelSize();
        this.setState({ panelSize: panelSize } );
    }

    _cE_FacilityDbUpdate() {

        //console.log("Devices:", fS.getDevices(fS.getFacilityUuidByName("genesis")))


        if (this._isMounted) {
            this.setState({
                facilities: fS.getFacilities(),
                devices: fS.getDevices(fS.getFacilityUuidByName("genesis"))
            });
        }
    }

    _calculatePanelSize() {
        return {width: window.innerWidth - 190, height: window.innerHeight - 240}
    }


    _triggerDevConUpdate() {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of this.state.devices.entries()) {
            const msg = key + " getdevcon";
            websocketCom.sendOutOfBandMessage(
                msg,
                fS.getFacilityUuidByName("genesis")
            );
        }
    }

    _triggerUpdate() {
        // eslint-disable-next-line no-unused-vars
        let msg = "facility activetable";
        websocketCom.sendOutOfBandMessage( msg, fS.getFacilityUuidByName("genesis"));
        msg = "facility devicetable";
        websocketCom.sendOutOfBandMessage( msg, fS.getFacilityUuidByName("genesis"));

        // eslint-disable-next-line
        for (const [key, value] of this.state.devices.entries()) {
            const msg1 = key + " getcluster";
            const msg2 = key + " getdevrole";
            const msg3 = key + " getcoreversion";
            websocketCom.sendOutOfBandMessage(
                msg1,
                fS.getFacilityUuidByName("genesis")
            );
            websocketCom.sendOutOfBandMessage(
                msg2,
                fS.getFacilityUuidByName("genesis")
            );
            websocketCom.sendOutOfBandMessage(
                msg3,
                fS.getFacilityUuidByName("genesis")
            );
        }

    }





        _handleTabChange(event, value) {
        this.setState({ tabSelected: value });
    }

    render() {

//console.log("devices =", this.state.devices)
//console.log("facilities =", this.state.facilities)


        let tabPanel

        switch (this.state.tabSelected) {
            case 0: tabPanel = <Topology panelSize={this.state.panelSize}
                                         devices={this.state.devices}
                                         facilities={this.state.facilities} />;
                    break;
            case 1: tabPanel = <Facilities panelSize={this.state.panelSize}
                                           devices={this.state.devices}
                                           facilities={this.state.facilities} />;
                    break;
            default: break;
        }


        const page = (
            <div>

                <div
                    id="frame"
                    style={{ backgroundColor: "white", border: "1px solid bdbdbd", boxShadow: "0px 0px 8px 0px #bdbdbd",
                        borderRadius: 9, marginTop: 15, paddingBottom: 0 }}
                >
                    {this._renderHeadline()}
                </div>
                <div style={{height: 20}} />
                <div
                    id="frame"
                    style={{ backgroundColor: "white", border: "1px solid bdbdbd", boxShadow: "0px 0px 8px 0px #bdbdbd",
                             borderRadius: 0, paddingBottom: 0 }}
                >
                    <AppBar position="static" color="default" elevation={0}   >
                        <Tabs
                            value={this.state.tabSelected}
                            onChange={this._handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab style={{fontSize: 12}} label="Topology & Devices" />
                            <Tab style={{fontSize: 12}} label="Facilities" />
                        </Tabs>
                    </AppBar>

                    {tabPanel}

                </div>
            </div>
        );

        return page;
    }

    // =============================================================================================================


    _renderHeadline() {
        return (
            <div id="header" style={{ display: "flex", flexDirection: "row" }}>
                <div
                    id="colorblotch"
                    style={{
                        width: 50,
                        height: 50,
                        backgroundColor: "LimeGreen",

                        position: "relative",
                        top: -15,
                        left: 20,
                        boxShadow: "1px 4px 7px 2px #bdbdbd",
                        borderRadius: 4
                    }}
                >
                    <PlatformIcon
                        style={{
                            width: 30,
                            height: 30,
                            color: "white",
                            position: "relative",
                            top: 10,
                            left: 11
                        }}
                    />
                </div>
                <div
                    id="headline"
                    style={{
                        flexGrow: 1,
                        textAlign: "left",
                        paddingTop: -20,
                        paddingLeft: 35
                    }}
                >
                    <div id="type">
                        <Typography
                            variant="overline"
                            style={{ fontSize: 10, color: "#757575" }}
                        >
                            Devices & Facilities
                        </Typography>
                    </div>
                    <div id="text">
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            style={{
                                fontSize: 16,
                                fontWeight: "bold",
                                textAlign: "left",
                                position: "relative",
                                top: -9
                            }}
                        >
                            Platform Management
                        </Typography>
                    </div>
                </div>

            </div>
        );
    }
}
export default  Platform;
