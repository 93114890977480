import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import PropTypes from "prop-types";
import sMS from "./../../stores/SessionManagementStore";

import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import cenoIoTPlatformLogo from "./../../images/CenoIoTLoge-whitetext.svg";
import Authenticate from "./../../components/Authenticate";
import QuickAccess from "./Panels/QuickAccess/QuickAccess";
import Dashboard from "./Panels/Dashboard/Dashboard";
import Services from "./Panels/Service/Service";
import Platform from "./Panels/Platform/Platform";
//import Deployment from "./Panels/Deployment/Deployment";
import Profile from "./Panels/Profile/Profile";
import Manage from "./Panels/Manage/Manage";
import NotificationSign from "./../../components/NotificationSign";
import SearchIcon from "@material-ui/icons/Search";
import { fade } from "@material-ui/core/styles/colorManipulator";
import actionCreators from "./../../actions/ActionCreators";

const drawerWidth = 140;
const logoWidth = 110;
//const logoHeight = 40;
const logoMin = logoWidth;
const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,

  },
  logo: {
    minWidth: logoMin,
    width: logoWidth,
    marginRight: 20
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    marginRight: 25 + "px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 6,
    height: "90%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 6,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 180
      }
    }
  },
  tabLabel: {
    fontSize: 16,
    paddingLeft: 5, paddingRight: 5
  },

  toolbar: theme.mixins.toolbar
});

class MainPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selection: 0,
      searchInput: ""
    };

    this._handleTabChange = this._handleTabChange.bind(this);
    this._handleSearchChange = this._handleSearchChange.bind(this);
    this._buildMainPanel = this._buildMainPanel.bind(this);
  }

  logoutUser() {
    actionCreators.SessionLifecycle("close", {});
    actionCreators.ActiveServiceClose()
  }

  _handleTabChange(event, value) {
    actionCreators.ActiveServiceClose()
    this.setState({ selection: value });
  }

  _handleSearchChange(event) {
    this.setState({ searchInput: event.target.value });
  }
  _buildMainPanel() {
    let mainPanel = <div />;
    switch (this.state.selection) {
      case 0:
        mainPanel = <Services searchInput={this.state.searchInput} />;
        break;
      case 1:
        mainPanel = <Platform searchInput={this.state.searchInput} />;
        break;
      case 2:
        mainPanel = <Dashboard />;
        break;
      case 3:
        mainPanel = <Profile />;
        break;
      case 4:
        mainPanel = <Manage />;
        break;
      default:
        break;
    }
    return mainPanel;
  }
  render() {
    const { classes } = this.props;

//let isDisabled =""
    const adminRights = sMS.getAdminRights()
    let manageTab = (
              <Tab disabled={true} style={{minWidth: 0}}
                label={<span className={classes.tabLabel}>Manage</span>}
              />    )
    if (adminRights === "full") {
        manageTab = (
              <Tab style={{minWidth: 0}}
                label={<span className={classes.tabLabel}>Manage</span>}
              />
        )
    }



    return (
      <div className={classes.root}>
        <Authenticate />
        <NotificationSign />
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classes.appBar}
          style={{ backgroundColor: "#424242" }}
        >
          <Toolbar variant="dense" >
            <source srcSet={cenoIoTPlatformLogo} type="image/svg+xml" />
            <img
              className={classes.logo}
              src={cenoIoTPlatformLogo}
              alt="CenoLabs Logo"
            />

            <Tabs
              value={this.state.selection}
              onChange={this._handleTabChange}
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons="desktop"
             >
              <Tab style={{minWidth: 0}} label={<span className={classes.tabLabel}>Services</span>} />
              <Tab style={{minWidth: 0}} label={<span className={classes.tabLabel}>Platform</span>} />
              <Tab style={{minWidth: 0}} label={<span className={classes.tabLabel}>Logging</span>} />
              <Tab style={{minWidth: 0}} label={<span className={classes.tabLabel}>Profile</span>} />
              {manageTab}
            </Tabs>
            <div style={{ flexGrow: 1 }} />
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Filter by name"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                onChange={this._handleSearchChange}
              />
            </div>

            <Button color="inherit" onClick={this.logoutUser}>
              Sign Out
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.toolbar} />
          <QuickAccess searchInput={this.state.searchInput} />
        </Drawer>

        <main className={classes.content}>
          <div className={classes.toolbar} />

          {this._buildMainPanel()}
        </main>
      </div>
    );
  }
}

MainPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(MainPage);
