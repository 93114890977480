import React from "react";
//import websocketCom from '../../../../webcom/WebsocketCom';
import aSDS from '../../../../stores/ActiveServiceDataStore';
import InterfaceRep from './../KingPigeon/InterfaceRep';


// so1 attribute set IO_device_setup "{\"type\":\"KingPigeon\", \"model\":\"M120T\",\"name\": \"CenoLabs Device Name\", \"width\":\"120\", \"height\":\"280\"}"
//so1 setattribute IO_device_data '{ "DIN1": {"name":"signal1", "value": true },"DIN2": {"name":"signal2", "value": false }, "DO1": {"name":"signal4", "value": false } }'

class OpenEdge_ComputeDevice extends React.Component {

  constructor(props) {
    super(props);

    //this.timer = null;

    this.state = {IO_device_data: {} }

    this._cE_AttributeUpdate = this._cE_AttributeUpdate.bind(this);
    //this._fetchAttributeData = this._fetchAttributeData.bind(this);
  }

  componentDidMount() {
    aSDS.on( "change", this._cE_AttributeUpdate );
    this._cE_AttributeUpdate();
    //this.timer = setInterval(this._fetchAttributeData, 2000);
  }

  componentWillUnmount() {
    aSDS.removeListener( "change", this._cE_AttributeUpdate );
    //clearTimeout(this.timer);
  }

  _cE_AttributeUpdate() {
    let IO_device_data = aSDS.getAttribute(this.props.soName, "IO_device_data")
    if (IO_device_data && IO_device_data !== "nil ") {
      IO_device_data = JSON.parse(IO_device_data);
      if (IO_device_data === null) { IO_device_data = {} }
    } else {IO_device_data = {} }
    this.setState({IO_device_data: IO_device_data});
  }


  //_fetchAttributeData() {
  //  const msg = this.props.soName+" getattribute IO_device_data";
  //  websocketCom.sendServiceMessage(msg);
  //}


  render() {

      const deviceFrontLeft = (
        <div style={{display: "flex", flexDirection: "column", border: "1px solid #4f4f4f", padding: 5}} >
        <InterfaceRep interface={"DIN1"} data={this.state.IO_device_data["DIN1"]} orientation={"left"} />
        <InterfaceRep interface={"DIN2"} data={this.state.IO_device_data["DIN2"]} orientation={"left"} />
        </div>
      )

      const deviceFrontRight = (
          <div style={{display: "flex", flexDirection: "column", border: "1px solid #4f4f4f", padding: 5}} >
          <InterfaceRep interface={"DO1"} data={this.state.IO_device_data["DO1"]} orientation={"right"} />
          <InterfaceRep interface={"DO2"} data={this.state.IO_device_data["DO2"]} orientation={"right"} />
          </div>
        )


    const deviceView =
          <div>
            <div style={{height: 0}} />
            <div style={{display: "flex", flexDirection: "row"}}>
              <div style={{flexGrow: 1}} />
              {deviceFrontLeft}
              <div style={{width: 15}} />
              {deviceFrontRight}
              <div style={{flexGrow: 1}} />
            </div>
            <div style={{height: 20}} />
            <div style={{fontFamily: "Roboto", fontSize: 13, fontWeight: "bold",
                                textAlign: "center", color: "WhiteSmoke"}} >
              {this.props.name}
            </div>
          </div>

    return deviceView;

  }
}

export default OpenEdge_ComputeDevice;
