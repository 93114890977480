import React from "react";
import websocketCom from "./../../../../webcom/WebsocketCom.js";
import UserManagementPanel from "./UserManagementPanel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

class GlobalSettings extends React.Component {
  constructor() {
    super();

    this.state = {
      showConfDelServiceDialog: false
    };
    this._handleClose = this._handleClose.bind(this);
    this._deleteServicePressed = this._deleteServicePressed.bind(this);
    this._deleteService = this._deleteService.bind(this);
  }

  _getActiveUserPermission() {
    return this.props.userList.get(this.props.activeUser);
  }

  _handleClose() {
    this.setState({ showConfDelServiceDialog: false });
  }

  _deleteServicePressed() {
    this.setState({ showConfDelServiceDialog: true });
  }

  _deleteService() {
    this.setState({ showConfDelServiceDialog: false });
    const msg = "service kill now";
    websocketCom.sendServiceMessage(msg);
  }

  _buildDeleteServicePanel() {
    const panel = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end"
        }}
      >
        <div
          style={{
            minWidth: 450 + "px"
          }}
        >
          <div>
            <Typography style={{fontSize: 14, fontWeight: "bold"}} >Delete This Service</Typography>
          </div>
          <div>
            <Typography variant="caption">
              Once you deleted a service, there is no going back. Please be
              certain.
            </Typography>
          </div>
        </div>
        <div
          style={{
            paddingBottom: 7 + "px",
            paddingLeft: 30 + "px"
          }}
        >
          <Button
            id="inputbutton"
            color="secondary"
            size="small"
            onClick={this._deleteServicePressed.bind(this)}
          >
            Delete Service
          </Button>
        </div>
      </div>
    );
    return panel;
  }

  _buildConfDelServiceDialog() {
    const panel = (
      <div>
        <Dialog
          open={this.state.showConfDelServiceDialog}
          onClose={this._handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Service"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action will delete the service and there is no going back.
              Confirm requested service deletion.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this._deleteService} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
    return panel;
  }

  render() {
    const permission = this._getActiveUserPermission();
    if (permission !== "admin") {
      const panel = (
        <div>
          <div style={{ marginLeft: 50 + "px", marginTop: 50 + "px" }}>
            Only users with admin permission can perform global service changes!
          </div>
          <div style={{ minHeight: 70 + "px" }} />
        </div>
      );
      return panel;
    }
    const panel = (
      <div>
        {this._buildConfDelServiceDialog()}
        <div>
          <UserManagementPanel
            activeUser={this.props.activeUser}
            userList={this.props.userList}
          />
          {/*this._buildDeleteServicePanel()*/}
        </div>
        <div style={{ height: 40 + "px" }} />
      </div>
    );
    return panel;
  }
}
export default GlobalSettings;
