
import React from "react";
//import * as d3 from "d3";
import PerfChart from "./PerformanceChart";

class PerformanceGraph extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
      this._buildDataPool = this._buildDataPool.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        }

  _buildDataPool() {
   
    let data = []
    // eslint-disable-next-line
        for (const [uuid, deviceData] of this.props.devices) {
          if (deviceData.get("active")) {
          const dataObject = { "name": deviceData.get("name"),
                               "util" : deviceData.get("devcon").get("utilization")[1]*1000,
                               "status" : deviceData.get("devcon").get("status") }
          data.push(dataObject);
          }  
        }
    return data
  }

  render() {
    const data = this._buildDataPool();
    const dataLength = data.length;

    let graphHeight = 20*dataLength + 20 + 20;
    if (graphHeight > 200) graphHeight = 200;
    

    return <div style={{width:400, height: graphHeight}}>
      
      <PerfChart data={data} height={graphHeight}/>

      </div>
  }
}


export default PerformanceGraph;

