// eslint-disable-next-line
import React from "react";
import { EventEmitter } from "events";

import aSDS from "./../../stores/ActiveServiceDataStore";
import websocketCom from "./../../webcom/WebsocketCom";


class MethodManager extends EventEmitter {

  constructor() {
    super();
    this.setMaxListeners(50)
    this.activeMethod = "";
    this.openMethods = {};
  }

  reset() {
    this.activeMethod = "";
    this.openMethods = {}
  }

  cE_DataStoreUpdate(type) {
    switch (type) {
      case "change":   // response to add/delete method
        this.checkConsistency();
        this._updateMethodCodeFromCore();
        break;
      case "MethodCodeChange":  // response to retrievesource (pull)
        //console.log("mM: MethodCodeChange");
        break;
      case "MethodCodeUpload": // response to loadmethod (push)
        //console.log("mM: MethodCodeUpload");
        //this._getMethodUploadStatus();
        break;
      case "ActualSetupChange":
        //console.log("mM: ActualSetupChange");
        break;
      default:
        break;
    }
  }


  _updateMethodCodeFromCore() {
    // iterate over all open SO methods and update
    let isChanged = false;
    for (const methodId in this.openMethods) {
      const soName = methodId.split(" ")[0];
      const methodName = methodId.split(" ")[1];
      const methodCode = aSDS.getMethodCode(soName, methodName);
      if (methodCode !== null) {
        this.openMethods[methodId].code = methodCode;
        this.openMethods[methodId].changed = false;

        const status = aSDS.getSmartObjectData(soName).get("methods").get(methodName).get("status");
        const resp = aSDS.getSmartObjectData(soName).get("methods").get(methodName).get("resp");
        this.openMethods[methodId].loadStatus = status;
        this.openMethods[methodId].loadResponse = resp;

        isChanged = true;
      }

    }
    if (isChanged) { this.emit("MM_dataChange") }
  }



  //_getMethodUploadStatus() {
  //  for (const methodId in this.openMethods) {
  //    const soName = methodId.split(" ")[0];
  //    const methodName = methodId.split(" ")[1];
  //    const status = aSDS.getSmartObjectData(soName).get("methods").get(methodName).get("status");
  //    const resp = aSDS.getSmartObjectData(soName).get("methods").get(methodName).get("resp");
  //    this.openMethods[methodId].loadStatus = status;
  //    this.openMethods[methodId].loadResponse = resp;
  //  }
  //}





  checkConsistency() {
    let deleteContainer = []
    for (const methodId in this.openMethods) {
      const soName = methodId.split(" ")[0];
      const methodName = methodId.split(" ")[1];
      if (! aSDS.checkSmartObjectMethodExist(soName, methodName)) {
        deleteContainer.push(methodId);
      }
      if (deleteContainer.length > 0) {
        for (const id in deleteContainer) {
          const methodId = deleteContainer[id];
          if (this.activeMethod === methodId) { this.activeMethod = "" }

          delete this.openMethods[methodId];
        }
        this.emit("MM_dataChange")
      }
    }
  }


  pushCode() {
    const method = this.openMethods[this.activeMethod]
    const code = '"'+method.code+'"';
    const msg = method.soName+" loadmethod "+method.methodName+" "+code;
    websocketCom.sendServiceMessage(msg);
    this.pullCode();
  }


  pullCode() {
    const method = this.openMethods[this.activeMethod]
    const msg = method.soName+" retrievesource "+method.methodName;
    websocketCom.sendServiceMessage(msg);
  }


  runMethod() {
    const method = this.openMethods[this.activeMethod]    
    const msg = method.soName+" call "+method.methodName;
    websocketCom.sendServiceMessage(msg);
  }


  openMethod(soName, methodName) {
    const methodId = soName+" "+methodName;

    // check if method already open...
    if (methodId in this.openMethods) {
      console.log("method already open. Make it active...");
      // check that user still has lock in service database or is unlocked
      // TODO: put code here

      // user owns method lock, make method active in editor
      this.activeMethod = methodId;
    } else {
      // new open method that is put into the methods list
      const method = this._createMethodData(soName, methodName);
      this.openMethods[methodId] = method;
      // now make it the active method in the editor
      this.activeMethod = methodId;
    }
    this.emit("MM_dataChange")
  }


  closeMethod(soName, methodName) {
    const methodId = soName+" "+methodName;
    if (this.activeMethod === methodId) {this.activeMethod = "" }
    delete this.openMethods[methodId];
    this.emit("MM_dataChange")
  }

  getMethods() { return this.openMethods;}

  getActiveMethod() { return this.activeMethod }

  checkOpen(soName, methodName) {
    const methodId = soName+" "+methodName;
    let response = false;
    if (methodId in this.openMethods) {
      response = true;
    }
    return response;
  }

  checkChanged(soName, methodName) {
    const methodId = soName+" "+methodName;
    let response = false;
    if (methodId in this.openMethods) {
      response = this.openMethods[methodId].changed
    }
    return response;

  }

  getActiveCode() {
    let code = "";
    if (this.activeMethod !== "" && this.activeMethod in this.openMethods) {
      code = this.openMethods[this.activeMethod].code;
    }
    return code
  }

  setActiveCode(code) {
    if (this.activeMethod !== "") {
      this.openMethods[this.activeMethod].code = code;

      if (!this.openMethods[this.activeMethod].changed) {
        this.openMethods[this.activeMethod].changed = true;
        //this.emit("MM_dataChange");
      }
      this.emit("MM_dataChange")
    }
  }


  _createMethodData(soName, methodName) {

    // check if there is data in the database so

    // if no data in service database so  we fetch from the core
    const lockStatus = { "activeUse": true, "status": "", "activeUser": "" }

    const method = { "soName": soName,
                     "methodName": methodName,
                     "code": "",
                     "changed": false,
                     "loadStatus": "",
                     "loadResponse": "",
                     "callResponse": "",
                     "lockStatus": lockStatus
                   }
   const msg = soName + " retrievesource " + methodName;
   websocketCom.sendServiceMessage(msg)

    return method;
  }



}

const methodManager = new MethodManager();
export default methodManager;
