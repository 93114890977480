import React from "react";
import websocketCom from '../../../webcom/WebsocketCom';
import aSDS from '../../../stores/ActiveServiceDataStore';
import fS from '../../../stores/FacilitiesStore';
import gLS from '../../../stores/GraphLayoutStore';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import LocationOnIcon from '@material-ui/icons/LocationOn';
//import GlobalIcon from '@material-ui/icons/Public';
import TypeIcon from '@material-ui/icons/Explore';
import BugReportIcon from '@material-ui/icons/BugReport';

class SoStatusLine extends React.Component {
  constructor(props) {
    super(props);

    this.timer = null;

    this._getActual = this._getActual.bind(this);
    //this._stopBubble = this._stopBubble.bind(this);
  }

  componentDidMount() {
    const jitter = Math.floor(Math.random() * 2000);
    this.timer = setInterval(this._getActual, 4000+jitter)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }


  _getActual() {
    if (this.props.graphData.graph_extension.view === "large") {
      const msg = this.props.soName+" getactual";
      websocketCom.sendServiceMessage(msg);
    }
  }

  //_clickGlobals(event) {
  //  event.stopPropagation();
  //  gLS.toggleGlobalView(this.props.soName)
  //}

  _clickDebug(event) {
    event.stopPropagation();
    gLS.toggleDebugView(this.props.soName);
  }

  _stopBubble(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const actualLocation = fS._getDeviceNameFromUuid(this.props.mapping.get("id"));
    const type = aSDS.getSOType(this.props.soName);
    const firstLocation = this.props.mapping.get("locations")[0];

    let locationIcon = ( <LocationOnIcon />)
    if (firstLocation === "freefloat") { locationIcon = ( <LocationOffIcon /> ) }

    //let global_icon_col = this.props.so_ac_col;
    let debug_icon_col = this.props.so_ac_col;
    //if (this.props.showGlobal.includes(this.props.soName)) {global_icon_col="white"}
    if (this.props.showDebug.includes(this.props.soName)) {debug_icon_col="white"}

    const view = (
      <div style={{display: "flex", flexDirectin: "row", color: "white"}} >
        <div style={{marginLeft: 10, marginRight: 3}} > {locationIcon}  </div>
        <div style={{fontFamily: "Roboto", fontSize: 15, fontWeight: "normal", paddingTop: 1}} >
          {actualLocation}
        </div>
        <div style={{width: 20}} />
        <TypeIcon style={{width: 18}} />
        <div style={{fontFamily: "Roboto", fontSize: 15, fontWeight: "normal", paddingTop: 2, paddingLeft: 5}} >
          {type}
        </div>
        <div style={{flexGrow: 1}} />

        <div style={{color: debug_icon_col, marginRight: 8 }}
             onClick={(event) => this._clickDebug(event) }
             onContextMenu={(event) => {this._stopBubble(event)}}
             onMouseDown={(event) => {this._stopBubble(event)}}
        >
          <BugReportIcon style={{width: 24, height: 24, marginTop: -5}}/>
        </div>

      </div> )

    return view;
  }

}

export default SoStatusLine;

