import {useD3} from './../../../../../hooks/useD3';
import React from 'react';
import * as d3 from 'd3';

function HistogramChart(props) {
  //let data = props.data;
  let metrikName = 'undefined';
  if (props.metrikName) metrikName = props.metrikName;

  let maxValue = d3.max(props.data, d => d);
  const numEntries = Object.keys(props.data).length;

  const ref = useD3(
    svg => {
      const height = 130;
      const width = 295;
      const margin = {top: 5, right: 5, bottom: 35, left: 55};

      var x = d3
        .scaleLinear()
        .domain([0, maxValue])
        .range([margin.left, width]);

      const xAxis = g =>
        g
          .style('font-size', '14px')
          .style('font-weight', 'bold')
          .attr('transform', `translate(0,${height})`)
          .call(d3.axisBottom(x).ticks(4));

      // set the parameters for the histogram
      var histogram = d3
        .histogram()
        .value(d => +d) // I need to give the vector of value
        .domain(x.domain()) // then the domain of the graphic
        .thresholds(x.ticks(20)); // then the numbers of bins

      // And apply this function to data to get the bins
      var bins = histogram(props.data);

      // Y axis: scale and draw:
      var y = d3.scaleLinear().range([height, 0]);
      y.domain([
        0,
        d3.max(bins, function(d) {
          return d.length;
        }),
      ]); // d3.hist has to be called before the Y axis obviously

      const yAxis = g =>
        g
          .attr('transform', `translate(${margin.left},0)`)
          .style('font-size', '14px')
          .style('font-weight', 'bold')
          .call(d3.axisLeft(y).ticks(3));

      svg.select('.x-axis').call(xAxis);
      svg.select('.y-axis').call(yAxis);

      svg
        .select('.plot-area')
        .selectAll('rect')
        .data(bins)
        .join('rect')
        .attr('x', 1)
        .attr('transform', function(d) {
          return 'translate(' + x(d.x0) + ',' + y(d.length) + ')';
        })
        .attr('width', function(d) {
          return x(d.x1) - x(d.x0);
        })
        .attr('height', function(d) {
          return height - y(d.length);
        })
        .style('fill', 'white');
    },
    [props.stateChange],
  );

  return (
    <svg
      ref={ref}
      style={{
        height: 150,
        width: 330,
        marginRight: '0px',
        marginLeft: '0px',
      }}>
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );
}

export default HistogramChart;
