import React from "react";
import dragDropStore from "./DragDropStore";
import gLS from "./../../stores/GraphLayoutStore";
import SmartObjectNG from "./SO_Details/DrawSO_NG";
import LinkNG from "./Link_NG";
import pM from "./../PopUp/PopupManager";
import Hotkeys from 'react-hot-keys';
import keyEventManager from "./../KeyEventManager/KeyEventManager";
import selectionManager from "./../SelectionManager/SelectionManager";

import mM from "./../CodeDev/MethodManager";
import aSDS from "./../../stores/ActiveServiceDataStore";
import config from "./../config"

class ServiceGraph extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    // define canvas size
    this.canvasSize = { width: config.setup.canvas.width, height: config.setup.canvas.height };

    this.state = { //showGlobal: gLS.getGlobalView(),
                   showDebug: gLS.getDebugView(),
                   scaleFactor: gLS.getScaleFactor(),
                   graphLayout: gLS.getGraphLayout(),
                   linkList: gLS.getLinkList(),
                   soStackTop: "" };

    this._cE_GraphLayoutUpdate = this._cE_GraphLayoutUpdate.bind(this);
    this.renderLinkLayout_NG = this.renderLinkLayout_NG.bind(this);
    this.renderSOs_NG = this.renderSOs_NG.bind(this);
    this._cE_DataStoreUpdate = this._cE_DataStoreUpdate.bind(this);
    this._cE_MethodCodeChange = this._cE_MethodCodeChange.bind(this);
    this._cE_MethodCodeUpload = this._cE_MethodCodeUpload.bind(this);
    this._cE_ActualSetupChange = this._cE_ActualSetupChange.bind(this);
    this._onKeyUp = this._onKeyUp.bind(this);
    this._callbackIsStackTop = this._callbackIsStackTop.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    gLS.on( "ChangeEvent_GraphLayoutUpdate", this._cE_GraphLayoutUpdate );
    pM.on( "popupUpdate", this._cE_GraphLayoutUpdate );
    aSDS.on("change", this._cE_DataStoreUpdate );
    aSDS.on("MethodCodeChange", this._cE_MethodCodeChange );
    aSDS.on("MethodCodeUpload", this._cE_MethodCodeUpload );
    aSDS.on("ActualSetupChange", this._cE_ActualSetupChange );
  }

  componentWillUnmount() {
    this._isMounted = false;
    gLS.removeListener( "ChangeEvent_GraphLayoutUpdate", this._cE_GraphLayoutUpdate );
    pM.removeListener( "popupUpdate", this._cE_GraphLayoutUpdate );
    aSDS.removeListener("change", this._cE_DataStoreUpdate );
    aSDS.removeListener("MethodCodeChange", this._cE_MethodCodeChange );
    aSDS.removeListener("MethodCodeUpload", this._cE_MethodCodeUpload );
    aSDS.removeListener("ActualSetupChange", this._cE_ActualSetupChange );

    mM.reset();
    selectionManager.reset();
    gLS.reset();
  }

  _cE_GraphLayoutUpdate() {
    if (this._isMounted) {
      this.setState({ //showGlobal: gLS.getGlobalView(),
                      showDebug: gLS.getDebugView(),
                      scaleFactor: gLS.getScaleFactor(),
                      graphLayout: gLS.getGraphLayout(),
                      linkList: gLS.getLinkList() })
    }
  }

  _cE_DataStoreUpdate() {mM.cE_DataStoreUpdate("change")}
  _cE_MethodCodeChange() {mM.cE_DataStoreUpdate("MethodCodeChange")}
  _cE_MethodCodeUpload() {mM.cE_DataStoreUpdate("MethodCodeUpload")}
  _cE_ActualSetupChange() {mM.cE_DataStoreUpdate("ActualSetupChange")}



  _callbackIsStackTop(soStackTop) {
    this.setState({soStackTop: soStackTop})
  }

  _handleMouseUp() {
    dragDropStore.mouseUpEventReceived("canvas");
    pM.deletePopups();
  }

  _handleClick() {
    //console.log("handle click")
    selectionManager.clearSelect();
  }

  _handleContext(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  _onKeyUp(keyName, e, handle) {
    //console.log("Delete Key pressed!:", keyName, e, handle)
    keyEventManager.keyPressedEvent(keyName);
  }


  render() {
    let view = <div />;
    const soContainerNG = this.renderSOs_NG();
    const linkContainerNG = this.renderLinkLayout_NG();
    const borderNG = this.renderBorder_NG();
    const popups = pM.getPopups();

    const viewbox = "0 0 "+this.canvasSize.width+" "+this.canvasSize.height;
    view = (
        <div id="svg-scroll-container"

        >
          <svg id="svgPanel" key="svgPanel" viewBox={viewbox}
               style={{ width: this.canvasSize.width * this.state.scaleFactor,
                        height: this.canvasSize.height * this.state.scaleFactor }}>


            <defs>
              <pattern id="caro-pattern" x="0" y="0" width="30" height="30" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="30" y2="0" strokeWidth="1" stroke="LightGray">
                </line>
                <line x1="0" y1="0" x2="0" y2="30" strokeWidth="1" stroke="LightGray">
                </line>

              </pattern>
            </defs>
            <rect x="0" y="0" width="100%" height="100%" fill="url(#caro-pattern)"
                  onMouseUp={ () => this._handleMouseUp() }  // needed to detect unsuccessfull drag drop for port connection
                  onClick={ () => this._handleClick() }  // meeded to clear selection if canvas clicked
                  onContextMenu={ (event) => this._handleContext(event) }

            >

            </rect>

            <Hotkeys keyName = "Delete, ctrl+x, Escape, ctrl+p, ctrl+m, ctrl+i, ctrl+o"
                     //onKeyDown={this._onKeyDown.bind(this)}
                     onKeyUp={this._onKeyUp} >
            {borderNG}
            {linkContainerNG}
            {soContainerNG}
            {popups}
            </Hotkeys>
          </svg>
        </div>
      );
    //}
    return view;
  }


  renderBorder_NG() {
    const borderWidth = config.setup.canvas.borderWidth;
    const lineMiddle = borderWidth/2;
    const canvasWidth = config.setup.canvas.width;
    const canvasHeight = config.setup.canvas.height;
    const b_col = config.setup.canvas.border_col;
    let borderContainer = [];
    const borderFigure = (
      <g key="border">

          <line x1="0" y1={lineMiddle} x2={canvasWidth-lineMiddle} y2={lineMiddle} stroke={b_col} strokeWidth={borderWidth} />
          <line x1={borderWidth} y1={borderWidth} x2={canvasWidth-borderWidth} y2={borderWidth} stroke="Gray" strokeWidth="1" />

          <line x1={lineMiddle} y1="0" x2={lineMiddle} y2={canvasHeight-lineMiddle} stroke={b_col} strokeWidth={borderWidth} />
          <line x1={borderWidth} y1={borderWidth} x2={borderWidth} y2={canvasHeight-borderWidth} stroke="Gray" strokeWidth="1" />

        <line x1={canvasWidth-lineMiddle} y1="0" x2={canvasWidth-lineMiddle} y2={canvasHeight} stroke={b_col} strokeWidth={borderWidth} />
        <line x1={canvasWidth-borderWidth} y1={borderWidth} x2={canvasWidth-borderWidth} y2={canvasWidth-borderWidth} stroke="Gray" strokeWidth="1" />

        <line x1="0" y1={canvasHeight-lineMiddle} x2={canvasWidth} y2={canvasHeight-lineMiddle} stroke={b_col} strokeWidth={borderWidth} />
        <line x1={borderWidth} y1={canvasHeight-borderWidth} x2={canvasWidth} y2={canvasHeight-borderWidth} stroke="Gray" strokeWidth="1" />
      </g>
    );
    borderContainer.push(borderFigure);
    return borderContainer
  }


  renderLinkLayout_NG() {
    let linkContainer = [];
    //const linkList = gLS.getLinkList();

    let linkCounter = 0
    for (const link of this.state.linkList) {
      const linkFigure = ( <LinkNG key={"linkng-"+link.name} link={link} linkCounter={linkCounter} /> );

      linkContainer.push(linkFigure);
      linkCounter++;
    }
    // <line x1={link.x1} y1={link.y1} x2={link.x2} y2={link.y2} stroke="gray" strokeWidth="4" />
    return linkContainer;
  }


  renderSOs_NG() {
    let soContainer = [];

    for (const so in this.state.graphLayout) {
      if (aSDS.checkSoExist(so)) {

        if (so !== this.state.soStackTop) {
        let soFigure = ( <SmartObjectNG key={so}
                                        soName={so}
                                        soData={aSDS.getSmartObjectData(so)}
                                        graphData={this.state.graphLayout[so]}
                                        scaleFactor={this.state.scaleFactor}
                                        //showGlobal={this.state.showGlobal}
                                        showDebug={this.state.showDebug}
                                        callbackIsStackTop={this._callbackIsStackTop} /> );
        soContainer.push(soFigure);
        }
      }
    }
    
    // finally take care of so top of drawing stack
    if (aSDS.checkSoExist(this.state.soStackTop)) {
    let soFigure = ( <SmartObjectNG key={this.state.soStackTop}
                                    soName={this.state.soStackTop}
                                    soData={aSDS.getSmartObjectData(this.state.soStackTop)}
                                    graphData={this.state.graphLayout[this.state.soStackTop]}
                                    scaleFactor={this.state.scaleFactor}
                                    //showGlobal={this.state.showGlobal}
                                    showDebug={this.state.showDebug}
                                    callbackIsStackTop={this._callbackIsStackTop} /> );
    soContainer.push(soFigure);
    }
    
    return soContainer
  }

}

export default ServiceGraph
