import React from "react";
import sMS from "./../../../../stores/SessionManagementStore";
import AddMember from "./AddMember";
import DeleteMember from "./DeleteMember";
import ResetPassword from "./ResetPassword";
// -------------------------------------------------------------
// material-ui
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PeopleIcon from "@material-ui/icons/People";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
// -------------------------------------------------------------


class Manage extends React.Component {
  _isMounted = false;

  constructor() {
    super();

    this.userMenuOpen = false

    this.state = {
        menuSelect: "Members",
        memberSelect: "",
        usersData: {},
        comError: false,
        userMenuAnchor: null,
        showModal_DialogType: ""
    };

    this._getUsersData = this._getUsersData.bind(this)
    this._handleUserMenuClicked = this._handleUserMenuClicked.bind(this)
    this._closeUserMenu = this._closeUserMenu.bind(this)
    this._renderHeadline = this._renderHeadline.bind(this)
    this._renderMenu = this._renderMenu.bind(this)
    this._renderDetails = this._renderDetails.bind(this)
    this.closeDialog = this.closeDialog.bind(this)

  }

  componentDidMount() {
    this._isMounted = true;
    // eslint-disable-next-line
    const response = this._getUsersData()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }




 _getUsersData() {

    const tokenRequestData = sMS.getAuthUpdateData()
    const url = "https://" + tokenRequestData.url + "/api/users";
    const dataSet_json = JSON.stringify(tokenRequestData.reAuthData)
    const isAsynchronous = true;
    let response;
    let xhr = new XMLHttpRequest();

    const _this = this;
    xhr.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        let response = JSON.parse(xhr.responseText);
        if (response.status === "error") {
          console.log("ERROR: Failed to retrieve users infomration");
          _this.setState({comError: true})
        } else {
          delete response.status
          _this.setState({usersData: response})
        }
      }
    };
    xhr.onerror = function() {
      response = {
        status: "error",
        description: "Could not connect to api authority."
      };
      console.log(response);
      _this.setState({comError: true})
    };

    xhr.open("POST", url, isAsynchronous);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.send(dataSet_json);
  }



  _handleUserMenuClicked(event) {
    this.userMenuOpen = true
    this.setState({userMenuAnchor: event.currentTarget})
  }

  _closeUserMenu(event, target) {
    this.userMenuOpen = false
    this.setState({userMenuAnchor: null})
    if (target !== "") {
      this.setState({showModal_DialogType: target})
    }
  }

  _addMember(event) {
    this.setState({showModal_DialogType: "add"})
  }

  closeDialog() {
    this.setState({showModal_DialogType: "",
                   memberSelect: ""})
    this._getUsersData()
  }

  _renderMenu() {
    return (
    <div id="menu">
    <div style={{marginTop: 40}} />
    <MenuList>
      <MenuItem dense={true} onClick={() => this.setState({menuSelect:"Members"})}>Members</MenuItem>
      <MenuItem dense={true} disabled={true} onClick={() => this.setState({menuSelect:"Teams"})}>Teams</MenuItem>
      <MenuItem dense={true} disabled={true} onClick={() => this.setState({menuSelect:"Collaborators"})}>Collaborators</MenuItem>
    </MenuList>
    </div>
    );
  }

  _renderMembers() {

      let memberContainer = []

      const members = this.state.usersData
      for (const member in members) {
        const data = members[member]
        let avatarStyle = {}
        member === this.state.memberSelect ? avatarStyle={backgroundColor: "#9c27b0"} : avatarStyle={}
        const entry = (
            <div key={member} onClick={() => this.setState({memberSelect: member}) }>
                <div style={{display: "flex", flexDirection: "row"}} >

                        <Avatar style={avatarStyle} >{data.given_name.charAt(0)}{data.family_name.charAt(0)}</Avatar>
                        <div style={{paddingLeft: 20, paddingTop: 3}}>
                            <div><Typography style={{fontWeight:"bold"}}>{data.given_name} {data.family_name}</Typography></div>
                            <div style={{marginTop: -3}}>{member}</div>
                        </div>
                </div>
                <div style={{height: 15}} />
            </div>
        )
        memberContainer.push(entry)
      }

    const panel =(
        <div>
            <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
                <div> <Typography style={{fontSize: 14, fontWeight:"bold"}}>Members</Typography></div>
                <div style={{width: 40}} />
                {/*<Fab size="small" color="primary" variant="extended" aria-label="add">
                    <AddIcon />
                    <div style={{width: 10}} />
                    Add New Member
                </Fab> */}
                <Button color="primary" size="small" variant="outlined" onClick={(e)=>this._addMember(e)} >Add Member</Button>

            </div>
            <div style={{height: 25}} />
            <div>{memberContainer}</div>
        </div>
    )

    return panel
  }

  _renderDetails() {
    let panel = <div />
    if (this.state.memberSelect === "") return panel

    const data = this.state.usersData[this.state.memberSelect]
    panel = (
        <div>
            <div style={{height: 40}} />
            <div style={{display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div> <Typography style={{fontSize: 16, fontWeight:"bold"}}>{data.given_name} {data.family_name}</Typography></div>
                <div style={{paddingLeft: 60}}>
                    <IconButton onClick={this._handleUserMenuClicked}>
                        <SettingsIcon />
                    </IconButton>
                    <Menu open={this.userMenuOpen} onClose={(e)=>this._closeUserMenu(e,"")} anchorEl={this.state.userMenuAnchor} >
                        <MenuItem key="update" disabled={true} dense={true} onClick={(e)=>this._closeUserMenu(e,"update")}>Update User Data</MenuItem>
                        <MenuItem key="reset" dense={true} onClick={(e)=>this._closeUserMenu(e,"reset")}>Reset Password</MenuItem>
                        <MenuItem key="delete" dense={true} onClick={(e)=>this._closeUserMenu(e,"delete")}>Delete This Member</MenuItem>
                    </Menu>

                </div>
            </div>
            <div style={{height: 5}} />
            <div>
                {this._renderText(this.state.memberSelect,"Short Name")}
                {this._renderText(data.email,"email")}
                <div style={{display: "flex", flexDirection: "row"}}>
                    {this._renderText(data.department,"Department")}
                    <div style={{width: 50}} />
                    {this._renderText(data.city,"City")}
                </div>
                {this._renderText(data.admin_rights,"Platform Admin Rights")}

            </div>
        </div>
    )

    return panel
  }



  render() {

    //let dialogPanel = (<div />)
    let showDialog_add = false
    let showDialog_delete = false
    let showDialog_reset = false
    let showDialog_update = false

    switch (this.state.showModal_DialogType) {
        case "add":
            showDialog_add = true
            break;
        case "delete":
            showDialog_delete = true
            break;
        case "reset":
            showDialog_reset = true
            break;
        case "update":
        // eslint-disable-next-line
            showDialog_update = true
            break;
        default:
            break;
    }

    const page = (
      <div>

        <AddMember
          isActive={showDialog_add}
          handle_func_closeDialog={this.closeDialog}
        />

        <DeleteMember
          isActive={showDialog_delete}
          handle_func_closeDialog={this.closeDialog}
          member={this.state.memberSelect}
        />

        <ResetPassword
          isActive={showDialog_reset}
          handle_func_closeDialog={this.closeDialog}
          member={this.state.memberSelect}
        />

        <div style={{height: 0}} />
        <div
          id="frame"
          style={{
            backgroundColor: "white",
            border: "1px solid bdbdbd",
            boxShadow: "0px 0px 8px 0px #bdbdbd",
            width: this.width,
            maxWidth: this.width,
            borderRadius: 9,
            marginTop: 15,
            paddingBottom: 0
          }}
        >
        {this._renderHeadline()}
        </div>

        <div style={{height: 20}} />



        <div
          id="frame"
          style={{
            backgroundColor: "white",
            border: "1px solid bdbdbd",
            boxShadow: "0px 0px 8px 0px #bdbdbd",
            width: this.width,
            maxWidth: this.width,
            borderRadius: 0,
            paddingTop: 15,
            paddingBottom: 10
          }}
        >
        <div style={{display: "flex", flexDirection: "row"}} >

            <div style={{paddingLeft: 40}}>
                {this._renderMembers()}
            </div>

            <div style={{paddingLeft: 90}}>
                {this._renderDetails()}
            </div>
        </div>
        </div>



</div>
    );

    return page;
  }

  // =============================================================================================================



  _renderText(value, topic) {
    return (
      <div style={{ textAlign: "left"}}>
        <div id="number">
          <Typography
            variant="body1"
            style={{
              fontSize: 14,
              fontWeight: "medium"
            }}
          >
            {value}
          </Typography>
        </div>
        <div
          id="caption"
          style={{ position: "relative", top: -9}}
        >
          <Typography
            variant="overline"
            style={{
              fontSize: 9,
              color: "#1757575"
            }}
          >
            {topic}
          </Typography>
        </div>
      </div>
    );
  }

  _renderHeadline() {
    return (
      <div id="header" style={{ display: "flex", flexDirection: "row" }}>
        <div
          id="colorblotch"
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#9c27b0",

            position: "relative",
            top: -15,
            left: 20,
            boxShadow: "1px 4px 7px 2px #bdbdbd",
            borderRadius: 4
          }}
        >
          <PeopleIcon
            style={{
              width: 30,
              height: 30,
              color: "white",
              position: "relative",
              top: 10,
              left: 10
            }}
          />
        </div>
        <div
          id="headline"
          style={{
            flexGrow: 1,
            textAlign: "left",
            paddingTop: -20,
            paddingLeft: 35
          }}
        >
          <div id="type">
            <Typography
              variant="overline"
              style={{ fontSize: 9, color: "#757575" }}
            >
              Platform Management
            </Typography>
          </div>
          <div id="text">
            <Typography
              variant="body1"
              fontWeight="bold"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                position: "relative",
                top: -9
              }}
            >
              People
            </Typography>
          </div>
        </div>

      </div>
    );
  }
}
export default  Manage;
