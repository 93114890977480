import React from "react";
import websocketCom from "../../../webcom/WebsocketCom";
import aSDS from "../../../stores/ActiveServiceDataStore";
import Number from "./Charts/Number";
import TimeSeries from "./Charts/Line";
import Histogram from "./Charts/Histogram";
import Scatter from "./Charts/Scatter";
import Digital from "./Charts/Digital";
import Bar from "./Charts/Bar";
import SettingsIcon from "@material-ui/icons/Settings";
import ReturnIcon from "@material-ui/icons/KeyboardReturn";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.chartTypes = ["Number", "Time Series", "Histogram", "Scatter Plot", "Digital Signal", "Bar"];

    this.state = {
      has_setup: false,
      chart_setup: null,
      chart_data: null,
    };

    this._cE_DataStoreUpdate = this._cE_DataStoreUpdate.bind(this);
  }

  componentDidMount() {
    aSDS.on("change", this._cE_DataStoreUpdate);

    let msg = this.props.soName + " getattribute chart_setup";
    websocketCom.sendServiceMessage(msg);
    msg = this.props.soName + " getattribute chart_data";
    websocketCom.sendServiceMessage(msg);

  }

  componentWillUnmount() {
    aSDS.removeListener("change", this._cE_DataStoreUpdate);
  }

  _cE_DataStoreUpdate() {
    // ----------------------------------------------------------------------------
    //  handle chart_setup
    let chartSetup = aSDS.getAttribute(this.props.soName, "chart_setup");
    if (chartSetup === null) {
      this.setState({ chart_setup: null, has_setup: false });
      return;
    }

    chartSetup = chartSetup.trim();

    if (chartSetup === "null") {
       this.setState({ chart_setup: null, has_setup: false });
      return;
    }

    let newChartSetup = null;
    try {
      const parsedData = JSON.parse(chartSetup);
      if (typeof parsedData === "object") {
        newChartSetup = parsedData;
      } else {
        console.log("ERROR: received data for chart_setup unvalid.");
        newChartSetup = null;
      }
    } catch (e) {
      console.log("ERROR: received data for chart_setup not valid JSON.");
      newChartSetup = null;
    }
    this.setState({ chart_setup: newChartSetup, has_setup: true });
     // ---------------------------------------------------------------------------

    // ---------------------------------------------------------------------------
    //  handle chart_data
    let chartData = aSDS.getAttribute(this.props.soName, "chart_data");
    if (chartData === null) {
      this.setState({ chart_data: null});
      return;
    }

    chartData = chartData.trim();
    if (chartData === "null") {
      this.setState({ chart_data: null });
      return;
    }

    let newChartData = null;
    try {
      const parsedData = JSON.parse(chartData);
      newChartData = parsedData;
    } catch (e) {
      console.log("ERROR: received data for chart_data not valid JSON.");
      newChartData = null;
    }
    this.setState({ chart_data: newChartData });
    // ---------------------------------------------------------------------------
  }

  _buildView() {

    let view = <div />;

    if (!this.state.has_setup) {
      view  = (
        <div style={{ textAlign: "center", fontFamily: "Roboto" }}>
          <div style={{height: 12}} />
          <NotInterestedIcon style={{ fontSize: 96 }} />
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            No view configured on this SmartObject.
          </div>
        </div>
      );
      return view;
    }


    if (this.state.chart_setup === null) {

      view = (
        <div style={{ textAlign: "center", fontFamily: "Roboto" }}>
          <div style={{ height: 48 }} />
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            Parsing configuration data failed.
          </div>
        </div>
      );
      return view;

    }

    if ( ! ("type" in this.state.chart_setup && this.chartTypes.includes(this.state.chart_setup.type) )) {
      view = (
        <div style={{ textAlign: "center", fontFamily: "Roboto" }}>
          <div style={{ height: 48 }} />
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            Requested chart type not supported..
          </div>
        </div>
      );
      return view;
    }

    switch (this.state.chart_setup.type) {
      case "Number":
        view = (
          <Number
            soName={this.props.soName}
            chartSetup={this.state.chart_setup}
            chartData={this.state.chart_data}
          />
        );
        break;
      case "Time Series":
        view = (
          <TimeSeries
            soName={this.props.soName}
            chartSetup={this.state.chart_setup}
            chartData={this.state.chart_data}
          />
        );
        break;
      case "Histogram":
        view = (
          <Histogram
            soName={this.props.soName}
            chartSetup={this.state.chart_setup}
            chartData={this.state.chart_data}
          />
        );
        break;
      case "Scatter Plot":
        view = (
          <Scatter
            soName={this.props.soName}
            chartSetup={this.state.chart_setup}
            chartData={this.state.chart_data}
          />
        );
        break;
      case "Digital Signal":
        view = (
          <Digital
            soName={this.props.soName}
            chartSetup={this.state.chart_setup}
            chartData={this.state.chart_data}
          />
        );
        break;
      case "Bar":
        view = (
          <Bar
            soName={this.props.soName}
            chartSetup={this.state.chart_setup}
            chartData={this.state.chart_data}
          />
        );
        break;
      default:
        view = <div>Unexpected Error!</div>;
        break;
    }

    return view;
  }

  render() {
    return this._buildView();
  }
}

export default Dashboard;
