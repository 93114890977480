import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";
import RestoreCard from "./RestoreCard";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import hS from "../../stores/HistoryStore";


class RestoreSnapshot extends React.Component {

    constructor(props) {
        super(props);
        // set size for this configuration panel
        this.width = 285; this.height = 313;

        this.historyDate = "";


        this.state = { history: [], moveOffset: {"x":0, "y":0} }

        this.moveCallback = this.moveCallback.bind(this);
        this._loadHistory = this._loadHistory.bind(this);
    }


    componentDidMount() {
        const msg = this.props.soName+" history"
        websocketCom.sendServiceMessage(msg);
        hS.addListener("history_update", this._loadHistory);
    }

    componentWillUnmount() {
        hS.removeListener("history_update", this._loadHistory);
    }

    _loadHistory() {
        const history = hS.getHistory();
        if (history.soName !== this.props.soName) {
            console.log("Wrong history loaded");
            return;
        }
        this.setState({history: history.items});
    }

    _panelClick(event) {event.stopPropagation() }

    moveCallback(delta) {
        this.setState({moveOffset: delta})
    }

    _buildHeadline() {
        return (
            <div id="headline" style={{ paddingBottom: 5, paddingLeft: 15 }} >
                <div id="type">
                    <div style={{display: "flex", fontFamily: "Roboto", fontSize: 13, color: "DimGray", paddingTop: 3}} >
                        <div style={{width: 9, height: 9, backgroundColor: "DimGray", marginTop: 4, marginRight: 4}} />
                        <div> {this.props.soName}</div>
                    </div>
                </div>
                <div id="text"
                     style={{ fontSize: 16, fontWeight: "bold", textAlign: "left", paddingTop: 2}} >
                    Restore
                </div>
            </div> )
    }


    _buildRestoreMenu() {

        let saveContainer = [];

        let panel

        if (this.state.history.length === 0) {

            panel = (
                <div style={{display: "flex", justifyContent: "center", backgroundColor: "White",
                             height: 100+"%", width: this.width-15 }} >
                    <NotInterestedIcon style={{fontSize: 120, color: "WhiteSmoke", marginTop: 30}} />
                </div> )

        } else {

            for (const entry of this.state.history ) {
                const item = ( <RestoreCard key={entry.date} entry={entry} soName={this.props.soName} id={this.props.id} /> )
                saveContainer.push(item)
            }

            panel = (
                <div style={{display: "flex", flexDirection: "column", height: 250, marginRight: 10, overflowY: "auto"}}>
                    {saveContainer}
                </div> )
        }

        return (
            <div style={{ cursor: "default" }} onClick={(event) => this._panelClick(event) }>
                {this._buildHeadline()}
                <div style={{height: 10}} />
                {panel}
            </div>
        )

    }


    render() {

        // Content of panel is defined here
        const view = (<div key={"content-ports-conf-"+this.props.soName}> {this._buildRestoreMenu()} </div>)

        let  geometry = this.props.location;
        //const scaleFactor = this.props.location.scaleFactor;
        geometry["x"] = geometry["x"]+this.state.moveOffset.x; geometry["y"] = geometry["y"]+this.state.moveOffset.y;
        geometry["width"] = this.width; geometry["height"] = this.height;

        return ( <Popup geometry={geometry} content={view}
                               id={this.props.id} elementClass={this.props.elementClass}
                               delCallback={this.props.delCallback}
                               moveCallback={this.moveCallback}
        /> )
    }
}

export default RestoreSnapshot;

