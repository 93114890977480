import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import activeServiceDataStore from "./../../stores/ActiveServiceDataStore";
import CheckpointHistory from "./CheckpointHistory";
//import uSS from "./../../stores/UserServicesStore";
//import fS from "./../../stores/FacilitiesStore";
// -------------------------------------------------------------
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
//import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// -------------------------------------------------------------

const styles = theme => ({
  soFormControl: {
    margin: theme.spacing.unit,
    minWidth: 450
  }
});

class DialogLoadSo extends React.Component {
  constructor(props) {
    super(props);

    this.historyDate = "";
    this.state = {
      selectedSo: ""
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.handleHistoryChange = this.handleHistoryChange.bind(this);
    this._handleChangeSo = this._handleChangeSo.bind(this);
  }

  handleClose() {
    this.setState({
      selectedSo: ""
    });
    this.historyDate = "";
    this.props.closeHandle();
  }

  handleRestore() {
    let msg = "";
    if (this.state.selectedSo !== "" && this.historyDate !== "") {
      msg = this.state.selectedSo + " load " + this.historyDate;
      websocketCom.sendServiceMessage(msg);
      this.handleClose();
    }
  }

  _buildSoList() {
    const soTemplates = activeServiceDataStore.getSoList();

    let MenuItemContainer = [];
    let menuItem = "";
    soTemplates.forEach(function(item, index) {
      menuItem = (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      );
      MenuItemContainer.push(menuItem);
    });
    return MenuItemContainer;
  }

  _handleChangeSo(event) {
    const soName = event.target.value;
    this.setState({ selectedSo: soName });
  }

  handleHistoryChange(date) {
    this.historyDate = date;
  }

  render() {
    const { classes } = this.props;

    let historyView = (
      <div
        style={{ backgroundColor: "#f5f5f5", flexGrow: 1, overflow: "auto" }}
      >
        <CheckpointHistory
          selectedSo={this.state.selectedSo}
          callbackHistoryChange={this.handleHistoryChange}
        />
      </div>
    );
    if (this.state.selectedSo === "") {
      historyView = (
        <div>Please select the SmartObject which you want to restore.</div>
      );
    }

    const dialog = (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Restore Checkpoint</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              height: 100 + "%",
              minHeight: 100 + "%"
            }}
          >
            <DialogContentText>
              This dialog restores the selected checkpoint for the chosen
              SmartObject.
            </DialogContentText>

            <div style={{ height: 8 + "px" }} />

            <FormControl className={classes.soFormControl} fullWidth>
              <div>
                <InputLabel htmlFor="serviceTemplate-simple">
                  SmartObject
                </InputLabel>
                <Select
                  value={this.state.selectedSo}
                  onChange={this._handleChangeSo}
                  inputProps={{
                    name: "serviceTemplate",
                    id: "serviceTemplate-simple"
                  }}
                  fullWidth
                >
                  {this._buildSoList()}
                </Select>
              </div>

              <div style={{ height: 25 + "px" }} />
            </FormControl>
            {historyView}

            <div style={{ minHeight: 20 + "px" }} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleRestore} color="primary">
            Restore
          </Button>
        </DialogActions>
      </Dialog>
    );

    return dialog;
  }
}

export default withStyles(styles)(DialogLoadSo);
