import React from "react";
import DebugIcon from '@material-ui/icons/BugReport';
import ProblemIcon from '@material-ui/icons/ReportProblem';


class ConfigMenuItem extends React.Component {

    //constructor() {
    //    super();
    //}

    //componentDidMount() {
    //}

    //componentWillUnmount() {
    //}

    _getIcon() {
        let icon = <div />;
        switch (this.props.text) {
            case "Debug": icon = (<DebugIcon style={{width: 20}} />);
                break;
            case "Problems": icon = (<ProblemIcon style={{width: 17}} />);
                break;
            default:
                break;
        }
        return icon;
    }

    _onClick() {
        let newSelection = "";
        if (this.props.text !== this.props.selection) { newSelection = this.props.text}
        this.props.callback(newSelection)
    }

    render() {

        let color = "Gray"; let color_bg = "transparent";
        let borderTop = "";
        if (this.props.selection === this.props.text) {
            color = "Black"; color_bg = "White";
            //let borderTop = "";
        }

        const menuLine = (
            <div style={{ display: "flex", flexDirection: "row", height: 25, alignItems: "center",
                          fontFamily: "Roboto", fontWeight: "bold", fontSize: 13,
                          color: color, backgroundColor: color_bg,
                          borderTop: borderTop }}
                 onClick={() => {this._onClick()} } >
                <div style={{ width: 10 }} />
                <div>{this._getIcon()}</div>
                <div style={{ width: 5 }} />
                <div>{this.props.text}</div>
                <div style={{ width: 10 }} />
            </div>
        )

        return menuLine
    }
}

export default ConfigMenuItem;
