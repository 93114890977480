import React from "react";
import websocketCom from "./../../../../webcom/WebsocketCom";
import uSS from "./../../../../stores/UserServicesStore";

import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

class PersonalSettings extends React.Component {
  _isMounted = false;
  _loggingLevels = {
    Off: "off",
    Info: "info",
    Warning: "warn",
    Error: "error",
    Fatal: "fatal"
  };
  constructor(props) {
    super(props);
    this.serviceName = props.serviceName;
    this.state = { logLevel: "" };

    this._cE_LogLevelUpdate = this._cE_LogLevelUpdate.bind(this);
    this._processLoggingSwitchOnOff = this._processLoggingSwitchOnOff.bind(
      this
    );
    this._handleChangeLogLevel = this._handleChangeLogLevel.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    //this.setState({
    //  logLevel: uSS.getServiceLogLevel(this.props.serviceName)
    //});
    this._cE_LogLevelUpdate();
    uSS.on("ChangeEvent_LogLevelUpdate", this._cE_LogLevelUpdate);
  }

  componentWillUnmount() {
    this._isMounted = false;
    uSS.removeListener("ChangeEvent_LogLevelUpdate", this._cE_LogLevelUpdate);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.serviceName = nextProps.serviceName;
    this._cE_LogLevelUpdate();
  }

  _cE_LogLevelUpdate() {
    const logLevelOnPlatform = uSS.getServiceLogLevel(this.serviceName);
    for (let level in this._loggingLevels) {
      if (this._loggingLevels[level] === logLevelOnPlatform) {
        this.setState({ logLevel: level });
        break;
      }
    }
  }

  _handleSwitchChange = element => event => {
    if (element) {
      switch (element) {
        case "checkedLogging":
          this._processLoggingSwitchOnOff(event);
          break;
        default:
          break;
      }
    }
  };

  _processLoggingSwitchOnOff(event) {
    const state = event.target.checked;
    if (state) {
      const msg = "registerlog " + this.serviceName + " error";
      websocketCom.sendUserMessage(msg);
    } else {
      const msg = "deregisterlog " + this.serviceName;
      websocketCom.sendUserMessage(msg);
    }
  }

  _handleChangeLogLevel(event) {
    const msg =
      "registerlog " +
      this.serviceName +
      " " +
      this._loggingLevels[event.target.value];
    websocketCom.sendUserMessage(msg);
  }

  _buildLoggingChecked() {
    let content = <div />;

    if (this.state.logLevel !== "Off") {
      let logLevelContainer = [];
      for (let level in this._loggingLevels) {
        const item = (
          <MenuItem key={level} value={level}>
            {level}
          </MenuItem>
        );
        logLevelContainer.push(item);

        //const logLevel = uSS.getServiceLogLevel();
        content = (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <div>Logging Level:</div>
            <div>
              <Select
                style={{ width: 100 + "px", marginLeft: 30 + "px" }}
                value={this.state.logLevel}
                onChange={this._handleChangeLogLevel}
                inputProps={{
                  name: "logLevel",
                  id: "logLevel-simple"
                }}
              >
                {logLevelContainer}
              </Select>
            </div>
          </div>
        );
      }
    }
    return content;
  }

  render() {
    let isLogSwitchOn = true;
    if (this.state.logLevel === "Off") isLogSwitchOn = false;
    const panel = (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <div
            style={{
              minWidth: 200 + "px",
              width: 400 + "px",
              maxWidth: 400 + "px"
            }}
          >
            <Typography style={{fontSize: 14, fontWeight: "bold"}}>Offline Logging</Typography>
          </div>
          <div>
            <Switch
              checked={isLogSwitchOn}
              onChange={this._handleSwitchChange("checkedLogging")}
              value="checkedLogging"
              size="small"
              color="primary"
            />
          </div>
        </div>
        {this._buildLoggingChecked()}
      </div>
    );
    return panel;
  }
}
export default PersonalSettings;
