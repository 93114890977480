import React from "react";
//import aSDS from "./../../stores/ActiveServiceDataStore";
//import gLS from "./../../stores/GraphLayoutStore";
import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";
import pM from "./PopupManager";
//import PortEntry from "./PortEntry";


class AddMethod extends React.Component {

  constructor(props) {
    super(props);
    // set size for this configuration panel
    this.width = 285; this.height = 110;
    this.methodNameError = false;
    this.createButtonIsEnabled = false;

    this.state = {methodName: "", methodHelpText: "", moveOffset: {"x":0, "y":0} }

    this.moveCallback = this.moveCallback.bind(this);
    this._createNewMethodClick = this._createNewMethodClick.bind(this);
    this._handleMethodNameChange = this._handleMethodNameChange.bind(this);
  }


  _panelClick(event) {event.stopPropagation() }

  moveCallback(delta) {
    this.setState({moveOffset: delta})
  }

  _createNewMethodClick(event) {
    // event.stopPropagation();
    const msg = this.props.soName+" addmethod "+this.state.methodName;
    websocketCom.sendServiceMessage(msg);
    this.setState({methodName: "" });
    pM.deletePopup(this.props.id);
  }


  _handleMethodNameChange(event) {
    this.setState({ methodName: event.target.value });

    // eslint-disable-next-line no-useless-escape
    const letterNumber = /^[_\-0-9a-zA-Z]+$/;
    if ( !(event.target.value.match(letterNumber)) && event.target.value !== "") {
      this.methodNameError = true;
    this.createButtonIsEnabled = false;
      this.setState({methodHelpText: "Unvalid character"})
      return
    }
    if (event.target.value === "") {
        this.methodNameError = true;
        this.createButtonIsEnabled = false;
        this.setState({methodHelpText: "Empty name"});
        return
    }
      if (event.target.value.length < 1) {
        this.methodNameError = true;
        this.createButtonIsEnabled = false;
        this.setState({methodHelpText: "Minimum length 3 characters"});
        return
      }
      this.methodNameError = false;
      this.createButtonIsEnabled = true;
      this.setState({methodHelpText: ""});
  }

  _buildHeadline() {
    return (
          <div id="headline" style={{ paddingBottom: 5, paddingLeft: 15 }} >
          <div id="type">
              <div style={{display: "flex", fontFamily: "Roboto", fontSize: 13, color: "DimGray", paddingTop: 3}} >
                <div style={{width: 9, height: 9, backgroundColor: "DimGray", marginTop: 4, marginRight: 4}} />
                <div> {this.props.soName}</div>
              </div>
          </div>
          <div id="text"
              style={{ fontSize: 16, fontWeight: "bold", textAlign: "left", paddingTop: 2}} >
              Add Method
          </div>
        </div>)
  }

  _buildAddMethodMenu() {

    const buttonBorder = this.createButtonIsEnabled ? "1px solid Gray" : "1px solid LightGray";
    //const buttonFontWeight = this.createButtonIsEnabled ? "bold" : "normal";

    const panel = (
      <div style={{ cursor: "default" }} onClick={(event) => this._panelClick(event) }>
        {this._buildHeadline()}
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",
                      paddingLeft: 15, paddingTop: 12, paddingRight: 20}}  >

          <input style={{fontFace: "Roboto", fontSize: 14, width: 180}}
                 onChange={(event)=>this._handleMethodNameChange(event)}
                 type="text" id="fname" name="fname" autoFocus />
          <div>
            <button style={{ fontFace: "Roboto", fontSize: 14,  backgroundColor: "WhiteSmoke", fontWeight: "normal",  border: buttonBorder,
                           height: 22, marginLeft: 0}}
                    type="button"
                    disabled={! this.createButtonIsEnabled}
                    onClick={(event)=>this._createNewMethodClick(event)} >
              Add
            </button>
          </div>
        </div>
        <div style={{color: "Red", paddingLeft: 15, paddingTop: 3, fontSize: 10 }} >
          {this.state.methodHelpText}
        </div>
      </div> )
    return panel

  }


  render() {

    // Content of panel is defined here
    const view = (<div key={"content-method-add-"+this.props.soName}> {this._buildAddMethodMenu()} </div>)

    let  geometry = this.props.location;
    //const scaleFactor = this.props.location.scaleFactor;
    geometry["x"] = geometry["x"]+this.state.moveOffset.x; geometry["y"] = geometry["y"]+this.state.moveOffset.y;
    geometry["width"] = this.width; geometry["height"] = this.height;

    const panel = ( <Popup geometry={geometry} content={view}
                           id={this.props.id} elementClass={this.props.elementClass}
                           delCallback={this.props.delCallback}
                           moveCallback={this.moveCallback}
                    /> )
    return panel
  }

}

export default AddMethod;
