import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
//import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const styles = {
  card: {
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    height: 135
  },
  media: {
    height: 85
  }
};
class ServiceCard2 extends React.Component {
  serviceCardClicked(e, serviceName) {
    this.props.callback(serviceName);
  }

  render() {
    const { classes } = this.props;

    const bg_col = this.props.raised ? 'Blue' : 'Orange'
    const card = (
      <div style={{ marginRight: 20, marginBottom: 10 }}>
        <Card className={classes.card} raised={this.props.raised}>
          <CardActionArea
            onClick={e => this.serviceCardClicked(e, this.props.serviceName)}
          >
            <div
                style={{backgroundColor: bg_col, color: "White", fontFamily: "Roboto"}}
              className={classes.media}
            >
                <div style={{display: "flex"}} >
                <div style={{ fontSize: 60, fontWeight: "bold", paddingLeft: 15, paddingTop: 10 }}>
                {this.props.serviceName.charAt(0)}
                </div>
                <div style={{ fontSize: 35, fontWeight: "bold", paddingLeft: 0, paddingTop: 35 }}>
                    {this.props.serviceName.charAt(1)}
                </div>
                </div>
            </div>
            <div style={{ paddingTop: 3 }} />
            <Typography
              variant="overline"
              style={{
                fontSize: 9,
                color: "#757575",
                paddingLeft: 15
              }}
            >
              Service
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                position: "relative",
                top: -9,

                paddingLeft: 15
              }}
            >
              {this.props.serviceName}
            </Typography>
          </CardActionArea>
        </Card>
      </div>
    );
    return card;
  }
}
export default withStyles(styles)(ServiceCard2);
