import React from "react";
import config from "./../config";

import PaletteIcon from '@material-ui/icons/Palette';


class MenuEntrySoCol  extends React.Component {

  constructor() {
    super();

    this.state = { color: "black",
                   color_bg: "transparent" };

    this._toggleColor = this._toggleColor.bind(this);
  }


  _colorClick(event, color) {
    this.props.callback(color);
  }


  _buildColorSelection() {
    const colors = config.so_col;
    let colorContainer = [];
    for (const color in colors) {
      const displayColor = colors[color].main
      const colorPatch = (
        <div key={color}
             style ={{width: 10, height: 10, backgroundColor: displayColor,
                      border: "1px solid Gray", marginLeft: 4}}
             onClick={(event) => this._colorClick(event, color)}
        />
      )
      colorContainer.push(colorPatch);
    }

    const entry = (
      <div style={{display: "flex"}} >
        <div>Color:</div>
        <div style={{display: "flex", paddingLeft: 8, paddingTop: 2}} >{colorContainer}</div>
      </div> )

    return entry;
  }


  _toggleColor() {
    const newColor = ( this.state.color === "black") ? "black" : "black";
    let newColor_bg = ( this.state.color_bg === "transparent") ? "WhiteSmoke" : "transparent";
    this.setState({color: newColor, color_bg: newColor_bg})
  }

  render() {

    const panel = (
      <div style={{paddingTop: 5, paddingBottom: 2,  display: "flex", flexDirection: "row", alignItems: "center",
                   color: this.state.color, backgroundColor: this.state.color_bg,
                   marginRight: 1}}
           onMouseEnter = { () => this._toggleColor() }
           onMouseLeave = { () => this._toggleColor() } >
        <div style={{width: 10}} />
        <div> <PaletteIcon style={{width: 17, height: 17}} /> </div>
        <div style={{width: 9}} />
        {this._buildColorSelection()}
        <div>{this.props.text}</div>
      </div>
    )

    return panel
  }

}

export default MenuEntrySoCol;

