class ResponseMessage {
  processMsg(msg, fullMsg) {
    const RESP_MESSAGE_ROOT = 1;
    const RESP_METHOD = 2;

    let parsedMsg = [null];

    if (msg[RESP_MESSAGE_ROOT] === "error") {
      parsedMsg = this._parseErrorMessage(msg);
    } else if (msg[RESP_MESSAGE_ROOT] === "service") {
      parsedMsg = this._parseServiceMessage(msg);
    } else if (msg[RESP_METHOD] === "error") {
      parsedMsg = this._parseErrorMessage(msg);
    } else if (msg[RESP_METHOD] === "method") {
      parsedMsg = this._parseMethodMessage(msg, fullMsg);
    } else if (msg[RESP_METHOD] === "global") {
      parsedMsg = this._parseGlobalMessage(msg);
    } else if (msg[RESP_METHOD] === "statistics") {
      parsedMsg = this._parseStatisticsMessage(msg);
    } else if (msg[RESP_METHOD] === "portstatistics") {
      parsedMsg = this._parsePortStatisticsMessage(msg);
    } else if (msg[RESP_METHOD] === "methodstatistics") {
      parsedMsg = this._parseMethodstatisticsMessage(msg);
    } else if (msg[RESP_METHOD] === "log") {
      parsedMsg = this._parseLogMessage(msg);
    } else if (msg[RESP_METHOD] === "owner") {
      parsedMsg = this._parseOwnerMessage(msg);
    } else if (msg[RESP_METHOD] === "location") {
      parsedMsg = this._parseLocationMessage(msg);
    } else if (msg[RESP_METHOD] === "actual") {
      parsedMsg = this._parseActualMessage(msg);
    } else if (msg[RESP_METHOD] === "state") {
      parsedMsg = this._parseStateMessage(msg);
    } else if (msg[RESP_METHOD] === "type") {
      parsedMsg = this._parseSOTypeMessage(msg);
    } else if (msg[RESP_METHOD] === "history") {
      parsedMsg = this._parseSOHistoryMessage(msg);
    } else if (msg[RESP_METHOD] === "loaded") {
      parsedMsg = this._parseSOLoadedMessage(msg);
    } else if (msg[RESP_METHOD] === "call") {
      parsedMsg = this._parseCallRespMessage(msg);
    } else if (msg[RESP_METHOD] === "attribute") {
      parsedMsg = this._parseAttributeRespMessage(msg);
    }
    return parsedMsg;
  }

  _parseErrorMessage(msg) {
    const msgWordCount = msg.length;
    const wordsInMsg = msgWordCount - 2;
    let notificationMsg = "";
    for (let i = 0; i < wordsInMsg; i++) {
      notificationMsg = notificationMsg + msg[2 + i] + " ";
    }
    notificationMsg = notificationMsg.trimRight();
    const notificationStoreUpdate = {
      scope: "notification",
      //type: msg[1],
      type: "error",
      origin: "service",
      message: notificationMsg
    };
    return [notificationStoreUpdate];
  }

  _parseSOHistoryMessage(msg) {
    const msgWordCount = msg.length;
    const wordsInMsg = msgWordCount - 3;
    let history = "";
    for (let i = 0; i < wordsInMsg; i++) {
      history = history + msg[3 + i] + " ";
    }
    history = history.trim();
    
    const historyUpdate = {
      scope: "history",
      so: msg[1],
      message: history
    };
    return [historyUpdate];
  }

  _parseSOLoadedMessage(msg) {
    const loadUpdate = {
      scope: "so-core",
      cmd: msg[2],
      so: msg[1]
    };
    return [loadUpdate]; 
  }
  
  _parseServiceMessage(msg) {
    const SERVICE_CMD = 2;

    if (msg[SERVICE_CMD] === "facility") {
      const parsedMsg = this._parseServiceFacilityMessage(msg);
      return parsedMsg;
    } else if (msg[SERVICE_CMD] === "prototypes") {
      const parsedMsg = this._parseServicePrototypesMessage(msg);
      return parsedMsg;
    }
  }

  _parseServiceFacilityMessage(msg) {
    const activeServiceMgmtStoreUpdate = {
      scope: "service",
      cmd: "facility",
      cmdOperation: "set",
      facility: msg[4]
    };
    return [activeServiceMgmtStoreUpdate];
  }

  _parseServicePrototypesMessage(msg) {
    //console.log("resp to service prototypes", msg)

    const msgWordCount = msg.length;
    const wordsInStatusMsg = msgWordCount - 3;
    let prototypeData = "";
    for (let i = 0; i < wordsInStatusMsg; i++) {
      prototypeData = prototypeData + msg[3 + i] + " ";
    }

    const activeServiceMgmtStoreUpdate = {
      scope: "service",
      cmd: "prototypes",
      data: prototypeData
    };
    //console.log("activeServiceMgmtStoreUpdate", activeServiceMgmtStoreUpdate)
    return [activeServiceMgmtStoreUpdate];
  }


  _parseMethodMessage(msg, fullMsg) {
    const SO_NICK = 1; // SO name
    const MESSAGE_SCOPE = 2; // method related response
    const OPERATION = 3; // method operation: source, loaded
    const METHOD_NAME = 4; // method name

    let platformStoreUpdate = null;

    if (msg[OPERATION] === "loaded") {
      const msgWordCount = msg.length;
      const wordsInStatusMsg = msgWordCount - 5;
      let statusMsg = "";
      for (let i = 0; i < wordsInStatusMsg; i++) {
        statusMsg = statusMsg + msg[5 + i] + " ";
      }
      platformStoreUpdate = {
        scope: "resp_method",
        cmd: msg[MESSAGE_SCOPE],
        cmdOperation: msg[OPERATION],
        so: msg[SO_NICK],
        name: msg[METHOD_NAME],
        status: statusMsg
      };
      return [platformStoreUpdate];
    } else if (msg[OPERATION] === "source") {

      const cutOffLength = (msg[0]+" "+msg[1]+" "+msg[2]+" "+msg[3]+" "+msg[4]+" ").length
      const code = fullMsg.substring(cutOffLength);
      platformStoreUpdate = {
        scope: "resp_method",
        cmd: msg[MESSAGE_SCOPE],
        cmdOperation: msg[OPERATION],
        so: msg[SO_NICK],
        name: msg[METHOD_NAME],
        code: code
      };
      return [platformStoreUpdate];
    } else if (msg[OPERATION] === "actual") {
      const codeStart = fullMsg.indexOf(msg[METHOD_NAME])+msg[METHOD_NAME].length+1
      let code = "";
      code = fullMsg.substring(codeStart);
      platformStoreUpdate = {
        scope: "resp_method",
        cmd: msg[MESSAGE_SCOPE],
        cmdOperation: msg[OPERATION],
        so: msg[SO_NICK],
        name: msg[METHOD_NAME],
        code: code
      };
      return [platformStoreUpdate];
    } else {
      console.log("Unknown method scope in response message.");
      return [null];
    }
  }

  _parseGlobalMessage(msg) {
    const SO_NICK = 1; // SO name
    const MESSAGE_SCOPE = 2; // "global"
    const OPERATION = 3; //  operation: set, table
    const KEY = 4; // lua table with key values if OPERATION = "table"
    // key if OPERATION = "set"
    const VALUE = 5; // empty if OPERATION = "table"
    // value if OPERATION = "set"
    //   note: value can be any number of space separated strings

    let platformStoreUpdate = {};
    let globalValue = "";

    if (msg[OPERATION] === "set") {
      const msgWordCount = msg.length;
      const wordsInMsg = msgWordCount - VALUE;
      for (let i = 0; i < wordsInMsg; i++) {
        globalValue = globalValue + msg[VALUE + i] + " ";
      }
      globalValue = globalValue.trimRight();
      platformStoreUpdate = {
        cmdOperation: "set",
        key: msg[KEY],
        value: globalValue
      };
    } else if (msg[OPERATION] === "table") {
      const msgWordCount = msg.length;
      const wordsInMsg = msgWordCount - 4;
      for (let i = 0; i < wordsInMsg; i++) {
        globalValue = globalValue + msg[4 + i] + " ";
      }
      globalValue = globalValue.trimRight();
      platformStoreUpdate = {
        cmdOperation: "table",
        table: globalValue
      };
    }

    platformStoreUpdate.scope = "global";
    platformStoreUpdate.cmd = msg[MESSAGE_SCOPE];
    platformStoreUpdate.so = msg[SO_NICK];

    return [platformStoreUpdate];
  }

  _parseStatisticsMessage(msg) {
    const SO_NICK = 1; // SO name
    const MESSAGE_SCOPE = 2; // statistics related response
    const OPERATION = 3; // statistics operation: set, table
    const KEY = 4;
    //const VALUE = 5;

    let platformStoreUpdate = {};
    if (msg[OPERATION] === "set") {
      const msgWordCount = msg.length;
      const wordsInMsg = msgWordCount - 5;
      let statsValue = "";
      for (let i = 0; i < wordsInMsg; i++) {
        statsValue = statsValue + msg[5 + i] + " ";
      }
      statsValue = statsValue.trimRight();

      platformStoreUpdate = {
        scope: "stats",
        cmd: msg[MESSAGE_SCOPE],
        cmdOperation: "set",
        so: msg[SO_NICK],
        key: msg[KEY],
        value: statsValue
      };
    } else if (msg[OPERATION] === "table") {
      const msgWordCount = msg.length;
      const wordsInMsg = msgWordCount - 4;
      let statsValue = "";
      for (let i = 0; i < wordsInMsg; i++) {
        statsValue = statsValue + msg[4 + i] + " ";
      }
      statsValue = statsValue.trimRight();
      platformStoreUpdate = {
        scope: "stats",
        cmd: msg[MESSAGE_SCOPE],
        cmdOperation: "table",
        so: msg[SO_NICK],
        table: statsValue
      };
    }
    return [platformStoreUpdate];
  }

  _parsePortStatisticsMessage(msg) {
    const SO_NICK = 1; // SO name
    const MESSAGE_SCOPE = 2; // statistics related response
    const OPERATION = 3; // statistics operation: set, table
    const PORT = 4;
    const KEY = 5;
    //const VALUE = 5;

    let platformStoreUpdate = {};
    if (msg[OPERATION] === "set") {
      const msgWordCount = msg.length;
      const wordsInMsg = msgWordCount - 6;
      let statsValue = "";
      for (let i = 0; i < wordsInMsg; i++) {
        statsValue = statsValue + msg[6 + i] + " ";
      }
      statsValue = statsValue.trimRight();

      platformStoreUpdate = {
        scope: "stats",
        cmd: msg[MESSAGE_SCOPE],
        cmdOperation: "set",
        so: msg[SO_NICK],
        port: msg[PORT],
        key: msg[KEY],
        value: statsValue
      };
    } else if (msg[OPERATION] === "table") {
      const msgWordCount = msg.length;
      const wordsInMsg = msgWordCount - 5;
      let statsValue = "";
      for (let i = 0; i < wordsInMsg; i++) {
        statsValue = statsValue + msg[5 + i] + " ";
      }
      statsValue = statsValue.trimRight();
      platformStoreUpdate = {
        scope: "stats",
        cmd: msg[MESSAGE_SCOPE],
        cmdOperation: "table",
        so: msg[SO_NICK],
        port: msg[PORT],
        table: statsValue
      };
    }
    return [platformStoreUpdate];
  }

  _parseMethodstatisticsMessage(msg) {
    const SO_NICK = 1; // SO name
    const MESSAGE_SCOPE = 2; // statistics related response
    const OPERATION = 3; // statistics operation: set, table
    const MESSAGE = 4;
    const KEY = 5;
    //const VALUE = 5;

    let platformStoreUpdate = {};
    if (msg[OPERATION] === "set") {
      const msgWordCount = msg.length;
      const wordsInMsg = msgWordCount - 6;
      let statsValue = "";
      for (let i = 0; i < wordsInMsg; i++) {
        statsValue = statsValue + msg[6 + i] + " ";
      }
      statsValue = statsValue.trimRight();

      platformStoreUpdate = {
        scope: "stats",
        cmd: msg[MESSAGE_SCOPE],
        cmdOperation: "set",
        so: msg[SO_NICK],
        message: msg[MESSAGE],
        key: msg[KEY],
        value: statsValue
      };
    } else if (msg[OPERATION] === "table") {
      const msgWordCount = msg.length;
      const wordsInMsg = msgWordCount - 5;
      let statsValue = "";
      for (let i = 0; i < wordsInMsg; i++) {
        statsValue = statsValue + msg[5 + i] + " ";
      }
      statsValue = statsValue.trimRight();
      platformStoreUpdate = {
        scope: "stats",
        cmd: msg[MESSAGE_SCOPE],
        cmdOperation: "table",
        so: msg[SO_NICK],
        message: msg[MESSAGE],
        table: statsValue
      };
    }
    return [platformStoreUpdate];
  }

  _parseLogMessage(msg) {
    const SO_NICK = 1;
    const OPERATION = 2;
    const LOG_OPERATION = 3;
    const LOG_LEVEL = 4;

    const so = msg[SO_NICK];
    const cmd = msg[OPERATION];
    const logCmd = msg[LOG_OPERATION];
    const logLevel = msg[LOG_LEVEL];

    let platformStoreUpdate = "";
    if (msg[LOG_OPERATION] === "level") {
      platformStoreUpdate = {
        scope: "setup_so_log",
        cmd: cmd,
        so: so,
        log_cmd: logCmd,
        log_level: logLevel
      };
      return [platformStoreUpdate];
    }
  }

  _parseOwnerMessage(msg) {
    const SO_NICK = 1;
    const OPERATION = 2;
    //const OWNER_OPERATION = 3; // = set
    const OWNER_VALUE = 4;

    const so = msg[SO_NICK];
    const cmd = msg[OPERATION];
    //const ownerCmd = msg[OWNER_OPERATION];
    const ownerValue = msg[OWNER_VALUE];

    // Message Format: info "SOnick" owner set "ownerSOnick"
    const platformStoreUpdate = {
      scope: "so-core",
      so: so,
      cmd: cmd,
      name: ownerValue
    };
    return [platformStoreUpdate];
  }

  _parseLocationMessage(msg) {
    //    const SO_NICK = 1;
    //    const OPERATION = 2;
    //    const LOCATION_OPERATION = 3; // = set
    //    const LOCATION_VALUE = 4;

    //    const so = msg[SO_NICK];
    //    const cmd = msg[OPERATION];
    //    const locationCmd = msg[LOCATION_OPERATION];
    //    const locationValue = msg[LOCATION_VALUE];

    //    console.log("resp location = ", locationValue)
    
    //    const platformStoreUpdate = {
    //      scope: "so-core",
    //      so: so,
    //      cmd: cmd,
    //      operation: locationCmd,
    //      name: locationValue
    //    };
    //    return [platformStoreUpdate];
  
  
  
    const SO_NICK = 1;
    const CMD = 2;
    const CMD_OPERATION = 3;
    const soName = msg[SO_NICK];
    const cmd = msg[CMD];
    const operation = msg[CMD_OPERATION];

    const length = msg.length;
    const numberValues = length - 4;
    
    let value = "";
    let temp = ""
    for (let i=0; i < numberValues;i++) {
      temp = msg[4+i].replace(",", "")
      msg[4+i] = temp
    }
    
    for (let i = 0; i < numberValues; i++) {
      value = value + msg[4 + i] + " ";
    }

    const platformStoreUpdate = {
      scope: "so-core",
      so: soName,
      cmd: cmd,
      operation: operation,
      //name: msg[4]
      name: value
    };
    return [platformStoreUpdate];
  
  
  }

  _parseActualMessage(msg) {
    const SO_NICK = 1;
    const OPERATION = 2;
    const ACTUAL_OPERATION = 3; // = set
    const ACTUAL_VALUE = 4;

    const so = msg[SO_NICK];
    const cmd = msg[OPERATION];
    const actualCmd = msg[ACTUAL_OPERATION];
    const actualValue = msg[ACTUAL_VALUE].replace(",","");

    // Message Format: resp "SOnick" owner set "ownerSOnick"
    const platformStoreUpdate = {
      scope: "location",
      so: so,
      cmd: cmd,
      operation: actualCmd,
      name: actualValue
    };

    return [platformStoreUpdate];
  }

  _parseStateMessage(msg) {
    // Message Format: resp "SOnick" state set <state>
    const platformStoreUpdate = {
      scope: "so-core",
      so: msg[1],
      cmd: msg[2],
      operation: msg[3],
      name: msg[4]
    };

    return [platformStoreUpdate];
  }

  _parseSOTypeMessage(msg) {
    // Message Format: resp "SOnick" type set <type>
    const platformStoreUpdate = {
      scope: "so-core",
      so: msg[1],
      cmd: msg[2],
      operation: msg[3],
      name: msg[4]
    };

    return [platformStoreUpdate];
  }

  _parseCallRespMessage(msg) {
  
    const SO_NICK = 1; // SO name
    const MESSAGE_SCOPE = 2; // method related response
    const METHOD_NAME = 3; // method operation: source, loaded

    if (msg[4] === "returned") {
      const msgWordCount = msg.length;
      const wordsInStatusMsg = msgWordCount - 5;
      let statusMsg = "";
      for (let i = 0; i < wordsInStatusMsg; i++) {
        statusMsg = statusMsg + msg[5 + i] + " ";
      }
      const platformStoreUpdate = {
        scope: "resp_call_method",
        cmd: msg[MESSAGE_SCOPE],
        //cmdOperation: msg[OPERATION],
        so: msg[SO_NICK],
        name: msg[METHOD_NAME],
        status: statusMsg
      };
      return [platformStoreUpdate];
    } 

  }

  _parseAttributeRespMessage(msg) {
    let platformStoreUpdate = "";
    
    const length = msg.length;
    const numberValues = length - 5;
    let value = "";
    for (let i = 0; i < numberValues; i++) {
      value = value + msg[5 + i] + " ";
    }
    value.trim();

    platformStoreUpdate = {
      scope: "so-core",
      so: msg[1],
      cmd: msg[2],
      operation: msg[3],
      attribName: msg[4],
      value: value
    };

    return [platformStoreUpdate];
  }

}

const responseMessage = new ResponseMessage();
export default responseMessage;
