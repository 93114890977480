import React from "react";
import websocketCom from "./../../../../webcom/WebsocketCom";

import Button from '@material-ui/core/Button';
import fS from "../../../../stores/FacilitiesStore";

class FacPanel extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this._click_selectFac = this._click_selectFac.bind(this);
        this._click_removeFac = this._click_removeFac.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _click_selectFac() {
        this.props.cb_facSelect(this.props.data.get("name"))
    }

    _click_removeFac(event) {
        event.stopPropagation();
        if (this.props.data.get("name") !== "genesis") {
            this.props.cb_facSelect("genesis")
            const msg = "facility delete";
            const facUuid = fS.getFacilityUuidByName(this.props.data.get("name"));
            websocketCom.sendOutOfBandMessage(msg, facUuid);
        }
    }

    render() {
        const data = this.props.data
        let numberDevices = 0;
        if (data.has("devices")) { numberDevices = data.get("devices").size; }
        const autodiscovery = data.get("autodiscovery") ? "Autodiscovery enabled" : "Autodiscovery not enabled";
        const name_col = this.props.facSelected === data.get("name") ? "blue" : "Black"
        const bg_col = this.props.facSelected === data.get("name") ? "rgba(0,0,255, 0.04)" : "White"

        let removeButton = (
            <div>
                <Button size="small" color="secondary"
                        style={{marginTop: 25, maxHeight: 30, marginRight: 20}}
                        onClick={(event) => this._click_removeFac(event) } >
                    Delete
                </Button>
            </div>
        )
        if (data.get("name") === "genesis") { removeButton = <div /> }


        const facPanel = (

            <div style={{backgroundColor: bg_col}} onClick={() => this._click_selectFac() } >

                <div style={{display: "flex"}} >
                <div>
                    <div style={{fontFamily: "Roboto", fontSize: 17, fontWeight: "bold"}} >
                        <div style={{height: 10}} />
                        <div style={{paddingLeft: 20, color: name_col}} > {data.get("name")}</div>
                    </div>

                    <div style={{display: "flex"}} >
                        <div style={{width: 20}} />
                        <div style={{paddingLeft: 0, fontWeight: "bold"}}>Status:</div>
                        <div style={{width: 5}} />
                        <div> {numberDevices} devices assigned </div>
                        <div style={{width: 20}} />
                        <div> {autodiscovery} </div>
                    </div>
                    <div style={{display: "flex"}} >
                        <div style={{width: 20}} />
                        <div style={{paddingLeft: 0, fontWeight: "bold"}}>Identifier:</div>
                        <div style={{width: 5}} />
                        <div>{data.get("uuid")}</div>
                    </div>

                </div>
                <div style={{flexGrow: 1 }} />
                    {removeButton}
                </div>
                <div style={{height: 10}} />
                <div style={{borderTop: "1px solid Gray", width: 100+"%"}} />
            </div> )

        return facPanel;
    }

}
export default FacPanel;
