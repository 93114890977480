import React from "react";
import AceEditor from "react-ace";
import aSDS from "./../../stores/ActiveServiceDataStore";
import mM from "./MethodManager";

import ErrorIcon from '@material-ui/icons/SyncProblem';
import DoneIcon from '@material-ui/icons/Done';
import EmptyMethodIcon from '@material-ui/icons/ErrorOutline';
import MethodDirtyIcon from '@material-ui/icons/SyncDisabled';
import SuccessPushIcon from '@material-ui/icons/Sync';
import Button from '@material-ui/core/Button';
import config from "./../config";
import 'ace-builds/webpack-resolver';
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-min-noconflict/theme-github";
import 'ace-builds/src-min-noconflict/ext-searchbox';


import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2
  },
  root: {
    background: theme.palette.info.main
  },
  doneIcon: { fontSize: 25, marginRight: 15 },
  message: {
    display: "flex",
    alignItems: "center",
    fontSize: 15,
    fontWeight: "bold"
  }
});



class Editor extends React.Component {

  constructor(props) {
    super(props);

    this.btnText="";

    this.state = {fontSize: 14, 
      hoverPull: false, hoverPush: false, hoverRun: false, 
      btnMsgOpen: false}

    this._onEditorChange = this._onEditorChange.bind(this);
    this._mouseOverButton = this._mouseOverButton.bind(this);
    this._mouseLeaveButton = this._mouseLeaveButton.bind(this);
    this._increaseFontSize = this._increaseFontSize.bind(this);
    this._decreaseFontSize = this._decreaseFontSize.bind(this);
  }


  _onEditorChange(newValue) {
    this.props.codeCallback(newValue);
console.log("change")
  }

  _pushCode() { mM.pushCode(); this.btnText="Push code to platform"; this.setState({ btnMsgOpen: true }); }
  _pullCode() { mM.pullCode(); this.btnText="Get code from platform"; this.setState({ btnMsgOpen: true }); }
  _runMethod() { mM.runMethod(); this.btnText="Execute method on platform"; this.setState({ btnMsgOpen: true }); }

handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ btnMsgOpen: false });
  };


  _mouseOverButton(button) {
    switch (button) {
      case "push":
        this.setState({hoverPush: true});
        break;
      case "pull":
        this.setState({hoverPull: true});
        break;
      case "run":
        this.setState({hoverRun: true});
        break;
      default:
        break;
    }
  }
  _mouseLeaveButton(button) {
    switch (button) {
      case "push":
        this.setState({hoverPush: false});
        break;
      case "pull":
        this.setState({hoverPull: false});
        break;
      case "run":
        this.setState({hoverRun: false});
        break;
      default:
        break;
    }
  }

  _increaseFontSize() {
    this.setState({ fontSize: this.state.fontSize + 1 })
  }
  _decreaseFontSize() {
    this.setState({ fontSize: this.state.fontSize - 1 })
  }

  render() {

    const { classes } = this.props;


    let isSyncEnable = true;
    let enableRunButton = false;
    let statusText = "";
    let statusIcon = <div />

    if (this.props.methodName === "") { isSyncEnable = false; enableRunButton = false; }
    else {
      const soName = this.props.methodName.split(" ")[0]
      const methodName = this.props.methodName.split(" ")[1]
      if (! aSDS.checkSoExist(soName)) {return <div />}
      const soData = aSDS.getSmartObjectData(soName);
      if (soData === null) {return <div />}
      if (! aSDS.checkSmartObjectMethodExist(soName, methodName)) {return <div />}
      const methodStatus = soData.get("methods").get(methodName).get("status");
      const methodResp = soData.get("methods").get(methodName).get("resp");
      const codeChanged = mM.checkChanged(soName, methodName)

      //soData.get("methods").get(methodName).get("codeChanged");
      if (methodStatus === "ERROR" && !codeChanged) {
        if (methodResp === "") {statusText = "ERROR in method"}
        else {statusText = methodResp}
        statusIcon = <ErrorIcon style={{color: "Red", fontSize:18, marginTop: 4}} />
      } else if (methodStatus === "OK" && !codeChanged ) {
        enableRunButton=true;
        statusText = "Method in sync with platform";
        statusIcon = <SuccessPushIcon style={{color: "DimGray", fontSize:18, marginTop: 4}} />
      } else if (methodStatus === "NIL" ) {
        statusText = "Method empty";
        statusIcon = <EmptyMethodIcon style={{color: "Orange", fontSize:18, marginTop: 4}} />
      } else {
        statusText = "Method dirty";
        statusIcon = <MethodDirtyIcon style={{color: "DimGray", fontSize:18, marginTop: 4}} />
      }

    }

    



    return (

         <div style={{border: "2px solid Gray" }} >

           <AceEditor
              mode="lua" theme="tomorrow"
              width={String(this.props.width-15)+"px"}
              height={String(window.innerHeight-config.setup.layout.headerPanelHeight-config.setup.layout.statusPanelHeight-105-this.props.offset)+"px"}
              fontSize={this.state.fontSize}
              showPrintMargin={false}
              onChange={this._onEditorChange.bind(this)}
              name="div-editor"
              value={this.props.code}
              readOnly={this.props.readOnly}
              placeholder=""
              tabSize={2}
              editorProps={{$blockScrolling: true}}

              setOptions={{ enableBasicAutocompletion: false, enableLiveAutocompletion: true}}

              commands={[
                {
                  name: 'font+',
                  bindKey: {win: 'Ctrl-]', mac: 'Command-]'},
                  exec: () => { this._increaseFontSize() }
                },
                {
                  name: 'font-',
                  bindKey: {win: 'Ctrl-[', mac: 'Command-['},
                  exec: () => { this._decreaseFontSize() }
                },
                 {
                  name: 'getcode',
                  bindKey: {win: 'Ctrl-g', mac: 'Command-g'},
                  exec: () => { this._pullCode() }
                },

              ]}

           />







           <div style={{display: "flex", flexDirection: "row", alignItems: "center", height: 26, borderTop: "1px solid LightGray"}} >
             <div style={{ fontSize: 11, color: "Gray", paddingLeft: 10, paddingRight: 5
                          }} >
             {statusIcon}
             </div>

             <div style={{fontFamily: "Roboto", fontSize: 13, fontWeight: "bold", color: "Gray", paddingTop: 2, paddingRight: 5,
             userSelect: "none" }} >
               {statusText}
            
             </div>
             <div style={{flexGrow: 1}} />
             <div>
               

                <Button style={{height:19, marginTop: 0, marginRight: 10, fontSize: 12}}
                        variant="outlined" 
                        color="primary"
                        disabled={! isSyncEnable}
                        onClick={ ()=>this._pushCode() }
                        onMouseOver={ () => {this._mouseOverButton("push")} }
                        onMouseLeave={ () => {this._mouseLeaveButton("push")} }
                >
                  Push
                </Button>




             </div>

             <div>
               


                <Button variant="outlined" style={{display: "none", height:19, marginRight: 15, fontSize: 12}}
                       disabled={! isSyncEnable }
                       onClick={ ()=>this._pullCode() }
                       onMouseOver={ () => {this._mouseOverButton("pull")} }
                       onMouseLeave={ () => {this._mouseLeaveButton("pull")} }
                >
                  Pull
                </Button>


             </div>




              <div>
               

                <Button style={{height:19, marginTop: 0, marginRight: 15, fontSize: 12}}
                       variant="outlined" 
                       color="primary"
                       disabled={! enableRunButton }
                       onClick={ ()=>this._runMethod() }
                       onMouseOver={ () => {this._mouseOverButton("run")} }
                       onMouseLeave={ () => {this._mouseLeaveButton("run")} }
                >
                  Run
                </Button>


             </div>





           </div>



           <Snackbar  
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={this.state.btnMsgOpen}
          autoHideDuration={1500}
          onClose={this.handleClose}
          ContentProps={{
            classes: { root: classes.root },
            "aria-describedby": "message-id"
          }}
          
          message={
            <span id="message-id" className={classes.message}>
              <DoneIcon style={{paddingRight: 15}} />
              
              
              {this.btnText}
                          </span>
          }
          
        />

         </div> )
  }

}

export default withStyles(styles)(Editor);
