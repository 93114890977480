import React from "react";

import sessionManager from "./../../ServiceManagement/SessionManager";
import sessionManagementStore from "./../../stores/SessionManagementStore";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from '@material-ui/core/InputAdornment';

import { Paper } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import cenoIoTPlatformLogo from "../../images/CenoIoTLogo.jpg";
import background from "../../images/login_background.jpg";

import AccountIdentifier from '@material-ui/icons/Cloud';
import AccountCircle from '@material-ui/icons/Person';
import AccountPassword from '@material-ui/icons/Lock';

class WelcomePage extends React.Component {
  //_isMounted = false;
  //isLoginInProgress = false;

  constructor(props, context) {
    super(props, context);
    this.version = "0.52.3";
    this.isLoginInProgress = false;
    this.state = {
      platformUrl: "",
      userName: "",
      password: "",
      platformErrDialogOpen: false,
      loginErrDialogOpen: false,
      authUnreachableErrDialogOpen: false,
      showCredits: false,
      noPlatformUrlError: false
    };

    this._handlePlatformUrlChange = this._handlePlatformUrlChange.bind(this);
    this._handleUserNameChange = this._handleUserNameChange.bind(this);
    this._handlePasswordChange = this._handlePasswordChange.bind(this);
    this._processLoginRequest = this._processLoginRequest.bind(this);
    this._sessionEstablished = this._sessionEstablished.bind(this);
    this._sessionEstablishment_Failed = this._sessionEstablishment_Failed.bind(
      this
    );
    this._showCredits = this._showCredits.bind(this);
  }

  componentDidMount() {
    sessionManagementStore.on(
      "ChangeEvent_SessionEstablished",
      this._sessionEstablished
    );
    sessionManagementStore.on(
      "ChangeEvent_SessionEstablishment_Failed",
      this._sessionEstablishment_Failed
    );
  }

  componentWillUnmount() {
    sessionManagementStore.removeListener(
      "ChangeEvent_SessionEstablished",
      this._sessionEstablished
    );
    sessionManagementStore.removeListener(
      "ChangeEvent_SessionEstablishment_Failed",
      this._sessionEstablishment_Failed
    );
  }

  _sessionEstablished() {
    this.isLoginInProgress = false;
    this.props.history.push("/main");
  }

  _sessionEstablishment_Failed() {
    this.isLoginInProgress = false;
    //console.log("error");
    this.setState({ platformErrDialogOpen: true });
    //this.displayPlatformErrorPanel();
  }

  _handlePlatformUrlChange(event) {
    this.setState({
      platformUrl: event.target.value
    });
  }
  _handleUserNameChange(event) {
    this.setState({
      userName: event.target.value
    });
  }
  _handlePasswordChange(event) {
    this.setState({
      password: event.target.value
    });
  }
  _processLoginRequest() {
    // do not try new login if last login request is still ongoing...
    if (this.isLoginInProgress) {
      return;
    }

    // do not process login request if no platforUrl is given
    if (this.state.platformUrl === "") {
      this.setState({ noPlatformUrlError: true });
      return;
    }

    const response = sessionManager.prepareUserSession(
      {
        login: this.state.userName,
        password: this.state.password
      },
      this.state.platformUrl
    );
    if (response["status"]) {
      this.isLoginInProgress = true;
    } else {
      if (response.msg === "authentication failed")
        this.setState({ loginErrDialogOpen: true, userName: "", password: "" });
      if (response.msg === "Could not connect to authentication authority.")
        this.setState({ authUnreachableErrDialogOpen: true });
    }
  }

  _handleClose = () => {
    this.setState({
      platformErrDialogOpen: false,
      loginErrDialogOpen: false,
      authUnreachableErrDialogOpen: false,
      showCredits: false,
      noPlatformUrlError: false
    });
  };

  _showCredits() {
    //console.log("credits");
    this.setState({
      showCredits: true
    });
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.platformErrDialogOpen}
          onClose={this._handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"ERROR"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Could not connect to your CenoLabs IoT platform. Please wait some
              time and try again. If the issue remains get into contact with
              CenoLabs support.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.loginErrDialogOpen}
          onClose={this._handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"ERROR"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Given username does not exist or wrong password entered.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.authUnreachableErrDialogOpen}
          onClose={this._handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"ERROR"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Could not connect to authentication authority.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.noPlatformUrlError}
          onClose={this._handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"ERROR"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              No Management Node defined.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showCredits}
          onClose={this._handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Credits"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              TBD. Show credits here...
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <div className="Welcome-page">
          <Paper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "no",
                height: 100 + "vh",
                backgroundImage: "url(" + background + ")",
                backgroundSize: "100% 100%"
              }}
            >
              <div style={{ minHeight: 20 + "vh" }} />

              <div id = "backgroundInput"
                style={{
                  backgroundColor: "white",
                  textAlign: "center",
                  margin: "auto",
                  minWidth: 450,
                  maxWidth: 450,
                  paddingTop: 20,
                  paddingBottom: 20,
                  borderColor: "#424242",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8
                }}
              >

              <div style={{textAlign: "center"}}>
              <Typography variant="h5"  style={{ color: "#424242", fontWeight: "fontWeightBold"}}>
                LOGIN
              </Typography>
              </div>

              <div style={{ minHeight: 15 }} />

              <div
                style={{
                  margin: "auto",
                  minWidth: 400 + "px",
                  width: 400 + "px",
                }}
              >
                <TextField
                  value={this.state.platformUrl}
                  onChange={this._handlePlatformUrlChange}
                  style={{ minWidth: 100 + "%" }}
                  id="filled-password-input"
                  label="Your Platform Identifier"
                  autoComplete="current-password"
                  margin="dense"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                    <InputAdornment position="start" >
                      <AccountIdentifier />
                    </InputAdornment>
                    ),
                  }}
                />
                <div style={{height: 5}} />
                <TextField
                  value={this.state.userName}
                  onChange={this._handleUserNameChange}
                  style={{ minWidth: 100 + "%" }}
                  id="filled-password-input"
                  label="Username"
                  autoComplete="current-password"
                  margin="dense"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                    ),
                  }}
                />
                <div style={{height: 5}} />
                <TextField
                  value={this.state.password}
                  onChange={this._handlePasswordChange}
                  style={{ minWidth: 100 + "%" }}
                  id="filled-password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  margin="dense"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                      <AccountPassword />
                    </InputAdornment>
                    ),
                  }}
                />
              </div>

              </div>

              <div id = "backgroundInput"
                style={{
                  backgroundColor: "#595857",
                  textAlign: "center",
                  margin: "auto",
                  minWidth: 450,
                  maxWidth: 450,
                  paddingTop: 15,
                  paddingBottom: 15,
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8
                }}
              >

              <div
                id="buttons"
                style={{ textAlign: "center", paddingTop: 7 + "px" }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={this._processLoginRequest}
                  style={{minWidth: 400, backgroundColor: "#f57c00"}}
                >
                  LOGIN
                </Button>
              </div>
              <div style={{textAlign: "center", color: "white", paddingTop: 10 }}>

                <Typography variant="body2">
                  Forgot password?
                </Typography>
              </div>

              </div>


              <div style={{ flexGrow: 1 }} />

              <div>


                <div style={{margin: "auto", textAlign: "center",  backgroundColor: "white", paddingTop: 10}}>
                  <img src={cenoIoTPlatformLogo} alt="CenoLabs IoT Platform" width="130" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "no",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "start",
                    paddingTop: 3,
                    paddingBottom: 5,
                    paddingLeft: 30,
                    paddingRight: 30,
                    backgroundColor: "white"
                  }}
                >
                  <div>
                    <Typography variant="body2" style={{ color: "#424242" }}>
                      ceno_dev {this.version} - cloud
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body2" style={{ color: "#424242" }}>
                      © 2021 CenoLabs GmbH  -  All Rights Reserved
                    </Typography>
                  </div>
                  <div>
                    <Tooltip
                      title="Click to show credits"
                      aria-label="Add"
                      placement="top-start"
                    >
                      <div onClick={this._showCredits}>
                        <Typography
                          variant="body2"
                          style={{ color: "#424242" }}
                        >
                          Credits
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default WelcomePage;
