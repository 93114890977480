import React from "react";
import websocketCom from "./../../webcom/WebsocketCom";
import Popup from "./Popup";
import pM from "./PopupManager";
import selectionManager from "./../SelectionManager/SelectionManager";
import aSDS from "./../../stores/ActiveServiceDataStore";
import gLS from "./../../stores/GraphLayoutStore";
import MenuEntry from "./MenuEntry";

class ContextPort  extends React.Component {

  constructor() {
    super();
    // set size for this configuration panel
    this.width = 185; this.height = 163;

    this.selectView = this.selectView.bind(this);
  }



  selectView(view) {
    const id = "port "+this.props.soName+" "+this.props.port;
    let msg = ""; let graphLayout=null; let soLayout=null; let scaleFactor=null; let location=null;

    switch (view) {

      case "showportdetails":
        pM.deletePopup(this.props.id);
        gLS.setView(this.props.soName, "large");
        selectionManager.addPortViewSelection(id);
        gLS.redraw();
        break;

      case "hideportdetails":
        pM.deletePopup(this.props.id);
        selectionManager.deletePortViewSelection(id);
        gLS.redraw();
        break;

      case "setportconstrained":
        pM.deletePopup(this.props.id);
        msg = this.props.soName+" setcon "+this.props.port+" empty";
        websocketCom.sendServiceMessage(msg);
        break;

      case "setportopen":
        pM.deletePopup(this.props.id);
        msg = this.props.soName+" setcon "+this.props.port+" nil";
        websocketCom.sendServiceMessage(msg);
        break;

      case "addinlet":
        pM.deletePopup(this.props.id);
        graphLayout = gLS.getGraphLayout();
        soLayout = graphLayout[this.props.soName];
        scaleFactor = this.props.location.scaleFactor;
        location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                    "y": (soLayout.graph_data.y),
                    "scaleFactor": scaleFactor }
        pM.addPopup(this.props.soName, this.props.port, location, "addinlet", "MediumSeaGreen");
        break;

      case "addoutlet":
        pM.deletePopup(this.props.id);
        graphLayout = gLS.getGraphLayout();
        soLayout = graphLayout[this.props.soName];
        scaleFactor = this.props.location.scaleFactor;
        location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
                    "y": (soLayout.graph_data.y),
                    "scaleFactor": scaleFactor }
        pM.addPopup(this.props.soName, this.props.port, location, "addoutlet", "MediumSeaGreen");
        break;

      case "delete":
        pM.deletePopup(this.props.id);
        selectionManager.delete_Id(id)
        msg = this.props.soName + " delport " + this.props.port;
        websocketCom.sendServiceMessage(msg);
        break;

        case "stats":
        pM.deletePopup(this.props.id);
        graphLayout = gLS.getGraphLayout();
        soLayout = graphLayout[this.props.soName];
        scaleFactor = this.props.location.scaleFactor;
        location = {"x": (soLayout.graph_data.x+soLayout.graph_data.width+30),
          "y": (soLayout.graph_data.y),
          "scaleFactor": scaleFactor }
        pM.addPopup(this.props.soName, this.props.port, location, "portstats");
        break;

      default:
        break;
    }

  }


  _buildPortContextMenu() {

    const portDetailView = selectionManager.getPortViewSelection(this.props.soName);
    let showEntry = <div />
    if (portDetailView === this.props.port) {
       showEntry = <MenuEntry iconName="hideportdetails" text="Hide Details" callback={this.selectView} />
    } else {
       showEntry = <MenuEntry iconName="showportdetails" text="Show Details" callback={this.selectView} />
    }

    const soData = aSDS.getSmartObjectData(this.props.soName);
    const portStatus = soData.get("ports").get(this.props.port).get("connectstatus");
    let statusEntry = <div />
    if (portStatus === "nil") {
       statusEntry = <MenuEntry iconName="setportconstrained" text="Set Constrained" callback={this.selectView} />
    } else {
       statusEntry = <MenuEntry iconName="setportopen" text="Set Open" callback={this.selectView} />
    }

    const panel = (
      <div style={{cursor: "default"}} >
        {showEntry}
        {statusEntry}
       <MenuEntry iconName="addinlet" text="Add Inlet..." callback={this.selectView} />
       <MenuEntry iconName="addoutlet" text="Add Outlet..." callback={this.selectView} />
       <MenuEntry iconName="stats" text="Statistics..." callback={this.selectView} />
       <div style={{minHeight: 1, maxHeight: 1, marginTop: 2, marginBottom: 2, marginRight: 0,  backgroundColor: "gray"}} />
       <MenuEntry iconName="delete" text={"Delete "+this.props.port} callback={this.selectView} />
      </div>

    )
    return panel
  }


  render() {

    // Content of panel is defined here
    const view = (<div key={"content-context-port-"+this.props.soName}> {this._buildPortContextMenu()} </div>)

    let  geometry = this.props.location;
    //const scaleFactor = this.props.location.scaleFactor;
    geometry["width"] = this.width; geometry["height"] = this.height;

    const panel = ( <Popup geometry={geometry} content={view}
                           id={this.props.id} elementClass={this.props.elementClass}
    delCallback={this.props.delCallback} /> )
    return panel
  }

}

export default ContextPort;

