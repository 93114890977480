import React from "react";
import websocketCom from "./../../webcom/WebsocketCom.js";
import aSDS from "./../../stores/ActiveServiceDataStore";
import aSMS from "./../../stores/ActiveServiceMgmtStore";
import fS from "./../../stores/FacilitiesStore";

import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import LensIcon from "@material-ui/icons/Lens";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineIcon from "@material-ui/icons/CheckBoxOutlineBlank";
//import DeviceIcon from '@material-ui/icons/Adjust';
import ClusterIcon from '@material-ui/icons/DeviceHub';
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";


const styles = theme => ({
  search: {
    position: "relative",
    borderStyle: "solid",
    borderWidth: 2 + "px",
    borderColor: "#eeeeee",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.55),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.8)
    },
    marginLeft: 25,
    marginRight: 25,
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 6,
    height: "90%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingLeft: theme.spacing.unit * 6,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%"
      }
    }
  },
  button: {
    margin: theme.spacing(1)
  },

  DeepOrange: { backgroundColor: "#ff7043" },
  LightGreen: { backgroundColor: "#9ccc65" },
  DeepPurple: { backgroundColor: "#7e57c2" },
  LightBlue: { backgroundColor: "#29b6f6" }

  //isActive: { color: "gray" },
  //notActive: { color: "black" }
});

class SoMapping extends React.Component {
  _isMounted = false;
  _facInfoTimer = null;
  _FAC_UPDATE_INTERVAL = 5000;

  constructor() {
    super();

    const SOfacName = aSMS.getFacility();
    const clearNameFacList = fS.getFacilitiesByName();
    this.facUuid = clearNameFacList[SOfacName];

    this.state = {
      //soName: "",
      soData: null,
      soHost: null,
      devices: new Map(),
      locationList: {},
    };

    this._changeEventFromDataStore = this._changeEventFromDataStore.bind(this);
    this._changeEventFromFacilitiesStore = this._changeEventFromFacilitiesStore.bind(this);

    this._requestFacUpdate = this._requestFacUpdate.bind(this);
    this._requestServingHostId = this._requestServingHostId.bind(this);
    this._locationChange = this._locationChange.bind(this);
    this._processChangeButtonPressed = this._processChangeButtonPressed.bind(
      this
    );
  }

  componentDidMount() {
    this._isMounted = true;

    fS.on("ChangeEvent_FacilityDbUpdate", this._changeEventFromFacilitiesStore);
    aSDS.on("change", this._changeEventFromDataStore);

    const soData = aSDS.getSmartObjectData(this.props.soName);
    this.setState({ soData: soData });
    this._changeEventFromFacilitiesStore();
    this._requestServingHostId();


    this._requestFacUpdate();
    this._facInfoTimer = window.setInterval(
      this._requestFacUpdate,
      this._FAC_UPDATE_INTERVAL
    );
  }

  componentWillUnmount() {
    this._isMounted = false;

    clearInterval(this._facInfoTimer);

    fS.removeListener( "ChangeEvent_FacilityDbUpdate", this._changeEventFromFacilitiesStore );
    aSDS.removeListener("change", this._changeEventFromDataStore);
  }

  _changeEventFromDataStore() {
    if (this._isMounted  && this.props.soName !== "") {
      const soData = aSDS.getSmartObjectData(this.props.soName);
      const location = soData
        .get("info")
        .get("host")
        .get("locations");
      let tempList = {};
      location.forEach(function(deviceUuid, index) {
        tempList[deviceUuid] = "";
      });

      this.setState({ soData: soData,
                      soHost: soData.get("info").get("host").get("id"),
                      locationList: tempList });
    }
  }

  _changeEventFromFacilitiesStore() {
    if (this._isMounted) {
      if (this.facUuid) {
        const devices = fS.getDevices(this.facUuid);
        //        console.log("devices =", devices);
        this.setState({ devices: devices });
      }
    }
  }



  _requestServingHostId() {
    if (this.props.soName !== "") {
      const msg = this.props.soName + " getactual";
      websocketCom.sendServiceMessage(msg);
    }
  }

  _requestFacUpdate() {
    const fac = this.facUuid;

    if (fac !== "") {
      let msg = "facility devicetable";
      websocketCom.sendOutOfBandMessage(msg, fac);
      //      console.log(msg);
      msg = "facility activetable";
      websocketCom.sendOutOfBandMessage(msg, fac);
      //      console.log(msg);
      // get update on host mapping
      // needs to be enabled if core does not indecate relocation of so
      this._requestServingHostId();
    }
  }


  _processChangeButtonPressed() {
    let msg = this.props.soName + " setlocation " + this.state.soHost;
    websocketCom.sendServiceMessage(msg);
    //    console.log("in processChangeButtonPressed", msg);
  }

  _locationChange(deviceUuid) {
    let tempLocationList = this.state.locationList;
    //    console.log("tempLocationList =", tempLocationList);
    if (deviceUuid in tempLocationList) {
      delete tempLocationList[deviceUuid];
    } else {
      tempLocationList[deviceUuid] = "";
    }

    let msg = this.props.soName + " setlocation ";
    for (const key in tempLocationList) {
      msg = msg + key + " ";
    }
    websocketCom.sendServiceMessage(msg);
    //    console.log("_locationChange:", msg);
  }


  _renderIsSoHostBadge(isExecutingDevice) {
    let view = <div />;
    if (isExecutingDevice) {
      view = (
        <div style={{color: "Blue",marginTop: -2}} >
          <LensIcon style={{ fontSize: 12 }} />
        </div>
      );
    }
    return view;
  }

  _renderIsSelectedBadge(isActive, details) {
    let view = <div />;

    if (isActive === "false") {
      view = (
        <div style={{color: "#9e9e9e", fontFamily: "Roboto", fontSize: 12, marginTop: -4, fontWeight: "normal"}} >
          n/a
        </div>
      )
      return view;
    }

    let isSelected = false;
    const deviceUuid = details.get("uuid");
    if (deviceUuid in this.state.locationList) isSelected = true;

    if (isSelected) {
      view = (
        <div style={{color: "Blue"}} >
        <CheckBoxIcon
          style={{ fontSize: 18 }}
          onClick={() => {
            this._locationChange(deviceUuid);
          }}
        />
        </div>
      );
    } else {
      view = (
        <div style={{color: "DimGray"}} >
        <CheckBoxOutlineIcon
          style={{ fontSize: 18 }}
          onClick={() => {
            this._locationChange(deviceUuid);
          }}
        />
        </div>
      );
    }

    return view;
  }

  _renderDeviceDescription(details) {
  //  const { classes } = this.props;

//    const colorDef = {
//      DeepOrange: classes.DeepOrange,
//      LightGreen: classes.LightGreen,
//      DeepPurple: classes.DeepPurple,
//      LightBlue: classes.LightBlue
//    };
    //const bgStyle = colorDef[details.get("color")];
    // render inactive hosts with light fontweight
    //let fontWeight = "fontWeightLight";
    let deviceIsActive = (details.get("active") === "true")
    //if (details.get("active") === "true") {
    //let icon_col = "#9e9e9e";
    if (deviceIsActive) {
    // render active hosts with regular fontweight
      //fontWeight = "fontWeightRegular";
      //icon_col = "DimGray"
      //if (details.get("uuid") in this.state.locationList) {
        // render active hosts which are part of so location with regular fontweight
        //fontWeight = "fontWeightBold";
      //}
    }
    let view = <div />;
    view = (
      <div
        style={{
          display: "flex",
          flexDirextion: "row",
          alignItems: "center"
        }}
      >
        <div style={{ flexGrow: 1, marginLeft: 15 }}>
          <div
            style={{
              display: "flex",
              flexDirextion: "row",
              alignItems: "center"
            }}
        >
            <div style={{marginTop: -3}}>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: 200}} >

                <div style={{display: "flex", alignItems: "center"}} >

                  <div style={{fontStyle: "Roboto", fontSize: 15, fontWeight:"bold"}} >
                    {details.get("name")}
                  </div>
                </div>

                <div style={{flexGrow: 1}} />

                <div style={{display: "flex", alignItems: "center"}} >
                  <ClusterIcon style={{width: 13, height: 13, marginTop: -2, marginLeft: 5, marginRight: 4, color: "Gray"}} />
                  <div style={{fontStyle: "Roboto", fontSize: 14, fontWeight:"regular", color: "Gray"}} >
                    {details.get("cluster")[0]}
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    );

    // render inactive hosts in lighter shade of gray
    if (details.get("active") === "false")
      view = <div style={{ color: "#9e9e9e" }}> {view} </div>;
    return view;
  }

  _renderEmptyLocationList() {
//    const { classes } = this.props;
    return (
      <div style={{ textAlign: "center", fontFamily: "Roboto", fontSize: 12, fontWeight: "normal" }}>
        <AllInclusiveIcon
          style={{ fontSize: 70, marginTop: 0, marginBottom: -20 }}
        />
        <p>Free floating SmartObject.</p>
        <button style={{marginTop: 25, fontStyle: "Roboto", fontSize: 12}} onClick={()=>this._processChangeButtonPressed()} >
        Change
        </button>
      </div>
    );
  }

  _buildHeadline() {
    return (
          <div id="headline" style={{ paddingBottom: 15, paddingLeft: 15 }} >
          <div id="type">
              <div style={{display: "flex", fontFamily: "Roboto", fontSize: 13, color: "DimGray", paddingTop: 3}} >
                <div style={{width: 9, height: 9, backgroundColor: "DimGray", marginTop: 4, marginRight: 4}} />
                <div> {this.props.soName}</div>
              </div>
          </div>
          <div id="text"
              style={{ fontSize: 16, fontWeight: "bold", textAlign: "left", paddingTop: 2}} >
              Set Mapping
          </div>
        </div>)
  }

  render() {
//    const { classes } = this.props;

    if (!this.state.soData) {
      return (
        <div>
          {this._buildHeadline()}
          <div style={{display: "flex", justifyContent: "center", backgroundColor: "White", height: 100+"%", width: 100+"%"}} >
            <NotInterestedIcon style={{fontSize: 120, color: "WhiteSmoke", marginTop: 40}} />
          </div>

        </div> )
    }

    if ("freefloat" in this.state.locationList) {
      return (
        <div>
          {this._buildHeadline()}
          {this._renderEmptyLocationList()}
        </div> )
    }

    let isSoHost = false;
    let isActive = false;
    let deviceContainer = [];

    for (let [deviceUuid, details] of this.state.devices) {

      // if cluster information is not available we need to fetch it from the core.
      if (details.get("cluster").length === 0) {
        const msg = deviceUuid+" getcluster";
        websocketCom.sendOutOfBandMessage(msg, this.facUuid);
      }

      isSoHost = false;
      if (deviceUuid === this.state.soHost) {
        isSoHost = true;
      }
      isActive = details.get("active");

      const entry = (
        <div key={deviceUuid} style={{display: "flex", alignItems: "center", paddingBottom: 5}} >
          <div style={{minWidth: 30, marginLeft: 15}}>
            {this._renderIsSoHostBadge(isSoHost)}
          </div>
          <div style={{minWidth: 20}}>
            {this._renderIsSelectedBadge(isActive, details)}
          </div>
          <div style={{flexGrow: 1}}>
            {this._renderDeviceDescription(details)}
          </div>
        </div>
      )

      //const showDevice = this._applyFilter(details.get("name"));
      const showDevice = details.get("name");
      if (showDevice) {
        if (isActive === "true") deviceContainer.unshift(entry);
        else deviceContainer.push(entry);
      }
    }

    const view = (
      <div style={{width: this.props.width}} >
        {this._buildHeadline()}
        <div style={{height: this.props.height-65, backgroundColor: "white", overflowY: "auto" }} >
          {deviceContainer}
        </div>
      </div>
    );
    return view;
  }
}

export default withStyles(styles)(SoMapping);
